import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton } from '@material-ui/core';
import { TextField } from 'components/Form/TextField';
import { QueryState } from 'components/QueryState';
import { StyledLink } from 'components/design-system/Link';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  BeneficiariesStepQuery,
  Beneficiary,
  BeneficiaryRelationship,
  Title,
  useBeneficiariesStepQuery,
  useUpdateUserProfileMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { isNumberInputValid } from 'helpers/inputHelpers';
import { trackGa } from 'helpers/track';
import sumBy from 'lodash/sumBy';
import numeral from 'numeral';
import { tillitFAQs } from 'paths';
import {
  FormProvider,
  SubmitHandler,
  UseFieldArrayMethods,
  UseFormMethods,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { HiExternalLink, HiX } from 'react-icons/hi';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { TitleField } from '../MoreAboutYouStep/TitleField';
import { FormActions } from '../_shared/FormActions';
import {
  BeneficiariesLayout,
  BeneficiaryAddButton,
  BeneficiaryFormErrorText,
  BeneficiaryLineFieldsLayout,
  BeneficiaryLineLayout,
} from './BeneficiariesStep.styles';
import { RelationshipField } from './RelationshipField';

function useInvalidateMutatedQueries() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(useUserProfileQuery.getKey());
    queryClient.invalidateQueries(useBeneficiariesStepQuery.getKey());
  };
}

const proportionErrorMessage = 'Proportion must be between 1 and 100';

const beneficiarySchema = Yup.object({
  id: Yup.string()
    .nullable()
    .transform((_, value) => (value === '' ? null : value)),
  title: Yup.mixed<Title>().not([Title.Unknown]).required('Select a title'),
  first: Yup.string().required('Provide a first name'),
  last: Yup.string().required('Provide a last name'),
  relationship: Yup.mixed<BeneficiaryRelationship>()
    .not([BeneficiaryRelationship.Unknown])
    .required('Select an option'),
  proportion: Yup.number()
    .typeError(proportionErrorMessage)
    .moreThan(0, proportionErrorMessage)
    .max(100, proportionErrorMessage)
    .required(proportionErrorMessage),
});

const beneficiariesFormSchema = Yup.object({
  beneficiaries: Yup.array()
    .of(beneficiarySchema)
    .when((val, schema) =>
      schema.isValidSync(val)
        ? schema
            .min(1, 'Please add at least one beneficiary')
            .max(10, 'Please add no more than 10 beneficiaries')
            .test({
              name: 'sum',
              message:
                'The sum of the proportion for all beneficiaries must total to 100%',
              test: (beneficiaries: any) =>
                sumBy(beneficiaries, (b: any) => b.proportion ?? 0) === 100,
            })
        : schema
    ),
});

type BeneficiariesStepFormValues = Yup.InferType<
  typeof beneficiariesFormSchema
>;

interface BeneficiariesStepProps {
  source?: 'openAccountStep';
  onProceed: () => void;
  onGoBack: () => void;
}

export function BeneficiariesStep(props: BeneficiariesStepProps) {
  const beneficiariesStepQuery = useBeneficiariesStepQuery();

  return (
    <QueryState {...beneficiariesStepQuery}>
      {(queryResult) => (
        <BeneficiariesStepForm
          {...props}
          data={queryResult.data?.userProfile!}
        />
      )}
    </QueryState>
  );
}

interface BeneficiariesStepFormProps extends BeneficiariesStepProps {
  data: NonNullable<BeneficiariesStepQuery['userProfile']>;
}

function BeneficiariesStepForm({
  source,
  onProceed,
  onGoBack,
  data,
}: BeneficiariesStepFormProps) {
  const {
    mutateAsync: updateUserProfile,
    isError,
    reset,
  } = useUpdateUserProfileMutation();
  const invalidateQueries = useInvalidateMutatedQueries();

  const beneficiaryLines = !!data.beneficiaries.length
    ? data.beneficiaries.map((b) => ({
        ...b,
        proportion: numeral(b.proportion).multiply(100).value(),
      }))
    : [
        {
          id: null,
          title: null,
          first: null,
          last: null,
          relationship: null,
          proportion: null,
        },
      ];

  const defaultValues = {
    beneficiaries: beneficiaryLines,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(beneficiariesFormSchema),
  });

  const fieldArrayMethod = useFieldArray<Beneficiary, 'key'>({
    control: methods.control,
    name: 'beneficiaries',
    keyName: 'key',
  });
  const { fields, append, remove } = fieldArrayMethod;

  const {
    handleSubmit,
    register,
    formState: { isDirty, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<BeneficiariesStepFormValues> = async (data) => {
    reset();
    const transformedBeneficiaries = data?.beneficiaries?.map((b) => ({
      ...b,
      proportion: numeral(b.proportion).divide(100).value(),
    }));
    try {
      if (isDirty) {
        await updateUserProfile({
          input: {
            beneficiaries: transformedBeneficiaries,
          },
        });
        invalidateQueries();
      }

      if (source === 'openAccountStep') {
        trackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.beneficiaries,
        });
      }

      onProceed();
    } catch {
      // error handled by state
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <StepContainer>
          <StepTitle>Set up your beneficiaries</StepTitle>
          <StepContent width={StepContentWidth.extraWide}>
            <StepText>
              <StepIntroductionTypography>
                We need to know what your wishes are for how your pension assets
                are handled when you die. You can nominate more than one
                beneficiary.{' '}
                <StyledLink
                  href={`${tillitFAQs}#what-will-happen-to-my-tillit-pension-when-i-die`}
                  target="_blank"
                  $icon
                >
                  How will my assets be handled when I die? <HiExternalLink />
                </StyledLink>
              </StepIntroductionTypography>
            </StepText>
          </StepContent>

          <ServerError isVisible={isError} />

          <StepContent width={StepContentWidth.xxWide}>
            <BeneficiariesLayout>
              {fields.map((field, index) => (
                <BeneficiaryLine
                  index={index}
                  key={field.key}
                  name={`beneficiaries[${index}]`}
                  defaultValues={field}
                  register={register}
                  hasRemoveButton={index !== 0}
                  remove={() => remove(index)}
                />
              ))}

              <ErrorMessage
                name="beneficiaries"
                render={({ message }) => (
                  <BeneficiaryFormErrorText>{message}</BeneficiaryFormErrorText>
                )}
              />

              <BeneficiaryAddButton
                disabled={fields.length === 10}
                onClick={() => {
                  append({
                    id: null!,
                    title: null!,
                    first: null!,
                    last: null!,
                    relationship: null!,
                    proportion: null!,
                  });
                }}
              >
                +
              </BeneficiaryAddButton>
            </BeneficiariesLayout>
          </StepContent>

          <StepActions>
            <FormActions onGoBack={onGoBack} disabled={isSubmitting} />
          </StepActions>
        </StepContainer>
      </FormProvider>
    </form>
  );
}

interface BeneficiaryLineProps {
  index: number;
  name: string;
  defaultValues: UseFieldArrayMethods<Beneficiary, 'key'>['fields'][number];
  hasRemoveButton: boolean;
  register: UseFormMethods['register'];
  remove: () => void;
}

const BeneficiaryLine = ({
  index,
  name,
  defaultValues,
  hasRemoveButton,
  register,
  remove,
}: BeneficiaryLineProps) => {
  return (
    <BeneficiaryLineLayout data-testid={`BeneficiaryLine-${index}`}>
      <BeneficiaryLineFieldsLayout>
        <input
          type="hidden"
          name={`${name}.id`}
          ref={register()}
          defaultValue={defaultValues.id}
        />
        <TitleField
          name={`${name}.title`}
          label="Title"
          defaultValue={defaultValues.title}
        />
        <TextField
          name={`${name}.first`}
          label="First name"
          placeholder="First name"
          inputRef={register()}
          defaultValue={defaultValues.first}
        />
        <TextField
          name={`${name}.last`}
          label="Last name"
          placeholder="Last name"
          inputRef={register()}
          defaultValue={defaultValues.last}
        />
        <RelationshipField
          name={`${name}.relationship`}
          label="Relationship"
          defaultValue={defaultValues.relationship}
        />
        <TextField
          name={`${name}.proportion`}
          label="Proportion"
          onKeyDown={(ev) => {
            if (!isNumberInputValid(ev)) {
              ev.preventDefault();
            }
          }}
          inputRef={register()}
          defaultValue={defaultValues.proportion}
          endAdornment="%"
        />
        {hasRemoveButton && (
          <IconButton
            color="default"
            aria-label="Remove line"
            onClick={() => remove()}
          >
            <HiX size={'1.5rem'} />
          </IconButton>
        )}
      </BeneficiaryLineFieldsLayout>
    </BeneficiaryLineLayout>
  );
};
