import { KeyValueRow } from 'components/KeyValueRow/KeyValueRow';
import { Loading } from 'components/Loading';
import { StyledLink } from 'components/design-system/Link';
import { FontWeight, TextNormal } from 'components/design-system/Text/Text';
import * as format from 'formatting';
import {
  Instrument,
  RecognisedChargeType,
  TradeIllustrationCostLineType,
  useGetTradeCostsAndChargesIllustrationQuery,
} from 'generated/graphql';
import * as tooltips from 'strings/tooltips';
import { PartialDeep } from 'type-fest';
import { getLongKidDocName } from '../_shared/copyHelpers';
import {
  ChargesSection,
  SubSectionWrapper,
} from './_shared/ImportantInformation.styles';

export interface MiFIDCostsAndChargesSectionProps {
  amountEntered: number;
  selectedInstrument: PartialDeep<Instrument>;
  trackPopoverClick: (popover: string) => void;
}

type ChargeRowProps = {
  charge: TradeIllustrationCostLineType;
  oneOff?: boolean;
  trackPopoverClick: (label: string) => void;
};

const ChargeRow = ({
  charge,
  trackPopoverClick,
  oneOff = false,
}: ChargeRowProps) => {
  const label =
    charge.chargeProportion !== null
      ? `${charge.description} (${format.percent(charge.chargeProportion!)}${
          oneOff ? '' : ' pa'
        })`
      : charge.description;

  const tooltipLookup: Partial<
    Record<RecognisedChargeType, JSX.Element | string>
  > = {
    [RecognisedChargeType.Ocf]: tooltips.MiFIDOngoingCosts,
    [RecognisedChargeType.Platform]: <tooltips.EstimatedTillitFee />,
    [RecognisedChargeType.Dealing]: tooltips.ZeroCommission,
    [RecognisedChargeType.StampDuty]: tooltips.StampDuty(0.005),
    [RecognisedChargeType.PtmLevy]: tooltips.PtmLevy(1.0, 10_000),
    [RecognisedChargeType.Transaction]: tooltips.MiFIDTransactionCosts,
    [RecognisedChargeType.Incidental]: tooltips.MiFIDIncidentalCosts,
  };

  const gaEventNameLookup: Partial<Record<RecognisedChargeType, string>> = {
    [RecognisedChargeType.Ocf]: 'Estimated fund fee',
    [RecognisedChargeType.Platform]: 'Estimated tillit fee',
    [RecognisedChargeType.Dealing]: 'No commission',
    [RecognisedChargeType.StampDuty]: 'Stamp duty',
    [RecognisedChargeType.PtmLevy]: 'PTM levy',
  };

  const tooltip = charge.chargeType
    ? tooltipLookup[charge.chargeType] ?? null
    : null;

  const gaEventName = charge.chargeType
    ? gaEventNameLookup[charge.chargeType]
    : charge.description;

  return (
    <KeyValueRow
      label={label}
      value={format.currencyFull(charge.amount)}
      noMargin
      stackMobile
      description={tooltip}
      onPopoverClick={() => gaEventName && trackPopoverClick(gaEventName)}
      key={charge.description}
    />
  );
};

export const MiFIDCostsAndChargesSection = ({
  amountEntered,
  selectedInstrument,
  trackPopoverClick,
}: MiFIDCostsAndChargesSectionProps) => {
  const {
    data: tradeIllustration,
    isLoading,
  } = useGetTradeCostsAndChargesIllustrationQuery(
    {
      trade: {
        amount: amountEntered,
        isin: selectedInstrument.isin!,
      },
    },
    {
      enabled: !!amountEntered,
    }
  );

  if (isLoading) {
    return <Loading />;
  } else if (!amountEntered) {
    return (
      <TextNormal>
        Enter a purchase amount to illustrate the costs and charges associated
        with this trade.
      </TextNormal>
    );
  } else {
    const oneOffs = tradeIllustration?.tradeCostsAndChargesIllustration?.oneOffCostsAndCharges.map(
      (charge) => (
        <ChargeRow
          charge={charge}
          trackPopoverClick={trackPopoverClick}
          oneOff
        />
      )
    );

    const ongoing = tradeIllustration?.tradeCostsAndChargesIllustration?.ongoingCostsAndCharges.map(
      (charge) => (
        <ChargeRow charge={charge} trackPopoverClick={trackPopoverClick} />
      )
    );

    const kiidDocName = getLongKidDocName(selectedInstrument?.instrumentType!);
    const kiidUrl = selectedInstrument?.kiidDocumentUrl;

    const hasAnyChargeInformation = !!oneOffs?.length || !!ongoing?.length;

    const finalAmountBeforeCharges =
      amountEntered +
      (tradeIllustration?.tradeCostsAndChargesIllustration.totalGrowth ?? 0);
    const finalAmountAfterCharges =
      tradeIllustration?.tradeCostsAndChargesIllustration.finalAmount ?? 0;
    const totalCharges =
      tradeIllustration?.tradeCostsAndChargesIllustration
        .totalCostsAndChargesOverPeriod ?? 0;

    return (
      <>
        <TextNormal>
          We've illustrated the estimated costs and charges of buying and
          holding this investment for{' '}
          <strong>
            {tradeIllustration?.tradeCostsAndChargesIllustration.periodMonths}{' '}
            months
          </strong>
          {', '}
          assuming{' '}
          <strong>
            {
              tradeIllustration?.tradeCostsAndChargesIllustration
                .assumedGrowthPercent
            }
            % growth per year
          </strong>
          . We use figures supplied by the fund manager for this purpose, and
          they may differ (up or down) from the actual costs and charges you
          incur over time.{' '}
        </TextNormal>
        <TextNormal>
          <StyledLink href="https://tillitinvest.com/faqs">
            Learn more about how we're required to show costs and charges
          </StyledLink>
          .
        </TextNormal>
        {!oneOffs?.length ? null : (
          <>
            <SubSectionWrapper>
              <TextNormal $noMargin $fontWeight={FontWeight.medium}>
                One-offs
              </TextNormal>
            </SubSectionWrapper>
            <ChargesSection>{oneOffs}</ChargesSection>
          </>
        )}
        {!ongoing?.length ? null : (
          <>
            <SubSectionWrapper>
              <TextNormal
                $noMargin
                $fontWeight={FontWeight.medium}
                style={{ marginTop: '1rem' }}
              >
                Ongoing
              </TextNormal>
            </SubSectionWrapper>
            <ChargesSection>{ongoing}</ChargesSection>
          </>
        )}
        {!hasAnyChargeInformation && (
          <TextNormal>
            We do not yet have the required data to illustrate the costs and
            charges of this trade &mdash; you should read the{' '}
            {kiidUrl ? (
              <StyledLink
                href={kiidUrl!}
                target="_blank"
                style={{ whiteSpace: 'normal' }}
              >
                {kiidDocName}
              </StyledLink>
            ) : (
              kiidDocName
            )}{' '}
            to find out more about how much this investment may cost over time.
          </TextNormal>
        )}
        {hasAnyChargeInformation && (
          <SubSectionWrapper>
            <TextNormal
              $noMargin
              $fontWeight={FontWeight.medium}
              style={{ marginTop: '1rem' }}
            >
              Totals
            </TextNormal>
            <ChargesSection>
              <KeyValueRow
                label={`What you might get back with no charges`}
                value={format.currencyFull(finalAmountBeforeCharges!)}
                noMargin
                stackMobile
              />
              <KeyValueRow
                label={'Total estimated charges'}
                value={format.currencyFull(totalCharges)}
                noMargin
                stackMobile
              />
              <KeyValueRow
                label={`What you might get back with charges applied`}
                value={format.currencyFull(finalAmountAfterCharges)}
                noMargin
                stackMobile
              />
            </ChargesSection>
          </SubSectionWrapper>
        )}
      </>
    );
  }
};
