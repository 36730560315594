import {
  AccountDetails,
  AddressDetails,
  BankDetails,
  LoginDetails,
  PersonalDetails,
} from 'components/MyDetails';
import React from 'react';
import { DetailsInner, Title } from '../Styles/DetailsSection.styles';

export function MyDetails() {
  return (
    <>
      <Title variant="subtitle1" align="left">
        My details
      </Title>
      <DetailsInner>
        <PersonalDetails />
        <AddressDetails />
        <BankDetails />
        <AccountDetails />
        <LoginDetails />
      </DetailsInner>
    </>
  );
}
