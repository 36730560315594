import { StepContent } from 'components/StepContainer';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { Card } from 'components/design-system/Card/Card';
import { H6 } from 'components/design-system/Heading/Heading';
import {
  StepContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { FontWeight, Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { BsFiletypePdf } from 'react-icons/bs';
import styled from 'styled-components';

const CardButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  max-width: 45rem;
  margin-top: 1.5rem;
`;

const CardButton = styled(Card)`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  justify-items: center;
  text-align: center;

  ${Text} {
    align-self: end;
  }
`;

const Divider = styled.hr`
  display: inline-block;
  width: 1.5rem;
  border-color: rgba(215, 22, 235, 0.25);
  border-width: 0 0 1px 0;
  margin: 0.5rem 0;
`;

const IllustrationLink = styled.a`
  max-width: 23rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  gap: 1rem;
  color: ${colors.richBlack};
  background-color: ${colors['grey-100']};
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-align: left;
  margin: 0 auto;
`;

export interface ReviewYourDecisionStepProps {
  source?: 'openAccountStep';
  illustrationDownloadUrl?: string;
  onProceed: () => void;
  onStartAgain: () => void;
}

export function ReviewYourDecision({
  source,
  illustrationDownloadUrl,
  onProceed,
  onStartAgain,
}: ReviewYourDecisionStepProps) {
  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Review your decision</StepTitle>
        <StepIntroduction $width={StepIntroductionWidth.extraWide}>
          <StepIntroductionTypography>
            We've got all the information we need to open your TILLIT Pension,
            if you're ready. Once opened, we'll help you set up your
            contributions.
          </StepIntroductionTypography>
          {illustrationDownloadUrl && (
            <IllustrationLink
              href={illustrationDownloadUrl || ''}
              target="_blank"
              rel="noreferrer"
              type="application/pdf"
            >
              <BsFiletypePdf size={'2.5rem'} />
              <Text $fontWeight={FontWeight.medium} $noMargin>
                Download TILLIT Pension illustration
              </Text>
            </IllustrationLink>
          )}
        </StepIntroduction>
      </StepContent>
      <CardButtonWrapper>
        <CardButton>
          <H6 $noMargin>Change my illustration</H6>
          <Divider />
          <Text>
            You can go back and amend details you gave us and create a new
            illustration.
          </Text>
          <CustomButtonV2 onClick={() => onStartAgain()} $color="secondary">
            Change my illustration
          </CustomButtonV2>
        </CardButton>
        <CardButton>
          <H6 $noMargin>Accept illustration and open a TILLIT Pension</H6>
          <Divider />
          <Text>
            We just need a couple more details and you'll be up and running in
            no time.
          </Text>
          <CustomButtonV2
            onClick={() => {
              if (source === 'openAccountStep') {
                trackGa({
                  event: GaEventNames.onboarding,
                  onboardingStep: OnboardingStepNames.reviewYourDecision,
                });
              }

              onProceed();
            }}
          >
            Open my TILLIT pension
          </CustomButtonV2>
        </CardButton>
      </CardButtonWrapper>
    </StepContainer>
  );
}
