import { Accordion } from 'components/Accordion';
import { StyledLink } from 'components/design-system/Link';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { Text } from 'components/design-system/Text/Text';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { generateFundDetailsPath } from 'paths';
import { FormActions } from './_shared/FormActions';
import {
  TextAccordionDetails,
  TextAccordionSummary,
} from './_shared/TextAccordion';

const DEFAULT_PENSION_ASSET_ID = process.env
  .REACT_APP_DEFAULT_PENSION_TDF_INVESTMENT_ASSET_ID!;

export interface IntroductionStepProps {
  onProceed: () => void;
  onGoBack: () => void;
}

export function IntroductionStep({
  onProceed,
  onGoBack,
}: IntroductionStepProps) {
  const onStepProceed = () => {
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.pensionIntroduction,
    });
    onProceed();
  };

  return (
    <StepContainer>
      <StepTitle>Opening a TILLIT Pension</StepTitle>
      <StepContent width={StepContentWidth.extraWide}>
        <StepText>
          <StepIntroductionTypography>
            The TILLIT Pension is a Self-Invested Personal Pension intended for
            investors who want to make their own investment decisions. You can
            ask your employer to contribute to it, and you can consolidate other
            pensions into it.
          </StepIntroductionTypography>
          <StepIntroductionTypography>
            We’re going to ask a few quick questions to produce a downloadable
            illustration outlining how costs, charges and growth in the TILLIT
            Pension might work out over time - this helps you compare between
            different providers, but is not a guarantee of future results.
          </StepIntroductionTypography>
        </StepText>
      </StepContent>
      <StepContent width={StepContentWidth.extraWide}>
        <Accordion>
          <TextAccordionSummary
            label={'How do I know if this is right for me?'}
            onClick={() => {
              trackGa({
                event: GaEventNames.selectContent,
                content_type: 'accordion',
                item_id:
                  'Pension intro - How do I know if this is right for me',
              });
            }}
          />
          <TextAccordionDetails>
            <Text>
              Opening a TILLIT Pension is a personal decision and we cannot
              advise you if it is right for you or not. If you’re in doubt, you
              should seek independent financial advice.
            </Text>
            <Text>
              By asking you a few questions we can produce an illustration
              document that you can read and decide upon at your leisure - it
              only takes a couple of minutes.
            </Text>
          </TextAccordionDetails>
        </Accordion>
        <Accordion>
          <TextAccordionSummary
            label={'How will my contributions be invested?'}
            onClick={() => {
              trackGa({
                event: GaEventNames.selectContent,
                content_type: 'accordion',
                item_id:
                  'Pension intro - how will my contributions be invested?',
              });
            }}
          />
          <TextAccordionDetails>
            <Text>
              Unless you choose otherwise, we will invest your contributions in
              our selected default investment,{' '}
              <StyledLink
                href={generateFundDetailsPath({
                  id: DEFAULT_PENSION_ASSET_ID,
                  slug: 'blackrock-lifepath',
                })}
                target="_blank"
              >
                BlackRock LifePath
              </StyledLink>
              . However, you can opt out of the default fund and make some or
              all of your own investment choices at any time from anything in
              our TILLIT Universe of funds.
            </Text>
          </TextAccordionDetails>
        </Accordion>
        <Accordion>
          <TextAccordionSummary
            label={'What are the charges?'}
            onClick={() => {
              trackGa({
                event: GaEventNames.selectContent,
                content_type: 'accordion',
                item_id: 'Pension intro - what are the charges',
              });
            }}
          />
          <TextAccordionDetails>
            <Text>
              We normally charge a flat platform fee across all your investment
              accounts of 0.40% per annum, but for a limited time we are
              reducing this platform charge to 0.25% for early adopters of the
              TILLIT Pension &mdash;{' '}
              <StyledLink
                href="https://d1ur1kq4xhe5pz.cloudfront.net/images/TILLIT-Pension-Early-Adopter-Terms-and-Conditions.pdf"
                target="_blank"
              >
                terms and conditions apply
              </StyledLink>
              .
            </Text>
            <Text>
              We do not charge you anything to add money to or transfer in to or
              out of your TILLIT Pension and there are no dealing commission
              charges.
            </Text>
            <Text>
              Each investment has its own ongoing charges, and for some
              investments you may be charged Stamp Duty or a PTM levy - you can{' '}
              <StyledLink
                href="https://knowledge.tillitinvest.com/faqs#fees"
                target="_blank"
              >
                learn more in our FAQs
              </StyledLink>
              .
            </Text>
          </TextAccordionDetails>
        </Accordion>
      </StepContent>
      <StepActions>
        <FormActions onProceed={onStepProceed} onGoBack={onGoBack} />
      </StepActions>
    </StepContainer>
  );
}
