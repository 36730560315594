import { Option } from 'components/Form/Select';
import { SelectField, SelectFieldProps } from 'components/Form/SelectField';
import { EmploymentStatus } from 'generated/graphql';

export interface EmploymentStatusFieldProps
  extends Omit<SelectFieldProps, 'options'> {}

export function EmploymentStatusField(props: EmploymentStatusFieldProps) {
  const employmentStatusOptions: Option[] = [
    { value: EmploymentStatus.CareOver_16, label: 'Care over 16' },
    { value: EmploymentStatus.CareUnder_16S, label: 'Care under 16' },
    { value: EmploymentStatus.Education, label: 'Student' },
    { value: EmploymentStatus.Employed, label: 'Employed' },
    { value: EmploymentStatus.Retired, label: 'Retired' },
    { value: EmploymentStatus.SelfEmployed, label: 'Self employed' },
    { value: EmploymentStatus.Unemployed, label: 'Unemployed' },
    { value: EmploymentStatus.Other, label: 'Other' },
  ];

  return <SelectField {...props} options={employmentStatusOptions} />;
}
