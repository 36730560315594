import { IconButton } from '@material-ui/core';
import { StandingOrderDetails } from 'components/Dialogs/StandingOrderDetails/StandingOrderDetails';
import { H6 } from 'components/design-system/Heading/Heading';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { Pill } from 'components/design-system/Pill/Pill';
import { FontSize, TextNormal } from 'components/design-system/Text/Text';
import { currencyFull, date, day } from 'formatting';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { generateAutoSaveInvestSubPath } from 'paths';
import React, { useState } from 'react';
import { FaUndo } from 'react-icons/fa';
import { HiOutlineTrash } from 'react-icons/hi';
import { MdEdit } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { AccountsQueryAccount } from 'types/graphqlTypes';
import { useAutoSaveInvestState } from '../../AutoSaveInvestContext';
import { RegularDepositEditDialog } from '../ManageDepositsDialog';
import { AlertLink } from '../_shared/styles';
import {
  BoldSpanWrapper,
  RowContainer,
  RowHeading,
  StandingOrderDetailsLink,
} from './SelectRegularDeposits.styles';

export interface SelectRegularDepositsProps {
  account: AccountsQueryAccount;
}

export function SelectRegularDeposits({ account }: SelectRegularDepositsProps) {
  const {
    state,
    setState,
    latestEmployerContribution,
  } = useAutoSaveInvestState();
  const history = useHistory();
  const [
    showStandingOrderDetailsDialog,
    setShowStandingOrderDetailsDialog,
  ] = useState<boolean>(false);
  const [showMultiDeposits, setShowMultiDeposits] = useState<number | null>(
    null
  );
  const recurringDeposits = state?.deposits ?? [];
  const anyAutoInvest = recurringDeposits.find(
    (deposit) => deposit.autoInvest && !deposit.isDeleted
  );

  const orders = state?.orders ?? [];

  const handleNewRegularDeposit = () => {
    history.push(
      generateAutoSaveInvestSubPath({
        wrapperType: getPathSegmentForWrapperType(account.wrapperType),
        action: 'create-deposit',
      })
    );
  };

  const handleEdit = (index: number) => {
    setShowMultiDeposits(index);
  };

  const handleToggle = (index: number) => {
    const selectedDeposit = recurringDeposits[index]!;
    const deposits = recurringDeposits!;

    const updatedDeposits = selectedDeposit.isNew
      ? [...deposits.slice(0, index), ...deposits.slice(index + 1)]
      : [
          ...deposits.slice(0, index),
          {
            ...selectedDeposit,
            isDeleted: selectedDeposit.isDeleted ? false : true,
          },
          ...deposits.slice(index + 1),
        ];

    setState({
      ...state,
      deposits: updatedDeposits,
    });
  };

  const handleViewStandingOrderDetails = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setShowStandingOrderDetailsDialog(true);
  };

  const handleCloseStandingOrderDetailsDialog = () => {
    setShowStandingOrderDetailsDialog(false);
  };

  return (
    <>
      <H6>My regular deposits</H6>

      {showMultiDeposits !== null && (
        <RegularDepositEditDialog
          account={account}
          index={showMultiDeposits}
          open
          closeDialog={() => {
            setShowMultiDeposits(null);
          }}
        />
      )}

      {recurringDeposits?.length === 0 ? (
        <>
          {orders.length === 0 ? (
            <TextNormal $noMargin>
              You currently have no regular deposits associated with your
              account. Create a{' '}
              <AlertLink onClick={handleNewRegularDeposit}>
                new regular deposit
              </AlertLink>
              .
            </TextNormal>
          ) : (
            <TextNormal $noMargin>
              <span>
                You currently have a regular investment with no regular deposits
                associated with your account. Create a{' '}
                <AlertLink onClick={handleNewRegularDeposit}>
                  new regular deposit
                </AlertLink>
                .
              </span>
            </TextNormal>
          )}
        </>
      ) : (
        <>
          {!anyAutoInvest && (
            <TextNormal $noMargin>
              You currently have no regular deposits set to auto-invest, your
              regular investment will not be actioned.{' '}
              <AlertLink onClick={() => handleEdit(0)}>Edit</AlertLink> or{' '}
              <AlertLink onClick={handleNewRegularDeposit}>
                create a new
              </AlertLink>{' '}
              regular deposit.
            </TextNormal>
          )}
          {recurringDeposits.map((deposit, index) => {
            return (
              <React.Fragment key={`${deposit.amount}-${deposit.paymentDate}`}>
                <RowContainer>
                  <RowHeading>
                    <BoldSpanWrapper>
                      {currencyFull(deposit.amount!)}
                    </BoldSpanWrapper>{' '}
                    on the{' '}
                    <BoldSpanWrapper>
                      {day(deposit.paymentDate!)}
                    </BoldSpanWrapper>{' '}
                    of the month set to{' '}
                    <BoldSpanWrapper>
                      {deposit.autoInvest ? 'auto-invest' : 'keep as cash'}
                    </BoldSpanWrapper>
                    .
                  </RowHeading>
                  <div>
                    {deposit.isDeleted && (
                      <Pill $color={'warning'} $fontSize={FontSize.xs}>
                        Removed
                      </Pill>
                    )}
                    {deposit.isNew && (
                      <Pill $color={'green'} $fontSize={FontSize.xs}>
                        Added
                      </Pill>
                    )}
                    {!deposit.isDeleted && !deposit.isNew && deposit.isEdited && (
                      <Pill $color={'green'} $fontSize={FontSize.xs}>
                        Changed
                      </Pill>
                    )}
                  </div>
                  <div>
                    <IconButton
                      type="button"
                      size="small"
                      onClick={() => {
                        handleEdit(index);
                      }}
                    >
                      <MdEdit title="Edit" />
                    </IconButton>

                    {deposit.isDeleted ? (
                      <IconButton
                        type="button"
                        size="small"
                        onClick={() => {
                          handleToggle(index);
                        }}
                      >
                        <FaUndo title="Undo" />
                      </IconButton>
                    ) : (
                      <IconButton
                        type="button"
                        size="small"
                        onClick={() => {
                          handleToggle(index);
                        }}
                      >
                        <HiOutlineTrash title="Remove" />
                      </IconButton>
                    )}
                  </div>
                </RowContainer>
              </React.Fragment>
            );
          })}

          {latestEmployerContribution && (
            <RowContainer>
              <RowHeading>
                <BoldSpanWrapper>
                  {currencyFull(latestEmployerContribution.amount)}
                </BoldSpanWrapper>{' '}
                estimated employer contribution.
                <InfoPopoverV2
                  size="small"
                  content={`This is based on your latest employer contribution on the 
                  ${date(latestEmployerContribution.completedDate)}`}
                />
              </RowHeading>
            </RowContainer>
          )}

          <StandingOrderDetails
            accountId={account.id!}
            wrapperType={account.wrapperType}
            open={showStandingOrderDetailsDialog}
            onClose={handleCloseStandingOrderDetailsDialog}
          />
          <StandingOrderDetailsLink onClick={handleViewStandingOrderDetails}>
            View standing order details
          </StandingOrderDetailsLink>
        </>
      )}
    </>
  );
}
