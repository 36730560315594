import { TargetDateInfoReturnContextProvider } from 'components/feature/FundDetails/hooks/useTargetDateInfo';
import { WrapperType } from 'generated/graphql';
import { isTypeSource } from 'pages/FundDetails/fundDetailsTypes';
import { FundsLayout } from './NewFundsPageLayout';

interface FundsListProps {
  selectedAccountType: WrapperType;
  selectedAccountId: string;
  accountIsClosed?: boolean;
}

export function FundsListPortfolioBuilderLayout({
  selectedAccountType,
  selectedAccountId,
  accountIsClosed,
}: FundsListProps) {
  const source = `portfolio-builder-${selectedAccountType.toLowerCase()}`;
  return (
    <TargetDateInfoReturnContextProvider>
      <FundsLayout
        selectedAccountType={selectedAccountType}
        selectedAccountId={selectedAccountId}
        accountIsClosed={accountIsClosed}
        source={isTypeSource(source) ? source : undefined}
      />
    </TargetDateInfoReturnContextProvider>
  );
}
