import TableContainer from '@material-ui/core/TableContainer';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { tableStyle } from '../../../shared.styles';

export const HoldingWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: ${(p) => p.theme.spacing(2.5, 0)};

  > *:first-child {
    margin-bottom: ${(p) => p.theme.spacing(2.5)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    margin: ${(p) => p.theme.spacing(5, 0)};

    > *:first-child {
      margin-bottom: ${(p) => p.theme.spacing(5)}px;
    }
  }
`;

export const TableContainerRoot = styled(TableContainer)`
  ${tableStyle};
  tbody {
    tr:nth-child(2n-1) {
      background-color: ${transparentize(0.95, colors.magenta)};
    }
  }
`;

export const HoldingsContainer = styled.div`
  width: 100%;
  margin: ${(p) => p.theme.spacing(2.5, 0)};
  text-align: left;

  & > * + * {
    margin-top: ${(p) => p.theme.spacing(1)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    margin: ${(p) => p.theme.spacing(5, 0)};
  }
`;
