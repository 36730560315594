import * as format from 'formatting';

export const LatestBuyPrice = (
  <>
    The latest buy price information we have, as of the date shown below. The
    actual price paid when your order settles will depend upon the time and date
    that the order is executed and prevailing market conditions.
  </>
);

export const LatestBuyPriceUpdate = (
  <>
    The date at which the ‘Latest buy price’ was recorded. This may be
    yesterday, or even earlier, depending on the type of asset being traded and
    recent market holidays.
  </>
);

export const EstimatedNumberOfUnits = (
  <>
    The estimated number of units or shares that will be bought based upon the
    latest buy price. Where they apply, the effect of stamp duty and any PTM
    levy is included in this estimate
  </>
);

interface EstimatedFundFeeProps {
  value?: string;
}
export const EstimatedFundFee = ({
  value = 'this order',
}: EstimatedFundFeeProps) => (
  <>
    There are additional fees associated with managing the investment, charged
    by the asset manager. This is the estimated annual fee based on the value of{' '}
    <u>{value}</u> and the Ongoing Charges Figure (OCF), and does not take into
    account future market movements. In some cases other charges may also apply.
    <br />
    <br />
    These charges are taken by the fund manager directly from your holding.
  </>
);

interface EstimatedTillitFeeProps {
  value?: string;
}
export const EstimatedTillitFee = ({
  value = 'this order',
}: EstimatedTillitFeeProps) => (
  <>
    The TILLIT fee is based on the value of your account, and it may rise or
    fall in line with your investments. This is the estimated annual fee based
    on the value of <u>{value}</u> and does not take into account future market
    movements.
    <br />
    <br />
    This will be charged to your account monthly in arrears.
  </>
);

interface EstimatedTotalFeeProps {
  value?: string;
}
export const EstimatedTotalFee = ({
  value = 'this order',
}: EstimatedTotalFeeProps) => (
  <>
    This is the sum of the estimated fund fee and estimated TILLIT platform fee
    for <u>{value}</u>.
  </>
);

export const StampDuty = (percentage: number = 0) => (
  <>
    Stamp duty is a one-off {format.percent(percentage)} tax paid when
    purchasing some exchange-traded instruments, including investment trusts.
  </>
);

export const PtmLevy = (amount: number = 0, threshold: number = 0) => (
  <>
    The Panel of Takeovers and Mergers is a regulatory body set up to ensure
    shareholders are all treated equally during takeover bids. Their funding
    comes from a one-off {format.currencyFull(amount)} charge they levy on sales
    and purchases in some exchange-traded instruments, including investment
    trusts, where the value of the trade is over{' '}
    {format.currencyFull(threshold)}.
  </>
);

export const MiFIDOngoingCosts = (
  <>
    There are additional fees associated with managing the investment, charged
    by the asset manager. This is the estimated annual fee based on the value of{' '}
    <u>this order</u> and the Ongoing Charges Figure (OCF). In some cases other
    charges may also apply.
    <br />
    <br />
    These charges are taken by the fund manager directly from your holding. The
    OCF used to illustrate your trade may differ slightly from the published
    value in the fund's documentation as it is a forecast based on several years
    of historical published OCF data.
  </>
);

export const MiFIDTransactionCosts = (
  <>
    Transaction costs are the costs that the fund incurs for buying and selling
    the investments that make up the fund. These costs are not included in the
    Ongoing Charges Figure (OCF) and are not deducted from the fund’s assets.
    <br />
    <br />
    Transaction costs are not directly paid by you, but instead they effectively
    reduce the returns that the fund achieves. They tend to be higher for funds
    that trade in private equity and property, or in funds that operate in
    emerging and frontier markets.
  </>
);

export const MiFIDIncidentalCosts = (
  <>
    Incidental costs are other costs incurred by or charged by the fund beyond
    transaction and ongoing costs, and are often related to performance fees or
    other charges that certain funds collect when its return outperforms some
    target.
  </>
);

export const LatestSellPrice = (
  <>
    The latest sale price information we have, as of the date shown below. The
    actual price paid when your order settles will depend upon the time and date
    that the order is executed and prevailing market conditions.
  </>
);

export const LatestSellPriceUpdate = (
  <>
    The date at which the ‘Latest sell price’ was recorded. This may be
    yesterday, or even earlier, depending on the type of asset being traded and
    recent market holidays.
  </>
);

export const EstimatedValue = (
  <>The estimated value of the order based upon the latest sell price.</>
);

export const PayeeName = <>Our custodian partner is Seccl Custody.</>;

export const RecommendedMinCashBalance = (
  <p>
    The minimum cash balance is a recommended (voluntary) cash balance to cover
    our platform fee. As long as there is sufficient cash in your account to
    cover our fees, they will be taken straight from your cash balance. If there
    is insufficient cash in your account we may have to sell down some of your
    investments to cover the fees.
  </p>
);

export const OrderPending = (
  <>This order is in progress and has not yet settled.</>
);

export const HoldingPendingOrders = (
  instrumentType: string,
  pendingBuyValue?: number,
  pendingSellAmount?: number
) => (
  <>
    {pendingBuyValue && !pendingSellAmount
      ? `You have ${format.currencyFull(
          pendingBuyValue
        )} worth of pending buy orders against this holding.`
      : null}
    {!pendingBuyValue && pendingSellAmount
      ? `You have ${format.units(pendingSellAmount)} ${
          instrumentType === 'Fund' ? "units'" : "shares'"
        } worth of pending sell orders against this holding.`
      : null}
    {pendingBuyValue && pendingSellAmount
      ? `You have ${format.currencyFull(
          pendingBuyValue
        )} worth pending buy orders and ${format.units(pendingSellAmount)} ${
          instrumentType === 'Fund' ? "units'" : "shares'"
        } worth of pending sell orders against this holding.`
      : null}
  </>
);

export const HoldingSize = (
  <>The holding size is calculated based on the total value of your account.</>
);

export const BookValue = (
  <>
    The total value of cash added and dividends or income received from your
    investments, less any withdrawals made and any TILLIT platform fees charged.
  </>
);

export const BookCost = (
  <>
    Book cost is a measure of the average amount that you paid for this
    investment. It changes when you sell or buy more, based upon the market
    price at the time you bought or sold.
  </>
);

export const Contributions = (
  <>
    This represents how much you have added to your TILLIT account over time,
    less any withdrawals you have made. This includes the value of any transfers
    into TILLIT from another platform provider.
  </>
);

export const ZeroCommission = <>TILLIT does not charge commission on trades.</>;
