import { ReactComponent as DrivingLicenceIcon } from 'assets/icons/onboarding/license.svg';
import { ReactComponent as PassportIcon } from 'assets/icons/onboarding/passport.svg';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { FontSize, FontWeight, Text } from 'components/design-system/Text/Text';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import {
  FileName,
  ImageLabelWrapper,
  PreviewContainer,
  PreviewGrid,
  PreviewImage,
  PreviewTitle,
  StyledButton,
  SvgWrapper,
} from './DocumentUploadStep.styles';
import { IdType, UploadFieldDetails } from './DocumentUploadStepV2';

interface DocumentUploadPreviewProps {
  documentType: string | null;
  frontSideFieldData?: UploadFieldDetails;
  backSideFieldData?: UploadFieldDetails;
  handleReplaceImage: () => void;
  handleNationalIdInstead: () => void;
  handleBackToUpload: () => void;
  onProceed: () => void;
}

export function DocumentUploadPreviewStep({
  documentType,
  frontSideFieldData,
  backSideFieldData,
  handleReplaceImage,
  handleNationalIdInstead,
  handleBackToUpload,
  onProceed,
}: DocumentUploadPreviewProps) {
  return (
    <>
      <StepContent>
        <StepTitle>Upload your ID</StepTitle>
        <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            In order to verify your identity, we need a copy of your ID. This
            can be your passport or driving licence.
          </StepIntroductionTypography>
        </StepIntroduction>
        <PreviewContainer>
          {documentType !== IdType.nationalIdCard ? (
            <>
              <PreviewTitle>
                <SvgWrapper>
                  {documentType === IdType.drivingLicence ? (
                    <DrivingLicenceIcon />
                  ) : (
                    <PassportIcon />
                  )}
                </SvgWrapper>
                <Text $fontWeight={FontWeight.normal} $noMargin>
                  {documentType === IdType.drivingLicence
                    ? 'Driving licence'
                    : 'Passport'}
                </Text>
              </PreviewTitle>
              {frontSideFieldData?.isImage && (
                <PreviewImage>
                  <img src={frontSideFieldData.base64} alt="Front of ID" />
                </PreviewImage>
              )}
              {frontSideFieldData?.fileName && (
                <Text $noMargin $fontSize={FontSize.small}>
                  {frontSideFieldData.fileName}
                </Text>
              )}
              <CustomButtonV2
                $color="light"
                $size="extraSmall"
                $isWide
                $noHover
                onClick={handleReplaceImage}
              >
                Replace image
              </CustomButtonV2>
            </>
          ) : (
            <>
              <PreviewTitle>
                <SvgWrapper>
                  <DrivingLicenceIcon />
                </SvgWrapper>
                <Text
                  $fontWeight={FontWeight.normal}
                  $fontSize={FontSize.small}
                  $noMargin
                >
                  National ID
                </Text>
              </PreviewTitle>
              <PreviewGrid>
                <div>
                  {frontSideFieldData?.isImage && (
                    <ImageLabelWrapper>
                      <Text $fontSize={FontSize.small}>Front of ID</Text>
                      <PreviewImage>
                        <img
                          src={frontSideFieldData.base64}
                          alt="Front of ID"
                        />
                      </PreviewImage>
                    </ImageLabelWrapper>
                  )}
                  {frontSideFieldData?.fileName && (
                    <FileName $noMargin $fontSize={FontSize.small}>
                      {frontSideFieldData.fileName}
                    </FileName>
                  )}
                </div>
                <div>
                  {backSideFieldData?.isImage && (
                    <>
                      <ImageLabelWrapper>
                        <Text $fontSize={FontSize.small}>Back of ID</Text>
                        <PreviewImage>
                          <img
                            src={backSideFieldData.base64}
                            alt="Back of ID"
                          />
                        </PreviewImage>
                      </ImageLabelWrapper>
                    </>
                  )}
                  {backSideFieldData?.fileName && (
                    <FileName $noMargin $fontSize={FontSize.small}>
                      {backSideFieldData.fileName}
                    </FileName>
                  )}
                </div>
              </PreviewGrid>
              <CustomButtonV2
                $color="light"
                $size="extraSmall"
                $isWide
                $noHover
                onClick={handleReplaceImage}
              >
                Replace images
              </CustomButtonV2>
            </>
          )}
        </PreviewContainer>
        {documentType !== IdType.nationalIdCard && (
          <Text $noMargin>
            Use{' '}
            <StyledButton
              type="button"
              name="documentType"
              onClick={handleNationalIdInstead}
            >
              national photo identity
            </StyledButton>{' '}
            card instead
          </Text>
        )}
      </StepContent>

      <StepActions>
        <>
          <StepButton
            type="submit"
            className="magenta"
            onClick={() => onProceed()}
          >
            Continue
          </StepButton>
          <GoBackButton onClick={handleBackToUpload} />
        </>
      </StepActions>
    </>
  );
}
