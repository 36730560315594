import { BuyButton } from 'components/Button/BuyButton';
import { SellButton } from 'components/Button/SellButton';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { GoBackButton } from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { useIsAssetAvailableToBuyById } from 'hooks/useIsAssetAvailableToBuy';
import { fundListPath } from 'paths/appPaths';
import { useHistory } from 'react-router-dom';
import { AnyAsset } from 'types/graphqlTypes';
import { ButtonsWrapper, DarkActionsContainer } from './DarkActions.styles';

export interface DarkActionsBoxProps {
  userHolds: boolean;
  authIndicator: boolean;
  isin: string;
  assetId: string;
  title: string;
  isInHeader?: boolean;
  asset: AnyAsset;
}

export function DarkActionsBox({
  asset,
  userHolds,
  authIndicator,
  isin,
  assetId,
  title,
  isInHeader = false,
}: DarkActionsBoxProps) {
  const isAssetAvailableToBuy = useIsAssetAvailableToBuyById(assetId);
  const history = useHistory();

  const trackActionBuy = () => {
    const tags =
      asset && asset.tags?.nodes
        ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
        : [];

    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'buy', // Buy, sell, deposit etc
      ecommerce: {
        items: [
          {
            item_id: asset.id,
            item_name: asset.name,
            affiliation: 'Tillit',
            currency: 'GBP',
            item_brand: asset.assetManager?.name,
            item_category: tags[0],
            item_category2: tags[1],
            item_category3: tags[2],
            item_category4: tags[3],
            item_category5: tags[4],
          },
        ],
      },
    });
  };

  const trackActionSell = () => {
    const tags =
      asset && asset.tags?.nodes
        ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
        : [];

    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'sell', // Buy, sell, deposit etc
      ecommerce: {
        items: [
          {
            item_id: asset.id,
            item_name: asset.name,
            affiliation: 'Tillit',
            currency: 'GBP',
            item_brand: asset.assetManager?.name,
            item_category: tags[0],
            item_category2: tags[1],
            item_category3: tags[2],
            item_category4: tags[3],
            item_category5: tags[4],
          },
        ],
      },
    });
  };

  return (
    <DarkActionsContainer>
      {isInHeader ? (
        <ButtonsWrapper>
          <CustomButtonV2
            variant="outlined"
            $color="white"
            onClick={() => history.push(fundListPath)}
          >
            Browse TILLIT funds
          </CustomButtonV2>
          {authIndicator && userHolds && isAssetAvailableToBuy && (
            <>
              <BuyButton
                assetId={assetId}
                selectedIsin={isin}
                onClick={() => trackActionBuy()}
                label="Buy more"
              />
              <SellButton
                asset={asset}
                canSell={true}
                isin={isin}
                onClick={() => trackActionSell()}
                $color="white"
                variant="outlined"
              />
            </>
          )}
        </ButtonsWrapper>
      ) : (
        <>
          {authIndicator && userHolds && isAssetAvailableToBuy && (
            <ButtonsWrapper>
              <BuyButton
                assetId={assetId}
                selectedIsin={isin}
                onClick={() => trackActionBuy()}
                label="Buy more"
              />
              <SellButton
                asset={asset}
                canSell={true}
                isin={isin}
                onClick={() => trackActionSell()}
              />
            </ButtonsWrapper>
          )}
          <ButtonsWrapper>
            <GoBackButton onClick={() => history.goBack()} />
          </ButtonsWrapper>
        </>
      )}
    </DarkActionsContainer>
  );
}
