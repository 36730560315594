import ClosedCardBackground from 'assets/backgrounds/Tillit_trust_background.jpg';
import { AccountSection } from 'components/Dashboard/Accounts/Account';
import { PerformanceSection } from 'components/Dashboard/Accounts/Account/PerformanceSection';
import { CashTransactionTable } from 'components/Tables/CashTransactionTable';
import { InternalTabs } from 'components/Tabs';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { useIsAccountClosed } from 'hooks/useAccountStatus';
import { PillGroup } from 'pages/MyDashboard/Accounts';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SupportedWrapperType } from 'types/utilityTypes';
import { NewHoldings } from '../Accounts/NewHoldings';
import { ContentContainer, InternalTabsWrapper } from './Account.styles';

const tabs = [
  {
    label: 'Holdings',
    value: 'holdings',
  },
  {
    label: 'Cash movements',
    value: 'cash-movements',
  },
];

export interface AccountProps {
  accountType: SupportedWrapperType;
  isBelowTablet: boolean;
}

export function Account({ accountType, isBelowTablet }: AccountProps) {
  const internalTabsRef = useRef<HTMLDivElement>(null);
  const [lastTabScrollInto, setLastScrollInto] = useState<string | null>(null);
  const isAccountClosed = useIsAccountClosed(accountType);
  const sectionBackground = isAccountClosed ? ClosedCardBackground : undefined;

  const [activeTab, setActiveTab] = useState<string>(tabs[0].value);

  const location = useLocation();
  const locationHash = location.hash;
  useEffect(() => {
    const selectedTab = tabs.find(
      ({ value }) => locationHash.slice(1) === value
    );
    if (selectedTab?.value && lastTabScrollInto !== selectedTab?.value) {
      setActiveTab(selectedTab?.value);
      if (internalTabsRef.current) {
        const newScrollY =
          window.scrollY +
          internalTabsRef.current.getBoundingClientRect().y -
          120;
        window.scroll(0, newScrollY);
        setLastScrollInto(selectedTab?.value);
      }
    }
  }, [locationHash, lastTabScrollInto, setLastScrollInto]);

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, newValue: string) => {
      trackGa({
        event: GaEventNames.selectContent,
        content_type: 'dashboard',
        item_id: `Dashboard ${accountType} section`,
      });
      setActiveTab(newValue);
    },
    [accountType]
  );

  return (
    <ContentContainer
      padding="large"
      square={false}
      $elevation={3}
      $elevationBreakpoint={'sm'}
      removeHorizontalPadding={!isAccountClosed && isBelowTablet}
      background={sectionBackground}
      data-testid="dashboard-section--account"
    >
      <AccountSection accountType={accountType}>
        {isAccountClosed ? (
          <CashTransactionTable accountType={accountType} dark={true} />
        ) : (
          <>
            <PerformanceSection accountType={accountType} />
            <PillGroup accountType={accountType} />
            <InternalTabsWrapper ref={internalTabsRef}>
              <InternalTabs
                tabsPerRow={4}
                tabs={tabs}
                value={activeTab}
                onChange={handleTabChange}
              />
            </InternalTabsWrapper>
            {activeTab === 'holdings' && (
              <NewHoldings accountType={accountType} />
            )}
            {activeTab === 'cash-movements' && (
              <CashTransactionTable accountType={accountType} />
            )}
          </>
        )}
      </AccountSection>
    </ContentContainer>
  );
}
