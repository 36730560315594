import { sortBy } from 'lodash';
import type {
  AccountsQueryAccountPosition,
  InstrumentsByIsinsQueryInstrumentsInstrument,
  RecurringTransactionsQueryRecurringTransactionsDeposits,
} from 'types/graphqlTypes';
import { LocalRecurringOrder } from '../../AutoSaveInvestContext';
import type { RegularOrderLineFormValues } from '../types';

export const deriveFormState = (
  recurringTransactionsDeposits: RecurringTransactionsQueryRecurringTransactionsDeposits | null,
  recurringTransactionsOrder: LocalRecurringOrder[] | null,
  positions: AccountsQueryAccountPosition[],
  instruments: InstrumentsByIsinsQueryInstrumentsInstrument[]
): RegularOrderLineFormValues[] => {
  if (recurringTransactionsOrder === null) {
    return [];
  }

  const totalDepositsAmount = recurringTransactionsDeposits
    ? recurringTransactionsDeposits
        .filter(({ autoInvest }) => autoInvest)
        .reduce((acc, { amount }) => acc + amount, 0)
    : 0;

  const defaultLines: RegularOrderLineFormValues[] = recurringTransactionsOrder
    ? recurringTransactionsOrder.reduce(
        (regularOrdersLines, recurringOrder) => {
          const accountPositions = positions?.find(
            ({ isin }) => isin === recurringOrder.isin
          );
          const instrument = instruments.find(
            ({ isin }) => isin === recurringOrder.isin
          );
          if (!instrument || !instrument.asset) {
            return regularOrdersLines;
          }
          return [
            ...regularOrdersLines,
            {
              assetId: `${instrument.assetId}`,
              assetName: instrument.asset.name,
              instrumentName: instrument.name,
              isin: recurringOrder.isin,
              percentage: `${recurringOrder.proportion * 100}`,
              currentValue: `${accountPositions?.currentValue ?? 0}`,
              amount: `${
                Math.round(
                  totalDepositsAmount * recurringOrder.proportion * 100
                ) / 100
              }`,
              included: 'true',
              isDeleted: recurringOrder.isDeleted ? 'true' : 'false',
              isEdited: recurringOrder.isEdited ? 'true' : 'false',
              isNew: recurringOrder.isNew ? 'true' : 'false',
            },
          ];
        },
        []
      )
    : [];

  return sortBy(defaultLines, ({ instrumentName }) => instrumentName);
};
