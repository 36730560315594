import { CustomButton } from 'components/Button/CustomButton';
import { TransferDialog } from 'components/Dialogs/Transfer/TransferDialog';
import { TransferAccountType } from 'components/Dialogs/TransferAccountType/TransferAccountType';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useCanTransfer } from 'hooks/useCanTransfer';
import { useModal } from 'mui-modal-provider';
import React, { useMemo } from 'react';
import { PromptCopy, TransferPromptContainer } from './TransferPrompt.styles';

export interface TransferPromptProps {
  wrapperType?: WrapperType;
  source?: string;
  onClick?: () => void;
}

export function TransferPrompt({
  wrapperType,
  source = 'onboarding',
  onClick,
}: TransferPromptProps) {
  const { showModal } = useModal({ disableAutoDestroy: true });
  const canTransfer = useCanTransfer();

  const ButtonDisabled = useMemo(() => {
    return !canTransfer.gia || !canTransfer.isa ? true : false;
  }, [canTransfer]);

  const handleOpenTransferForm = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: 'Start Transfer - ISA',
    });
    onClick?.();
    wrapperType
      ? showModal(TransferDialog, {
          wrapperType: wrapperType,
        })
      : showModal(TransferAccountType);
  };

  return (
    <TransferPromptContainer>
      <PromptCopy>Have accounts elsewhere?</PromptCopy>
      <CustomButton
        className="purple"
        fullWidth
        disabled={ButtonDisabled}
        style={{
          whiteSpace: 'break-spaces',
        }}
        onClick={handleOpenTransferForm}
      >
        Transfer to TILLIT
      </CustomButton>
    </TransferPromptContainer>
  );
}
