import Cookies from 'js-cookie';

export function ShouldShowMfaSetup(id?: string) {
  const COOKIE_NAME = id ? `${id}_viewedMfaStep` : 'viewedMfaStep';
  const SHOW_MFA_COOKIE_EXPIRY_DAYS = 7;
  const viewedMfaStep = Cookies.get(COOKIE_NAME);
  if (!viewedMfaStep) {
    Cookies.set(COOKIE_NAME, 'true', {
      expires: SHOW_MFA_COOKIE_EXPIRY_DAYS,
      secure: true,
      sameSite: 'lax',
    });
    return true;
  } else {
    return false;
  }
}
