import {
  AccountStatus,
  PensionTransferDetailsInputType,
  PensionTransferDrawdownStatus,
  PensionTransferIntentDetailsInputType,
  PensionTransferIntentDrawdownStatus,
  TransferMethod,
  WrapperType,
  useAccountsQuery,
  useCreateAnotherAccountMutation,
  useCreateTransferMutation,
  useTransfersQuery,
  useUpdateUserProfileMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { deriveAccountTypeFromWrapperType } from 'helpers/AccountTypeWrapperTypeHelpers';
import { useQueryClient } from 'react-query';

export interface TransferPensionDetails {
  safeguardedBenefits: boolean;
  anyDrawdown: boolean;
}

interface StartTransfer {
  accountType: WrapperType;
  cedingProviderId: string;
  cedingProviderAccountNumber: string;
  method: TransferMethod;
  pensionTransferDetails?: TransferPensionDetails;
}

export enum StartTransferType {
  duringOnboarding = 'During onboarding',
  asSecondAccount = 'As second account',
  forExistingAccount = 'For existing account',
}

const getPensionTransferDetailsInput = (
  pensionDetails?: TransferPensionDetails
): PensionTransferDetailsInputType | undefined => {
  return pensionDetails
    ? {
        drawdownStatus: !pensionDetails.anyDrawdown
          ? PensionTransferDrawdownStatus.NoDrawdown
          : PensionTransferDrawdownStatus.Unknown,
        hasSafeguardedRights: pensionDetails.safeguardedBenefits,
      }
    : undefined;
};

const getPensionTransferIntentDetails = (
  pensionDetails?: TransferPensionDetails
): PensionTransferIntentDetailsInputType | undefined => {
  return pensionDetails
    ? {
        drawdownStatus: !pensionDetails.anyDrawdown
          ? PensionTransferIntentDrawdownStatus.NoDrawdown
          : PensionTransferIntentDrawdownStatus.Unknown,
        hasSafeguardedRights: pensionDetails.safeguardedBenefits,
      }
    : undefined;
};

export function useTransfer() {
  const userProfileQuery = useUserProfileQuery();
  const accounts = userProfileQuery.data?.clientSummary?.accounts ?? [];
  const activeAccounts = accounts.filter(
    (acc) => acc.status === AccountStatus.Active
  );

  const queryClient = useQueryClient();

  const {
    mutateAsync: mutateUserProfileAsync,
  } = useUpdateUserProfileMutation();
  const setOnboardingTransferIntent = ({
    accountType,
    cedingProviderId,
    cedingProviderAccountNumber,
    method,
    pensionTransferDetails,
  }: StartTransfer) => {
    return mutateUserProfileAsync({
      input: {
        transferIntent: {
          accountType: deriveAccountTypeFromWrapperType(accountType),
          cedingProviderAccountNumber,
          cedingProviderId,
          method,
          pensionDetails: getPensionTransferIntentDetails(
            pensionTransferDetails
          ),
        },
      },
    });
  };

  const { mutateAsync: createAnotherAccount } = useCreateAnotherAccountMutation(
    {
      onSuccess: () => {
        queryClient.invalidateQueries(useUserProfileQuery.getKey());
        queryClient.invalidateQueries(useAccountsQuery.getKey());
        queryClient.invalidateQueries(useTransfersQuery.getKey());
      },
    }
  );
  const transferAndCreateSecondAccount = ({
    accountType,
    cedingProviderId,
    cedingProviderAccountNumber,
    method,
  }: StartTransfer) => {
    createAnotherAccount({
      input: {
        wrapperType: accountType,
        transferDetails: {
          cedingProviderAccountNumber,
          cedingProviderId,
          method,
        },
      },
    });
  };

  const { mutateAsync: createTransferMutation } = useCreateTransferMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(useTransfersQuery.getKey());
    },
  });
  const transferToExistingAccount = (
    {
      accountType,
      cedingProviderId,
      cedingProviderAccountNumber,
      method,
      pensionTransferDetails,
    }: StartTransfer,
    accountId: string
  ) => {
    return createTransferMutation({
      input: {
        cedingProviderId: cedingProviderId,
        cedingProviderAccountNumber: cedingProviderAccountNumber,
        accountId: accountId!,
        method: method,
        pensionDetails: getPensionTransferDetailsInput(pensionTransferDetails),
      },
    });
  };

  const getStartTransferType = (accountType: WrapperType) => {
    const hasAccount = !!accounts.length;

    const existingAccount = activeAccounts.find(
      (acc) => acc.wrapperType === accountType
    );

    if (!hasAccount) {
      return StartTransferType.duringOnboarding;
    }
    if (existingAccount) {
      return StartTransferType.forExistingAccount;
    }
    if (!existingAccount) {
      return StartTransferType.asSecondAccount;
    }
  };

  const startTransfer = async ({
    accountType,
    cedingProviderId,
    cedingProviderAccountNumber,
    method,
    pensionTransferDetails,
  }: StartTransfer) => {
    const startTransferType = getStartTransferType(accountType);
    const existingAccount = activeAccounts.find(
      (acc) => acc.wrapperType === (accountType as any)
    );

    if (startTransferType === StartTransferType.duringOnboarding) {
      return setOnboardingTransferIntent({
        accountType,
        cedingProviderId,
        cedingProviderAccountNumber,
        method,
        pensionTransferDetails,
      });
    }

    if (startTransferType === StartTransferType.forExistingAccount) {
      return transferToExistingAccount(
        {
          accountType,
          cedingProviderId,
          cedingProviderAccountNumber,
          method,
          pensionTransferDetails,
        },
        existingAccount?.id!
      );
    }

    if (startTransferType === StartTransferType.asSecondAccount) {
      return transferAndCreateSecondAccount({
        accountType,
        cedingProviderId,
        cedingProviderAccountNumber,
        method,
        pensionTransferDetails,
      });
    }
  };

  return { startTransfer, getStartTransferType };
}
