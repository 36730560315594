import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { QueryState } from 'components/QueryState';
import { StyledLink } from 'components/design-system/Link';
import {
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
} from 'components/design-system/StepComponents/StepComponents';
import { useMode } from 'components/feature/mode/useMode';
import { NationalInsuranceNumberStep } from 'components/feature/openAccount/steps/NationalInsuranceNumberStep';
import { useAuth } from 'context/AuthContext';
import {
  AccountAssetBuyStatus,
  Position,
  WrapperType,
  useAccountQuery,
  useAccountsQuery,
  useAssetQuery,
  usePortfolioRebalancingQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { useIsNinoRequired } from 'hooks/useIsNinoRequired';
import { mifidToInvest } from 'paths';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { PartialDeep } from 'type-fest';
import {
  AssetQueryAsset,
  UserProfileQueryClientSummaryAccounts,
} from 'types/graphqlTypes';
import {
  AddBuyOrderToBasketStep,
  OnProceedAction,
} from './steps/AddBuyOrderToBasketStep';
import { MifidStep } from './steps/MifidStep';
import { PreviewStep } from './steps/PreviewStep';
import { QuickOrderConfirm } from './steps/QuickOrderConfirm';
import { QuickOrderReceived } from './steps/QuickOrderReceived';
import { SelectAccountStepBuy } from './steps/SelectAccountStepBuy';

export interface AddBuyOrderToBasketFullFlowDialogProps
  extends ResponsiveDialogProps {
  openDialog: () => void;
  closeDialog: () => void;
  open: boolean;
  assetId: string;
  selectedAccountType?: WrapperType;
  selectedAccountId?: string;
  selectedPosition?: PartialDeep<Position>;
  selectedIsin?: string;
  handleInstrumentChange?: (isin: string) => void;
}

export interface AddBuyOrderToBasketFullFlowFormProps {
  closeDialog: () => void;
  asset: AssetQueryAsset;
  selectedAccountType?: WrapperType;
  selectedAccountId?: string;
  accounts: UserProfileQueryClientSummaryAccounts;
  selectedPosition?: PartialDeep<Position>;
  selectedIsin?: string;
  handleInstrumentChange?: (isin: string) => void;
  amountStepGoBack?: () => void;
}

interface SelectedAccount {
  selectedAccountType: WrapperType;
  selectedAccountId: string;
}

type steps =
  | 'selectAccount'
  | 'selectAmount'
  | 'postSelectAmount'
  | 'ninoRequired'
  | 'mifidRequired'
  | 'quickOrderConfirm'
  | 'quickOrderReceived'
  | 'preview';

export function AddBuyOrderToBasketFullFlowForm({
  closeDialog,
  asset,
  selectedAccountType,
  selectedAccountId,
  accounts,
  selectedPosition,
  selectedIsin,
  handleInstrumentChange,
  amountStepGoBack,
}: AddBuyOrderToBasketFullFlowFormProps) {
  const [activeStep, setActiveStep] = useState<steps | null>(null);
  const [activeAccount, setActiveAccount] = useState<SelectedAccount | null>(
    null
  );
  const [quickOrderId, setQuickOrderId] = useState<string | undefined>();

  const [mode] = useMode();

  useEffect(() => {
    if (activeStep !== null || !asset) {
      return;
    }

    if (selectedAccountType && selectedAccountId) {
      setActiveStep('selectAmount');
      setActiveAccount({
        selectedAccountType,
        selectedAccountId,
      });
      return;
    }

    if (accounts && accounts?.length === 1 && accounts[0].id) {
      setActiveStep('selectAmount');
      setActiveAccount({
        selectedAccountType: accounts[0].wrapperType,
        selectedAccountId: accounts[0].id,
      });
      return;
    }

    if (mode !== null && mode.mode !== 'resume' && mode.wrapperType) {
      setActiveStep('selectAmount');
      const account = accounts!.find(
        ({ wrapperType }) => wrapperType === mode.wrapperType
      );
      setActiveAccount({
        selectedAccountType: mode.wrapperType,
        selectedAccountId: account?.id!,
      });
      return;
    }

    if (accounts && accounts?.length > 1) {
      setActiveStep('selectAccount');
    }
  }, [
    accounts,
    activeStep,
    selectedAccountType,
    selectedAccountId,
    asset,
    mode,
  ]);

  useEffect(() => {}, [quickOrderId]);

  const isNinoRequired = useIsNinoRequired();

  const portfolioRebalancingQuery = usePortfolioRebalancingQuery(
    {
      id: quickOrderId as string, // if the query is enabled this will be a string.
    },
    {
      onSuccess: (data) => {
        if (activeStep === 'postSelectAmount') {
          if (
            data.portfolioRebalancing?.buyOrders[0].context?.status ===
            AccountAssetBuyStatus.RequiresMifidIdentifier
          ) {
            if (isNinoRequired) {
              setActiveStep('ninoRequired');
            } else {
              setActiveStep('mifidRequired');
            }
          } else {
            setActiveStep('quickOrderConfirm');
          }
        }
      },
      enabled: !!quickOrderId,
    }
  );
  const quickBuyOrderRequiresMifid =
    portfolioRebalancingQuery?.data?.portfolioRebalancing?.buyOrders[0].context
      ?.status === AccountAssetBuyStatus.RequiresMifidIdentifier;

  return (
    <>
      {activeStep === 'selectAccount' && (
        <SelectAccountStepBuy
          onProceed={({ selectedAccountType, selectedAccountId }) => {
            setActiveAccount({
              selectedAccountType,
              selectedAccountId,
            });
            setActiveStep('selectAmount');
          }}
          asset={asset}
        />
      )}
      {activeStep === 'selectAmount' && activeAccount && (
        <AddBuyOrderToBasketStep
          flow="fullFlow"
          selectedPosition={selectedPosition}
          selectedIsin={selectedIsin}
          handleInstrumentChange={handleInstrumentChange}
          onProceed={(action, orderId) => {
            if (action === OnProceedAction.addToBasket) {
              closeDialog();
            } else if (action === OnProceedAction.quickOrder) {
              setQuickOrderId(orderId);
              setActiveStep('postSelectAmount');
            }
          }}
          onGoBack={() => {
            if (amountStepGoBack) {
              amountStepGoBack();
              return;
            }
            if (!selectedAccountId && accounts && accounts.length > 1) {
              setActiveStep('selectAccount');
            } else {
              closeDialog();
            }
          }}
          asset={asset}
          {...activeAccount}
        />
      )}
      {activeStep === 'postSelectAmount' && (
        <QueryState {...portfolioRebalancingQuery}>{() => null}</QueryState>
      )}
      {activeStep === 'ninoRequired' && quickOrderId && (
        <NationalInsuranceNumberStep
          onProceed={() =>
            setActiveStep(
              quickBuyOrderRequiresMifid ? 'mifidRequired' : 'quickOrderConfirm'
            )
          }
          onGoBack={() => setActiveStep('selectAmount')}
          source="quickOrderStep"
          introduction={
            <StepIntroduction $width={StepIntroductionWidth.normal} mb={2}>
              <StepIntroductionTypography>
                To trade{' '}
                {
                  portfolioRebalancingQuery.data?.portfolioRebalancing
                    ?.buyOrders[0].instrument.name
                }
                , our regulator requires that we ask for your National Insurance
                number.{' '}
                <StyledLink href={mifidToInvest} target="_blank">
                  Learn more
                </StyledLink>
                .
              </StepIntroductionTypography>
            </StepIntroduction>
          }
        />
      )}
      {activeStep === 'mifidRequired' && quickOrderId && (
        <MifidStep
          quickOrderId={quickOrderId}
          onProceed={() => setActiveStep('quickOrderConfirm')}
          onGoBack={() => setActiveStep('selectAmount')}
        />
      )}
      {activeStep === 'quickOrderConfirm' && quickOrderId && activeAccount && (
        <QuickOrderConfirm
          quickOrderId={quickOrderId}
          {...activeAccount}
          onProceed={() => {
            setActiveStep('quickOrderReceived');
          }}
          onGoBack={() => {
            setActiveStep('selectAmount');
          }}
        />
      )}
      {activeStep === 'quickOrderReceived' && quickOrderId && activeAccount && (
        <QuickOrderReceived
          type="buy"
          quickOrderId={quickOrderId}
          {...activeAccount}
          onProceed={() => {
            closeDialog();
          }}
        />
      )}
      {activeStep === 'preview' && activeAccount && (
        <PreviewStep
          {...activeAccount}
          onProceed={() => {
            closeDialog();
          }}
        />
      )}
    </>
  );
}

export function AddBuyOrderToBasketFullFlowDialog({
  closeDialog,
  open,
  assetId,
  selectedAccountType,
  selectedAccountId,
  selectedPosition,
  selectedIsin,
  handleInstrumentChange,
}: AddBuyOrderToBasketFullFlowDialogProps) {
  const { signedIn } = useAuth();

  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });
  const accounts = userProfileQuery.data?.clientSummary?.accounts ?? [];

  const assetQuery = useAssetQuery(
    { id: parseInt(assetId) },
    { enabled: open }
  );
  const asset = assetQuery.data?.asset;

  const queryClient = useQueryClient();

  const handleCloseDialog = () => {
    // invalidate so dashboard updates
    queryClient.invalidateQueries(
      useAccountQuery.getKey({ id: selectedAccountId! })
    );
    // invalidate so basket updates
    queryClient.invalidateQueries(useAccountsQuery.getKey());

    closeDialog();
  };
  return (
    <>
      {(!selectedAccountId || (accounts && accounts?.length > 0)) && (
        <ResponsiveDialog open={open} onClose={handleCloseDialog}>
          {open && (
            <QueryState {...assetQuery}>
              {() =>
                asset ? (
                  <AddBuyOrderToBasketFullFlowForm
                    closeDialog={handleCloseDialog}
                    asset={asset}
                    selectedAccountId={selectedAccountId}
                    selectedAccountType={selectedAccountType}
                    selectedPosition={selectedPosition}
                    selectedIsin={selectedIsin}
                    handleInstrumentChange={handleInstrumentChange}
                    accounts={accounts}
                  />
                ) : null
              }
            </QueryState>
          )}
        </ResponsiveDialog>
      )}
    </>
  );
}

export const useDialog = () => {
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return { open, openDialog, closeDialog };
};
