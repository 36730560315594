import { useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import { BuyButton } from 'components/Button/BuyButton';
import { SellButton } from 'components/Button/SellButton';
import { DataPill } from 'components/Pills';
import { InfoPopover } from 'components/Popover';
import { SwipeToReveal } from 'components/SwipeToReveal/SwipeToReveal';
import { HoldingOrdersTable } from 'components/Tables/HoldingOrdersTable';
import { HoldingTabs } from 'components/Tabs';
import { DarkThemeWrapper } from 'components/Wrappers/DarkThemeWrapper';
import { colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import { currencyFull, percent } from 'formatting';
import { AccountsQuery, WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { generateFundDetailsPath } from 'paths';
import React, { useMemo, useState } from 'react';
import {
  HiDownload,
  HiOutlineChartBar,
  HiOutlineChevronDown,
  HiOutlineShoppingCart,
  HiX,
} from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { animated, useChain, useSpring, useSpringRef } from 'react-spring';
import useMeasure from 'react-use-measure';
import * as tooltips from 'strings/tooltips';
import { useTheme } from 'styled-components';
import { AnyAsset } from 'types/graphqlTypes';
import {
  Actions,
  CardDetails,
  CardHeader,
  CloseButton,
  Container,
  ContentContainer,
  DarkUniCardSummary,
  DarkUniCurrentValue,
  DarkUniFundName,
  DarkUniTotalReturn,
  DocumentLink,
  DocumentsContainer,
  DownloadIconWrapper,
  HeaderActions,
  Label,
  Main,
  PendingOrdersIndicator,
  TabSection,
  TabTitle,
  Value,
} from './HoldingCard.styles';
import { PerformanceSection } from './PerformanceSection';

type Position = Exclude<
  AccountsQuery['accounts'],
  null | undefined
>[number]['positions'][number];

interface DocumentType {
  title: string;
  url: string;
}

export interface HoldingCardProps {
  position: Position;
  accountId: string;
  canBuy?: boolean;
  canSell?: boolean;
  isClosing?: boolean;
  displayAssetName: string;
  accountType: WrapperType;
}

export function DarkUniverseHoldingCard({
  position,
  accountId,
  canBuy = true,
  canSell = true,
  isClosing = false,
  displayAssetName,
  accountType,
}: HoldingCardProps) {
  const assetName = position.instrument?.asset?.name;
  const asset = position.instrument?.asset!;
  const assetId = position.instrument?.asset?.id;
  const slug = position.instrument?.asset?.slug;
  const currentValue = position.currentValue;
  const totalReturn = position.growthProportion;
  const allocation = position.allocation;
  const isin = position.isin;

  const [open, setOpen] = useState(false);
  const [didOpen, setDidOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('performance');

  const theme = useTheme();
  const atLeastTablet = useMediaQuery(theme.breakpoints.up('sm'));
  const atLeastDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [summaryRef, { height: summaryHeight }] = useMeasure();
  const [detailsRef, { height: detailsHeight }] = useMeasure();

  const tabs = useMemo(
    () => [
      {
        label: <HiOutlineChartBar />,
        value: 'performance',
      },
      {
        label: <HiOutlineShoppingCart />,
        value: 'orders',
      },
    ],
    []
  );

  const containerStyles = useSpring(
    open && !atLeastTablet ? { margin: '0px -20px' } : { margin: '0px 0px' }
  );

  const getHorizontalPadding = () => {
    if (atLeastDesktop) {
      return 5;
    } else {
      return 2.5;
    }
  };

  const getTopPadding = () => {
    if (atLeastDesktop) {
      return 10.75;
    } else if (atLeastTablet) {
      return 5.625;
    } else {
      return 4.5;
    }
  };

  const getBottomPadding = () => {
    if (atLeastDesktop) {
      return 5;
    } else if (atLeastTablet) {
      return 2.5;
    } else {
      return 1.25;
    }
  };

  const mainStyles = useSpring(
    open
      ? {
          minWidth: '100%',
          padding: theme.spacing(
            getTopPadding(),
            getHorizontalPadding(),
            getBottomPadding()
          ),
          height:
            detailsHeight +
            theme.spacing(getTopPadding()) +
            theme.spacing(getBottomPadding()),
          onRest: () => setDidOpen(true),
        }
      : {
          minWidth: '0%',
          padding: atLeastDesktop
            ? theme.spacing(2.25, 2.5, 2.25)
            : theme.spacing(1.25, 1.25, 1.25),
          height: Math.max(
            64,
            summaryHeight + theme.spacing(atLeastDesktop ? 2.5 : 1.25) * 2
          ),
          //immediate: true,
        }
  );

  const summaryAnimationRef = useSpringRef();
  const summaryProps = useSpring({
    opacity: open ? 0 : 1,
    ref: summaryAnimationRef,
  });
  const detailsAnimationRef = useSpringRef();
  const detailsProps = useSpring({
    opacity: open ? 1 : 0,
    ref: detailsAnimationRef,
  });

  useChain(
    open
      ? [summaryAnimationRef, detailsAnimationRef]
      : [detailsAnimationRef, summaryAnimationRef]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDidOpen(false);
  };

  const handleBuy = () => {
    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'buy',
      ecommerce: {
        items: [
          {
            item_id: assetId,
            item_name: assetName,
            affiliation: 'Tillit',
            currency: 'GBP',
            item_brand: '', // @todo - can we get these?
            item_category: '',
            item_category2: '',
            item_category3: '',
            item_category4: '',
            item_category5: '',
            item_variant: accountType,
          },
        ],
      },
    });
  };

  const handleSell = () => {
    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'sell',
      ecommerce: {
        items: [
          {
            item_id: assetId,
            item_name: assetName,
            affiliation: 'Tillit',
            currency: 'GBP',
            item_brand: '', // @todo - can we get these?
            item_category: '',
            item_category2: '',
            item_category3: '',
            item_category4: '',
            item_category5: '',
            item_variant: accountType,
          },
        ],
      },
    });
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const activeTabContent = useMemo(() => {
    switch (activeTab) {
      case 'orders':
        return (
          <>
            <TabTitle>Orders</TabTitle>
            <HoldingOrdersTable accountId={accountId} isin={isin!} />
          </>
        );
      default:
        return <PerformanceSection accountId={accountId} position={position} />;
    }
  }, [accountId, activeTab, isin, position]);

  const documents = useMemo(() => {
    const docs: Array<DocumentType> = [];

    if (position.instrument?.kiidDocumentUrl) {
      docs.push({
        title:
          position.instrument?.instrumentType === 'Fund'
            ? 'Key Investor Information Document'
            : 'Key Information Document',
        url: position.instrument?.kiidDocumentUrl,
      });
    }

    if (position.instrument?.factsheetUrl) {
      docs.push({ title: 'Factsheet', url: position.instrument?.factsheetUrl });
    }

    return docs;
  }, [
    position.instrument?.factsheetUrl,
    position.instrument?.instrumentType,
    position.instrument?.kiidDocumentUrl,
  ]);

  const DataPillArgs = [
    {
      value: currencyFull(currentValue!),
      label: 'Current value',
    },
    {
      value: percent(allocation!),
      label: (
        <>
          Holding size
          <InfoPopover
            color="primary"
            size="small"
            content={tooltips.HoldingSize}
          />
        </>
      ),
    },
  ];

  interface ActionButtonProps {
    asset: AnyAsset;
    isin?: string | null;
    accountId?: string;
    accountType?: WrapperType;
  }

  const actionButtons = ({
    asset,
    isin,
    accountId,
    accountType,
  }: ActionButtonProps) => {
    const isActive = position.instrument?.status === 'Active';

    const actions = [];

    if (isActive) {
      actions.push(
        <SellButton
          canSell={canSell}
          isin={position?.instrument?.isin}
          accountId={accountId}
          accountType={accountType}
          selectedPosition={position}
          asset={asset!}
          $color="secondary"
          onClick={handleSell}
          hover={false}
          size="small"
        />
      );
    }

    if (!isClosing && isActive) {
      actions.push(
        <BuyButton
          assetId={asset!.id?.toString() || ''}
          selectedAccountId={accountId}
          selectedAccountType={accountType}
          selectedIsin={isin || undefined}
          small
          size="small"
          onClick={handleBuy}
          label="Buy more"
          hover={false}
        />
      );
    }

    return actions;
  };

  return (
    <DarkThemeWrapper>
      <SwipeToReveal
        enabled={!open}
        actions={actionButtons({ asset, isin, accountId, accountType })}
      >
        <Container className={clsx(open && 'open')} style={containerStyles}>
          <Main $backgroundColor={colors.richBlack} style={mainStyles}>
            <CloseButton onClick={handleClose} style={detailsProps}>
              <HiX />
            </CloseButton>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                minHeight: '100%',
              }}
            >
              <CardDetails style={detailsProps}>
                <div ref={detailsRef}>
                  <CardHeader>
                    <DarkUniFundName>
                      <Link
                        to={generateFundDetailsPath({
                          id: assetId!,
                          slug: slug!,
                        })}
                      >
                        {displayAssetName}
                      </Link>
                    </DarkUniFundName>
                    <DataPill size="small" dark={true} content={DataPillArgs} />
                    <HeaderActions>
                      {actionButtons({
                        asset,
                        isin,
                        accountId,
                        accountType,
                      }).map((action, i) => (
                        <React.Fragment key={i}>{action}</React.Fragment>
                      ))}
                    </HeaderActions>
                  </CardHeader>

                  {didOpen && (
                    <>
                      {!!documents.length ? (
                        <DocumentsContainer>
                          {documents.map((d) => (
                            <DocumentLink
                              key={d.title}
                              target="_blank"
                              href={d.url}
                            >
                              {d.title}
                              <DownloadIconWrapper>
                                <HiDownload />
                              </DownloadIconWrapper>
                            </DocumentLink>
                          ))}
                        </DocumentsContainer>
                      ) : null}
                      <TabSection>
                        <HoldingTabs
                          value={activeTab}
                          onChange={handleTabChange}
                          tabs={tabs}
                          assetClass={'darkUniverse'}
                        />
                        <ContentContainer>{activeTabContent}</ContentContainer>
                      </TabSection>
                    </>
                  )}
                </div>
              </CardDetails>
              <DarkUniCardSummary onClick={handleOpen} style={summaryProps}>
                <div ref={summaryRef}>
                  <DarkUniFundName>
                    {position.hasPendingOrders && <PendingOrdersIndicator />}
                    {displayAssetName}
                  </DarkUniFundName>
                </div>
                <HiOutlineChevronDown size={30} />
              </DarkUniCardSummary>
            </div>
          </Main>

          <DarkUniCurrentValue $backgroundColor={colors.richBlack}>
            <animated.div style={summaryProps}>
              <Value>{currencyFull(currentValue!)}</Value>
              <Label>Current value</Label>
            </animated.div>
          </DarkUniCurrentValue>

          <DarkUniTotalReturn $backgroundColor={colors.richBlack}>
            <animated.div style={summaryProps}>
              <Value>{percent(totalReturn!, true)}</Value>
              <Label>Growth</Label>
            </animated.div>
          </DarkUniTotalReturn>

          <Actions>
            {actionButtons({ asset, isin, accountId, accountType }).map(
              (action, i) => (
                <React.Fragment key={i}>{action}</React.Fragment>
              )
            )}
          </Actions>
        </Container>
      </SwipeToReveal>
    </DarkThemeWrapper>
  );
}
