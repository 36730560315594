import { useMediaQuery } from '@material-ui/core';
import {
  getBuyEstimate,
  getSellEstimate,
} from 'components/Dialogs/Trading/helpers';
import { StyledLink } from 'components/design-system/Link';
import {
  FontSize,
  FontWeight,
  Text,
  TextAlign,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import { getKidDocName } from 'components/feature/PortfolioBuilder/_shared/copyHelpers';
import { useCombinedFundBreakdown } from 'components/feature/PortfolioBuilder/hooks/useGetBreakdowns';
import { MinTradeUnitStatus } from 'components/feature/autoSaveInvest/regularInvest/_shared/MinTradeUnitStatus';
import { useAuth } from 'context/AuthContext';
import * as format from 'formatting';
import {
  Account,
  PortfolioRebalancingStatus,
  Position,
  RebalancingTransactionStatus,
  useAccountsQuery,
} from 'generated/graphql';
import { getOrderInstrumentShareClass } from 'helpers/instrumentNaming';
import { max } from 'lodash';
import orderBy from 'lodash/orderBy';
import { generateFundDetailsPath } from 'paths';
import { useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { PartialDeep } from 'type-fest';
import {
  AnyRebalancing,
  AnyRebalancingBuyOrder,
  ListRebalancingBuyOrder,
  RebalancingQueryBuyOrder,
} from 'types/graphqlTypes';
import {
  CancelledCross,
  CompletedCheck,
  FundNameWrapper,
  InProgressHourglass,
  OpenInNew,
  StyledChevronButton,
} from '../../../Checkout/steps/OrderConfirmation/OrderConfirmation.styles';
import {
  DetailsContainer,
  DetailsLayout,
  ItemDetails,
  OverViewContainerBuyHeader,
  OverviewBuyContainer,
  RowContainer,
} from '../../../Checkout/steps/OrderConfirmation/OrderDetails.styles';
import { ShareClassInfoPopover } from '../ShareClassInfoPopover';
import { ErrorContainer, SmallCopyWrapper } from './OrderStatusTable.styles';

interface BuyOrderDetailsProps {
  portfolioRebalancing: AnyRebalancing;
  selectedAccount: PartialDeep<Account>;
}
export const BuyOrderDetails = ({
  portfolioRebalancing,
  selectedAccount,
}: BuyOrderDetailsProps) => {
  const { signedIn } = useAuth();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const accountsQuery = useAccountsQuery(undefined, { enabled: signedIn });
  const positionsData = accountsQuery?.data?.accounts?.filter(
    (a) => a.wrapperType === selectedAccount.wrapperType
  )[0].positions;
  const { totalBasketAmount } = useCombinedFundBreakdown(
    selectedAccount.id!,
    selectedAccount.wrapperType!
  );

  const sortedBuyOrders = orderBy(portfolioRebalancing.buyOrders, [
    (bo: AnyRebalancingBuyOrder) => bo?.instrument?.asset?.assetClass,
    (bo: AnyRebalancingBuyOrder) => bo?.instrument?.asset?.name,
  ]);

  const allBuyOrdersFinished =
    portfolioRebalancing.status === PortfolioRebalancingStatus.Cancelled ||
    portfolioRebalancing.status === PortfolioRebalancingStatus.Completed;

  return (
    <div>
      {isMdUp && (
        <RowContainer>
          <OverViewContainerBuyHeader $allCompleted={allBuyOrdersFinished}>
            <TextSmall $noMargin $fontWeight={FontWeight.medium}>
              Instrument
            </TextSmall>

            <TextSmall $noMargin $fontWeight={FontWeight.medium}>
              Asset class
            </TextSmall>

            <TextSmall $noMargin $fontWeight={FontWeight.medium}>
              Share class <ShareClassInfoPopover size="small" disableMargin />
            </TextSmall>

            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.medium}
              $textAlign={TextAlign.right}
              $noMargin
            >
              Amount
            </Text>

            <Text
              $fontSize={FontSize.small}
              $fontWeight={FontWeight.medium}
              $textAlign={TextAlign.right}
              $noMargin
            >
              {allBuyOrdersFinished ? `Quantity` : `Est. quantity`}
            </Text>
          </OverViewContainerBuyHeader>
        </RowContainer>
      )}
      <div>
        {sortedBuyOrders.map((buyOrder) => {
          const position = positionsData?.find(
            (p) => p?.instrument?.isin! === buyOrder?.isin!
          );
          return buyOrder ? (
            <BuyOrderItemDetails
              key={buyOrder?.isin}
              buyOrder={buyOrder}
              position={position}
              portfolioRebalancing={portfolioRebalancing}
              totalBasketAmount={totalBasketAmount}
              selectedAccount={selectedAccount}
            />
          ) : null;
        })}
      </div>
    </div>
  );
};

interface BuyOrderItemDetailsProps {
  portfolioRebalancing: AnyRebalancing;
  buyOrder: AnyRebalancingBuyOrder;
  position: PartialDeep<Position> | undefined;
  totalBasketAmount: number;
  selectedAccount: PartialDeep<Account>;
}

function isRebalancingQueryBuyOrder(
  buyOrder: RebalancingQueryBuyOrder | ListRebalancingBuyOrder
): buyOrder is RebalancingQueryBuyOrder {
  return (buyOrder as RebalancingQueryBuyOrder).context !== undefined;
}

export const BuyOrderItemDetails = ({
  buyOrder,
  position,
  portfolioRebalancing,
  totalBasketAmount,
  selectedAccount,
}: BuyOrderItemDetailsProps) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [showDetails, setShowDetails] = useState(false);

  const isPortfolioRebalancingCancelled =
    portfolioRebalancing.status === PortfolioRebalancingStatus.Cancelled;
  const buyOrderComplete =
    buyOrder.status === RebalancingTransactionStatus.Completed;
  const allBuyOrdersFinished =
    portfolioRebalancing.status === PortfolioRebalancingStatus.Cancelled ||
    portfolioRebalancing.status === PortfolioRebalancingStatus.Completed;

  const buyOrderInProgress =
    buyOrder.status &&
    [
      RebalancingTransactionStatus.Pending,
      RebalancingTransactionStatus.Waiting,
    ].includes(buyOrder.status) &&
    !isPortfolioRebalancingCancelled;

  const instrument = buyOrder.instrument;

  const context = isRebalancingQueryBuyOrder(buyOrder)
    ? buyOrder.context
    : undefined;

  const charges = context ? context.charges : undefined;
  const askPrice = instrument?.askPrice! * instrument?.quoteUnit! ?? 0;

  const existingUnits = position?.quantity ?? 0;
  const boughtUnits =
    buyOrderComplete && buyOrder.executedQuantity
      ? buyOrder.executedQuantity
      : getBuyEstimate(instrument!, buyOrder.amount!, charges!);
  const totalUnits = boughtUnits + existingUnits;
  const currentValue = getSellEstimate(instrument!, existingUnits);
  const currentPositionSize = position?.allocation;

  const currentMarketValue =
    selectedAccount?.valuationSummary?.marketValue || 0;
  const availableCash = selectedAccount?.valuationSummary?.uninvestedCash || 0;

  const currentInvestments = currentMarketValue - availableCash;
  const totalInvestmentsIncludingBasket =
    currentInvestments + totalBasketAmount;

  const percentageOf =
    max([currentMarketValue, totalInvestmentsIncludingBasket]) || 0;
  const estimatedPositionSize =
    (currentValue + buyOrder.amount!) / percentageOf;

  const totalCost = askPrice * boughtUnits;
  const fundFees = totalCost * ((instrument?.ongoingChargePercent ?? 0) / 100);
  const tillitFee = charges?.tillitFeeProportion
    ? totalCost * charges?.tillitFeeProportion
    : null;
  const totalFees = tillitFee ? fundFees + tillitFee : null;

  const kiidUrl = instrument?.kiidDocumentUrl;
  const factsheetUrl = instrument?.factsheetUrl;
  const contractNoteUrl = buyOrder?.contractNoteUrl;

  const shareClassRaw = instrument
    ? getOrderInstrumentShareClass(
        instrument,
        instrument?.asset?.instruments?.nodes ?? [],
        true
      )
    : null;
  const shareClass = shareClassRaw ? shareClassRaw : 'NA';

  return (
    <div>
      <OverviewBuyContainer
        $showDetails={showDetails}
        $completed={buyOrderComplete || isPortfolioRebalancingCancelled}
        onClick={() => {
          setShowDetails(!showDetails);
        }}
        $allCompleted={allBuyOrdersFinished}
      >
        <FundNameWrapper $noMargin>
          {isPortfolioRebalancingCancelled && <CancelledCross size="1rem" />}
          {buyOrderComplete && <CompletedCheck size="1rem" />}
          {buyOrder.status === RebalancingTransactionStatus.Pending && (
            <InProgressHourglass size="1rem" />
          )}
          <div>
            {isMdUp && (
              <TextXS
                $noMargin
                as={Link}
                to={generateFundDetailsPath({
                  id: instrument?.asset?.id!,
                  slug: instrument?.asset?.slug!,
                })}
              >
                <strong>{buyOrder.instrument?.name}</strong> <OpenInNew />
              </TextXS>
            )}
            {!isMdUp && (
              <TextSmall $noMargin as="span">
                <strong>{buyOrder.instrument?.name}</strong>
              </TextSmall>
            )}
          </div>
        </FundNameWrapper>
        <ItemDetails label="Asset class">
          {buyOrder.instrument?.asset?.assetClass?.name}
        </ItemDetails>
        <ItemDetails label="Share class">{shareClass}</ItemDetails>
        <ItemDetails label="Amount" isNumber>
          {buyOrderComplete
            ? format.currencyFull(buyOrder.executedAmount!)
            : format.currencyFull(buyOrder.amount!)}
        </ItemDetails>
        <ItemDetails
          label={
            allBuyOrdersFinished
              ? `${instrument?.instrumentType === 'Fund' ? 'Units' : 'Shares'}`
              : `Est. ${
                  instrument?.instrumentType === 'Fund' ? 'units' : 'shares'
                }`
          }
          isNumber
        >
          {buyOrder.status !== RebalancingTransactionStatus.Completed &&
          portfolioRebalancing.status === PortfolioRebalancingStatus.Cancelled
            ? '-'
            : format.units(boughtUnits)}
        </ItemDetails>
        <StyledChevronButton $completed={buyOrderComplete}>
          {showDetails ? (
            <HiChevronUp size="2rem" title="Open" />
          ) : (
            <HiChevronDown size="2rem" title="Close" />
          )}
        </StyledChevronButton>
        <ErrorContainer>
          {instrument && instrument.askPrice && selectedAccount.wrapperType && (
            <MinTradeUnitStatus
              variant="popover"
              amount={buyOrder.amount}
              askPrice={instrument.askPrice}
              minimumTradeUnit={instrument.minimumTradeUnit}
              fontSize={FontSize.small}
              wrapperType={selectedAccount.wrapperType}
            />
          )}
        </ErrorContainer>
      </OverviewBuyContainer>
      <DetailsContainer
        $showDetails={showDetails}
        $completed={buyOrderComplete}
      >
        <DetailsLayout $showDetails={showDetails}>
          {buyOrder.completedUtc && (
            <SmallCopyWrapper>
              <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                Completed:
              </TextSmall>{' '}
              <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                {format.date(buyOrder.completedUtc)}
              </TextSmall>
            </SmallCopyWrapper>
          )}
          {buyOrderInProgress && (
            <>
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Current position size:
                </TextSmall>{' '}
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {existingUnits}{' '}
                  {instrument?.instrumentType === 'Fund' ? 'units' : 'shares'}
                </TextSmall>
              </SmallCopyWrapper>
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Current value:{' '}
                </TextSmall>
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {format.currencyFull(currentValue)}
                </TextSmall>
              </SmallCopyWrapper>
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Est. new position:
                </TextSmall>{' '}
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {format.percent(estimatedPositionSize)}{' '}
                </TextSmall>
              </SmallCopyWrapper>
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Last price update:
                </TextSmall>{' '}
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {format.date(instrument?.priceDate)}
                </TextSmall>
              </SmallCopyWrapper>
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Est. fund fee (
                  {format.percent(
                    (instrument?.ongoingChargePercent ?? 0) / 100
                  )}
                  pa):{' '}
                </TextSmall>
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {`${format.currencyFull(fundFees)} per annum`}{' '}
                </TextSmall>
              </SmallCopyWrapper>
              {totalFees && (
                <SmallCopyWrapper>
                  <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                    Est. total fee:{' '}
                  </TextSmall>
                  <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                    {format.currencyFull(totalFees)} per annum
                  </TextSmall>
                </SmallCopyWrapper>
              )}
              {charges?.tillitFeeProportion && tillitFee && (
                <SmallCopyWrapper>
                  <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                    Est. TILLIT fee (
                    {format.percent(charges?.tillitFeeProportion)} pa):{' '}
                  </TextSmall>
                  <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                    {format.currencyFull(tillitFee)} per annum{' '}
                  </TextSmall>
                </SmallCopyWrapper>
              )}
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Est. new position size:{' '}
                </TextSmall>
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {format.units(totalUnits)}{' '}
                  {instrument?.instrumentType === 'Fund' ? 'units' : 'shares'}
                </TextSmall>
              </SmallCopyWrapper>
              <SmallCopyWrapper>
                <TextSmall $noMargin $fontWeight={FontWeight.medium}>
                  Current position size:
                </TextSmall>{' '}
                <TextSmall $noMargin $fontWeight={FontWeight.normal}>
                  {format.percent(currentPositionSize!)}
                </TextSmall>
              </SmallCopyWrapper>
            </>
          )}
          {!!kiidUrl && (
            <TextXS $noMargin>
              <StyledLink href={kiidUrl} target="_blank">
                {getKidDocName(instrument?.instrumentType!)}
              </StyledLink>
            </TextXS>
          )}
          {!!factsheetUrl && (
            <TextXS $noMargin>
              <StyledLink href={factsheetUrl} target="_blank">
                Factsheet
              </StyledLink>
            </TextXS>
          )}
          {!!contractNoteUrl && (
            <TextXS $noMargin>
              <StyledLink href={contractNoteUrl} target="_blank">
                Contract note
              </StyledLink>
            </TextXS>
          )}
        </DetailsLayout>
      </DetailsContainer>
    </div>
  );
};
