import React, { useState } from 'react';
import {
  TabContainer,
  TabHeading,
  TabList,
  TabPanel,
  TabPanelContainer,
  TabTitle,
} from './Tabs.styles';
import { FontSize } from '../Text/Text';

interface Tab {
  title: string;
  content: React.ReactNode;
}

interface TabsProps {
  isBorderless?: boolean;
  stretchHeadings?: boolean;
  headingSize?: FontSize;
  title: React.ReactNode;
  tabs: Tab[];
  onClick?: (title: string) => void;
}

export const Tabs = ({
  isBorderless = false,
  stretchHeadings = false,
  headingSize = FontSize.normal,
  title,
  tabs = [],
  onClick,
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const isLastTabActive = tabs.length === activeTab + 1;

  return (
    <TabContainer>
      <TabTitle>{title}</TabTitle>
      <TabList stretchHeadings={stretchHeadings}>
        {tabs.map((tab, index) => {
          return (
            <TabHeading
              key={index}
              aria-selected={activeTab === index}
              aria-controls={`panel:r0:${index}`}
              id={`tab:r0:${index}`}
              onClick={() => {
                setActiveTab(index);
                if (onClick) {
                  onClick(tab.title);
                }
              }}
              $fontSize={headingSize}
              $active={activeTab === index}
              isBorderless={isBorderless}
              stretchHeadings={stretchHeadings}
            >
              {tab.title}
            </TabHeading>
          );
        })}
      </TabList>
      <TabPanelContainer
        $isLastTabActive={isLastTabActive}
        isBorderless={isBorderless}
      >
        {tabs.map((tab, index) => {
          return (
            <TabPanel
              aria-labelledby={`tab:r0:${index}`}
              id={`panel:r0:${index}`}
              role="tabpanel"
              key={index}
              $active={activeTab === index}
              isBorderless={isBorderless}
            >
              {activeTab === index && tab.content}
            </TabPanel>
          );
        })}
      </TabPanelContainer>
    </TabContainer>
  );
};
