import { useMediaQuery } from '@material-ui/core';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { GaEventNames } from 'constants/gaConstants';
import { FiltersQuery, useFiltersQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useAssetsFilter } from 'hooks/useAssetsFilter';
import _ from 'lodash';
import { useTheme } from 'styled-components';
import { FilterMenu } from './FilterMenu';
import { IconFilters } from './IconFilters';
import { FilterActions, FiltersGrid, VideoAskSpacer } from './_shared';

type FilterNode = Exclude<
  FiltersQuery['tagCategories'],
  undefined | null
>['nodes'][number];

const splitFiltersData = (nodes: FilterNode[]) => {
  const filters = [] as FilterNode[];

  // filter out icon filters
  if (!!nodes.length) {
    nodes.forEach((node) => {
      if (node.code !== 'ISNEW' && node.code !== 'HASFULLVIDEO') {
        filters.push(node);
      }
    });
  }

  // order filters - investment goals first, asset class second, then the rest
  const sortedFilters = _.sortBy(filters, ({ code }) => {
    if (code === 'INVGOAL') {
      return -2;
    } else if (code === 'CLASS') {
      return -1;
    }
    return 0;
  });

  return sortedFilters;
};

interface FiltersProps {
  updateCount?: (count: number) => void;
  variant: 'portfolio-builder' | 'list' | 'slider';
}
export function Filters({ updateCount, variant }: FiltersProps) {
  const { variables, update } = useAssetsFilter();
  const filtersQuery = useFiltersQuery();

  const trackFilters = (label: string, selectedOptions: string[] = []) => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'fund filter',
      item_id: selectedOptions.join(', '),
      filter_group: label,
    });
  };

  const handleChange = (
    name: string,
    value: string[],
    label: string,
    selectedOptions: string[]
  ) => {
    const categoryTags =
      filtersQuery.data?.tagCategories?.nodes
        .find((cat) => cat.code === name)
        ?.tags?.nodes?.map((t) => t.code) ?? [];
    variables.tags = _(variables.tags)
      .differenceBy(categoryTags, (tag) => tag.replace('+', ''))
      .union(value)
      .value();
    trackFilters(label, selectedOptions);
    update(variables);
    updateCount?.(variables.tags.length);
  };

  const sortedFilters = splitFiltersData(
    filtersQuery.data?.tagCategories?.nodes ?? []
  );

  const handleClear = async () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'clear filters',
    });

    variables.tags = undefined;
    update(variables);
    await setTimeout(() => window.scrollTo(0, 0), 500);
  };

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <FiltersGrid $variant={variant}>
        {sortedFilters.map((cat) => {
          const options =
            _.orderBy(
              cat.tags?.nodes,
              (t) => t.order ?? Number.MAX_SAFE_INTEGER
            )
              .filter(
                (n) =>
                  n.assets.totalCount > 0 &&
                  ![
                    'DIFFERENTEX',
                    'INCBOOSTERSEX',
                    'INVFORDECADESEX',
                    'READYMADEEX',
                    'RETURNSLEDBYVALEX',
                    'SHIELDPROTECTEX',
                  ].includes(n.code)
              )
              .map((n) => ({
                value: n.code,
                label: n.name,
                totalCount: n.assets.totalCount,
                // disabled:
                //   n.requirements !== null &&
                //   n.requirements !== undefined &&
                //   _.difference(n.requirements, variables.tags ?? []).length === 0,
              })) ?? [];
          return (
            <FilterMenu
              key={cat.code}
              name={cat.code}
              label={cat.name}
              value={_.intersectionWith(
                variables.tags as Array<string>,
                cat.tags.nodes,
                (a, b) => a.includes(b.code)
              )}
              options={options}
              onChange={handleChange}
              variant={variant}
            />
          );
        })}
      </FiltersGrid>
      <FilterActions $variant={variant}>
        {(!isMdUp || variant === 'list') && (
          <div style={{ margin: '0.5rem 0' }}>
            <IconFilters />
          </div>
        )}

        <CustomButtonV2
          className="filter-glossary"
          $color="secondary"
          onClick={handleClear}
          $noHover={true}
          disabled={!variables?.tags || variables?.tags?.length === 0}
        >
          Clear filters
        </CustomButtonV2>
        {isMdUp && variant === 'list' && <VideoAskSpacer />}
      </FilterActions>
    </>
  );
}
