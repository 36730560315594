import { QueryState } from 'components/QueryState';
import { StyledLink } from 'components/design-system/Link';
import {
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
} from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames } from 'constants/gaConstants';
import {
  AccountAssetBuyStatus,
  PortfolioRebalancing,
  PortfolioRebalancingStatus,
  RebalancingBuyValidationError,
  RebalancingSellValidationError,
  useAccountsQuery,
  usePortfolioRebalancingQuery,
  usePortfolioRebalancingsQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import { useIsNinoRequired } from 'hooks/useIsNinoRequired';
import { NotFound } from 'pages/NotFound/NotFound';
import {
  generateDynamicPortfolioConstructionBasketPath,
  generateDynamicPortfolioConstructionPath,
  mifidToInvest,
} from 'paths';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { PartialDeep } from 'type-fest';
import {
  AccountsQueryAccount,
  RebalancingQueryRebalancing,
} from 'types/graphqlTypes';
import { trackCheckoutProgress } from '../PortfolioBuilder/_shared/trackingHelpers';
import { NationalInsuranceNumberStep } from '../openAccount/steps/NationalInsuranceNumberStep';
import {
  CheckoutContainer,
  CheckoutWrapper,
  CheckoutWrapperContainer,
} from './Checkout.styles';
import { DpFlowTypes } from './flowType';
import { GeneralError } from './steps/GeneralError/GeneralError';
import { ISAAllowanceLimit } from './steps/ISAAllowanceLimit/ISAAllowanceLimit';
import { InsufficientCash } from './steps/InsufficientCash/InsufficientCash';
import { MiFidRequired } from './steps/MiFidRequired/MiFidRequired';
import { OrderConfirmation } from './steps/OrderConfirmation/OrderConfirmation';
import { OrderReceived } from './steps/OrderReceived/OrderReceived';
import { PotentialFundingShortfall } from './steps/PotentialFundingShortfall/PotentialFundingShortfall';
import { UnitPriceChanges } from './steps/UnitPriceChanges/UnitPriceChanges';

export enum DPCheckoutSteps {
  error = 'error',
  emptyBasket = 'emptyBasket',
  unitPriceChanges = 'unitPriceChanges',
  insufficientCash = 'insufficientCash',
  potentialFundingShortfall = 'potentialFundingShortfall',
  ninoStep = 'ninoStep',
  mifidStep = 'mifidStep',
  isaAllowanceLimit = 'isaAllowanceLimit',
  orderConfirmation = 'orderConfirmation',
  orderReceived = 'orderReceived',
  invalidState = 'invalidState',
}

interface FormFlow {
  flowType: DpFlowTypes;
  requiresMifid: boolean;
  requiresNino: boolean;
  requiresUnitChangesStep: boolean;
}

const getFlowFirstStep = (flowType: DpFlowTypes): DPCheckoutSteps => {
  const lookUpStepStartStep = {
    [DpFlowTypes.sellsOnly]: DPCheckoutSteps.orderConfirmation,
    [DpFlowTypes.buysOnly]: DPCheckoutSteps.orderConfirmation,
    [DpFlowTypes.buysOnlyInsufficientCash]: DPCheckoutSteps.insufficientCash,
    [DpFlowTypes.buysAndSells]: DPCheckoutSteps.orderConfirmation,
    [DpFlowTypes.buysAndSellsExpectedSufficientCash]:
      DPCheckoutSteps.potentialFundingShortfall,
    [DpFlowTypes.buysAndSellsInsufficientCash]:
      DPCheckoutSteps.insufficientCash,
    [DpFlowTypes.returningToBuys]: DPCheckoutSteps.orderConfirmation,
    [DpFlowTypes.returningSellsLessThanExpected]:
      DPCheckoutSteps.insufficientCash, // @todo - add actual funding shortfall step
    [DpFlowTypes.returningToBuysInsufficientCash]:
      DPCheckoutSteps.insufficientCash, // @todo - add 'Insufficient cash on resuming step step'
    [DpFlowTypes.isaLimitExceededWithBasket]: DPCheckoutSteps.isaAllowanceLimit,
    [DpFlowTypes.invalidState]: DPCheckoutSteps.invalidState,
    [DpFlowTypes.openAccount]: DPCheckoutSteps.insufficientCash,
  };
  return lookUpStepStartStep[flowType] || DPCheckoutSteps.invalidState;
};

const deriveFormFlow = (
  portfolioRebalancing: PartialDeep<PortfolioRebalancing>,
  availableCash: number,
  wrapperType: string,
  mifidSupplied: boolean,
  isNinoRequired: boolean
): FormFlow => {
  const hasBuyOrders = (portfolioRebalancing?.buyOrders || []).length > 0;
  const buyValidationErrors = portfolioRebalancing.buyValidationErrors;
  const hasSellOrders = (portfolioRebalancing?.sellOrders || []).length > 0;

  const buyTotal =
    portfolioRebalancing.buyOrders?.reduce((acc, buyOrder) => {
      return acc + (buyOrder?.amount || 0);
    }, 0) || 0;
  const sellTotal =
    portfolioRebalancing.sellOrders?.reduce((acc, sellOrder) => {
      return acc + (sellOrder?.enteredAmount || 0);
    }, 0) || 0;
  const orderTotal = buyTotal - sellTotal;
  const buysRequireMifid = portfolioRebalancing.buyOrders?.some(
    (buyOrder) =>
      buyOrder?.context?.status ===
      AccountAssetBuyStatus.RequiresMifidIdentifier
  );

  const requiresUnitChangesStep =
    portfolioRebalancing.status === PortfolioRebalancingStatus.Draft &&
    !!portfolioRebalancing.sellOrders &&
    portfolioRebalancing.sellOrders.some((sellOrder) => {
      return sellOrder?.quotedBid !== sellOrder?.instrument?.bidPrice;
    });

  const formFlow: Pick<
    FormFlow,
    'requiresMifid' | 'requiresNino' | 'requiresUnitChangesStep'
  > = {
    requiresMifid: !!(hasBuyOrders && buysRequireMifid && !mifidSupplied),
    requiresNino: !!(hasBuyOrders && buysRequireMifid && isNinoRequired),
    requiresUnitChangesStep,
  };

  if (['new-gia', 'new-isa'].includes(wrapperType)) {
    return { flowType: DpFlowTypes.openAccount, ...formFlow };
  }

  if (
    buyValidationErrors?.includes(
      RebalancingBuyValidationError.ExceedsIsaAllowance
    )
  ) {
    return { flowType: DpFlowTypes.isaLimitExceededWithBasket, ...formFlow };
  }

  /**
   * Buys only flows:
   */
  if (hasBuyOrders && !hasSellOrders) {
    if (
      buyValidationErrors?.includes(
        RebalancingBuyValidationError.InsufficientFunds
      )
    ) {
      return { flowType: DpFlowTypes.buysOnlyInsufficientCash, ...formFlow };
    }

    if (portfolioRebalancing.canBuy) {
      return { flowType: DpFlowTypes.buysOnly, ...formFlow };
    }
  }

  /**
   * Sells only flows:
   */
  if (!hasBuyOrders && hasSellOrders) {
    if (
      portfolioRebalancing.sellValidationErrors?.includes(
        RebalancingSellValidationError.InvalidStatus
      )
    ) {
      return { flowType: DpFlowTypes.invalidState, ...formFlow };
    }
    if (portfolioRebalancing.canSell) {
      return { flowType: DpFlowTypes.sellsOnly, ...formFlow };
    }
  }

  /**
   * Buy and sell flows - returning to buys flows:
   */
  if (
    hasBuyOrders &&
    hasSellOrders &&
    portfolioRebalancing.status === PortfolioRebalancingStatus.Waiting
  ) {
    // @todo - distinguish between returningSellsLessThanExpected AND returningToBuysInsufficientCash
    if (
      buyValidationErrors?.includes(
        RebalancingBuyValidationError.InsufficientFunds
      )
    ) {
      if (portfolioRebalancing.didNotRaiseEnough) {
        return {
          flowType: DpFlowTypes.returningSellsLessThanExpected,
          ...formFlow,
        };
      } else {
        return {
          flowType: DpFlowTypes.returningToBuysInsufficientCash,
          ...formFlow,
        };
      }
    }

    return {
      flowType: DpFlowTypes.returningToBuys,
      ...formFlow,
    };
  }

  /**
   * Buy and sell flows:
   */
  if (hasBuyOrders && hasSellOrders) {
    // handle any general errors
    if (
      portfolioRebalancing.sellValidationErrors?.includes(
        RebalancingSellValidationError.InvalidStatus
      )
    ) {
      return {
        flowType: DpFlowTypes.invalidState,
        ...formFlow,
      };
    }

    // handle insufficient cash error
    if (
      availableCash < orderTotal ||
      buyValidationErrors?.includes(
        RebalancingBuyValidationError.InsufficientFunds
      )
    ) {
      return {
        flowType: DpFlowTypes.buysAndSellsInsufficientCash,
        ...formFlow,
      };
    }

    if (availableCash < buyTotal) {
      return {
        flowType: DpFlowTypes.buysAndSellsExpectedSufficientCash,
        ...formFlow,
      };
    }

    if (portfolioRebalancing.canSell) {
      return { flowType: DpFlowTypes.buysAndSells, ...formFlow };
    }
  }

  return { flowType: DpFlowTypes.invalidState, ...formFlow };
};

export type DPCheckoutStepsType = keyof typeof DPCheckoutSteps;

export interface DynamicPortfolioConstructionCheckoutStepsProps {
  selectedAccount: AccountsQueryAccount;
  portfolioRebalancing: RebalancingQueryRebalancing;
  wrapperType: string;
}

export function DynamicPortfolioConstructionCheckoutSteps({
  selectedAccount,
  portfolioRebalancing,
  wrapperType,
}: DynamicPortfolioConstructionCheckoutStepsProps) {
  const [toggleHandleStaleBidPrice] = useToggle(
    'global-checkout-handle-stale-bid-price'
  );

  const history = useHistory();
  const [activeFormFlow, setActiveFormFlow] = useState<FormFlow | null>(null);
  const activeFlow = activeFormFlow?.flowType;
  const [activeStep, setActiveStep] = useState<DPCheckoutStepsType | null>(
    null
  );

  const isNinoRequired = useIsNinoRequired();
  const queryClient = useQueryClient();
  const userProfileQuery = useUserProfileQuery();
  const [depositedAmount, setDepositedAmount] = useState<number | null>(null);
  const [basketSummary, setBasketSummary] = useState<number>(0);
  const recommendedCashBalance = selectedAccount?.recommendedCashBalance || 0;
  const availableCash = selectedAccount?.valuationSummary?.uninvestedCash || 0;
  const hasBuyOrders =
    portfolioRebalancing?.buyOrders &&
    portfolioRebalancing?.buyOrders?.length > 0;
  const hasSellOrders =
    portfolioRebalancing?.sellOrders &&
    portfolioRebalancing?.sellOrders?.length > 0;

  const handleEmptyBasket = useCallback(() => {
    history.push(
      generateDynamicPortfolioConstructionPath({
        wrapperType: getPathSegmentForWrapperType(selectedAccount.wrapperType),
      })
    );
  }, [history, selectedAccount.wrapperType]);

  useEffect(() => {
    // an active step has already been selected exit the code block
    if (activeStep) {
      return;
    }

    // handle empty basket
    if (!hasBuyOrders && !hasSellOrders) {
      handleEmptyBasket();
    }

    const formFlow = deriveFormFlow(
      portfolioRebalancing,
      availableCash,
      wrapperType,
      userProfileQuery.data?.userProfile?.mifidIdentifierSupplied || false,
      isNinoRequired
    );
    setActiveFormFlow(formFlow);
    if (formFlow.requiresNino) {
      setActiveStep(DPCheckoutSteps.ninoStep);
    } else if (formFlow.requiresMifid) {
      setActiveStep(DPCheckoutSteps.mifidStep);
    } else if (toggleHandleStaleBidPrice && formFlow.requiresUnitChangesStep) {
      setActiveStep(DPCheckoutSteps.unitPriceChanges);
    } else {
      setActiveStep(getFlowFirstStep(formFlow.flowType));
    }

    trackGa({
      event: GaEventNames.checkoutStart,
      checkoutFlow: formFlow.flowType,
    });
  }, [
    portfolioRebalancing,
    activeStep,
    handleEmptyBasket,
    hasBuyOrders,
    hasSellOrders,
    setActiveFormFlow,
    availableCash,
    wrapperType,
    userProfileQuery.data?.userProfile?.mifidIdentifierSupplied,
    isNinoRequired,
    toggleHandleStaleBidPrice,
  ]);

  useEffect(() => {
    const buysSummary =
      portfolioRebalancing.buyOrders && hasBuyOrders
        ? portfolioRebalancing.buyOrders.reduce(function (a, b) {
            return a + (b?.amount || 0);
          }, 0)
        : 0;
    const sellsSummary =
      portfolioRebalancing.sellOrders && hasSellOrders
        ? portfolioRebalancing.sellOrders.reduce(function (a, b) {
            return a + (b?.enteredAmount || 0);
          }, 0)
        : 0;
    const summary = buysSummary - sellsSummary;
    setBasketSummary(summary);
  }, [
    hasBuyOrders,
    hasSellOrders,
    portfolioRebalancing.buyOrders,
    portfolioRebalancing.sellOrders,
  ]);

  const onUserConfirmation = () => {
    setActiveStep(DPCheckoutSteps.orderReceived);
    queryClient.invalidateQueries(useAccountsQuery.getKey());
    queryClient.invalidateQueries(usePortfolioRebalancingsQuery.getKey());
  };

  const onAddCashOnProceed = (amount: number) => {
    setDepositedAmount(amount);
    queryClient.invalidateQueries(useAccountsQuery.getKey());
    queryClient.invalidateQueries(
      usePortfolioRebalancingQuery.getKey({
        id: portfolioRebalancing.id!,
      })
    );
    if (activeFlow === DpFlowTypes.buysAndSellsInsufficientCash) {
      setActiveStep(DPCheckoutSteps.potentialFundingShortfall);
    } else {
      setActiveStep(DPCheckoutSteps.orderConfirmation);
    }
  };

  const onPotentialFundingShortfallProceed = () => {
    setActiveStep(DPCheckoutSteps.orderConfirmation);
  };

  const onNinoStepProceed = () => {
    queryClient.invalidateQueries(useAccountsQuery.getKey());
    queryClient.invalidateQueries(
      usePortfolioRebalancingQuery.getKey({
        id: portfolioRebalancing.id!,
      })
    );
    if (activeFormFlow?.requiresMifid) {
      setActiveStep(DPCheckoutSteps.mifidStep);
    } else if (activeFormFlow?.flowType) {
      setActiveStep(getFlowFirstStep(activeFormFlow?.flowType));
    }
  };

  const onNinoStepBack = () => {
    history.push(
      generateDynamicPortfolioConstructionBasketPath({
        wrapperType: wrapperType.toLowerCase(),
      })
    );
    trackGa({
      event: GaEventNames.checkoutEdit,
      content_type: 'cta button',
      item_id: 'edit order - Nino not supplied',
      checkoutStep: DPCheckoutSteps.ninoStep,
      checkoutFlow: activeFlow,
    });
  };

  const onMifidStepProceed = () => {
    queryClient.invalidateQueries(useAccountsQuery.getKey());
    queryClient.invalidateQueries(
      usePortfolioRebalancingQuery.getKey({
        id: portfolioRebalancing.id!,
      })
    );
    if (toggleHandleStaleBidPrice && activeFormFlow?.requiresUnitChangesStep) {
      setActiveStep(DPCheckoutSteps.unitPriceChanges);
    } else if (activeFormFlow?.flowType) {
      setActiveStep(getFlowFirstStep(activeFormFlow?.flowType));
    }
  };
  const onMifidStepBack = () => {
    history.push(
      generateDynamicPortfolioConstructionBasketPath({
        wrapperType: getPathSegmentForWrapperType(selectedAccount.wrapperType),
      })
    );
    trackGa({
      event: GaEventNames.checkoutEdit,
      content_type: 'cta button',
      item_id: 'edit order - MiFid not supplied',
      checkoutStep: DPCheckoutSteps.mifidStep,
      checkoutFlow: activeFlow,
    });
  };

  const buyOrderETIs = portfolioRebalancing.buyOrders?.filter(
    (buyOrder) =>
      buyOrder?.context?.status ===
      AccountAssetBuyStatus.RequiresMifidIdentifier
  );

  const fundNames = buyOrderETIs?.length
    ? buyOrderETIs.map((buyOrder) => buyOrder?.instrument?.name)
    : [];

  const lastFundName = fundNames.pop();
  const fundNamesString =
    fundNames.length === 0
      ? lastFundName
      : fundNames.join(',') + ' and ' + lastFundName;

  return (
    <CheckoutContainer>
      {activeStep === DPCheckoutSteps.error && <GeneralError />}

      {activeStep === DPCheckoutSteps.ninoStep && activeFlow && (
        <NationalInsuranceNumberStep
          onProceed={onNinoStepProceed}
          onGoBack={onNinoStepBack}
          source="checkoutStep"
          userProfile={userProfileQuery.data?.userProfile!}
          introduction={
            <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
              <StepIntroductionTypography>
                To trade the following funds, our regulator requires that we ask
                for your National Insurance number: {fundNamesString}.{' '}
                <StyledLink href={mifidToInvest} target="_blank">
                  Learn more
                </StyledLink>
                .
              </StepIntroductionTypography>
            </StepIntroduction>
          }
        />
      )}

      {activeStep === DPCheckoutSteps.mifidStep && activeFlow && (
        <MiFidRequired
          onProceed={onMifidStepProceed}
          onBack={onMifidStepBack}
          fundNamesString={fundNamesString!}
        />
      )}

      {activeStep === DPCheckoutSteps.isaAllowanceLimit && activeFlow && (
        <ISAAllowanceLimit
          selectedAccountType={selectedAccount.wrapperType}
          activeFlow={activeFlow}
        />
      )}

      {activeStep === DPCheckoutSteps.unitPriceChanges && activeFlow && (
        <UnitPriceChanges
          activeFlow={activeFlow}
          portfolioRebalancing={portfolioRebalancing}
          selectedAccountId={selectedAccount.id!}
          selectedWrapperType={selectedAccount.wrapperType}
          onProceed={() => {
            trackCheckoutProgress(DPCheckoutSteps.unitPriceChanges, activeFlow);
            queryClient.invalidateQueries(useAccountsQuery.getKey());
            queryClient.invalidateQueries(
              usePortfolioRebalancingQuery.getKey({
                id: portfolioRebalancing.id!,
              })
            );
            if (activeFormFlow?.flowType) {
              setActiveStep(getFlowFirstStep(activeFormFlow?.flowType));
            }
          }}
        />
      )}

      {activeStep === DPCheckoutSteps.insufficientCash && activeFlow && (
        <InsufficientCash
          activeFlow={activeFlow}
          accountId={selectedAccount.id!}
          selectedAccountType={selectedAccount.wrapperType}
          recommendedCashBalance={recommendedCashBalance}
          basketSummary={basketSummary}
          availableCash={availableCash}
          portfolioRebalancing={portfolioRebalancing}
          addCashOnProceed={(amount) => {
            trackCheckoutProgress(DPCheckoutSteps.insufficientCash, activeFlow);
            onAddCashOnProceed(amount);
          }}
        />
      )}

      {activeStep === DPCheckoutSteps.potentialFundingShortfall && activeFlow && (
        <PotentialFundingShortfall
          selectedAccountType={selectedAccount.wrapperType}
          onProceed={() => {
            trackCheckoutProgress(
              DPCheckoutSteps.potentialFundingShortfall,
              activeFlow
            );
            onPotentialFundingShortfallProceed();
          }}
        />
      )}

      {activeStep === DPCheckoutSteps.orderConfirmation && activeFlow && (
        <OrderConfirmation
          onProceed={() => {
            trackCheckoutProgress(
              DPCheckoutSteps.orderConfirmation,
              activeFlow
            );
            onUserConfirmation();
          }}
          onBack={() =>
            history.push(
              generateDynamicPortfolioConstructionBasketPath({
                wrapperType: getPathSegmentForWrapperType(
                  selectedAccount.wrapperType
                ),
              })
            )
          }
          activeFlow={activeFlow}
          availableCash={availableCash}
          depositedAmount={depositedAmount}
          portfolioRebalancing={portfolioRebalancing}
          selectedAccount={selectedAccount}
          selectedAccountType={selectedAccount.wrapperType}
        />
      )}

      {activeStep === DPCheckoutSteps.orderReceived && activeFlow && (
        <OrderReceived
          selectedAccountType={selectedAccount.wrapperType}
          selectedAccountId={selectedAccount.id!}
          activeFlow={activeFlow}
          portfolioRebalancing={portfolioRebalancing}
        />
      )}
    </CheckoutContainer>
  );
}

export interface DynamicPortfolioConstructionCheckoutProps {
  selectedAccount: AccountsQueryAccount;
  selectedAccountId: string;
}
export function DynamicPortfolioConstructionCheckout({
  selectedAccount,
  selectedAccountId,
  ...props
}: DynamicPortfolioConstructionCheckoutProps) {
  const accountsQuery = useAccountsQuery();

  const { selectedRebalancingId, wrapperType } = useParams<{
    selectedRebalancingId: string;
    wrapperType: string;
  }>();

  const portfolioRebalancingQuery = usePortfolioRebalancingQuery({
    id: selectedRebalancingId,
  });

  const activePortfolioRebalancing =
    portfolioRebalancingQuery.data?.portfolioRebalancing;

  return (
    <QueryState dataTestid="accountsQuery" {...accountsQuery}>
      {() => {
        return (
          <QueryState
            dataTestid="portfolioRebalancingQuery"
            {...portfolioRebalancingQuery}
          >
            {() => {
              if (!selectedAccount) {
                return null;
              }
              if (!activePortfolioRebalancing) {
                return <NotFound />;
              }
              return (
                <CheckoutWrapperContainer maxWidth="lg">
                  <CheckoutWrapper>
                    <DynamicPortfolioConstructionCheckoutSteps
                      selectedAccount={selectedAccount}
                      portfolioRebalancing={activePortfolioRebalancing}
                      wrapperType={wrapperType}
                      {...props}
                    />
                  </CheckoutWrapper>
                </CheckoutWrapperContainer>
              );
            }}
          </QueryState>
        );
      }}
    </QueryState>
  );
}
