import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import React, { useState } from 'react';
import { HiDownload } from 'react-icons/hi';
import { DocumentContainer, Download, Title } from './Styles/Document.style';

export interface IDocument {
  title: string;
  url: string;
}

export function Document({ title, url }: IDocument) {
  const [hover, setHover] = useState('');

  const handleClick = (url: string, title: string) => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'fund internal page',
      item_id: `downloaded document - ${title}`,
    });
    window.open(url, '_blank');
  };

  const handleMouseEnter = () => {
    setHover('active');
  };

  const handleMouseLeave = () => {
    setHover('');
  };

  return (
    <DocumentContainer
      onClick={() => handleClick(url, title)}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <Title>{title}</Title>
      <Download href={url} target="_blank" className={hover}>
        <HiDownload />
      </Download>
    </DocumentContainer>
  );
}
