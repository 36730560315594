import { Checkbox } from '@material-ui/core';
import { CustomButton } from 'components/Button/CustomButton';
import { StyledLink } from 'components/design-system/Link';
import { GoBackButton } from 'components/design-system/StepComponents/StepComponents';
import { wrapperNameFriendly } from 'components/feature/Transfers/startTransferForm/steps/_shared/WrapperNameUtils';
import { WrapperType } from 'generated/graphql';
import { tillitDarkUniversePath, tillitFAQs } from 'paths/externalPaths';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ButtonWrapper,
  CardFace,
  CheckboxesWrapper,
  Divider,
  Eligibility,
  FormControlLabelConfirmCheckbox,
  Introduction,
  Li,
  Note,
  StyledSpanLink,
  TransferCopyTitle,
  TransferCopyWrapper,
  Ul,
  UlWarning,
  Warning,
  WhatYouShouldKnow,
} from './TransferCopy.styles';

interface TransferCashCopyProps {
  expanded?: boolean;
  createTransferLoading: boolean;
  wrapperType: WrapperType;
  cedingProviderName: string | null;
}

export const TransferCashCopy = ({
  cedingProviderName,
  createTransferLoading,
  wrapperType,
  expanded,
}: TransferCashCopyProps) => {
  const { register, formState } = useFormContext();

  if (!expanded) {
    return null;
  }
  return (
    <TransferCopyWrapper>
      <TransferCopyTitle variant="h2">Transfer as cash</TransferCopyTitle>
      <Introduction>
        By transferring as cash, {cedingProviderName} will sell all your
        holdings down to cash before transferring your{' '}
        {wrapperNameFriendly(wrapperType)} to TILLIT.
      </Introduction>
      <Introduction>
        Cash transfers typically take a few working days to complete and we will
        keep you informed every step of the way.
      </Introduction>
      <Divider />
      <WhatYouShouldKnow variant="h3">What you should know</WhatYouShouldKnow>
      <Ul>
        <Li component="li">
          {cedingProviderName} may charge dealing and/or exit fees.
        </Li>
        <Li component="li">
          {cedingProviderName} will be in control of when your holdings are sold
          down to cash.
        </Li>
        {wrapperType === WrapperType.Gia && (
          <Li component="li">You may incur Capital Gains Tax.</Li>
        )}
      </Ul>
      <Note>
        Once your account has been transferred to TILLIT, you will need to login
        to make your investments. Capital at risk.
      </Note>
      <CheckboxesWrapper>
        <FormControlLabelConfirmCheckbox
          htmlFor="authorise"
          control={
            <Checkbox id="authorise" name="authorise" inputRef={register} />
          }
          label={
            <>
              I authorise TILLIT to transfer my{' '}
              {wrapperNameFriendly(wrapperType)} as cash from{' '}
              {cedingProviderName}.
            </>
          }
        />
      </CheckboxesWrapper>
      <ButtonWrapper>
        <CustomButton
          variant="contained"
          disabled={!formState.isValid || createTransferLoading}
          type="submit"
          className="magenta"
        >
          Start Transfer
        </CustomButton>
      </ButtonWrapper>
    </TransferCopyWrapper>
  );
};

interface TransferHoldingsCopyWhatYouShouldKnowProps {
  createTransferLoading: boolean;
  wrapperType: WrapperType;
  cedingProviderName: string | null;
  handleCardFlip: () => void;
}

export const TransferHoldingsCopyWhatYouShouldKnow = ({
  cedingProviderName,
  createTransferLoading,
  wrapperType,
  handleCardFlip,
}: TransferHoldingsCopyWhatYouShouldKnowProps) => {
  const { register, formState } = useFormContext();
  return (
    <>
      <WhatYouShouldKnow variant="h3">What you should know</WhatYouShouldKnow>
      <Ul>
        <Li component="li">
          We accept transfers of funds, investment trusts and ETFs (including
          REITs and ETCs), even if they are not currently in the TILLIT
          Universe.{' '}
          <StyledLink
            href={tillitFAQs + '#what-kinds-of-assets-can-i-transfer-to-tillit'}
            target="_blank"
          >
            Read more
          </StyledLink>
          .
        </Li>
        <Li component="li">
          Any transferred funds, investment trusts and ETFs not currently in the
          TILLIT Universe will be hosted in our{' '}
          <StyledLink href={tillitDarkUniversePath} target="_blank">
            Dark Universe
          </StyledLink>
          . You will be able to continue to trade these in your account as
          usual.
        </Li>
        <Li component="li">
          Any{' '}
          <StyledSpanLink onClick={() => handleCardFlip()}>
            ineligible assets
          </StyledSpanLink>{' '}
          will be sold by {cedingProviderName} and transferred as cash.
        </Li>
        <Li component="li">
          {cedingProviderName} may charge dealing, transfer or exit fees.{' '}
          <StyledLink
            href={
              tillitFAQs +
              '#what-does-it-cost-to-transfer-my-holdings-in-specie'
            }
            target="_blank"
          >
            Read more
          </StyledLink>
          . If you are unsure, please contact your current provider.
        </Li>
        <Li component="li">
          Once a transfer has been initiated, it cannot be cancelled.
        </Li>
        {wrapperType === WrapperType.Isa && (
          <Li component="li">
            This transfer does not have an impact on your ISA allowance.
          </Li>
        )}
        {wrapperType === WrapperType.Gia && (
          <Li component="li">
            If your current provider has to sell any instruments down to cash,
            you may incur Capital Gains Tax.
          </Li>
        )}
      </Ul>
      <Note>
        Find out more about ineligible assets{' '}
        <StyledSpanLink onClick={() => handleCardFlip()}>here</StyledSpanLink>.
      </Note>
      <CheckboxesWrapper>
        <FormControlLabelConfirmCheckbox
          htmlFor="confirmReadAndUnderstood"
          control={
            <Checkbox
              id="confirmReadAndUnderstood"
              name="confirmReadAndUnderstood"
              inputRef={register}
            />
          }
          label={
            <>
              I confirm that I have read and understood the information in ‘What
              you should know’.
            </>
          }
        />
        <FormControlLabelConfirmCheckbox
          htmlFor="authorise"
          control={
            <Checkbox id="authorise" name="authorise" inputRef={register} />
          }
          label={
            <>
              I authorise TILLIT to transfer my {wrapperType} in specie from{' '}
              {cedingProviderName}.
            </>
          }
        />
        <FormControlLabelConfirmCheckbox
          htmlFor="accept"
          control={<Checkbox id="accept" name="accept" inputRef={register} />}
          label={
            <>
              I accept that any ineligible assets will be be sold down and
              transferred as cash.
            </>
          }
        />
      </CheckboxesWrapper>
      <Warning>
        Capital at risk.{' '}
        {wrapperType === WrapperType.Isa &&
          'Tax treatment depends on an individual’s circumstances and may be subject to change.'}
      </Warning>
      <ButtonWrapper>
        <CustomButton
          variant="contained"
          disabled={!formState.isValid || createTransferLoading}
          type="submit"
          className="magenta"
        >
          Start Transfer
        </CustomButton>
      </ButtonWrapper>
    </>
  );
};

interface TransferHoldingsEligibleAssetsProps {
  handleCardFlip: () => void;
}

export const TransferHoldingsEligibleAssets = ({
  handleCardFlip,
}: TransferHoldingsEligibleAssetsProps) => {
  return (
    <>
      <WhatYouShouldKnow variant="h2">Eligible assets</WhatYouShouldKnow>
      <Eligibility>
        We only accept in specie transfers for eligible funds, investment trusts
        and ETFs.
      </Eligibility>
      <UlWarning>We cannot accept:</UlWarning>
      <Ul>
        <Li component="li">Individual stocks &amp; shares</Li>
        <Li component="li">Non-GBP denominated assets</Li>
        <Li component="li">Cryptocurrencies</Li>
        <Li component="li">Leveraged ETFs</Li>
        <Li component="li">
          Complex assets (including over-the-counter instruments, private
          assets, etc)
        </Li>
        <Li component="li">Any unregulated assets</Li>
      </Ul>
      <Introduction>
        In addition, you may hold a fund in a restricted share class which we
        may not have access to. In this case we would seek to onboard the share
        class for you, but if we are unable to do so we may have to convert your
        holding into an eligible share class.
        <StyledLink
          href={
            tillitFAQs +
            '#what-happens-if-tillit-cannot-host-the-same-share-class-of-a-fund-i-want-to-transfer'
          }
          target="_blank"
        >
          Read more
        </StyledLink>
        .{' '}
      </Introduction>
      <Warning style={{ fontStyle: 'italic' }}>
        To find out more, please go the{' '}
        <StyledLink href={tillitDarkUniversePath} target="_blank">
          Dark Universe
        </StyledLink>{' '}
        on our website or take a look at our FAQs.
      </Warning>
      <ButtonWrapper>
        <GoBackButton onClick={() => handleCardFlip()} />
      </ButtonWrapper>
    </>
  );
};

interface TransferHoldingsCopyProps {
  expanded?: boolean;
  createTransferLoading: boolean;
  wrapperType: WrapperType;
  cedingProviderName: string | null;
}

export const TransferHoldingsCopy = ({
  cedingProviderName,
  createTransferLoading,
  wrapperType,
  expanded,
}: TransferHoldingsCopyProps) => {
  const [showFront, toggleShowFront] = useState(true);
  if (!expanded) {
    return null;
  }

  const handleCardFlip = () => {
    toggleShowFront(!showFront);
  };

  return (
    <>
      <TransferCopyWrapper>
        <TransferCopyTitle variant="h2">Transfer my Holdings</TransferCopyTitle>
        <Introduction>
          Transferring your existing holdings without selling them down to cash
          is called an ‘in specie’ transfer. By transferring in specie,{' '}
          {cedingProviderName} will transfer all{' '}
          <StyledSpanLink onClick={() => handleCardFlip()}>
            eligible holdings
          </StyledSpanLink>{' '}
          and any uninvested cash to TILLIT. Any ineligible holdings will be
          sold down to cash and will be transferred along with your eligible
          holdings.
        </Introduction>
        <Introduction>
          An in specie transfer typically takes 4-8 weeks to complete, depending
          on your current provider and the complexity of your account. Our
          Transfer Concierge team will keep you in the loop every step of the
          way.
        </Introduction>
        <Divider />
        <CardFace $active={showFront}>
          <TransferHoldingsCopyWhatYouShouldKnow
            handleCardFlip={handleCardFlip}
            cedingProviderName={cedingProviderName}
            createTransferLoading={createTransferLoading}
            wrapperType={wrapperType}
          />
        </CardFace>
        <CardFace $active={!showFront}>
          <TransferHoldingsEligibleAssets handleCardFlip={handleCardFlip} />
        </CardFace>
      </TransferCopyWrapper>
    </>
  );
};
