import { AccordionSummary, Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const StyledFundDetails = styled.div`
  width: 100%;
`;

export const StyledBoxInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    gap: 4rem;
  }
`;

export const StyledBoxRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 2rem;
`;

export const Heading = styled(Typography)`
  color: ${colors.richBlack};
  font-family: ${({ theme }) => theme.typography.bodyFontFamily};
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 1rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 1.5rem;
  }
  ${(p) =>
    p.theme.darkUniverse &&
    `
  color: ${colors.richBlack};
`}
`;

export const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
  margin-bottom: 1.5rem;
`;

export const StyledDetailsBox = styled.div`
  width: 100%;
  background: transparent;
  flex-grow: 1;

  ${({ theme }) => theme.breakpoints.up('md')} {
    &:first-child {
      grid-area: 1 / 1 / 3 / 2;
    }
    &:nth-child(2) {
      grid-area: 1 / 2 / 3 / 3;
    }
    &:nth-child(3) {
      grid-area: 3 / 1 / 4 / 2;
    }
    &:last-child {
      grid-area: 3 / 2 / 4 / 3;
    }
  }
`;

export const StyledB = styled.b`
  font-weight: 500;
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    margin: 1.5rem 0;
  }
  .MuiAccordionSummary-expandIcon {
    margin-right: 0.5rem;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`;

export const StyledDetailsContent = styled.div`
  padding: 0;
`;
