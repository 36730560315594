import { IconButton } from '@material-ui/core';
import { H6 } from 'components/design-system/Heading/Heading';
import { Pill } from 'components/design-system/Pill/Pill';
import {
  Text,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { assetClassColors, colors } from 'constants/colors';
import { transitions } from 'constants/transitions';
import { BsPinFill } from 'react-icons/bs';
import { FaShoppingBasket, FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { HiChevronDown } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BottomShadow, SectionShadow } from 'theme/shared/base.styles';

export const Pin = styled(BsPinFill)`
  fill: ${colors['purple-600']};
  transform: translate(-0.25rem, 0.125rem);
`;

export const FundCardContainerInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

interface FundCardHeaderProps {
  $isExpanded: boolean;
}

export const FundCardHeader = styled.div<FundCardHeaderProps>`
  position: relative;
  width: 100%;

  filter: ${({ $isExpanded }) =>
    $isExpanded
      ? 'grayscale(80%)  brightness(0.8) blur(2px)'
      : 'grayscale(0%) brightness(1) blur(0px)'};
  transition: 0.35s filter ${transitions.smoothEaseOut};
  will-change: filter;
`;

export const FundCardHeaderTag = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: auto;
  z-index: 2;
`;

export const AssetIconContainer = styled.div`
  align-self: flex-start;
  ${({ theme }) => theme.breakpoints.up('md')} {
    align-self: center;
  }
  svg,
  img {
    flex-shrink: 0;
    width: 100%;
    max-width: 3rem;
  }
`;

export const Name = styled.p`
  margin: 0 ${(p) => p.theme.spacing(1.25)}px;
  font-size: 0.875rem;
  font-weight: 300;
`;

export type assetClasses = keyof typeof assetClassColors;

export const Details = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  gap: 1rem;
  text-align: center;
  background-color: ${colors.white};
  z-index: 1;
`;

export interface FundOneLinerProps {
  $isName: boolean;
}

export const FundOneLiner = styled(H6)<FundOneLinerProps>`
  margin-bottom: auto;
  font-family: ${({ theme, $isName }) =>
    $isName
      ? theme.typography.bodyFontFamily
      : theme.typography.headingFontFamily};
`;

export const FundsAction = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  gap: 0.75rem;
  ${(p) => p.theme.breakpoints.up('sm')} {
    flex-direction: row;
    margin-top: 0.5rem;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
  }
`;

export const AccordionWrapper = styled.div``;

export const AccordionTitle = styled.div`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface AccordionBodyProps {
  $isActive: boolean;
}
export const AccordionBody = styled.div<AccordionBodyProps>`
  max-height: ${({ $isActive }) => ($isActive ? '250px' : '0')};
  overflow: hidden;
  transition: max-height 0.35s;
`;

interface AccordionChevronProps {
  $isActive: boolean;
  onClick: () => void;
}
const StyledChevronButton = styled.button<AccordionChevronProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border: 0;
  padding: 0 0.25rem;
  border-radius: 100%;
  justify-self: flex-end;
  background-color: transparent;
  color: ${colors.richBlack};
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${colors.grey};
    color: ${colors.richBlack};
    outline: none;
  }
`;

interface AccordionChevronIconProps {
  $isActive: boolean;
}
export const AccordionChevronIcon = styled(
  HiChevronDown
)<AccordionChevronIconProps>`
  transform: ${({ $isActive }) =>
    $isActive ? 'rotate(-180deg)' : 'rotate(0)'};
  transition: transform 0.35s;
`;
export const AccordionChevron = ({
  $isActive,
  onClick,
}: AccordionChevronProps) => {
  return (
    <StyledChevronButton $isActive={$isActive} onClick={onClick}>
      <AccordionChevronIcon $isActive={$isActive} />
    </StyledChevronButton>
  );
};

export const FundTagsContainer = styled.div`
  width: 100%;
  margin: 0 -0.5rem;

  ${(p) => p.theme.breakpoints.up('sm')} {
    width: auto;
    margin: 0 0 0.25rem;
  }
`;

export const CardFundTagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 0.5rem;
  margin: 0;
  cursor: pointer;
  & > button {
    margin: 0 0.25rem 0.5rem 0.25rem;
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    display: flex;
    gap: 0.5rem;
    border-bottom: none;
    align-items: center;
    padding-bottom: 0;
    margin: 0;
    white-space: auto;
    flex-wrap: wrap;
    justify-content: center;
    & > button {
      margin: 0;
    }
  }
`;

export const CardTagButton = styled.button`
  color: ${colors.richBlack};
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: underline;
  }
`;
export const NewTagWrapper = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
`;

export const NewTag = styled.span`
  color: ${colors.magenta};
  background: rgba(215, 22, 235, 0.1);
  padding: 4px 12px;
  border-radius: 18px;
  font-size: 0.6875rem;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 400;
  vertical-align: middle;
`;

export const VideoWrapper = styled.div`
  width: 100%;
  background-color: ${colors.grey};
  .vjs-text-track-cue {
    font-size: 0.75rem !important;
    font-weight: 400;
    & > div {
      padding: 1px;
      line-height: 15px;
      font-family: 'IBM Plex Sans' !important;
    }
  }
`;

export const VideoIconLink = styled(Link)`
  display: inline-flex;
  border: 1px solid ${colors.vapor};
  padding: 0.125rem 0.5rem;

  border-radius: 1.5rem;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    border: none;
    height: auto;
    width: auto;
    padding: 0;
    border-radius: 100%;
  }
`;

export const MissingTag = styled(Pill)`
  text-decoration: line-through;
  color: #777;
`;

export const MissingMore = styled(TextSmall)`
  margin: 0;
  color: #777;
`;

export const PlusMore = styled(Text)`
  padding-top: 0.25rem;
  color: #777;
`;

export const TagsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0 1rem;
  cursor: pointer;

  > svg {
    margin-top: 0.4rem;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
  }

  margin-bottom: 1rem;

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: -1rem;
    left: 0;
    background-color: ${colors.midGrey};
  }
`;
export const TagsContainer = styled.div`
  overflow: hidden;
  transition: max-height 0.25s linear;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  color: ${colors.darkGrey};
  top: 0.5rem;
  right: 0.5rem;
`;

interface FundCardProps {
  $isExpanded?: boolean;
}

export const FundCard = styled.div<FundCardProps>`
  position: relative;
  border-radius: 10px;
  border: solid 1px ${colors.grey};
  background: white;
  overflow: hidden;
  ${BottomShadow}
  transform: translateY(0);
  transition: 0.25s all ease-in-out;
  will-change: transform, box-shadow;

  ${({ theme }) => theme.breakpoints.up('md')} {
    &:hover {
      transform: translateY(-5px);
      ${SectionShadow}
    }
  }

  &::after {
    content: '';
    width: 100%;
    height: ${({ $isExpanded }) => ($isExpanded ? '2rem' : 0)};
    background: ${colors.white};
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 0) 100%
    );
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 1;
  }
`;

export const VolumeButton = styled(IconButton)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.25rem;
  left: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 0.25rem;
  outline: none;
  font-size: 1rem;
  transition: 0.3s background-color;
  cursor: pointer;
  z-index: 1;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;

export const MuteIcon = styled(FaVolumeMute)`
  fill: #eee;
`;

export const VolumeIcon = styled(FaVolumeUp)`
  fill: #eee;
`;

export const CartButton = styled(IconButton)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  background-color: transparent;
  font-size: 1rem;
  bottom: 1rem;
  right: 1rem;
  z-index: 2;

  &:hover,
  &:focus {
    background-color: ${colors['grey-200']};
  }
`;

export const CartLink = styled(Link)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  background-color: transparent;
  font-size: 1rem;
  bottom: 1rem;
  right: 1rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;

  &:hover,
  &:focus {
    background-color: ${colors['grey-200']};
  }
`;

export const CartIcon = styled(FaShoppingBasket)`
  fill: ${colors.magenta};
`;

export const ThumbnailImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  pointer-events: auto;
  opacity: 1;
  transition: opacity 0.15s ${transitions.smoothEaseOut};

  &.playing {
    opacity: 0;
    pointer-events: none;
  }
`;

export const ActiveFallbackImage = styled.img`
  position: relative;
  width: 100%;
`;

export const PassiveImageContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  background: ${colors['grey-50']};
`;

export const PassiveImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 33.8%;
`;

export const CardExpandedCloseButton = styled.button`
  font-size: 1.5rem;
  line-height: 0;
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0 1rem;

  &:hover,
  &:focus {
    outline: none;
  }

  &:visited {
    color: inherit;
  }
`;

export const CardSubtitleContainer = styled.div`
  width: auto;
  text-align: center;
  a {
    font-size: 0.875rem;
  }
`;

export const NoVideoImage = styled.img`
  width: 100%;
`;

export const CardPerformanceContainer = styled.div`
  position: relative;
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  p {
    flex-basis: 50%;
    &:first-child {
      position: relative;
      &::after {
        position: absolute;
        top: 0;
        right: -1rem;
        content: '';
        width: 1px;
        height: 100%;
        background-color: ${colors['magenta-100']};
      }
    }
  }
`;

interface CardOverlayContainerProps {
  $isExpanded: boolean;
}

export const CardOverlayContainer = styled.div<CardOverlayContainerProps>`
  position: absolute;
  top: 20%;
  bottom: -20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0.75rem 1rem 2.75rem 1rem;
  background-color: ${colors.white};
  z-index: 1;
  border-radius: 10px;
  box-shadow: ${({ $isExpanded }) =>
    $isExpanded
      ? '0px -5px 20px 10px rgba(0,0,0,0.15)'
      : '0px 0px 0px rgba(0, 0, 0, 0)'};
  opacity: ${({ $isExpanded }) => ($isExpanded ? 1 : 0)};
  transform: ${({ $isExpanded }) =>
    $isExpanded ? 'translateY(0%)' : 'translateY(110%)'};
  opacity: ${({ $isExpanded }) => ($isExpanded ? '1' : '0.5')};
  transition: 0.35s all ${transitions.smoothEaseOut};
  will-change: transform, opacity;
  overflow-y: auto;
`;

export const SearchAssetName = styled(TextNormal)`
  margin-top: -1rem;
`;
