import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { H4 } from 'components/design-system/Heading/Heading';
import { Text } from 'components/design-system/Text/Text';
import type { LocalRecurringDeposits } from 'components/feature/autoSaveInvest/AutoSaveInvestContext';
import * as format from 'formatting';
import { day } from 'formatting';
import _ from 'lodash';
import styled from 'styled-components';

export interface RegularDepositsSummaryProps {
  recurringTransactionsDeposits: LocalRecurringDeposits[];
  onManageDeposits?: () => void;
}

const NoHorizontalPaddingTable = styled(Table)`
  & td:first-child,
  & th:first-child {
    padding-left: 0;
  }
  & td:last-child,
  & th:last-child {
    padding-right: 0;
  }
  margin-bottom: 1rem;

  & tbody .MuiTableCell-root {
    font-size: 0.75rem;
  }
`;

const ManageDepositWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
    gap: 0;
  }
`;

const NewUpdatedMessageContainer = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

export const RegularDepositsSummary = ({
  recurringTransactionsDeposits,
  onManageDeposits,
}: RegularDepositsSummaryProps) => {
  const anyNewOrChangedDeposits = recurringTransactionsDeposits.some(
    ({ isNew, isEdited }) => isNew || isEdited
  );

  const orderedByDate = _.orderBy(
    recurringTransactionsDeposits,
    (x) => x.paymentDate
  );

  return (
    <>
      <H4>Regular deposits</H4>
      {recurringTransactionsDeposits?.length === 0 ? (
        <Text $noMargin>You do not have any regular deposits set up.</Text>
      ) : (
        <NoHorizontalPaddingTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>Day</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell>Instruction</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderedByDate.map((d) => (
              <TableRow key={d?.paymentDate}>
                <TableCell width="10%">{day(d?.paymentDate)}</TableCell>
                <TableCell width="10%" align="right">
                  {format.currencyFull(d.amount, true)}
                </TableCell>
                <TableCell>
                  {d.autoInvest ? 'Auto-invest' : 'Hold as cash'}
                </TableCell>
                <TableCell width="10%" align="right">
                  <i>
                    {d.isEdited
                      ? 'Updated'
                      : d.isNew
                      ? 'New'
                      : d.isDeleted
                      ? 'Removed'
                      : ''}
                  </i>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </NoHorizontalPaddingTable>
      )}
      <ManageDepositWrapper>
        {anyNewOrChangedDeposits && (
          <NewUpdatedMessageContainer>
            <Text $noMargin>
              <i>
                Any new or updated regular deposits will need to be set up as
                standing orders with your bank. We'll give you the details you
                need for the standing order on the next screen.
              </i>
            </Text>
          </NewUpdatedMessageContainer>
        )}
        {onManageDeposits && (
          <CustomButtonV2
            $color="light"
            $isWide={false}
            $size="small"
            onClick={onManageDeposits}
          >
            Manage deposits
          </CustomButtonV2>
        )}
      </ManageDepositWrapper>
    </>
  );
};
