import { hexagonPagePath } from 'paths';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useGetReturnPath = () => {
  const { search, hash } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  if (queryParams.has('return-path')) {
    const returnPath = queryParams.get('return-path') as string;
    if (hash) {
      return returnPath + hash;
    }

    return returnPath;
  }

  return hexagonPagePath;
};
