import {
  LinkStepButton,
  StepActions,
  StepContainer,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { WrapperType } from 'generated/graphql';
import { dashboardPath } from 'paths';
import { wrapperNameFriendly } from '../_shared/WrapperNameUtils';

export interface SuccessStepProps {
  wrapperType: WrapperType;
}

export function Success({ wrapperType }: SuccessStepProps) {
  return (
    <StepContainer>
      <StepTitle>
        Your {wrapperNameFriendly(wrapperType)} is on the move!
      </StepTitle>
      <StepText>
        <StepIntroductionTypography>
          We have sent you an email confirming the details of your transfer and
          we'll be in touch as soon as we have an update.
        </StepIntroductionTypography>
      </StepText>

      <StepActions>
        <LinkStepButton to={dashboardPath} className="magenta" fullWidth>
          My Dashboard
        </LinkStepButton>
      </StepActions>
    </StepContainer>
  );
}
