import { Container } from 'components/Container/Container.style';
import React from 'react';
import { Title } from './MyDetails.style';

export interface BaseProps {
  title: string;
  children: React.ReactNode;
}

export function Base({ title, children }: BaseProps) {
  return (
    <Container padding="medium" square={false}>
      <Title variant="h3">{title}</Title>
      {children}
    </Container>
  );
}
