import { FundDetails } from 'components/FundDetails/FundDetails';
import { Loading } from 'components/Loading';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { StyledLink } from 'components/design-system/Link';
import {
  FontWeight,
  TextLarge,
  TextNormal,
} from 'components/design-system/Text/Text';
import { getLongKidDocName } from 'components/feature/PortfolioBuilder/_shared/copyHelpers';
import * as format from 'formatting';
import {
  RecognisedChargeType,
  TradeIllustrationCostLineType,
  useGetTradeCostsAndChargesIllustrationQuery,
} from 'generated/graphql';
import * as tooltips from 'strings/tooltips';
import {
  DetailsContainer,
  Heading,
  StyledB,
  StyledDetailsBox,
  StyledDetailsContent,
} from './Styles/DetailsBox.style';

type ChargeRowProps = {
  charge: TradeIllustrationCostLineType;
  oneOff?: boolean;
  trackPopoverClick: (label: string) => void;
};

const ChargeRow = ({
  charge,
  trackPopoverClick,
  oneOff = false,
}: ChargeRowProps) => {
  const label =
    charge.chargeProportion !== null
      ? `${charge.description} (${format.percent(charge.chargeProportion!)}${
          oneOff ? '' : ' pa'
        })`
      : charge.description;

  const tooltipLookup: Partial<
    Record<RecognisedChargeType, JSX.Element | string>
  > = {
    [RecognisedChargeType.Ocf]: tooltips.MiFIDOngoingCosts,
    [RecognisedChargeType.Platform]: <tooltips.EstimatedTillitFee />,
    [RecognisedChargeType.Dealing]: tooltips.ZeroCommission,
    [RecognisedChargeType.StampDuty]: tooltips.StampDuty(0.005),
    [RecognisedChargeType.PtmLevy]: tooltips.PtmLevy(1.0, 10_000),
    [RecognisedChargeType.Transaction]: tooltips.MiFIDTransactionCosts,
    [RecognisedChargeType.Incidental]: tooltips.MiFIDIncidentalCosts,
  };

  const gaEventNameLookup: Partial<Record<RecognisedChargeType, string>> = {
    [RecognisedChargeType.Ocf]: 'Estimated fund fee',
    [RecognisedChargeType.Platform]: 'Estimated tillit fee',
    [RecognisedChargeType.Dealing]: 'No commission',
    [RecognisedChargeType.StampDuty]: 'Stamp duty',
    [RecognisedChargeType.PtmLevy]: 'PTM levy',
  };

  const tooltip = charge.chargeType
    ? tooltipLookup[charge.chargeType] ?? null
    : null;

  const gaEventName = charge.chargeType
    ? gaEventNameLookup[charge.chargeType]
    : charge.description;

  return (
    <>
      <FundDetails
        label={
          <>
            {label}{' '}
            <InfoPopoverV2
              color={'primary'}
              $width="wide"
              placement={'top-end'}
              onClick={() => gaEventName && trackPopoverClick(gaEventName)}
              content={tooltip}
            />
          </>
        }
        value={format.currencyFull(charge.amount)}
        variant="outlined"
      />
    </>
  );
};

export interface MiFIDCostsAndChargesProps {
  lumpSum: number;
  selectedIsin: string;
  instrumentType: string;
  kiidDocumentUrl: string;
  trackPopoverClick: (popover: string) => void;
}

export function MiFIDCostsAndCharges({
  lumpSum,
  selectedIsin,
  instrumentType,
  kiidDocumentUrl,
  trackPopoverClick,
}: MiFIDCostsAndChargesProps) {
  const {
    data: tradeIllustration,
    isLoading,
  } = useGetTradeCostsAndChargesIllustrationQuery(
    {
      trade: {
        amount: lumpSum,
        isin: selectedIsin!,
      },
    },
    {
      enabled: !!lumpSum,
    }
  );

  const oneOffs = tradeIllustration?.tradeCostsAndChargesIllustration?.oneOffCostsAndCharges.map(
    (charge) => (
      <ChargeRow charge={charge} trackPopoverClick={trackPopoverClick} oneOff />
    )
  );

  const ongoing = tradeIllustration?.tradeCostsAndChargesIllustration?.ongoingCostsAndCharges.map(
    (charge) => (
      <ChargeRow charge={charge} trackPopoverClick={trackPopoverClick} />
    )
  );

  const hasAnyChargeInformation = !!oneOffs?.length || !!ongoing?.length;

  const finalAmountBeforeCharges =
    lumpSum +
    (tradeIllustration?.tradeCostsAndChargesIllustration.totalGrowth ?? 0);
  const finalAmountAfterCharges =
    tradeIllustration?.tradeCostsAndChargesIllustration.finalAmount ?? 0;
  const totalCharges =
    tradeIllustration?.tradeCostsAndChargesIllustration
      .totalCostsAndChargesOverPeriod ?? 0;

  const kiidDocName = getLongKidDocName(instrumentType!);
  const kiidUrl = kiidDocumentUrl;

  return isLoading ? (
    <Loading />
  ) : (
    <StyledDetailsBox>
      <Heading variant="h6">Costs and charges</Heading>
      <TextNormal>
        These are the annual costs based on an initial investment amount of{' '}
        <StyledB>{format.currencyFull(lumpSum)}</StyledB> over{' '}
        <StyledB>
          {tradeIllustration?.tradeCostsAndChargesIllustration.periodMonths}{' '}
          months
        </StyledB>{' '}
        assuming a net{' '}
        <StyledB>
          {
            tradeIllustration?.tradeCostsAndChargesIllustration
              .assumedGrowthPercent
          }
          % growth rate
        </StyledB>
        . We use figures supplied by the fund manager for this purpose, and they
        may differ (up or down) from the actual costs and charges you incur over
        time.{' '}
      </TextNormal>
      <TextNormal>
        <StyledLink href="https://tillitinvest.com/faqs">
          Learn more about how we're required to show costs and charges
        </StyledLink>
        .
      </TextNormal>

      <StyledDetailsContent>
        <DetailsContainer>
          {!oneOffs?.length ? null : (
            <>
              <TextLarge $noMargin $fontWeight={FontWeight.light}>
                One-offs
              </TextLarge>
              {oneOffs}
            </>
          )}
          {!ongoing?.length ? null : (
            <>
              <TextLarge
                $noMargin
                $fontWeight={FontWeight.light}
                style={{ marginTop: '1rem' }}
              >
                Ongoing
              </TextLarge>
              {ongoing}
            </>
          )}
          {!hasAnyChargeInformation && (
            <TextNormal>
              We do not yet have the required data to illustrate the costs and
              charges of this trade &mdash; you should read the{' '}
              {kiidUrl ? (
                <StyledLink
                  href={kiidUrl!}
                  target="_blank"
                  style={{ whiteSpace: 'normal' }}
                >
                  {kiidDocName}
                </StyledLink>
              ) : (
                kiidDocName
              )}{' '}
              to find out more about how much this investment may cost over
              time.
            </TextNormal>
          )}
          {hasAnyChargeInformation && (
            <>
              <TextLarge
                $noMargin
                $fontWeight={FontWeight.light}
                style={{ marginTop: '1rem' }}
              >
                Totals
              </TextLarge>
              <FundDetails
                label={'What you might get back with no charges'}
                value={format.currencyFull(finalAmountBeforeCharges!)}
                variant="outlined"
              />
              <FundDetails
                label={'Total estimated charges'}
                value={format.currencyFull(totalCharges)}
                variant="outlined"
              />
              <FundDetails
                label={`What you might get back with charges applied`}
                value={format.currencyFull(finalAmountAfterCharges)}
                variant="outlined"
              />
            </>
          )}
        </DetailsContainer>
      </StyledDetailsContent>
    </StyledDetailsBox>
  );
}
