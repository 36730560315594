import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SeoProps {
  title?: string;
  description?: string;
  url?: string;
}

const defaultTitle = 'Invest in funds picked by experts';
const defaultDescription =
  'With insights straight from experts, unique tools and filters and a fee tied to time, not wealth, you can invest long-term with conviction. Find the right funds for you with a TILLIT Stocks and Shares ISA or GIA. Capital at risk.';

export const Seo = ({ title, description, url }: SeoProps) => {
  const seo = {
    title: `${title || defaultTitle} - TILLIT`,
    description: description || defaultDescription,
    url: url || window.location.href,
  };
  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="title" content={seo.title} />
      <meta name="description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="TILLIT" />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:locale:alternate" content="en_GB" />
    </Helmet>
  );
};
