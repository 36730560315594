import { GaEventNames } from 'constants/gaConstants';
import { AnimatePresence } from 'framer-motion';
import { AccountType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { openAccountPath, openAccountTransferPath } from 'paths';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { IntroductionStep } from '../openSipp/steps/IntroductionStep';
import { Wrapper } from './OpenAccountForm.style';
import {
  BasicDeclarations,
  BasicDeclarationsFormValues,
} from './steps/BasicDeclarations/BasicDeclarations';
import { ChooseAccountTypePreAuth } from './steps/ChooseAccountTypePreAuth/ChooseAccountTypePreAuth';
import {
  EmailAddressStep,
  EmailFormValues,
} from './steps/EmailAddressStep/EmailAddressStep';
import {
  EmailVerifyStep,
  FormLocation,
} from './steps/EmailVerifyStep/EmailVerifyStep';
import { NameStep, NameStepFormValues } from './steps/NameStepPreAuth/NameStep';
import {
  PasswordFormValues,
  PasswordStep,
} from './steps/PasswordStep/PasswordStep';
import {
  PromoCodeFormValues,
  PromoCodeStep,
} from './steps/PromoCodeStep/PromoCodeStep';
import { ReferralWelcomeStep } from './steps/ReferralWelcomeStep';

enum MultistepFormSteps {
  ReferralWelcome = 'ReferralWelcome',
  ChooseAccountTypeV2 = 'ChooseAccountTypeV2',
  PromoCodeStep = 'PromoCodeStep',
  SippIntroduction = 'SippIntroduction',
  BasicDeclarations = 'BasicDeclarations',
  NameStep = 'NameStep',
  EmailAddressStep = 'EmailAddressStep',
  PasswordStep = 'PasswordStep',
  EmailVerify = 'EmailVerify',
}

const tallForms: MultistepFormSteps[] = [];

export function OpenAccountFormPreAuth() {
  const location = useLocation();

  const isTransfer = location.pathname === openAccountTransferPath;

  const params = new URLSearchParams(location.search);
  const referralCode = params.has('referralCode')
    ? (params.get('referralCode') as string)
    : undefined;

  const [activeStep, setActiveStep] = useState<MultistepFormSteps | undefined>(
    referralCode
      ? MultistepFormSteps.ReferralWelcome
      : MultistepFormSteps.ChooseAccountTypeV2
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const [selectedAccountType, setSelectedAccountType] = useState<AccountType>();
  const [
    promoCodeFormValues,
    setPromoCodeFormValues,
  ] = useState<PromoCodeFormValues>();
  const [
    basicDeclarationsValues,
    setBasicDeclarationsValues,
  ] = useState<BasicDeclarationsFormValues>();
  const [
    emailAddressFormValues,
    setEmailAddressFormValues,
  ] = useState<EmailFormValues>();
  const [
    passwordFormValues,
    setPasswordFormValues,
  ] = useState<PasswordFormValues>();
  const [nameFormValues, setNameFormValues] = useState<NameStepFormValues>();

  useEffectOnce(() => {
    trackGa({
      event: GaEventNames.onboardingStart,
    });
  });

  return (
    <Wrapper isTallForm={activeStep && tallForms.includes(activeStep)}>
      <AnimatePresence>
        {activeStep === MultistepFormSteps.ReferralWelcome && referralCode && (
          <ReferralWelcomeStep
            referralCode={referralCode}
            onProceed={(accountType) => {
              if (accountType === AccountType.Sipp) {
                setSelectedAccountType(accountType);
                setActiveStep(MultistepFormSteps.SippIntroduction);
              } else {
                setActiveStep(MultistepFormSteps.ChooseAccountTypeV2);
              }
            }}
          />
        )}

        {activeStep === MultistepFormSteps.ChooseAccountTypeV2 && (
          <ChooseAccountTypePreAuth
            onProceed={(values) => {
              setSelectedAccountType(values);
              if (values === AccountType.Sipp) {
                setActiveStep(MultistepFormSteps.SippIntroduction);
              } else {
                setActiveStep(MultistepFormSteps.BasicDeclarations);
              }
            }}
            onPromoCode={() => {
              setActiveStep(MultistepFormSteps.PromoCodeStep);
            }}
            promoCode={promoCodeFormValues?.promoCode}
          />
        )}

        {activeStep === MultistepFormSteps.PromoCodeStep && (
          <PromoCodeStep
            onProceed={(values) => {
              setPromoCodeFormValues(values);
              setActiveStep(MultistepFormSteps.ChooseAccountTypeV2);
            }}
            onGoBack={() => {
              setActiveStep(MultistepFormSteps.ChooseAccountTypeV2);
            }}
          />
        )}

        {activeStep === MultistepFormSteps.SippIntroduction && (
          <IntroductionStep
            onProceed={() => {
              setActiveStep(MultistepFormSteps.BasicDeclarations);
            }}
            onGoBack={() => {
              setActiveStep(
                referralCode && selectedAccountType === AccountType.Sipp
                  ? MultistepFormSteps.ReferralWelcome
                  : MultistepFormSteps.ChooseAccountTypeV2
              );
            }}
          />
        )}

        {activeStep === MultistepFormSteps.BasicDeclarations && (
          <BasicDeclarations
            values={basicDeclarationsValues}
            onProceed={(values) => {
              setBasicDeclarationsValues(values);
              setActiveStep(MultistepFormSteps.NameStep);
            }}
            onGoBack={() => {
              if (selectedAccountType === AccountType.Sipp) {
                setActiveStep(MultistepFormSteps.SippIntroduction);
              } else {
                setActiveStep(MultistepFormSteps.ChooseAccountTypeV2);
              }
            }}
          />
        )}

        {activeStep === MultistepFormSteps.NameStep && (
          <NameStep
            selectedAccountType={selectedAccountType!}
            onProceed={(values) => {
              setNameFormValues(values);
              setActiveStep(MultistepFormSteps.EmailAddressStep);
            }}
            onGoBack={() => {
              setActiveStep(MultistepFormSteps.BasicDeclarations);
            }}
            values={nameFormValues}
          />
        )}

        {activeStep === MultistepFormSteps.EmailAddressStep && (
          <EmailAddressStep
            onProceed={(values) => {
              setEmailAddressFormValues(values);
              setActiveStep(MultistepFormSteps.PasswordStep);
            }}
            onGoBack={() => {
              setActiveStep(MultistepFormSteps.NameStep);
            }}
            values={emailAddressFormValues}
          />
        )}

        {activeStep === MultistepFormSteps.PasswordStep &&
          selectedAccountType &&
          basicDeclarationsValues &&
          emailAddressFormValues &&
          nameFormValues && (
            <PasswordStep
              onProceed={(values) => {
                setPasswordFormValues(values);
                setActiveStep(MultistepFormSteps.EmailVerify);
              }}
              onGoBack={() => {
                setActiveStep(MultistepFormSteps.EmailAddressStep);
              }}
              selectedAccountType={selectedAccountType}
              promoCodeFormValues={promoCodeFormValues}
              basicDeclarationsValues={basicDeclarationsValues}
              emailAddressFormValues={emailAddressFormValues}
              nameFormValues={nameFormValues}
              referralCode={referralCode}
            />
          )}

        {activeStep === MultistepFormSteps.EmailVerify &&
          emailAddressFormValues &&
          basicDeclarationsValues &&
          passwordFormValues && (
            <EmailVerifyStep
              onProceed={() => {
                window.location.href = isTransfer
                  ? openAccountTransferPath
                  : openAccountPath;
              }}
              formLocation={FormLocation.OpenAccount}
              email={emailAddressFormValues.email}
              password={passwordFormValues.password}
              basicDeclarationsValues={basicDeclarationsValues}
            />
          )}
      </AnimatePresence>
    </Wrapper>
  );
}
