import { CombinedQueryState } from 'components/QueryState/QueryState';
import { H1, H5 } from 'components/design-system/Heading/Heading';
import { FundsSwiper } from 'components/feature/Funds/FundsSwiper/FundsSwiper';
import { useFundListOptions } from 'components/feature/FundsList/hooks/useFundListOptions';
import { useRecentlyViewedAssets } from 'components/feature/FundsList/hooks/useRecentlyViewedAssets';
import { useAssetsQuery } from 'hooks/useAssetsQuery';
import { AssetQueryAsset } from 'types/graphqlTypes';
import {
  RecentlyViewedFundsHeading,
  RecentlyViewedFundsInnerContainer,
  StyledRecentlyViewedFundsContainer,
} from './RecentlyViewedFundsSlice.styles';

export interface RecentlyViewedFundsSliceProps {
  asset?: AssetQueryAsset;
  headingWrapper?: 'h1' | 'h5';
}

export default function RecentlyViewedFundsSlice({
  asset,
  headingWrapper,
}: RecentlyViewedFundsSliceProps) {
  const { recentlyViewed } = useRecentlyViewedAssets();

  const filteredRecentlyViewed = asset
    ? recentlyViewed.filter(
        (recentlyViewedAssetId) => parseInt(recentlyViewedAssetId) !== asset.id
      )
    : recentlyViewed;

  const recentlyViewedQueries = useAssetsQuery(
    !!filteredRecentlyViewed
      ? filteredRecentlyViewed.map((recentlyViewedAssetId) => ({
          id: parseInt(recentlyViewedAssetId),
        }))
      : []
  );
  const [options, setOptions] = useFundListOptions();

  if (filteredRecentlyViewed.length === 0) {
    return null;
  }

  const funds = recentlyViewedQueries
    .map((query) => {
      return query.data?.asset;
    })
    .filter(
      (asset) => asset !== undefined && asset !== null
    ) as AssetQueryAsset[];

  const HeadingWrapper = headingWrapper === 'h5' ? H5 : H1;
  return (
    <CombinedQueryState queries={recentlyViewedQueries}>
      <StyledRecentlyViewedFundsContainer>
        <RecentlyViewedFundsInnerContainer maxWidth="lg">
          <RecentlyViewedFundsHeading>
            <HeadingWrapper>Recently viewed funds</HeadingWrapper>
          </RecentlyViewedFundsHeading>
          <FundsSwiper
            funds={funds}
            options={options}
            setOptions={setOptions}
          />
        </RecentlyViewedFundsInnerContainer>
      </StyledRecentlyViewedFundsContainer>
    </CombinedQueryState>
  );
}
