import { Typography } from '@material-ui/core';
import { StyledTextField } from 'components/Input';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const FormContainer = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(3.75)}px;
`;

export const DialogTitle = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

export const StyledInput = styled(StyledTextField)`
  position: relative;
  margin-top: ${(p) => p.theme.spacing(2.5)}px;
  &.MuiTextField-root {
    label {
      top: -4px;
      font-size: 1rem;
      font-weight: 300;
    }
    .MuiInput-root {
      margin-top: 0.75rem;
      input,
      textarea {
        font-size: 1rem;
        font-weight: 300;
        color: ${colors.richBlue};
      }
    }
  }
`;

export const NoOptionCopy = styled(Typography)`
  color: ${colors.richBlack};
  font-size: 0.75rem;
  a {
    color: ${colors.magenta};
    text-decoration: underline;
  }
`;

export const AccountNumberHelpText = styled(Typography)`
  margin-top: ${(p) => p.theme.spacing(1.25)}px;
  font-size: 0.75rem;
  font-weight: 400;
  font-style: italic;
  color: ${colors.richBlue};
`;

export const ActionContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: ${(p) => p.theme.spacing(7)}px;

  & > *:last-child {
    margin-top: ${(p) => p.theme.spacing(1)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;

    & > *:last-child {
      margin-top: 0;
      margin-left: ${(p) => p.theme.spacing(2)}px;
    }
  }
`;

export const TransferCopyWrapper = styled.div`
  display: flex;
`;
