import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import {
  FontSize,
  FontWeight,
  Text,
  TextAlign,
} from 'components/design-system/Text/Text';
import { percent } from 'formatting';
import { sum } from 'lodash';
import { useState } from 'react';
import { Breakdown, BreakdownComponentProps } from '../breakdownTypes';
import {
  ChevronIconButton,
  DrillDownLine,
  LogoNameContainer,
  RowDescriptionContainer,
  RowDescriptionLayout,
  RowDivider,
  ShowAllButton,
  StyledChevronIcon,
  StyledChevronIconProps,
  TableBreakdownWrapper,
  TableRowLayout,
} from './TableBreakdown.styles';

export interface TableBreakdownProps extends BreakdownComponentProps {
  $descriptionHasPopover?: boolean;
}

export const TableBreakdown = ({
  breakdown,
  $descriptionHasPopover = false,
}: TableBreakdownProps) => {
  const hasDescription =
    breakdown.lines?.some((line) => line.description) ?? false;

  const hasLogo = breakdown.lines?.some((line) => line.imgSrc) ?? false;

  const sortedBreakdown =
    breakdown.lines?.sort((a, b) => {
      return b.proportion - a.proportion;
    }) ?? [];

  const [isCollapsed, setIsCollapsed] = useState(sortedBreakdown.length > 13);

  return (
    <TableBreakdownWrapper>
      <TableRowLayout $hasDescription={hasDescription}>
        <Text $noMargin $fontWeight={FontWeight.medium}>
          {breakdown.name}
        </Text>
        <Text
          $noMargin
          $fontWeight={FontWeight.medium}
          $textAlign={TextAlign.right}
        >
          Percentage
        </Text>
      </TableRowLayout>
      <RowDivider $isBold />
      {sortedBreakdown
        .slice(0, isCollapsed ? 10 : undefined)
        .map((line, index) => (
          <TableBreakdownRow
            line={line}
            siblingHasDescription={hasDescription}
            siblingDescriptionAsPopover={$descriptionHasPopover}
            siblingHasLogo={hasLogo}
            key={line.name}
            altBackground={!(index % 2)}
          />
        ))}
      {isCollapsed && (
        <TableExpandRow
          hiddenLines={sortedBreakdown.slice(10)}
          expandHiddenLines={() => {
            setIsCollapsed(false);
          }}
        />
      )}
    </TableBreakdownWrapper>
  );
};

interface TableBreakdownRowProps {
  line: Breakdown;
  siblingHasDescription: boolean;
  siblingDescriptionAsPopover: boolean;
  siblingHasLogo: boolean;
  altBackground?: boolean;
}

const TableBreakdownRow = ({
  line,
  siblingHasDescription,
  siblingDescriptionAsPopover,
  siblingHasLogo,
  altBackground = false,
}: TableBreakdownRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasSubLines = line.lines?.length ? true : false;

  const handleClick = (event: React.MouseEvent) => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <TableRowLayout
        $hasDescription={
          siblingHasDescription && (!siblingDescriptionAsPopover || hasSubLines)
        }
        $altBackground={altBackground}
        onClick={handleClick}
      >
        <LogoNameContainer $hasLogo={siblingHasLogo}>
          {!!line?.imgSrc && (
            <img
              alt=""
              src={line.imgSrc}
              width={40}
              height={40}
              style={{ objectFit: 'contain' }}
            />
          )}
          <Text
            $noMargin
            $fontWeight={FontWeight.normal}
            $fontSize={FontSize.small}
          >
            {line.name}
            {siblingHasDescription && siblingDescriptionAsPopover && (
              <>
                {' '}
                <InfoPopoverV2
                  popupId={`popup-${line.name.replace(' ', '-')}`}
                  content={line.description}
                  size="small"
                  placement="top"
                  disableMargin
                />
              </>
            )}
          </Text>
        </LogoNameContainer>
        <Text
          $noMargin
          $fontWeight={FontWeight.normal}
          $fontSize={FontSize.small}
          $textAlign={TextAlign.right}
        >
          {line.proportion === 0 ? '-' : percent(line.proportion)}
        </Text>
        {!!line.description &&
          (!siblingDescriptionAsPopover || hasSubLines) && (
            <TableRowChevron $isActive={isExpanded} />
          )}
      </TableRowLayout>
      {!!line.description && (!siblingDescriptionAsPopover || hasSubLines) && (
        <RowDescriptionContainer $isExpanded={isExpanded}>
          <RowDescriptionLayout>
            {!siblingDescriptionAsPopover && (
              <Text
                $noMargin
                $fontWeight={FontWeight.normal}
                $fontSize={FontSize.small}
              >
                {line.description}
              </Text>
            )}
            {hasSubLines &&
              line.lines
                ?.sort((a, b) => {
                  return b.proportion - a.proportion;
                })
                .map((subLine) => (
                  <DrillDownLine>
                    {subLine.name && (
                      <Text
                        $noMargin
                        $fontWeight={FontWeight.normal}
                        $fontSize={FontSize.small}
                      >
                        {subLine.name}
                      </Text>
                    )}
                    {subLine.proportion && (
                      <Text
                        $noMargin
                        $fontWeight={FontWeight.normal}
                        $fontSize={FontSize.small}
                        $textAlign={TextAlign.right}
                      >
                        {percent(subLine.proportion)}
                      </Text>
                    )}
                  </DrillDownLine>
                ))}
          </RowDescriptionLayout>
        </RowDescriptionContainer>
      )}
      <RowDivider />
    </>
  );
};

const TableRowChevron = ({ $isActive }: StyledChevronIconProps) => {
  return (
    <StyledChevronIcon $isActive={$isActive}>
      <ChevronIconButton $isActive={$isActive} />
    </StyledChevronIcon>
  );
};

interface TableExpandRowProps {
  hiddenLines: Breakdown[];
  expandHiddenLines: () => void;
}

const TableExpandRow = ({
  hiddenLines,
  expandHiddenLines,
}: TableExpandRowProps) => {
  const proportion = sum(hiddenLines.map((line) => line.proportion)) ?? 0;

  return (
    <>
      <TableRowLayout $altBackground>
        <LogoNameContainer>
          <Text
            $noMargin
            $fontWeight={FontWeight.normal}
            $fontSize={FontSize.small}
          >
            {hiddenLines.length} Others (
            <ShowAllButton onClick={() => expandHiddenLines()}>
              show all
            </ShowAllButton>
            )
          </Text>
        </LogoNameContainer>
        <Text
          $noMargin
          $fontWeight={FontWeight.normal}
          $fontSize={FontSize.small}
          $textAlign={TextAlign.right}
        >
          {percent(proportion)}
        </Text>
      </TableRowLayout>
      <RowDivider />
    </>
  );
};
