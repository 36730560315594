import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { InvestmentTrustPerformanceGraph } from 'components/Graphs/InvestmentTrustPerformanceGraph';
import { PerformanceGraph } from 'components/Graphs/PerformanceGraph';
import { TimeHorizonTabs } from 'components/design-system/Tabs/TimeHorizonTabs';
import * as format from 'formatting';
import { FundPerformanceComparisonPeriod } from 'generated/graphql';
import React, { useMemo, useState } from 'react';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { NoContent } from './Styles/FundDetails.style';

export interface PerformanceBoxProps {
  isin: string;
  asset: AssetQueryAsset;
}

export function PerformanceBox({ isin, asset }: PerformanceBoxProps) {
  const [period, setPeriod] = useState(
    FundPerformanceComparisonPeriod.FiveYears
  );

  const isInvestmentTrust = asset.structure?.id === 'INVTRUST';

  const valuationSummary = useMemo(
    () =>
      asset.instruments?.nodes?.find((n) => n!.isin === isin)?.valuationSummary,
    [asset.instruments?.nodes, isin]
  );

  const handleChangeHorizon = (event: React.ChangeEvent<{}>, newValue: any) => {
    setPeriod(newValue);
  };

  return (
    <Container maxWidth={false} style={{ padding: 0 }}>
      {!asset.canShowPerformanceCharts ? (
        <NoContent>Chart is not currently available.</NoContent>
      ) : (
        <>
          <TimeHorizonTabs
            trackingContext="fund details page"
            activeValue={period}
            onChange={handleChangeHorizon}
            tabs={[
              {
                label: '5 years',
                value: FundPerformanceComparisonPeriod.FiveYears,
              },
              {
                label: '3 years',
                value: FundPerformanceComparisonPeriod.ThreeYears,
              },
              {
                label: '1 year',
                value: FundPerformanceComparisonPeriod.OneYear,
              },
              {
                label: '6 months',
                value: FundPerformanceComparisonPeriod.SixMonths,
              },
              {
                label: '3 months',
                value: FundPerformanceComparisonPeriod.ThreeMonths,
              },
            ]}
          />

          {isInvestmentTrust ? (
            <InvestmentTrustPerformanceGraph isin={isin} period={period} />
          ) : (
            <PerformanceGraph isin={isin} period={period} />
          )}
        </>
      )}

      <Box mt={1}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="right">5&nbsp;years</TableCell>
                <TableCell align="right">3&nbsp;years</TableCell>
                <TableCell align="right">1&nbsp;year</TableCell>
                <TableCell align="right">6&nbsp;months</TableCell>
                <TableCell align="right">3&nbsp;months</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Total return
                </TableCell>
                <TableCell align="right">
                  {formatTotalReturn(valuationSummary?.totalReturnOver5Years)}
                </TableCell>
                <TableCell align="right">
                  {formatTotalReturn(valuationSummary?.totalReturnOver3Years)}
                </TableCell>
                <TableCell align="right">
                  {formatTotalReturn(valuationSummary?.totalReturnOver1Year)}
                </TableCell>
                <TableCell align="right">
                  {formatTotalReturn(valuationSummary?.totalReturnOver6Months)}
                </TableCell>
                <TableCell align="right">
                  {formatTotalReturn(valuationSummary?.totalReturnOver3Months)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
}

function formatTotalReturn(value: any) {
  return Number.isFinite(value) ? format.percent(value / 100) : '-';
}
