import {
  assetClasses,
  assetColouring,
} from 'components/design-system/_shared/assetColouring';
import { colors } from 'constants/colors';

import styled from 'styled-components';

/**
 * @file
 *
 * This file contains the font sizes / font weights / etc we use in the design system.
 *
 * This might not cover all use cases - sometimes we might need to override this with styled(CustomTypography)`...`
 *
 * We probably don't want to add any values outside of the design system:
 *  - having this component be constrained by the design system will help push us in the right direction,
 *  - If we continuously put in more sizes this file will become bloated very quickly.
 */

export enum FontSize {
  xs = 'XS',
  small = 'SMALL',
  normal = 'normal',
  large = 'large',
  extraLarge = 'extraLarge',
}
export const fontSizeLookup = {
  [FontSize.xs]: '0.75rem',
  [FontSize.small]: '0.875rem',
  [FontSize.normal]: '1rem',
  [FontSize.large]: '1.25rem',
  [FontSize.extraLarge]: '2rem',
};
export const lineHeightLookup = {
  [FontSize.xs]: '1.25rem',
  [FontSize.small]: '1.375rem',
  [FontSize.normal]: '1.5rem',
  [FontSize.large]: '2rem',
  [FontSize.extraLarge]: '2.5rem',
};

export enum FontStyle {
  italic = 'italic',
}
const fontStyleLookup = {
  [FontStyle.italic]: 'italic',
};

export enum TextAlign {
  center = 'CENTER',
  left = 'LEFT',
  right = 'RIGHT',
}
const textAlignLookup = {
  [TextAlign.center]: 'center',
  [TextAlign.left]: 'left',
  [TextAlign.right]: 'right',
};

export enum FontWeight {
  light = 'LIGHT',
  normal = 'NORMAL',
  medium = 'MEDIUM',
}
const fontWeightLookup = {
  [FontWeight.light]: '300',
  [FontWeight.normal]: '400',
  [FontWeight.medium]: '500',
};

export interface TextProps {
  $noMargin?: boolean;
  $fontSize?: FontSize;
  $textAlign?: TextAlign;
  $fontWeight?: FontWeight;
  $fontStyle?: FontStyle;
  assetClass?: assetClasses;
  $isDarkBg?: boolean;
  $isError?: boolean;
}

/**
 * Base Text component.
 *
 * You probably don't want - use TextLarge / TextNormal / TextSmall / QuoteLarge / Quote.
 *
 * This is not a base component that can be used for any text in any circumstance. The options on
 * this component are constrained by the TILLIT design system, which is documented in figma.
 *
 * Do not add any options to this component that aren't in the TILLIT design system.
 */
export const Text = styled.p<TextProps>`
  font-family: 'IBM Plex Sans';
  color: ${(p) =>
    p.$isError ? colors.danger : p.$isDarkBg ? colors.white : colors.richBlack};
  text-decoration: none;
  a,
  a:visited,
  a:hover,
  a:focus {
    color: ${(p) => (p.$isDarkBg ? colors.white : colors.richBlack)};
    text-decoration: none;
  }
  ${(p) => (p.$noMargin ? 'margin: 0;' : 'margin: 0 0 1.25rem 0;')}
  ${(p) => (p.$fontSize ? `font-size: ${fontSizeLookup[p.$fontSize]}` : '')};
  ${(p) =>
    p.$fontSize ? `line-height: ${lineHeightLookup[p.$fontSize]}` : ''};
  ${(p) =>
    p.$textAlign ? `text-align: ${textAlignLookup[p.$textAlign]}` : ''};
  ${(p) =>
    p.$fontWeight
      ? `font-weight: ${fontWeightLookup[p.$fontWeight]};`
      : `font-weight: ${fontWeightLookup[FontWeight.light]};`};
  ${(p) =>
    p.$fontStyle ? `font-style: ${fontStyleLookup[p.$fontStyle]};` : ''};

  ${assetColouring}
`;

type TextSizeArgs = Parameters<typeof Text>;
export type TextSizeProps = TextSizeArgs[0];

export const TextLarge = (props: TextSizeProps) => {
  return (
    <Text
      $fontSize={FontSize.large}
      $fontWeight={FontWeight.light}
      {...props}
    />
  );
};

export const TextNormal = (props: TextSizeProps) => (
  <Text $fontSize={FontSize.normal} $fontWeight={FontWeight.light} {...props} />
);

export const TextSmall = (props: TextSizeProps) => (
  <Text $fontSize={FontSize.small} $fontWeight={FontWeight.light} {...props} />
);

export const TextXS = (props: TextSizeProps) => (
  <Text $fontSize={FontSize.xs} $fontWeight={FontWeight.light} {...props} />
);

export const QuoteLarge = (props: TextSizeProps) => (
  <Text
    $fontSize={FontSize.extraLarge}
    $fontWeight={FontWeight.light}
    {...props}
  />
);

export const Quote = (props: TextSizeProps) => (
  <Text
    $fontSize={FontSize.large}
    $fontWeight={FontWeight.light}
    $fontStyle={FontStyle.italic}
    {...props}
  />
);

export const MonoDd = styled.dd`
  font-family: ${({ theme }) => theme.typography.bodyMono};
`;

/**
 * Wrapper for text blocks
 *
 * If you use this then it'll set the margin for the last p.
 */
interface TextWrapperProps {
  /**
   * By default the last paragraph will get margin-bottom 2.5rem. Which is what we normally want.
   * However sometimes it'll be easier to have the text block have 0 margin around it so you can
   * use css's gap option. This option enables that.
   */
  $noMargin?: boolean;
}
export const TextWrapper = styled.div<TextWrapperProps>`
  ${Text}:last-child {
    ${(p) => (p.$noMargin ? 'margin: 0 0 0 0;' : 'margin: 0 0 2.5rem 0;')}
  }
`;
