import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { H4, H6 } from 'components/design-system/Heading/Heading';
import { StyledLink } from 'components/design-system/Link';
import {
  StepActions,
  StepButton,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
} from 'components/design-system/StepComponents/StepComponents';
import {
  FontWeight,
  Text,
  TextSmall,
} from 'components/design-system/Text/Text';
import {
  Pill,
  PillContainer,
} from 'components/feature/PortfolioBuilder/AddToBasket/AddToBasketDialog.style';
import { colors } from 'constants/colors';
import { currencyFull, date, day } from 'formatting';
import { WrapperType } from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import { generateAutoSaveInvestSubPath } from 'paths';
import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { FaUndo } from 'react-icons/fa';
import { HiOutlineTrash } from 'react-icons/hi';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  AccountsQueryAccount,
  UserProfileQueryClientSummaryAccount,
} from 'types/graphqlTypes';
import * as yup from 'yup';
import { useAutoSaveInvestState } from '../AutoSaveInvestContext';
import { RegularDepositEditForm } from './dialogs/RegularDepositEditForm';

export interface RegularDepositLineFormValues {
  amount: number;
  paymentDate: number;
  autoInvest: boolean;
  isDeleted: 'true' | 'false';
  isEdited: 'true' | 'false';
  isNew: 'true' | 'false';
}

export const Section = styled.div`
  padding: 1rem 0;
  margin: 1.5rem 0 0 0;
  border-top: 1px solid ${colors.midGrey};
  border-bottom: 1px solid ${colors.midGrey};
  & + & {
    margin-top: 0;
    border-top: none;
  }
`;

export const DepositContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2.5rem 2.5rem;
`;

const basicDeclarationsFormSchema = yup.object().shape({
  deposits: yup.array().of(
    yup.object().shape({
      amount: yup.number().required(),
      paymentDate: yup.number().required(),
      autoInvest: yup.boolean().required(),
      isDeleted: yup.string().required(),
      isEdited: yup.string().required(),
      isNew: yup.string().required(),
    })
  ),
});

export interface IntroductionFormValue {
  selectedRegularDeposits: boolean[];
}

export interface MultipleDepositsStepProps {
  onProceed?: (newUpdatedDepositAmount: number) => void;
  wrapperType: WrapperType;
  showNoDepositsMessage: boolean;
  onEdit?: (index: number) => void;
}

export function MultipleDepositsStep({
  onProceed,
  wrapperType,
  showNoDepositsMessage,
  onEdit,
}: MultipleDepositsStepProps) {
  const {
    state,
    setState,
    latestEmployerContribution,
  } = useAutoSaveInvestState();

  const recurringDeposits = state?.deposits ?? [];
  const selectedRegularDeposits = state?.deposits?.map(
    (recurringDeposit, index) => {
      return {
        amount: recurringDeposit.amount,
        paymentDate: recurringDeposit.paymentDate,
        autoInvest: recurringDeposit.autoInvest,
        isDeleted: recurringDeposit.isDeleted ? 'true' : 'false',
        isEdited: recurringDeposit.isEdited ? 'true' : 'false',
        isNew: recurringDeposit.isNew ? 'true' : 'false',
      };
    },
    []
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      deposits: selectedRegularDeposits,
    },
    resolver: yupResolver(basicDeclarationsFormSchema),
  });

  const { register, handleSubmit, errors, control, setValue, watch } = methods;

  const fieldArrayMethod = useFieldArray<RegularDepositLineFormValues>({
    control,
    name: 'deposits',
  });
  const { fields, remove } = fieldArrayMethod;

  const onSubmit = (close: boolean = false) => async (data: {
    deposits: RegularDepositLineFormValues[];
  }) => {
    const newSelectedRegularDeposits = data.deposits?.map((dataDeposit) => {
      return {
        amount: dataDeposit.amount,
        paymentDate: dataDeposit.paymentDate,
        autoInvest: dataDeposit.autoInvest,
        isDeleted: dataDeposit.isDeleted === 'true',
        isEdited: dataDeposit.isEdited === 'true',
        isNew: dataDeposit.isNew === 'true',
      };
    });

    setState({
      ...state,
      deposits: newSelectedRegularDeposits,
    });

    const newUpdatedDepositAmount =
      newSelectedRegularDeposits?.reduce(
        (acc, deposit) =>
          acc + (deposit.autoInvest && !deposit.isDeleted ? deposit.amount : 0),
        0
      ) ?? 0;

    if (close) {
      onProceed?.(newUpdatedDepositAmount);
    }
  };

  return (
    <div>
      <PillContainer>
        <Pill>{getShortNameForWrapperType(wrapperType)}</Pill>
      </PillContainer>
      <H4>Manage regular deposits</H4>
      <form
        onSubmit={handleSubmit(onSubmit(true))}
        style={{ display: 'contents' }}
      >
        <StepIntroduction $width={StepIntroductionWidth.extraWide}>
          {showNoDepositsMessage && (
            <>
              <StepIntroductionTypography textAlign="left">
                You don't have any regular deposits setup for auto investment.
              </StepIntroductionTypography>
              <StepIntroductionTypography textAlign="left">
                You need to select the deposits you want to auto invest, or{' '}
                <StyledLink
                  as={Link}
                  to={{
                    pathname: generateAutoSaveInvestSubPath({
                      wrapperType: getPathSegmentForWrapperType(wrapperType),
                      action: 'create-deposit',
                    }),
                    state: { from: 'auto-save-invest' },
                  }}
                >
                  setup a new regular deposit
                </StyledLink>
                .
              </StepIntroductionTypography>
            </>
          )}
        </StepIntroduction>

        {latestEmployerContribution && (
          <Section>
            <H6 $noMargin>Estimated employer contribution</H6>
            <Text $noMargin $fontWeight={FontWeight.normal}>
              {currencyFull(latestEmployerContribution.amount)}
            </Text>
            <TextSmall $noMargin>
              This is based on your latest employer contribution on the{' '}
              {date(latestEmployerContribution.completedDate)}
            </TextSmall>
          </Section>
        )}

        <Section>
          {recurringDeposits.length === 0 ? (
            <TextSmall>No regular deposits set up</TextSmall>
          ) : (
            <>
              <TextSmall $noMargin={recurringDeposits.length > 0}>
                You have the following regular deposits. Select the ones you
                want to set up a regular investment instruction for.
              </TextSmall>
              {fields.map((field, index) => {
                const watchIsDeleted = watch(`deposits[${index}].isDeleted`);
                return (
                  <DepositContainer key={field.id}>
                    <input
                      type="hidden"
                      name={`deposits[${index}].amount`}
                      id={`deposits[${index}].amount`}
                      defaultValue={field.amount}
                      ref={register()}
                    />
                    <input
                      type="hidden"
                      name={`deposits[${index}].paymentDate`}
                      id={`deposits[${index}].paymentDate`}
                      defaultValue={field.paymentDate}
                      ref={register()}
                    />
                    <input
                      name={`deposits[${index}].isDeleted`}
                      id={`deposits[${index}].isDeleted`}
                      defaultValue={field.isDeleted}
                      ref={register()}
                      style={{ display: 'none' }}
                    />
                    <input
                      type="hidden"
                      name={`deposits[${index}].isEdited`}
                      id={`deposits[${index}].isEdited`}
                      defaultValue={field.isEdited}
                      ref={register()}
                    />
                    <input
                      type="hidden"
                      name={`deposits[${index}].isNew`}
                      id={`deposits[${index}].isNew`}
                      defaultValue={field.isNew}
                      ref={register()}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={`deposits[${index}].autoInvest`}
                          defaultChecked={field.autoInvest}
                          inputRef={register()}
                          value={'true'}
                        />
                      }
                      label={
                        <>
                          {currencyFull(field.amount!)} on the{' '}
                          {day(field.paymentDate!)} of the month
                          {watchIsDeleted === 'true' && ' (removed)'}
                          {field.isNew === 'true' && ' (new)'}
                          {field.isEdited === 'true' &&
                            field.isNew !== 'true' &&
                            watchIsDeleted !== 'true' &&
                            ' (updated)'}
                        </>
                      }
                    />
                    <IconButton
                      type="button"
                      size="small"
                      onClick={() => {
                        onEdit?.(index);
                      }}
                    >
                      <MdEdit title="Edit" />
                    </IconButton>

                    {watchIsDeleted === 'true' ? (
                      <IconButton
                        type="button"
                        size="small"
                        onClick={() => {
                          setValue(`deposits[${index}].isDeleted`, 'false');
                          setTimeout(handleSubmit(onSubmit(false)), 1);
                        }}
                      >
                        <FaUndo title="Undo" />
                      </IconButton>
                    ) : (
                      <IconButton
                        type="button"
                        size="small"
                        onClick={() => {
                          if (field.isNew === 'true') {
                            remove(index);
                          } else {
                            setValue(`deposits[${index}].isDeleted`, 'true');
                          }
                          setTimeout(handleSubmit(onSubmit(false)), 1);
                        }}
                      >
                        <HiOutlineTrash title="Remove" />
                      </IconButton>
                    )}
                  </DepositContainer>
                );
              })}
            </>
          )}
        </Section>

        <ErrorMessage
          name="selectedRegularDeposits"
          errors={errors}
          render={({ message }) => (
            <FormHelperText error={true}>{message}</FormHelperText>
          )}
        />

        <StepActions>
          <StepButton type="submit" className="magenta">
            Done
          </StepButton>
        </StepActions>
      </form>
    </div>
  );
}

interface RegularDepositEditProps {
  index: number;
  onClose: () => void;
  account: AccountsQueryAccount | UserProfileQueryClientSummaryAccount;
}

export const RegularDepositEdit = ({
  index,
  onClose,
  account,
}: RegularDepositEditProps) => {
  const { state, setState } = useAutoSaveInvestState();

  if (state?.deposits === null || state?.deposits === undefined) {
    return null;
  }

  const selectedDeposit = state?.deposits[index];
  return (
    <RegularDepositEditForm
      onComplete={() => {
        onClose();
      }}
      deposit={selectedDeposit}
      index={index}
      account={account}
      onDepositChange={(updatedDeposit) => {
        if (state?.deposits === null || state?.deposits === undefined) {
          return;
        }

        setState({
          ...state,
          deposits: [
            ...state?.deposits.slice(0, index),
            { ...selectedDeposit, ...updatedDeposit, isEdited: true },
            ...state?.deposits.slice(index + 1),
          ],
        });

        onClose();
      }}
      variant="autoSaveInvest"
    />
  );
};

interface MultipleDepositsStepDialogProps extends ResponsiveDialogProps {
  onProceed: (newUpdatedDepositAmount: number) => void;
  showNoDepositsMessage: boolean;
  open: boolean;
  closeDialog: () => void;
  account: AccountsQueryAccount | UserProfileQueryClientSummaryAccount;
}

export function MultipleDepositsStepDialog({
  onProceed,
  open,
  showNoDepositsMessage,
  closeDialog,
  account,
}: MultipleDepositsStepDialogProps) {
  const { state } = useAutoSaveInvestState();

  const hasSingleDeposit = state?.deposits?.length === 1;

  const [editState, setEditState] = useState<number | null>(
    hasSingleDeposit ? 0 : null
  );

  return (
    <ResponsiveDialog open={open} onClose={closeDialog}>
      {open && editState === null && (
        <MultipleDepositsStep
          onProceed={onProceed}
          wrapperType={account.wrapperType}
          showNoDepositsMessage={showNoDepositsMessage}
          onEdit={(index) => {
            setEditState(index);
          }}
        />
      )}

      {open && editState !== null && (
        <RegularDepositEdit
          index={editState}
          onClose={() => {
            if (hasSingleDeposit) {
              closeDialog();
            } else {
              setEditState(null);
            }
          }}
          account={account}
        />
      )}
    </ResponsiveDialog>
  );
}

interface RegularDepositEditDialogProps extends ResponsiveDialogProps {
  index: number;
  open: boolean;
  closeDialog: () => void;
  account: AccountsQueryAccount | UserProfileQueryClientSummaryAccount;
}

export function RegularDepositEditDialog({
  index,
  open,
  closeDialog,
  account,
}: RegularDepositEditDialogProps) {
  const [editState, setEditState] = useState<number | null>(index);
  return (
    <ResponsiveDialog open={open} onClose={() => closeDialog()}>
      {open && editState !== null && (
        <RegularDepositEdit
          index={index}
          onClose={() => {
            setEditState(null);
            closeDialog();
          }}
          account={account}
        />
      )}
    </ResponsiveDialog>
  );
}
