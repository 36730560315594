import { FundDetailsContainer, Label, Value } from './Styles/FundDetails.style';

interface FundDetailsProps {
  label: string | JSX.Element;
  value: string | JSX.Element;
  variant?: 'contained' | 'outlined';
}

export function FundDetails({
  label,
  value,
  variant = 'contained',
}: FundDetailsProps) {
  const extraMargin = label === 'OCF' ? true : false;
  return (
    <FundDetailsContainer $layout={variant} $bottomMargin={extraMargin}>
      <Label $layout={variant}>{label}</Label>
      <Value $layout={variant}>{value}</Value>
    </FundDetailsContainer>
  );
}
