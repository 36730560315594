import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { TextSmall } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import { ExtraSmallStraitNeumorphicShadow } from 'theme/shared/base.styles';

export const BeneficiariesLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;
`;

export const BeneficiaryLineLayout = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    border: 1px solid ${colors['grey-400']};
    padding: 0 1rem 1.5rem 1rem;
    border-radius: 5px;

    ${ExtraSmallStraitNeumorphicShadow}

    :not(:first-child) {
      padding: 0 1rem;
    }
  }
`;

export const BeneficiaryLineFieldsLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1rem;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    .MuiFormControl-fullWidth:first-of-type {
      grid-area: 1 / 1 / 2 / 3;
    }

    :not(:first-child) {
      grid-template-rows: repeat(4, 1fr);
    }

    button:first-of-type {
      grid-area: 4 / 1 / 5 / 3;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: 1fr 1.5fr 1.5fr 1.5fr 1.5fr 45px;
    grid-template-rows: 1fr;
  }
`;

export const BeneficiaryAddButton = styled(CustomButtonV2)`
  margin-top: 0.5rem;
  width: 48px;
  font-size: 1.5rem;
  font-weight: 400;
`;

export const BeneficiaryFormErrorText = styled(TextSmall)`
  color: ${colors.danger};
  margin-bottom: 0;
`;
