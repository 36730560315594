import { QueryState } from 'components/QueryState';
import { SelectAccount } from 'components/SelectAccount/SelectAccount';
import {
  OrderType,
  SelectOrderType,
} from 'components/SelectOrderType/SelectOrderType';
import { CustomSwitch } from 'components/Switch/Switch';
import { H3, TextAlign } from 'components/design-system/Heading/Heading';
import {
  DynamicPortfolioConstructionList,
  DynamicPortfolioConstructionListPaged,
} from 'components/feature/OrdersList/OrdersList';
import { ToastProvider } from 'context/ToastContext';
import {
  WrapperType,
  useAccountsQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { useState } from 'react';
import {
  DynamicPortfolioConstructionListContainer,
  HeadingLayout,
  OrdersFiltersWrapper,
  RebalancingOrdersWrapper,
} from './Orders.styles';

export const DashboardOrders = () => {
  const userProfileQuery = useUserProfileQuery();
  const accountsQuery = useAccountsQuery();
  const [
    activeWrapperType,
    setActiveWrapperType,
  ] = useState<WrapperType | null>(null);
  const [activeOrderType, setActiveOrderType] = useState<OrderType>('all');
  const [includeCompleteOrders, setIncludeCompleteOrders] = useState(false);
  const pagedOrdersEnabled =
    userProfileQuery.data?.userProfile?.pagedOrdersTabEnabled;

  return (
    <QueryState {...accountsQuery}>
      {({ data }) => {
        const selectedAccount = data?.accounts?.find(
          ({ wrapperType }) => wrapperType === activeWrapperType
        );
        return (
          <>
            <ToastProvider>
              <RebalancingOrdersWrapper>
                <HeadingLayout>
                  <OrdersFiltersWrapper>
                    {pagedOrdersEnabled && (
                      <SelectOrderType
                        onChange={(orderType) => setActiveOrderType(orderType)}
                        selectedOrderType={activeOrderType}
                      />
                    )}
                    <SelectAccount
                      selectedAccountType={activeWrapperType}
                      onChange={(wrapperType) => {
                        setActiveWrapperType(wrapperType);
                      }}
                    />
                  </OrdersFiltersWrapper>
                  <H3 $textAlign={TextAlign.center} $noMargin>
                    Orders
                  </H3>
                </HeadingLayout>
                <div>
                  {pagedOrdersEnabled && (
                    <CustomSwitch
                      checked={includeCompleteOrders}
                      onChange={(ev) => {
                        setIncludeCompleteOrders(ev.target.checked);
                      }}
                      label="Include completed orders"
                    />
                  )}
                </div>
                <DynamicPortfolioConstructionListContainer>
                  <QueryState {...userProfileQuery}>
                    {({ data }) => {
                      const pagedOrdersEnabled =
                        data?.userProfile?.pagedOrdersTabEnabled;

                      return pagedOrdersEnabled ? (
                        <>
                          <DynamicPortfolioConstructionListPaged
                            includeCompleteOrders={includeCompleteOrders}
                            selectedAccount={selectedAccount}
                            activeWrapperType={activeWrapperType}
                            selectedOrderType={activeOrderType}
                          />
                        </>
                      ) : (
                        <>
                          <DynamicPortfolioConstructionList
                            includeCompleteOrders={includeCompleteOrders}
                            selectedAccount={selectedAccount}
                            activeWrapperType={activeWrapperType}
                          />
                        </>
                      );
                    }}
                  </QueryState>
                </DynamicPortfolioConstructionListContainer>
              </RebalancingOrdersWrapper>
            </ToastProvider>
          </>
        );
      }}
    </QueryState>
  );
};
