import { ChargesType, Instrument } from 'generated/graphql';
import { PartialDeep } from 'type-fest';

/**
 *
 * This function will calculate the units of a purchase based on the amount of the purchase
 *
 * @param instrument -   Object of type Instrument to be sold
 * @param amount -   Amount to be sold
 * @param charges  - Optional charges of type ChargesType
 * @returns
 */
export function getBuyEstimate(
  instrument: Pick<Instrument, 'askPrice' | 'minimumTradeUnit' | 'quoteUnit'>,
  amount: number,
  charges?: PartialDeep<ChargesType>
) {
  const { askPrice = 1, minimumTradeUnit = 1, quoteUnit = 1 } = instrument;
  const amountAfterPtm = amount - getPtmLevyAmount(amount, charges);
  const amountAfterFees =
    amountAfterPtm - getStampDutyAmount(amountAfterPtm, charges);
  return (
    floorToMultiple(amountAfterFees / askPrice!, minimumTradeUnit) * quoteUnit!
  );
}

/**
 *
 * Get units to be sold based on the amount to be sold
 *
 * @param instrument  - Object of type Instrument to be sold
 * @param amount -  Amount to be sold
 * @param charges - Optional charges of type ChargesType
 * @returns
 */
export function getSellEstimateUnits(
  instrument: PartialDeep<Instrument>,
  amount: number,
  charges?: PartialDeep<ChargesType>
) {
  const { bidPrice = 1, minimumTradeUnit = 1, quoteUnit = 1 } = instrument;
  const amountAfterPtm = amount - getPtmLevyAmount(amount, charges);
  const amountAfterFees =
    amountAfterPtm - getStampDutyAmount(amountAfterPtm, charges);
  return (
    floorToMultiple(amountAfterFees / bidPrice!, minimumTradeUnit) * quoteUnit!
  );
}

/**
 *
 * This function calculates (in pounds) the amount to be sold based on the units to be sold
 *
 * @param instrument -  Object of type Instrument to be sold
 * @param units - Units to be sold
 * @returns
 */
export function getSellEstimate(
  instrument: PartialDeep<Instrument>,
  units: number
) {
  const { bidPrice = 1, minimumTradeUnit = 1, quoteUnit = 1 } = instrument;
  const estimate =
    floorToMultiple(units, minimumTradeUnit) * bidPrice! * quoteUnit!;
  return Math.max(estimate, 0.01);
}

export function floorToMultiple(x: number, multiple: number) {
  return Math.floor(x / multiple) * multiple;
}

function getPtmLevyAmount(amount: number, charges?: PartialDeep<ChargesType>) {
  return charges?.ptmLevyApplicable &&
    amount > charges.ptmLevyApplicableOrderValueThreshold!
    ? charges.ptmLevyAmountWhereApplicable!
    : 0;
}

function getStampDutyAmount(
  amount: number,
  charges?: PartialDeep<ChargesType>
) {
  return charges?.stampDutyApplicable
    ? amount * charges.stampDutyProportion!
    : 0;
}

interface DeriveMinTradeableAmountProps {
  askPrice: number | undefined | null;
  minimumTradeUnit: number;
}

export function deriveMinTradeableAmount({
  askPrice,
  minimumTradeUnit,
}: DeriveMinTradeableAmountProps) {
  if (askPrice === null || askPrice === undefined) {
    return null;
  }

  return Math.round(askPrice * minimumTradeUnit * 100) / 100;
}

export enum MinTradeUnitStatusEnum {
  lessThanMinimumTradeUnit = 'lessThanMinimumTradeUnit',
  closeToMinimumTradeUnit = 'closeToMinimumTradeUnit',
  highProportionUntradeable = 'highProportionUntradeable',
  closeToHighProportionUntradeable = 'closeToHighProportionUntradeable',
  allGood = 'allGood',
}

interface MinTradeUnitStatusProps {
  tradeAmount: number;
  askPrice: number;
  minTradeableUnit: number;
}

export type MinTradeUnitStatus =
  | {
      status: Exclude<
        MinTradeUnitStatusEnum,
        MinTradeUnitStatusEnum.highProportionUntradeable
      >;
      minTradeableAmount: number;
    }
  | {
      status: MinTradeUnitStatusEnum.highProportionUntradeable;
      estNotInvested: number;
      minTradeableAmount: number;
    };

export function deriveMinTradeUnitStatus({
  tradeAmount,
  askPrice,
  minTradeableUnit,
}: MinTradeUnitStatusProps): MinTradeUnitStatus | null {
  const tradeableUnitFluctuation = 1.05;
  const highProportionUntradeable = 0.1;

  const minTradeableAmount = deriveMinTradeableAmount({
    askPrice,
    minimumTradeUnit: minTradeableUnit,
  });

  if (minTradeableAmount === null) {
    return null;
  }

  if (tradeAmount < minTradeableAmount) {
    return {
      status: MinTradeUnitStatusEnum.lessThanMinimumTradeUnit,
      minTradeableAmount,
    };
  }

  if (tradeAmount < minTradeableAmount * tradeableUnitFluctuation) {
    return {
      status: MinTradeUnitStatusEnum.closeToMinimumTradeUnit,
      minTradeableAmount,
    };
  }

  const cashRemaining = tradeAmount % minTradeableAmount;
  const cashRemainingProportion = cashRemaining / tradeAmount;
  if (cashRemainingProportion > highProportionUntradeable) {
    return {
      status: MinTradeUnitStatusEnum.highProportionUntradeable,
      minTradeableAmount,
      estNotInvested: Math.round(cashRemaining * 100) / 100,
    };
  }

  const potentialCashRemaining =
    tradeAmount % (minTradeableAmount * tradeableUnitFluctuation);
  const potentialCashRemainingProportion = potentialCashRemaining / tradeAmount;
  if (potentialCashRemainingProportion > highProportionUntradeable) {
    return {
      status: MinTradeUnitStatusEnum.closeToHighProportionUntradeable,
      minTradeableAmount,
    };
  }

  return { status: MinTradeUnitStatusEnum.allGood, minTradeableAmount };
}
