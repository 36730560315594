import { Loading } from 'components/Loading';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { StyledLink } from 'components/design-system/Link';
import {
  TextAlign,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import { AccountType, useReferralCodeQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { AiOutlineClose } from 'react-icons/ai';
import {
  StepContent,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import {
  CoBrandedLogo,
  ReferralActionsContainer,
  ReferralCoBrandingContainer,
  ReferralWelcomeContainer,
  TillitLogo,
} from './ReferralWelcomeStep.styles';

export interface ReferralWelcomeStepProps {
  referralCode: string;
  onProceed: (AccountType: AccountType) => void;
}

export function ReferralWelcomeStep({
  referralCode,
  onProceed,
}: ReferralWelcomeStepProps) {
  const { getSignInPath } = useAuth();
  const {
    data: referralCodeData,
    isLoading: referralDataLoading,
  } = useReferralCodeQuery(
    {
      code: referralCode,
    },
    {
      onSuccess: (data) => {
        if (
          !data.referralCode?.employer ||
          data.referralCode?.initialAccountType !== AccountType.Sipp
        ) {
          // We're only interested in handling employer referrals for pensions just now, because
          // there's an explosion of different messaging we might want to employ for other cases
          // (and some don't make a lot of sense yet - like an employer-linked ISA). If we aren't
          // on the happy path, just bail out and let the parent component handle it.
          onProceed(AccountType.NotSpecified);
        }
      },
    }
  );

  const employerName =
    referralCodeData?.referralCode?.employer?.displayName ||
    referralCodeData?.referralCode?.employer?.companyName;
  const initialAccountType = referralCodeData?.referralCode?.initialAccountType;

  const handleGetStarted = () => {
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.referralCode,
    });
    onProceed(initialAccountType || AccountType.NotSpecified);
  };

  return referralDataLoading ? (
    <Loading />
  ) : (
    <ReferralWelcomeContainer>
      <StepContent>
        <StepTitle>Let's start your TILLIT Pension journey</StepTitle>
      </StepContent>

      {referralCodeData?.referralCode?.employer?.logoUrl && (
        <ReferralCoBrandingContainer>
          <TillitLogo />
          <AiOutlineClose />
          <CoBrandedLogo
            src={referralCodeData?.referralCode?.employer?.logoUrl}
            alt={employerName || ''}
          />
        </ReferralCoBrandingContainer>
      )}

      <StepContent>
        <TextNormal $textAlign={TextAlign.center}>
          <strong>{employerName}</strong> has offered to make pension
          contributions into a TILLIT Pension (a Self-Invested Personal Pension,
          or SIPP) instead of their default pension scheme.
        </TextNormal>
        <TextNormal $textAlign={TextAlign.center}>
          We'll spend the next few minutes getting to know you, helping you to
          decide if the TILLIT Pension is right for you and setting up your
          pension contributions.
        </TextNormal>

        <ReferralActionsContainer>
          <CustomButtonV2 onClick={handleGetStarted}>
            Let's get started
          </CustomButtonV2>

          <TextSmall
            $textAlign={TextAlign.center}
            style={{ fontWeight: 300 }}
            $noMargin
          >
            Already have an account?
            <br />{' '}
            <StyledLink
              href={getSignInPath(undefined, undefined, referralCode)}
            >
              Sign in
            </StyledLink>
          </TextSmall>
        </ReferralActionsContainer>
      </StepContent>
    </ReferralWelcomeContainer>
  );
}
