import { TextNormal, TextSmall } from 'components/design-system/Text/Text';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { TargetDateForm } from '../TargetDateForm';
import { TargetDateFormValues } from '../TargetDateForm/TargetDateForm';
import { getTargetDateInstrument } from '../helpers/getTargetDateInstrument';
import { useTargetDateInfo } from '../hooks/useTargetDateInfo';
import {
  CloseForm,
  EditDetailsButton,
  TargetDateBannerContainer,
  TargetDateBannerContent,
  TargetDateBannerInner,
} from './TargetDateBanner.styles';

export interface TargetDateBannerProps {
  assetData: AssetQueryAsset;
  onProceed?: (data: TargetDateFormValues) => void;
}

export function TargetDateBanner({
  assetData,
  onProceed,
}: TargetDateBannerProps) {
  const bannerContentContainer = useAnimation();
  const bannerFormContainer = useAnimation();
  const { targetDateInfo } = useTargetDateInfo();
  const [retirementYear, setRetirementYear] = React.useState(0);
  const [instrumentName, setInstrumentName] = React.useState('');
  const [targetRange, setTargetRange] = React.useState('');

  useEffect(() => {
    if (targetDateInfo?.yearOfBirth && targetDateInfo?.intendedRetirementAge) {
      setRetirementYear(
        targetDateInfo.yearOfBirth + targetDateInfo.intendedRetirementAge
      );
    }
  }, [targetDateInfo?.yearOfBirth, targetDateInfo?.intendedRetirementAge]);

  useEffect(() => {
    const selectedInstrument = getTargetDateInstrument(
      assetData,
      retirementYear
    );
    if (selectedInstrument.instrument) {
      setInstrumentName(selectedInstrument.instrument.name);
      const targetRange = () => {
        if (selectedInstrument) {
          return !selectedInstrument.startDate
            ? `${selectedInstrument.endDate} and earlier`
            : !selectedInstrument.endDate
            ? `${selectedInstrument.startDate} and above`
            : `${selectedInstrument.startDate} - ${selectedInstrument.endDate}`;
        } else {
          return 'N/A';
        }
      };
      setTargetRange(targetRange);
    }
  }, [assetData, retirementYear]);

  const handleEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    bannerContentContainer.start({
      height: '0',
      opacity: '0',
      pointerEvents: 'none',
    });
    bannerFormContainer.start({
      height: 'auto',
      opacity: '1',
      pointerEvents: 'auto',
    });
  };

  const startCloseAnimation = () => {
    bannerContentContainer.start({
      height: 'auto',
      opacity: '1',
      pointerEvents: 'auto',
    });
    bannerFormContainer.start({
      height: '0',
      opacity: '0',
      pointerEvents: 'none',
    });
  };

  const handleCloseForm = () => {
    startCloseAnimation();
  };

  const handleForm = (data: TargetDateFormValues) => {
    startCloseAnimation();
    setRetirementYear(
      Number(data.yearOfBirth) + Number(data.intendedRetirementAge)
    );
    onProceed?.(data);
  };

  return !retirementYear ? null : (
    <TargetDateBannerContainer>
      <TargetDateBannerInner maxWidth="lg" className="dark-background">
        <motion.div
          initial={{ height: 'auto', opacity: '1', overflow: 'hidden' }}
          animate={bannerContentContainer}
          transition={{ duration: 0.25 }}
        >
          <TargetDateBannerContent>
            <TextNormal $noMargin $isDarkBg>
              We're showing you the <b>{instrumentName}</b> of this fund because
              the retirement dates it covers (<b>{targetRange}</b>) best match
              your planned retirement in <b>{retirementYear}</b>.
            </TextNormal>

            <TextSmall $noMargin $isDarkBg>
              Are these not correct?
              <br />
              <EditDetailsButton onClick={handleEdit}>
                Change my details
              </EditDetailsButton>
            </TextSmall>
          </TargetDateBannerContent>
        </motion.div>
        <motion.div
          initial={{ height: '0', opacity: '0', overflow: 'hidden' }}
          animate={bannerFormContainer}
          transition={{ duration: 0.25 }}
        >
          <CloseForm onClick={handleCloseForm}>
            <AiOutlineClose />
          </CloseForm>
          {targetDateInfo && (
            <TargetDateForm
              $editMode
              $theme="light"
              $canGoBack={false}
              onProceed={handleForm}
              title="About you"
            />
          )}
        </motion.div>
      </TargetDateBannerInner>
    </TargetDateBannerContainer>
  );
}
