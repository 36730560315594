import { TextXS } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export interface typeColorProps {
  $type: 'buy' | 'sell';
}

export const IconWrapper = styled.div<typeColorProps>`
  display: inline-flex;
  gap: 0.125rem;
  margin-left: 0.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 1.5rem;
  background-color: ${({ $type }) =>
    transparentize(0.9, $type === 'buy' ? colors.success : colors.danger)};
`;

export const IconTitleWrapper = styled.div<typeColorProps>`
  display: flex;
  align-items: baseline;
  gap: 0.5rem;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: space-between;
  }

  h4,
  h5,
  h6 {
    color: ${({ $type }) => ($type === 'buy' ? colors.success : colors.danger)};
  }
`;

export const OrderWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`;

export const SmallCopyWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
    grid-column: 1 / 1;
  }
`;

export const StyledTextXS = styled(TextXS)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25rem;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1 / -1;
`;
