import { colors } from 'constants/colors';
import styled from 'styled-components';

export const TopBar = styled.button`
  border: none;
  padding: 0.75rem 0;
  background: none;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

interface ContentProps {
  isExpanded: boolean;
}
export const Content = styled.div<ContentProps>`
  display: flex;
  width: 100%;
  max-height: ${({ isExpanded }) => (isExpanded ? '50rem' : '0')};
  overflow: ${({ isExpanded }) => !isExpanded && 'hidden'};
  opacity: ${({ isExpanded }) => (isExpanded ? '1' : '0')};
  transition: max-height 0.3s ease-in-out, opacity 0.3s 0.2s linear;
`;

export const SubSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
  margin-bottom: 0.5rem;
`;

export const TextRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }
`;

export const ChargesSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  gap: 0.5rem 0;
  border-left: 1px solid ${colors['magenta-300']};
`;
