import { yupResolver } from '@hookform/resolvers/yup';
import { ComboBoxInput } from 'components/Form/ComboBoxInput';
import { QueryState } from 'components/QueryState';
import {
  GoBackLink,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepFormContainer,
  StepIntroductionTypography,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType, useTransferProvidersQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { dashboardTransfersPath } from 'paths';
import { Controller, useForm } from 'react-hook-form';
import { useIntercom } from 'react-use-intercom';
import * as yup from 'yup';
import { CedingProvider } from '../../types';
import { wrapperNameFriendly } from '../_shared/WrapperNameUtils';
import {
  AccountNumberHelpText,
  CantFindYourProvider,
  NoOptionCopy,
  StyledInput,
} from './ProviderDetails.styles';

const CEDING_PROVIDER_ACCOUNT_NUMBER__MIN_LENGTH = 2;
const CEDING_PROVIDER_ACCOUNT_NUMBER__MAX_LENGTH = 20;

const accountNumberSchema = yup
  .string()
  .trim()
  .min(
    CEDING_PROVIDER_ACCOUNT_NUMBER__MIN_LENGTH,
    `Please enter at least ${CEDING_PROVIDER_ACCOUNT_NUMBER__MIN_LENGTH} characters`
  )
  .max(
    CEDING_PROVIDER_ACCOUNT_NUMBER__MAX_LENGTH,
    `Please enter a maximum of ${CEDING_PROVIDER_ACCOUNT_NUMBER__MAX_LENGTH} characters`
  )
  .required('Please provide your account number');

const transferSchema = yup.object().shape({
  cedingProviderAccountNumber: accountNumberSchema,
  cedingProvider: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
      helpText: yup.string().nullable(),
    })
    .required('Please provide the ceding provider name'),
});

interface ProviderDetailsFormValues {
  cedingProviderAccountNumber: string;
  cedingProvider: CedingProvider | null;
}

export interface ProviderDetailsOnProceedValues {
  cedingProviderAccountNumber: string;
  cedingProvider: CedingProvider;
}

export type OnProceedCallback = (
  values: ProviderDetailsOnProceedValues
) => void;

export interface ProviderDetailsProps {
  wrapperType: WrapperType;
  onProceed: OnProceedCallback;
  providerDetails?: ProviderDetailsFormValues;
}

export function ProviderDetails({
  wrapperType,
  onProceed,
  providerDetails,
}: ProviderDetailsProps) {
  const methods = useForm<ProviderDetailsFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(transferSchema),
    defaultValues: {
      cedingProvider: null,
      ...providerDetails,
    },
  });

  const { register, handleSubmit, control, watch } = methods;

  const onSubmit = async (data: ProviderDetailsFormValues) => {
    if (data.cedingProvider === null) {
      return;
    }

    onProceed({
      cedingProvider: data.cedingProvider,
      cedingProviderAccountNumber: data.cedingProviderAccountNumber,
    });
  };

  const { showNewMessages } = useIntercom();
  const transferProvidersQuery = useTransferProvidersQuery({ wrapperType });

  const cedingProvider = watch('cedingProvider');
  const cedingProviderHelpText = cedingProvider?.helpText || '';

  const cedingProviderAccountNumber = watch('cedingProviderAccountNumber');

  const transferDetailsValid =
    cedingProvider?.id &&
    accountNumberSchema.isValidSync(cedingProviderAccountNumber);

  const handleCantFindYourProviderButton = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `transfer dialog - provider details - can't find provider`,
    });
    showNewMessages(
      `I need help with my ${wrapperNameFriendly(
        wrapperType
      )} transfer, I can't find my provider`
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StepContainer>
        <StepContent width={StepContentWidth.extraWide}>
          <StepTitle>
            Transfer my {wrapperNameFriendly(wrapperType)} to TILLIT
          </StepTitle>
          <StepIntroductionTypography>
            Can't find your provider?{' '}
            <CantFindYourProvider
              type="button"
              onMouseDown={handleCantFindYourProviderButton}
            >
              Let us know
            </CantFindYourProvider>{' '}
            where you want to transfer from and our Transfers Concierge team
            will look into this for you.
          </StepIntroductionTypography>
          <StepFormContainer>
            <QueryState {...transferProvidersQuery}>
              {() => (
                <Controller
                  control={control}
                  id="cedingProvider"
                  name="cedingProvider"
                  render={({ onChange, value }) => {
                    return (
                      <ComboBoxInput
                        label="Provider name"
                        options={
                          transferProvidersQuery.data?.transferProviders!
                        }
                        value={value}
                        optionLabel={(option) => option.name}
                        onChange={(ev, option) => {
                          onChange(option);
                        }}
                        noOptionText={
                          <NoOptionCopy>
                            Can't find your provider?{' '}
                            <CantFindYourProvider
                              type="button"
                              onMouseDown={handleCantFindYourProviderButton}
                            >
                              Let us know
                            </CantFindYourProvider>{' '}
                            where you want to transfer from and our Transfers
                            Concierge team will look into this for you.
                          </NoOptionCopy>
                        }
                      />
                    );
                  }}
                />
              )}
            </QueryState>
            <div>
              <StyledInput
                inputRef={register}
                id="cedingProviderAccountNumber"
                name="cedingProviderAccountNumber"
                label="Account number"
                fullWidth={true}
              />
              {cedingProviderHelpText && (
                <AccountNumberHelpText>
                  {cedingProviderHelpText}
                </AccountNumberHelpText>
              )}
            </div>
          </StepFormContainer>
        </StepContent>
        <StepActions>
          <StepButton
            type="submit"
            className="magenta"
            disabled={!transferDetailsValid}
          >
            Continue
          </StepButton>
          <GoBackLink to={dashboardTransfersPath} />
        </StepActions>
      </StepContainer>
    </form>
  );
}
