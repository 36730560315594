import { Loading } from 'components/Loading';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import { TextAlign, TextNormal } from 'components/design-system/Text/Text';
import {
  AccountType,
  WrapperType,
  useApplyReferralCodeMutation,
  useReferralCodeQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { openPensionPath } from 'paths';
import { AiOutlineClose } from 'react-icons/ai';
import {
  GoBackButton,
  StepContent,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import {
  CoBrandedLogo,
  ReferralActionsContainer,
  ReferralCoBrandingContainer,
  ReferralWelcomeContainer,
  TillitLogo,
} from './ConfirmReferralStep.styles';

export type returnData = {
  employerName?: string;
  returnPath?: string;
};

export interface ConfirmReferralStepProps {
  mode?: 'normal' | 'dialog';
  referralCode: string;
  onProceed: ({ employerName, returnPath }: returnData) => void;
  onBack?: () => void;
}

export function ConfirmReferralStep({
  mode = 'normal',
  referralCode,
  onProceed,
  onBack,
}: ConfirmReferralStepProps) {
  const userProfileQuery = useUserProfileQuery();

  const hasPensionAccount = userProfileQuery.data?.clientSummary?.accounts?.some(
    (account) => {
      return account.wrapperType === WrapperType.Sipp;
    }
  );

  const {
    mutateAsync: applyReferralCodeMutate,
    isLoading,
    isError,
  } = useApplyReferralCodeMutation();

  const {
    data: referralCodeData,
    isLoading: referralDataLoading,
  } = useReferralCodeQuery(
    {
      code: referralCode,
    },
    {
      onSuccess: (data) => {
        if (
          !data.referralCode?.employer ||
          (data.referralCode?.initialAccountType !== AccountType.Sipp &&
            mode === 'normal')
        ) {
          // We're only interested in handling employer referrals for pensions just now, because
          // there's an explosion of different messaging we might want to employ for other cases
          // (and some don't make a lot of sense yet - like an employer-linked ISA). If we aren't
          // on the happy path, just bail out and let the parent component handle it.
          onProceed({});
        }
      },
    }
  );

  const employerName =
    referralCodeData?.referralCode?.employer?.displayName ||
    referralCodeData?.referralCode?.employer?.companyName;

  const handleApplyReferralCode = async () => {
    try {
      await applyReferralCodeMutate({
        input: { code: referralCode },
      });
      onProceed({ employerName, returnPath: openPensionPath });
    } catch {
      // Error handled by state
    }
  };

  return referralDataLoading ? (
    <Loading />
  ) : (
    <ReferralWelcomeContainer>
      <StepContent>
        {hasPensionAccount ? (
          <StepTitle>
            Allow {employerName} to contribute to your TILLIT Pension
          </StepTitle>
        ) : (
          <StepTitle>Let's start your TILLIT Pension journey</StepTitle>
        )}
      </StepContent>

      {referralCodeData?.referralCode?.employer?.logoUrl && (
        <ReferralCoBrandingContainer>
          <TillitLogo />
          <AiOutlineClose />
          <CoBrandedLogo
            src={referralCodeData?.referralCode?.employer?.logoUrl}
            alt={employerName || ''}
          />
        </ReferralCoBrandingContainer>
      )}

      {hasPensionAccount || mode === 'dialog' ? (
        <StepContent>
          <TextNormal $textAlign={TextAlign.center}>
            <strong>{employerName}</strong> has offered to make pension
            contributions into your TILLIT Pension (a Self-Invested Personal
            Pension, or SIPP).
          </TextNormal>
          <TextNormal $textAlign={TextAlign.center} $noMargin>
            By continuing you agree to allow {employerName} to make
            contributions to your TILLIT Pension.
          </TextNormal>

          <ServerError isVisible={isError} />

          <ReferralActionsContainer>
            <CustomButtonV2
              disabled={isLoading}
              onClick={async () => {
                await handleApplyReferralCode();
              }}
            >
              Continue
            </CustomButtonV2>
            {onBack && <GoBackButton onClick={onBack} />}
          </ReferralActionsContainer>
        </StepContent>
      ) : (
        <StepContent>
          <TextNormal $textAlign={TextAlign.center}>
            <strong>{employerName}</strong> has offered to make pension
            contributions into a TILLIT Pension (a Self-Invested Personal
            Pension, or SIPP).
          </TextNormal>
          <TextNormal $textAlign={TextAlign.center}>
            We'll spend the next few minutes helping you to decide if the TILLIT
            Pension is right for you and setting up your pension contributions.
          </TextNormal>

          <ServerError isVisible={isError} />

          <ReferralActionsContainer>
            <CustomButtonV2
              disabled={isLoading}
              onClick={() => {
                handleApplyReferralCode();
              }}
            >
              Open pension account
            </CustomButtonV2>
            {onBack && <GoBackButton onClick={onBack} />}
          </ReferralActionsContainer>
        </StepContent>
      )}
    </ReferralWelcomeContainer>
  );
}
