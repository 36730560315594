import {
  ClickAwayListener,
  Fade,
  IconButtonProps,
  Paper,
  PopperPlacementType,
  Slide,
} from '@material-ui/core';
import {
  bindHover,
  bindPopper,
  bindToggle,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import {
  Arrow,
  StyledIconButton,
  StyledPopper,
  StyledSpan,
  styledPopperPaddingOptions,
} from './InfoPopoverV2.styles';

export interface InfoPopoverProps {
  className?: string;
  color?: IconButtonProps['color'];
  content: React.ReactNode;
  disableMargin?: boolean;
  disablePadding?: boolean;
  isDark?: boolean;
  $width?: 'narrow' | 'normal' | 'wide';
  label?: string;
  onClick?: () => void;
  popupId?: string;
  size?: IconButtonProps['size'];
  placement?: PopperPlacementType;
  openOn?: 'click' | 'hover';
  $padding?: styledPopperPaddingOptions;
}

export function InfoPopoverV2({
  content,
  popupId,
  className,
  color = 'default',
  size = 'medium',
  onClick,
  disablePadding = true,
  disableMargin = false,
  isDark = false,
  label,
  $width = 'narrow',
  placement = 'top-start',
  openOn = 'click',
  $padding,
}: InfoPopoverProps) {
  const bindFunction = openOn === 'click' ? bindToggle : bindHover;
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const popperState = usePopupState({ variant: 'popper', popupId });

  const handleClickAway = useCallback(() => popperState.close(), [popperState]);

  const modifiers = useMemo(
    () => ({
      flip: {
        enabled: true,
      },
      arrow: {
        enabled: true,
        element: arrowRef,
      },
    }),
    [arrowRef]
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <span
        style={{ display: 'inline-flex', verticalAlign: 'text-bottom' }}
        onClick={() => onClick?.()}
      >
        <span onClick={(ev) => ev.stopPropagation()}>
          {label ? (
            <StyledSpan {...bindFunction(popperState)}>{label}</StyledSpan>
          ) : (
            <StyledIconButton
              type="button"
              size={size}
              color={color}
              className={className}
              $disablePadding={disablePadding}
              $disableMargin={disableMargin}
              {...bindFunction(popperState)}
            >
              <HiOutlineInformationCircle />
            </StyledIconButton>
          )}
        </span>
        <StyledPopper
          {...bindPopper(popperState)}
          placement={placement}
          modifiers={modifiers}
          $isDark={isDark}
          $width={$width}
          $padding={$padding}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Fade {...TransitionProps}>
              <div style={{ position: 'relative', overflow: 'hidden' }}>
                <Paper elevation={0} className="MuiPaper-root">
                  {content}
                </Paper>
                <Slide
                  {...TransitionProps}
                  direction={placement.startsWith('bottom') ? 'up' : 'down'}
                >
                  <Arrow ref={setArrowRef} />
                </Slide>
              </div>
            </Fade>
          )}
        </StyledPopper>
      </span>
    </ClickAwayListener>
  );
}

interface PopoverProps extends InfoPopoverProps {
  children: (
    props: ReturnType<typeof bindToggle> | ReturnType<typeof bindHover>
  ) => React.ReactNode;
  openOn?: 'click' | 'hover';
  placement?: PopperPlacementType;
}

export function Popover({
  content,
  popupId,
  isDark = false,
  placement = 'top',
  children,
  openOn = 'click',
  $padding,
}: PopoverProps) {
  const bindFunction = openOn === 'click' ? bindToggle : bindHover;
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);
  const popperState = usePopupState({ variant: 'popper', popupId });

  const handleClickAway = useCallback(() => {
    popperState.close();
  }, [popperState]);

  const modifiers = useMemo(
    () => ({
      flip: {
        enabled: false,
      },
      arrow: {
        enabled: true,
        element: arrowRef,
      },
    }),
    [arrowRef]
  );

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        {children(bindFunction(popperState))}
      </ClickAwayListener>
      <StyledPopper
        {...bindPopper(popperState)}
        placement={placement}
        modifiers={modifiers}
        $isDark={isDark}
        transition
        $padding={$padding}
        $width="narrow"
        onMouseLeave={() => handleClickAway()}
      >
        {({ TransitionProps, placement }) => (
          <Fade {...TransitionProps}>
            <div style={{ position: 'relative', overflow: 'hidden' }}>
              <Paper elevation={0}>{content}</Paper>
              <Slide
                {...TransitionProps}
                direction={placement.startsWith('bottom') ? 'up' : 'down'}
              >
                <Arrow ref={setArrowRef} />
              </Slide>
            </div>
          </Fade>
        )}
      </StyledPopper>
    </>
  );
}
