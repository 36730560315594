import { ButtonsWrapper } from 'components/ButtonsWrapper';
import { DepositForm } from 'components/Dialogs/Deposit/DepositForm';
import { DepositFormVariant } from 'components/Dialogs/Deposit/DepositFormVariant';
import {
  CustomButtonV2,
  LinkCustomButton,
} from 'components/design-system/Button/CustomButtonV2';
import {
  H2,
  TextAlign as HeadingAlign,
} from 'components/design-system/Heading/Heading';
import { FontStyle, Text, TextAlign } from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { currencyFull } from 'formatting';
import { PortfolioRebalancingStatus, WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import {
  generateCheckoutPath,
  generateDynamicPortfolioConstructionBasketPath,
} from 'paths';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RebalancingQueryRebalancing } from 'types/graphqlTypes';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepContentWidth,
} from '../../../../design-system/StepComponents/StepComponents';
import { useEditWaitingOrder } from '../../../PortfolioBuilder/_shared/useEditWaitingOrder';
import { DPCheckoutSteps } from '../../Checkout';
import { AddCashWrapper } from '../../Checkout.styles';
import { DpFlowTypes } from '../../flowType';
import { ValueContainer } from './InsufficientCash.styles';

export interface InsufficientCashProps {
  activeFlow: DpFlowTypes;
  accountId: string;
  selectedAccountType: WrapperType;
  recommendedCashBalance: number;
  basketSummary: number;
  availableCash: number;
  addCashOnProceed: (amount: number) => void;
  portfolioRebalancing: RebalancingQueryRebalancing;
}

interface IntroTextProps {
  valueRequired: number;
  submittedDate: Date | null;
  recommendedCashBalance: number;
}

const StandardIntroText = ({
  valueRequired,
  recommendedCashBalance,
}: IntroTextProps) => {
  return (
    <>
      <H2 $textAlign={HeadingAlign.center}>Insufficient cash</H2>
      <Text $textAlign={TextAlign.center} $noMargin={true}>
        To complete your order, please add cash to your account. So that we can
        place your orders and make sure that you have sufficient cash in your
        account to cover fees, please add:
      </Text>
      <ValueContainer>{currencyFull(valueRequired)}</ValueContainer>
      <Text $textAlign={TextAlign.center} $fontStyle={FontStyle.italic}>
        This includes your recommended minimum cash balance of{' '}
        <strong>{currencyFull(recommendedCashBalance)}</strong>.
      </Text>
      <Text $textAlign={TextAlign.center} $noMargin={true}>
        To add cash and complete your order, click 'Add cash'. Alternatively,
        you can edit your order.
      </Text>
    </>
  );
};

const OpenAccountIntroText = ({
  valueRequired,
  recommendedCashBalance,
}: IntroTextProps) => {
  return (
    <>
      <H2 $textAlign={HeadingAlign.center}>Add cash</H2>
      <Text $textAlign={TextAlign.center} $noMargin={true}>
        To complete your order, please add cash to your account. So that we can
        place your orders and make sure that you have sufficient cash in your
        account to cover fees, please add:
      </Text>
      <ValueContainer>{currencyFull(valueRequired)}</ValueContainer>
      <Text $textAlign={TextAlign.center} $fontStyle={FontStyle.italic}>
        This includes your recommended minimum cash balance of{' '}
        <strong>{currencyFull(recommendedCashBalance)}</strong>.
      </Text>
      <Text $textAlign={TextAlign.center} $noMargin={true}>
        To add cash and complete your order, click 'Add cash'. Alternatively,
        you can edit your order.
      </Text>
    </>
  );
};

const BuysAndSellsInsufficientCashIntroText = ({
  valueRequired,
  recommendedCashBalance,
}: IntroTextProps) => {
  return (
    <>
      <H2 $textAlign={HeadingAlign.center}>Insufficient cash</H2>
      <Text $textAlign={TextAlign.center}>
        Based on your current cash balance and the value of the orders in your
        basket, there is insufficient cash to complete your order.
      </Text>
      <Text $textAlign={TextAlign.center} $noMargin={true}>
        Please edit your order or add cash in order to proceed. Based on the
        cash currently available in your account and the expected value to be
        raised from the SELL orders, we estimate a shortfall of:
      </Text>
      <ValueContainer>{currencyFull(valueRequired)}</ValueContainer>
      <Text $textAlign={TextAlign.center} $fontStyle={FontStyle.italic}>
        This includes your recommended minimum cash balance of{' '}
        <strong>{currencyFull(recommendedCashBalance)}</strong>.
      </Text>
      <Text $textAlign={TextAlign.center} $noMargin={true}>
        To add cash and complete your order, click 'Add cash'. Alternatively,
        you can edit your order.
      </Text>
    </>
  );
};

const ReturningSellsLessThanExpectedIntroText = ({
  valueRequired,
  submittedDate,
  recommendedCashBalance,
}: IntroTextProps) => {
  return (
    <>
      <H2 $textAlign={HeadingAlign.center}>
        Add cash to complete your BUY order
      </H2>
      <Text $textAlign={TextAlign.center}>
        The SELL orders from your portfolio rebalancing{' '}
        {submittedDate && <>on {format.date(submittedDate)} </>}have now
        settled. However, the cash raised from the sales combined with the
        available cash in your account is insufficient to cover the outstanding
        BUY orders.
      </Text>
      <Text $textAlign={TextAlign.center} $noMargin={true}>
        To complete your order, please add cash to your account. So that we can
        place your orders and make sure that you have sufficient cash in your
        account to cover fees, please add:
      </Text>
      <ValueContainer>{currencyFull(valueRequired)}</ValueContainer>
      <Text $textAlign={TextAlign.center} $fontStyle={FontStyle.italic}>
        This includes your recommended minimum cash balance of{' '}
        <strong>{currencyFull(recommendedCashBalance)}</strong>.
      </Text>
      <Text $textAlign={TextAlign.center} $noMargin={true}>
        To add cash and complete your order, click 'Add cash'. Alternatively,
        you can edit your order.
      </Text>
    </>
  );
};
const ReturningToBuysInsufficientCashIntroText = ({
  valueRequired,
  recommendedCashBalance,
}: IntroTextProps) => {
  return (
    <>
      <H2 $textAlign={HeadingAlign.center}>
        Add cash to complete your BUY order
      </H2>
      <Text $textAlign={TextAlign.center}>
        Based on your current cash balance and the value of the outstanding BUY
        orders, there is insufficient cash to complete your order. So that we
        can place your order and make sure that you have sufficient cash in your
        account to cover fees, please add:
      </Text>
      <ValueContainer>{currencyFull(valueRequired)}</ValueContainer>
      <Text $textAlign={TextAlign.center} $fontStyle={FontStyle.italic}>
        This includes your recommended minimum cash balance of{' '}
        <strong>{currencyFull(recommendedCashBalance)}</strong>.
      </Text>
      <Text $textAlign={TextAlign.center} $noMargin={true}>
        To add cash and complete your order, click ‘Add cash’. Alternatively,
        you can edit your order.
      </Text>
    </>
  );
};

const introTextLookup: {
  [k in string]: (props: IntroTextProps) => JSX.Element;
} = {
  [DpFlowTypes.buysAndSellsInsufficientCash]: BuysAndSellsInsufficientCashIntroText,
  [DpFlowTypes.returningSellsLessThanExpected]: ReturningSellsLessThanExpectedIntroText,
  [DpFlowTypes.returningToBuysInsufficientCash]: ReturningToBuysInsufficientCashIntroText,
  [DpFlowTypes.openAccount]: OpenAccountIntroText,
};

export function InsufficientCash({
  activeFlow,
  accountId,
  selectedAccountType,
  recommendedCashBalance,
  basketSummary,
  availableCash,
  addCashOnProceed,
  portfolioRebalancing,
}: InsufficientCashProps) {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const paymentId = queryParams.has('paymentId')
    ? (queryParams.get('paymentId') as string)
    : undefined;

  // TODO: some more work will be required to still display this screen if the user would be left with 0 available cash
  // after the purchase as this would not cover the minimum cash balance.
  // maybe will become a warning rather than a hard action?
  const [addCashActive, setAddCashActive] = useState(!!paymentId);
  const [valueRequired, setValueRequired] = useState(
    fixPrecision(
      Math.abs(availableCash - basketSummary) + recommendedCashBalance
    )
  );
  const [minimumAmount, setMinimumAmount] = useState(
    fixPrecision(Math.abs(availableCash - basketSummary))
  );
  const handleAddCash = () => {
    setAddCashActive(true);
  };

  const editOrder = useEditWaitingOrder(
    accountId,
    selectedAccountType,
    portfolioRebalancing
  );
  const handleEditOrder = () => {
    if (editOrder === null) {
      return;
    }
    editOrder();
  };

  const handlePostDeposit = useCallback(
    (amount: number) => {
      if (availableCash < basketSummary) {
        setAddCashActive(false);
        setValueRequired(
          parseFloat(Math.abs(availableCash - basketSummary).toFixed(2))
        );
        setMinimumAmount(fixPrecision(Math.abs(availableCash - basketSummary)));
      } else {
        setAddCashActive(false);
        addCashOnProceed(amount);
      }
    },
    [addCashOnProceed, availableCash, basketSummary]
  );

  const IntroText = introTextLookup[activeFlow] || StandardIntroText;

  const submittedDate = portfolioRebalancing?.sellOrders?.length
    ? new Date(portfolioRebalancing.sellOrders[0]?.submittedUtc)
    : null;

  const isQuickOrder = portfolioRebalancing.isQuickOrder;

  const returnPath =
    process.env.REACT_APP_BASE_URL +
    generateCheckoutPath({
      selectedRebalancingId: portfolioRebalancing.id as string,
      wrapperType: getPathSegmentForWrapperType(selectedAccountType),
    });

  return (
    <div>
      {addCashActive && (
        <AddCashWrapper>
          <DepositForm
            variant={DepositFormVariant.Checkout}
            minimumAmount={minimumAmount}
            defaultValue={valueRequired}
            accountId={accountId}
            onClose={handlePostDeposit}
            isPolling
            returnPath={returnPath}
            urlPaymentId={paymentId}
            displayBuffer={
              activeFlow === DpFlowTypes.buysAndSellsInsufficientCash
            }
            proceedOnComplete={true}
            isCheckout={true}
          />
        </AddCashWrapper>
      )}
      {!addCashActive && (
        <StepContainer>
          <StepContent width={StepContentWidth.wide}>
            <IntroText
              valueRequired={valueRequired}
              submittedDate={submittedDate}
              recommendedCashBalance={recommendedCashBalance}
            />
          </StepContent>
          <StepActions>
            <ButtonsWrapper $isHorizontal={!isQuickOrder}>
              {!isQuickOrder &&
                portfolioRebalancing.status ===
                  PortfolioRebalancingStatus.Waiting && (
                  <CustomButtonV2
                    $size="normal"
                    $color="secondary"
                    $isWide={false}
                    onClick={() => {
                      trackGa({
                        event: GaEventNames.checkoutEdit,
                        content_type: 'cta button',
                        item_id: 'edit order - insufficient cash',
                        checkoutStep: DPCheckoutSteps.insufficientCash,
                        checkoutFlow: activeFlow,
                      });

                      handleEditOrder();
                    }}
                  >
                    Edit order
                  </CustomButtonV2>
                )}
              {!isQuickOrder &&
                portfolioRebalancing.status !==
                  PortfolioRebalancingStatus.Waiting && (
                  <LinkCustomButton
                    $size="normal"
                    $color="secondary"
                    $isWide={false}
                    to={generateDynamicPortfolioConstructionBasketPath({
                      wrapperType: getPathSegmentForWrapperType(
                        selectedAccountType
                      ),
                    })}
                    onClick={() => {
                      trackGa({
                        event: GaEventNames.checkoutEdit,
                        content_type: 'cta button',
                        item_id: 'edit order - insufficient cash',
                        checkoutStep: DPCheckoutSteps.insufficientCash,
                        checkoutFlow: activeFlow,
                      });
                    }}
                  >
                    Edit order
                  </LinkCustomButton>
                )}
              <CustomButtonV2
                $size="normal"
                $color="primary"
                $isWide={false}
                onClick={handleAddCash}
              >
                Add cash
              </CustomButtonV2>
            </ButtonsWrapper>
          </StepActions>
        </StepContainer>
      )}
    </div>
  );
}

function fixPrecision(n: number) {
  return parseFloat(n.toFixed(2));
}
