import { IsaDeclarationStepContent } from 'components/feature/openAccount/steps/IsaDeclarationStep/IsaDeclarationStep';
import { GaEventNames } from 'constants/gaConstants';
import { AffirmationKind } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useHistory } from 'react-router-dom';

export interface ISADeclarationStepProps {
  onBack: () => void;
}

export function ISADeclarationStep({ onBack }: ISADeclarationStepProps) {
  const history = useHistory();

  const handleBack = () => {
    //TODO: Add tracking to when user closes dialog without proceeding
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'isa declaration dialog',
      item_id: `go back button clicked`,
    });
    onBack();
  };

  const handleContinue = async (madeAffirmations: AffirmationKind[]) => {
    history.push('/confirmation', { madeAffirmations });
  };

  return (
    <IsaDeclarationStepContent
      onContinue={handleContinue}
      onCancel={handleBack}
    />
  );
}
