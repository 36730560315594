import { useFetcher } from 'api/TillitGraphQL';
import { useUnauthBasketPlacement } from 'components/feature/PortfolioBuilder/UnauthBasketPlacement/useUnauthBasketPlacement';
import type { Basket } from 'components/feature/PortfolioBuilder/hooks/useFundsBasket';
import { GaEventNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import {
  AccountStatus,
  AccountsDocument,
  AccountsQuery,
  AccountsQueryVariables,
  PortfolioRebalancingsDocument,
  PortfolioRebalancingsQuery,
  PortfolioRebalancingsQueryVariables,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { ShouldShowMfaSetup } from 'helpers/userMfaReminder';
import { useToggle } from 'hooks/useFeatureToggle';
import { useGetReturnPath } from 'hooks/useGetReturnPath';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce, useLocalStorage, useSessionStorage } from 'usehooks-ts';
import { Wrapper } from '../openAccount/OpenAccountForm.style';
import {
  EmailVerifyStep,
  FormLocation,
} from '../openAccount/steps/EmailVerifyStep/EmailVerifyStep';
import { SetupMfaMethodForm } from '../setupMfa/SetupMfaMethodForm';
import { SetupSmsMfaForm } from '../setupMfa/sms/SetupSmsMfaForm';
import {
  ConfirmReferralStep,
  returnData,
} from './steps/ConfirmReferralStep/ConfirmReferralStep';
import {
  EmailPasswordFormValues,
  EmailPasswordStep,
  LoginApiResBody,
} from './steps/EmailPasswordStep/EmailPasswordStep';
import { MfaStep } from './steps/MfaStep/MfaStep';

export interface PasswordStepProps {
  onProceed: () => void;
  onGoBack: () => void;
}

enum MultistepFormSteps {
  EmailPasswordStep = 'EmailPasswordStep',
  EmailVerify = 'EmailVerify',
  MfaStep = 'MfaStep',
  MfaSetup = 'MfaSetup',
  ConfirmReferralStep = 'ConfirmReferralStep',
}

export function SignInForm() {
  const history = useHistory();
  const { signedIn } = useAuth();
  const defaultReturnPath = useGetReturnPath();
  const [, setLastLoginTimestampIsoString] = useSessionStorage<string | null>(
    'lastLoginTimestamp',
    null
  );

  const getSafeReturnPath = (path: string) => {
    try {
      // Validate that the return path is a platform-relative URL
      const baseUri = new URL(document.baseURI);
      const urlParsed = new URL(path, document.baseURI);
      if (urlParsed.origin !== baseUri.origin) {
        // Attempting to direct to a different origin - reject and just return to /
        console.error(
          `An attempt was made to redirect to a different origin (${path}). Redirecting to '/'.`
        );
        return '/';
      } else {
        return path;
      }
    } catch (e) {
      console.error(e);
      return '/';
    }
  };

  const [allowTotp] = useToggle('global-allow-totp');

  const [basketLs] = useLocalStorage<Basket>('nonAccountBasket', {});
  const hasUnknownBasket = !!basketLs.unknown;

  useEffectOnce(() => {
    if (signedIn) {
      history.replace(getSafeReturnPath(defaultReturnPath));
    }
  });

  const [activeStep, setActiveStep] = useState<MultistepFormSteps | undefined>(
    MultistepFormSteps.EmailPasswordStep
  );
  const [emailPasswordFormValues, setEmailPasswordFormValues] = useState<
    EmailPasswordFormValues | undefined
  >();

  const [loginResultState, setLoginResultState] = useState<
    LoginApiResBody | undefined
  >();

  const fetchAccounts = useFetcher<AccountsQuery, AccountsQueryVariables>(
    AccountsDocument
  );
  const fetchPortfolioRebalancings = useFetcher<
    PortfolioRebalancingsQuery,
    PortfolioRebalancingsQueryVariables
  >(PortfolioRebalancingsDocument);

  const { addUnknownBasketItemsToAccountBasket } = useUnauthBasketPlacement();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const hasReferralCode = params.has('referralCode');

  const onLoginComplete = async (stepReturnPath?: string) => {
    setLastLoginTimestampIsoString(new Date().toISOString());

    const returnPath = stepReturnPath || defaultReturnPath;

    if (!hasUnknownBasket) {
      window.location.href = getSafeReturnPath(returnPath);
      return;
    }

    try {
      const fetchAccountsRes = await fetchAccounts();
      const activeAccounts =
        fetchAccountsRes.accounts &&
        fetchAccountsRes.accounts.filter(
          (acc) => acc.status === AccountStatus.Active
        );

      if (activeAccounts?.length !== 1) {
        window.location.href = getSafeReturnPath(returnPath);
        return;
      }

      const account = activeAccounts[0];
      const fetchPortfolioRebalancingsRes = await fetchPortfolioRebalancings({
        filter: { active: true, accountId: account?.id },
      });

      const portfolioRebalancing = fetchPortfolioRebalancingsRes.portfolioRebalancings.find(
        (portfolioRebalancing) => portfolioRebalancing.accountId === account?.id
      );

      await addUnknownBasketItemsToAccountBasket(account, portfolioRebalancing);

      window.location.href = getSafeReturnPath(returnPath);
    } catch {
      // Do nothing - we don't want to interrupt the login flow - the user will
      // be prompted to assign the unknown basket items to an account in the dialog.
      window.location.href = getSafeReturnPath(returnPath);
    }
  };

  const onLogin = (userId?: string) => {
    try {
      const showMfaStep = ShouldShowMfaSetup(userId);

      if (showMfaStep) {
        setActiveStep(MultistepFormSteps.MfaSetup);
      } else if (hasReferralCode) {
        setActiveStep(MultistepFormSteps.ConfirmReferralStep);
      } else {
        onLoginComplete();
      }
    } catch (error) {
      onLoginComplete();
    }
  };

  const handleSkip = () => {
    trackGa({
      event: GaEventNames.mfa,
      status: 'skipped',
    });
    onLoginComplete();
  };

  return (
    <Wrapper>
      {activeStep === MultistepFormSteps.EmailPasswordStep && (
        <EmailPasswordStep
          onProceed={(values, loginResult) => {
            if (loginResult.requireMfa) {
              setEmailPasswordFormValues(values);
              setActiveStep(MultistepFormSteps.MfaStep);
              setLoginResultState(loginResult);
            } else if (loginResult.requireEmailVerify) {
              setEmailPasswordFormValues(values);
              setActiveStep(MultistepFormSteps.EmailVerify);
            } else {
              onLogin(loginResult.userId);
            }
          }}
        />
      )}
      {activeStep === MultistepFormSteps.EmailVerify &&
        emailPasswordFormValues && (
          <>
            <EmailVerifyStep
              email={emailPasswordFormValues.username}
              password={emailPasswordFormValues.password}
              formLocation={FormLocation.SignIn}
              onProceed={() => {
                onLoginComplete();
              }}
            />
          </>
        )}
      {activeStep === MultistepFormSteps.MfaStep &&
        loginResultState &&
        emailPasswordFormValues && (
          <MfaStep
            emailPasswordFormValues={emailPasswordFormValues}
            defaultLoginResult={loginResultState}
            onProceed={(success) => {
              if (success) {
                if (hasReferralCode) {
                  setActiveStep(MultistepFormSteps.ConfirmReferralStep);
                } else {
                  onLoginComplete();
                }
              }
            }}
          />
        )}
      {activeStep === MultistepFormSteps.MfaSetup && allowTotp?.enabled && (
        <SetupMfaMethodForm
          proceedBtnText={hasUnknownBasket ? 'Continue' : 'Add cash'}
          onProceed={() => {
            if (hasReferralCode) {
              setActiveStep(MultistepFormSteps.ConfirmReferralStep);
            } else {
              onLoginComplete();
            }
          }}
          onSkip={() => {
            if (hasReferralCode) {
              setActiveStep(MultistepFormSteps.ConfirmReferralStep);
            } else {
              handleSkip();
            }
          }}
        />
      )}

      {activeStep === MultistepFormSteps.MfaSetup && !allowTotp?.enabled && (
        <SetupSmsMfaForm
          title="Secure your account"
          onProceed={() => {
            if (hasReferralCode) {
              setActiveStep(MultistepFormSteps.ConfirmReferralStep);
            } else {
              onLoginComplete();
            }
          }}
          onSkip={() => {
            if (hasReferralCode) {
              setActiveStep(MultistepFormSteps.ConfirmReferralStep);
            } else {
              handleSkip();
            }
          }}
          switchingToExistingSms={false}
        />
      )}

      {activeStep === MultistepFormSteps.ConfirmReferralStep && (
        <ConfirmReferralStep
          referralCode={params.get('referralCode')!}
          onProceed={({ returnPath }: returnData) => {
            onLoginComplete(returnPath);
          }}
        />
      )}
    </Wrapper>
  );
}
