import { getBuyEstimate } from 'components/Dialogs/Trading/helpers';
import { KeyValueRow } from 'components/KeyValueRow/KeyValueRow';
import { FontWeight, TextNormal } from 'components/design-system/Text/Text';
import * as format from 'formatting';
import { Instrument } from 'generated/graphql';
import * as tooltips from 'strings/tooltips';
import {
  ChargesSection,
  SubSectionWrapper,
} from './_shared/ImportantInformation.styles';
import { BuyCharges } from './_shared/ImportantInformation.types';

export interface LegacyCostsAndChargesSectionProps {
  trackPopoverClick: (popover: string) => void;
  charges: BuyCharges;
  amountEntered: number;
  selectedInstrument: Pick<
    Instrument,
    | 'askPrice'
    | 'minimumTradeUnit'
    | 'quoteUnit'
    | 'ongoingChargePercent'
    | 'instrumentType'
  >;
}

export const LegacyCostsAndChargesSection = ({
  trackPopoverClick,
  charges,
  amountEntered,
  selectedInstrument,
}: LegacyCostsAndChargesSectionProps) => {
  const { EstimatedFundFee, EstimatedTillitFee, EstimatedTotalFee } = tooltips;

  // Units and Amounts
  const unitsPurchased =
    (selectedInstrument &&
      charges &&
      amountEntered &&
      getBuyEstimate(selectedInstrument, amountEntered, charges)) ||
    0;

  // Fees and Price
  const askPrice = selectedInstrument?.askPrice ?? 0;
  const totalCost = unitsPurchased ? askPrice * unitsPurchased : null;
  const fundFees =
    unitsPurchased && totalCost
      ? totalCost * ((selectedInstrument?.ongoingChargePercent ?? 0) / 100)
      : null;
  const tillitFee = totalCost
    ? totalCost * (charges?.tillitFeeProportion ?? 0.004)
    : null;
  const totalFees = fundFees && tillitFee ? fundFees + tillitFee : null;

  return (
    <>
      <SubSectionWrapper>
        <TextNormal $noMargin $fontWeight={FontWeight.medium}>
          One-offs
        </TextNormal>
      </SubSectionWrapper>
      <ChargesSection>
        <KeyValueRow
          label={'Commission'}
          description={tooltips.ZeroCommission}
          onPopoverClick={() => trackPopoverClick('No commission')}
          value={'£0'}
          noMargin
          stackMobile
        />
        {charges?.stampDutyApplicable && charges?.stampDutyProportion && (
          <KeyValueRow
            label={`Stamp duty (${format.percent(
              charges.stampDutyProportion!
            )})`}
            description={tooltips.StampDuty(charges.stampDutyProportion)}
            onPopoverClick={() => trackPopoverClick('Stamp duty')}
            value={format.currencyFull(
              (amountEntered || 0) * charges?.stampDutyProportion!
            )}
            noMargin
            stackMobile
          />
        )}
        {charges?.ptmLevyApplicable &&
          amountEntered > charges.ptmLevyApplicableOrderValueThreshold && (
            <KeyValueRow
              label={'PTM levy (£)'}
              description={tooltips.PtmLevy(
                charges.ptmLevyAmountWhereApplicable,
                charges.ptmLevyApplicableOrderValueThreshold
              )}
              onPopoverClick={() => trackPopoverClick('PTM levy')}
              value={format.currencyFull(charges.ptmLevyAmountWhereApplicable)}
              noMargin
              stackMobile
            />
          )}
      </ChargesSection>
      <SubSectionWrapper>
        <TextNormal
          $noMargin
          $fontWeight={FontWeight.medium}
          style={{ marginTop: '1rem' }}
        >
          Ongoing
        </TextNormal>
      </SubSectionWrapper>
      <ChargesSection>
        <KeyValueRow
          label={`${selectedInstrument.instrumentType} OCF (${format.percent(
            (selectedInstrument?.ongoingChargePercent ?? 0) / 100
          )} pa)`}
          description={<EstimatedFundFee />}
          onPopoverClick={() => trackPopoverClick('Estimated fund fee')}
          value={`${format.currencyFull(fundFees!)} per annum`}
          noMargin
          stackMobile
        />
        <KeyValueRow
          label={`TILLIT platform fee (${format.percent(
            charges?.tillitFeeProportion ?? 0.004
          )} pa)`}
          description={<EstimatedTillitFee />}
          onPopoverClick={() => trackPopoverClick('Estimated tillit fee')}
          value={`${format.currencyFull(tillitFee!)} per annum`}
          noMargin
          stackMobile
        />
        <KeyValueRow
          label={'Total est. ongoing cost (£)'}
          description={<EstimatedTotalFee />}
          onPopoverClick={() => trackPopoverClick('Estimated total fee')}
          value={`${format.currencyFull(totalFees!)} per annum`}
          noMargin
          stackMobile
        />
      </ChargesSection>
    </>
  );
};
