import styled from 'styled-components';

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BreakdownLayout = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: column;
    gap: 0;
  }
`;

export const BreakdownToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0 auto;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    justify-content: flex-end;
  }
`;

export const DisplayOptionsWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-content: space-between;
  grid-template-columns: 1fr;
  grid-template-areas: 'display';
  justify-items: center;
  margin: 1rem auto 0;
`;
