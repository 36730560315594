import { useDarkUniverseTheme } from 'hooks/useDarkUniverseTheme';
import React from 'react';
import { ThemeProvider } from 'styled-components';

interface DarkThemeWrapperProps {
  children: React.ReactNode;
}
export const DarkThemeWrapper = ({ children }: DarkThemeWrapperProps) => {
  const theme = useDarkUniverseTheme();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
