import { Text } from 'components/design-system/Text/Text';
import * as format from 'formatting';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { PoweredBy } from '../PoweredBy';
import {
  DisclosureInner,
  DisclosureSliceContainer,
} from '../Styles/Disclosure.style';

export interface DisclosureSliceProps {
  asset: AssetQueryAsset;
  selectedIsin: string;
}

export function DisclosureSlice({ asset, selectedIsin }: DisclosureSliceProps) {
  const currentInstrument = asset?.instruments?.nodes?.find(
    (i) => i.isin === selectedIsin
  );
  const instrumentHasFeDate =
    currentInstrument?.valuationSummary?.lastUpdatedUtc ?? false;

  return (
    <DisclosureSliceContainer>
      <DisclosureInner maxWidth={'lg'}>
        {instrumentHasFeDate && (
          <div>
            <Text>
              All information should be used for indicative purposes only. Data
              provided by FE fundinfo, retrieved on{' '}
              {format.date(currentInstrument?.valuationSummary?.lastUpdatedUtc)}
              . Ongoing Charge Figures (OCF) provided by Seccl Custody Limited.
              Performance figures are calculated on a bid price to bid price
              basis (mid to mid for OEICs), include fund charges and are updated
              to the most recent price date available unless stated otherwise.
              TILLIT cannot guarantee that the data is accurate or complete.
            </Text>
            <Text>
              Past performance is not a reliable indicator of future results.
              The value of an investment, and the income from it, can fall as
              well as rise. You could get back less than originally invested.
            </Text>
          </div>
        )}
        <PoweredBy />
      </DisclosureInner>
    </DisclosureSliceContainer>
  );
}
