import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepIntroductionTypography,
  StepTitle,
  StepWrapper,
} from 'components/design-system/StepComponents/StepComponents';
import { appSignInPath, openAccountPath } from 'paths';

export function CheckoutDialog({ onClose, ...props }: ResponsiveDialogProps) {
  return (
    <ResponsiveDialog {...props} onClose={onClose}>
      <StepWrapper>
        <StepContainer>
          <StepContent>
            <StepTitle>Checkout</StepTitle>
            <StepIntroductionTypography $noMargin>
              To checkout, you need to open an account to start investing or
              sign in.
            </StepIntroductionTypography>
          </StepContent>
          <StepActions $isHorizontal>
            <LinkCustomButton to={openAccountPath} $color="primary" fullWidth>
              Start investing
            </LinkCustomButton>
            <LinkCustomButton to={appSignInPath} $color="secondary" fullWidth>
              Sign in
            </LinkCustomButton>
          </StepActions>
        </StepContainer>
      </StepWrapper>
    </ResponsiveDialog>
  );
}
