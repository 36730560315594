import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/ResponsiveDialog';
import { WrapperType } from 'generated/graphql';
import React from 'react';
import { OnSubmittedCallback } from './Create';
import { Transfer } from './Transfer';

export interface TransferDialogProps extends ResponsiveDialogProps {
  wrapperType: WrapperType;
  onSubmitted?: OnSubmittedCallback;
  skipSuccessStep?: boolean;
}

export function TransferDialog({
  wrapperType,
  onClose,
  onSubmitted,
  skipSuccessStep = false,
  ...props
}: TransferDialogProps) {
  return (
    <ResponsiveDialog {...props} onClose={onClose}>
      <Transfer
        isDialog={true}
        wrapperType={wrapperType}
        onSubmitted={onSubmitted}
        skipSuccessStep={skipSuccessStep}
        onClose={() => onClose?.()}
      />
    </ResponsiveDialog>
  );
}
