import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

export interface FundDetailsProps {
  $layout: 'contained' | 'outlined';
  $bottomMargin?: boolean;
}

export const FundDetailsContainer = styled.div<FundDetailsProps>`
  position: relative;
  width: 100%;
  display: flex;

  ${(p) =>
    p.$layout === 'contained' &&
    css`
      flex-direction: column;
      flex-wrap: nowrap;
      padding: 0.5rem 1rem;
      border-radius: 10px;
      background-color: ${colors['grey-50']};
    `}

  ${(p) =>
    p.$layout === 'outlined' &&
    css`
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      padding: ${(p) => p.theme.spacing(0.625, 0, 2.25, 0)};
      background-color: transparent;
      border-bottom: 1px solid ${colors['grey-400']};
      margin-bottom: ${p.$bottomMargin ? '30px' : 0};
      > * {
        width: 50%;
      }
    `}
  ${(p) => p.theme.breakpoints.up('sm')} {
    margin-bottom: 0;
  } ;
`;

export const Label = styled(Typography)<FundDetailsProps>`
  font-size: ${(p) => (p.$layout === 'contained' ? '0.875rem' : '1.125rem')};
  font-weight: ${(p) => (p.$layout === 'contained' ? '300' : '500')};
`;

export const Value = styled(Typography)<FundDetailsProps>`
  font-size: ${(p) => (p.$layout === 'contained' ? '1rem' : '1.125rem')};
  font-weight: ${(p) => (p.$layout === 'contained' ? '400' : '300')};
`;
