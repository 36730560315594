import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';
// import { Section } from '@material-ui/core';

export const DetailsInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  ${(p) => p.theme.breakpoints.up('md')} {
    display: block;
    columns: 2;
    column-gap: ${(p) => p.theme.spacing(5)}px;
  }

  > div {
    flex-basis: 100%;
    margin-bottom: ${(p) => p.theme.spacing(2.5)}px;
    break-inside: avoid;

    ${(p) => p.theme.breakpoints.up('sm')} {
      margin-bottom: ${(p) => p.theme.spacing(5)}px;
    }
  }
`;

export const DetailsTitleSection = styled.div`
  padding: 0;
  margin-bottom: ${(p) => p.theme.spacing(2.5)}px;
  width: 100%;
`;

export const DetailsDataSection = styled.div`
  padding: 0;
  width: 100%;
`;

export const Title = styled(Typography)`
  color: ${colors.white};
  margin-bottom: ${(p) => p.theme.spacing(5)}px;
`;

export const SingleAction = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  margin-top: ${(p) => p.theme.spacing(2.5)}px;

  > * {
    flex-basis: 100%;
  }
`;
