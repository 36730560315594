import { VideoJsPlayer, VideoJsPlayerOptions } from '@tillitinvest/video.js';
import passiveIcon from 'assets/icons/Passive.svg';
import meetTheManagerPlaceholderLarge from 'assets/meet-the-manager_large.jpg';
import meetTheManagerPlaceholderMedium from 'assets/meet-the-manager_medium.jpg';
import meetTheManagerPlaceholderSmall from 'assets/meet-the-manager_small.jpg';
import { VideoPlayer } from 'components/VideoPlayer/VideoPlayer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SearchAssetsQueryAsset } from 'types/graphqlTypes';
import {
  ActiveFallbackImage,
  MuteIcon,
  PassiveImage,
  PassiveImageContainer,
  ThumbnailImage,
  VideoWrapper,
  VolumeButton,
  VolumeIcon,
} from './FundGridCard.style';

import { FundsGridCardOptions } from '../hooks/useFundListOptions';

/**
 * Fetches the optimised poster image based on the original URL.
 *
 * @param {string} originalUrl - The original URL of the poster image.
 * @return {string} The URL of the optimised poster image.
 */
const optimisedPosterImageSrcSet = (originalUrl: string) => {
  const regex = new RegExp(`/(?!.*/).*`);
  return `${originalUrl.replace(
    regex,
    '/thumbnail_small.webp'
  )} 288w, ${originalUrl.replace(regex, '/thumbnail_medium.webp')} 400w`;
};

export interface FundCardVideoProps {
  asset: SearchAssetsQueryAsset;
  options: FundsGridCardOptions;
  setOptions: (options: FundsGridCardOptions) => void;
  isExpanded: boolean;
  videoEntryIsIntersecting?: boolean;
  canHover: boolean;
  isSmUp: boolean;
  videoInViewIsIntersecting?: boolean;
  isHover: boolean;
}

export function FundGridCardVideo({
  asset,
  options,
  setOptions,
  isExpanded,
  videoEntryIsIntersecting,
  canHover,
  isSmUp,
  videoInViewIsIntersecting,
  isHover,
}: FundCardVideoProps) {
  const [play, setPlay] = useState<Promise<void> | undefined>(undefined);
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef<HTMLDivElement>(null);

  const playerRef = useRef<VideoJsPlayer>();

  const isActivelyManaged = asset.isActivelyManaged;

  const handleVideoPlay = useCallback(() => {
    const hasErrors = playerRef.current?.error();
    if (playerRef.current && !hasErrors) {
      playerRef.current.muted(options.isMuted);
      const textTracks = playerRef.current.textTracks();
      for (var i = 0; i < textTracks.length; i++) {
        const textTrack = textTracks[i];
        if (textTrack.language === 'en') {
          textTrack.mode = 'hidden';
        }
      }

      if (playerRef.current.currentTime() < 19) {
        playerRef.current.currentTime(19);
      }

      if (!isPlaying && play === undefined) {
        const playPromise = playerRef.current.play();
        setPlay(playPromise);
        playPromise?.then(() => {
          setIsPlaying(true);

          // We only need to capture the play promise to let us correctly stop trying to play
          // if you quickly hover on and off the video (where the 'pause' intent is happening
          // before the video play promise has resolved). After that it doesn't matter, and
          // keeping it around means we can't restart the video.
          setPlay(undefined);
        });
      }
    }
  }, [isPlaying, play, options.isMuted]);

  const handleVideoPause = useCallback(() => {
    const hasErrors = playerRef.current?.error();

    if (!hasErrors) {
      const stopPlaying = () => {
        if (playerRef.current && isPlaying) {
          setIsPlaying(false);
          playerRef.current.pause();
          playerRef.current.hasStarted(false); // This might not be desirable but I think on mouse leave we will need to show the poster image again
        }
      };

      // This seems to be pretty edge-casey, because we should be burning the
      // play promise immediately when playback actually starts, but here we are...
      if (play) {
        play.then(stopPlaying);
      } else {
        stopPlaying();
      }
    }
  }, [isPlaying, play]);

  useEffect(() => {
    if (!canHover && !isSmUp) {
      !!videoInViewIsIntersecting && !isExpanded
        ? handleVideoPlay()
        : handleVideoPause();
    }
  }, [
    canHover,
    handleVideoPause,
    handleVideoPlay,
    isExpanded,
    isSmUp,
    videoInViewIsIntersecting,
  ]);

  useEffect(() => {
    if (!canHover) {
      return;
    }

    if (isHover && !isExpanded) {
      handleVideoPlay();
    } else {
      handleVideoPause();
    }
  }, [isHover, canHover, isExpanded, handleVideoPlay, handleVideoPause]);

  const introVideo = asset?.introVideo;
  const videoOptions: VideoJsPlayerOptions | undefined = introVideo
    ? {
        autoplay: false,
        controls: false,
        responsive: true,
        fluid: true,
        html5: {
          nativeTextTracks: false,
        },
        sources: [
          {
            src: introVideo?.videoUrl!,
            type: 'application/x-mpegURL',
          },
        ],
        tracks: [
          {
            src: introVideo.subtitlesUrl!,
            kind: 'subtitles',
            srclang: 'en',
            label: 'English',
          },
        ],
      }
    : undefined;

  return (
    <>
      {introVideo && videoOptions ? (
        <VideoWrapper ref={videoRef}>
          {isPlaying && (
            <>
              <VolumeButton
                onClick={() => {
                  if (playerRef.current) {
                    playerRef.current.muted(!options.isMuted);
                    setOptions({
                      ...options,
                      isMuted: !options.isMuted,
                    });
                  }
                }}
              >
                {options.isMuted ? <MuteIcon /> : <VolumeIcon />}
              </VolumeButton>
            </>
          )}
          <ThumbnailImage
            srcSet={optimisedPosterImageSrcSet(introVideo.thumbnailUrl!)}
            src={introVideo?.thumbnailUrl!}
            alt={asset.description!}
            className={isHover ? 'playing' : ''}
            loading="lazy"
          />
          {!!videoEntryIsIntersecting && (
            <VideoPlayer
              videoJsOptions={videoOptions}
              shouldShowEndCta={false}
              video={asset.description!}
              videoType="Fund Details - Intro"
              nearlyCompleteCtaLink="#meet-the-manager"
              nearlyCompleteCtaText="Watch the full interview"
              roundedBorders={false}
              setPlayerRef={(player: VideoJsPlayer) => {
                playerRef.current = player;
              }}
            />
          )}
        </VideoWrapper>
      ) : isActivelyManaged ? (
        <ActiveFallbackImage
          src={meetTheManagerPlaceholderLarge}
          srcSet={`${meetTheManagerPlaceholderSmall} 400w, ${meetTheManagerPlaceholderMedium} 920w, ${meetTheManagerPlaceholderLarge} 1440w`}
          alt={asset.name}
          loading="lazy"
        />
      ) : (
        <PassiveImageContainer>
          <PassiveImage loading="lazy" src={passiveIcon} alt={asset.name} />
        </PassiveImageContainer>
      )}
    </>
  );
}
