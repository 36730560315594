import { Popover } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import {
  FontWeight,
  Text,
  TextSmall,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { currencyFull, percent } from 'formatting';
import numeral from 'numeral';
import React, { useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import styled from 'styled-components';

export interface Breakdown {
  name: string;
  percentage: number;
  amount: number;
  percentageChange?: number;
}
export type Breakdowns = Breakdown[];

interface BreakdownItemColor {
  name: string;
  color: string;
}
export type BreakdownItemColors = BreakdownItemColor[];

interface StackedBarChartProps {
  breakdowns: Breakdowns;
  breakdownItemColors: BreakdownItemColors;
  onClick?: (breakdown: Breakdown) => void;
  disabled?: boolean;
  faded?: boolean;
  isHovered?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  activeBreakdown?: string | null;
}

const PercentageIncrease = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  color: ${colors.success};
  ${Text} {
    color: ${colors.success};
  }
`;

const PercentageDecrease = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  color: ${colors.danger};
  ${Text} {
    color: ${colors.danger};
  }
`;

interface BarChartWrapperProps {
  disabled?: boolean;
  faded?: boolean;
}

export const StackedBar = styled.button`
  color: white;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: 'hidden';
  border: none;
  transition: width 0.5s, opacity 0.25s ease-in-out, filter 0.25s ease-in-out;
`;

export const BarChartWrapper = styled.div<BarChartWrapperProps>`
  display: flex;
  height: 2rem;
  width: 100%;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  filter: ${({ faded }) => (faded ? 'blur(4px)' : 'none')};
`;

export const StackedBarChart = ({
  breakdowns,
  breakdownItemColors,
  disabled,
  faded,
  onClick,
  isHovered,
  onMouseEnter,
  onMouseLeave,
  activeBreakdown,
}: StackedBarChartProps) => {
  const [hoveredBreakdown, setHoveredBreakdown] = useState<string | null>(null);

  const onMouseEnterBar = (breakdown: Breakdown) => {
    setHoveredBreakdown(breakdown.name);
  };

  const onMouseLeaveBar = (_: Breakdown) => {
    setHoveredBreakdown(null);
  };

  return (
    <BarChartWrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      faded={faded}
    >
      {breakdowns
        .filter((breakdown) => breakdown.percentage > 0)
        .map((breakdown) => {
          return (
            <Popover
              key={breakdown.name}
              popupId="asset-allocation-chart-popover"
              openOn="hover"
              content={
                <>
                  <TextSmall $fontWeight={FontWeight.medium} $noMargin>
                    {breakdown.name}
                  </TextSmall>
                  <TextSmall $noMargin>
                    {percent(breakdown.percentage / 100)} |{' '}
                    {currencyFull(breakdown.amount)}
                  </TextSmall>
                  {isGreaterThan(breakdown.percentageChange, 0) && (
                    <PercentageIncrease>
                      <AiOutlineArrowUp />{' '}
                      <TextSmall $noMargin>
                        {percent(breakdown.percentageChange! / 100)}
                      </TextSmall>
                    </PercentageIncrease>
                  )}
                  {isLessThan(breakdown.percentageChange, 0) && (
                    <PercentageDecrease>
                      <AiOutlineArrowDown />{' '}
                      <TextSmall $noMargin>
                        {percent(breakdown.percentageChange! / 100)}
                      </TextSmall>
                    </PercentageDecrease>
                  )}
                </>
              }
            >
              {(props: any) => {
                const getBackground = () => {
                  const color =
                    breakdownItemColors.find(
                      (breakdownItemColor) =>
                        breakdownItemColor.name === breakdown.name
                    )?.color || 'hotpink';

                  const backgroundProps: Record<string, string> = {
                    backgroundColor: color,
                  };

                  if (breakdown.name === 'Cash') {
                    backgroundProps.background = `linear-gradient(135deg, ${colors.richBlack} 25%, transparent 25%) -4px 0/ 8px 8px, linear-gradient(225deg, #d8d8d8 25%, transparent 25%) -4px 0/ 8px 8px, linear-gradient(315deg, ${colors.richBlack} 25%, transparent 25%) 0px 0/ 8px 8px, linear-gradient(45deg, #d8d8d8 25%, #e8e8e8 25%) 0px 0/ 8px 8px`;
                  }

                  return backgroundProps;
                };

                const style: Record<string, string> = {
                  ...getBackground(),
                  width: `${breakdown.percentage}%`,
                  cursor: !!onClick ? 'pointer' : 'default',
                };

                const currentBarHovered = hoveredBreakdown === breakdown.name;
                const currentBarActive = activeBreakdown === breakdown.name;
                const anotherChartIsActive = isHovered && !hoveredBreakdown;

                if (
                  (isHovered && !currentBarActive && !currentBarHovered) ||
                  (anotherChartIsActive && !currentBarActive) ||
                  (activeBreakdown && !currentBarActive && !currentBarHovered)
                ) {
                  style.filter = 'grayscale(1)';
                  style.opacity = '0.3';
                }
                return (
                  <StackedBar
                    style={style}
                    {...props}
                    onClick={() => !disabled && !!onClick && onClick(breakdown)}
                    onMouseOver={(e) => {
                      props.onMouseOver(e);
                      onMouseEnterBar(breakdown);
                    }}
                    onMouseLeave={(e) => {
                      props.onMouseLeave(e);
                      onMouseLeaveBar(breakdown);
                    }}
                  >
                    {/* {`${assetBreakdown.assetClass} ${Math.round(
                      assetBreakdown.percentage
                    )}%`} */}
                  </StackedBar>
                );
              }}
            </Popover>
          );
        })}
    </BarChartWrapper>
  );
};

const tolerance = 0.01;
function isLessThan(a: number | undefined, b: number | undefined) {
  if (a === undefined || b === undefined) {
    return false;
  }
  return numeral(a).subtract(b).value() < -tolerance;
}

function isGreaterThan(a: number | undefined, b: number | undefined) {
  if (a === undefined || b === undefined) {
    return false;
  }
  return numeral(a).subtract(b).value() > tolerance;
}

/**
 * AssetAllocationChartKeyItemContainer component
 *
 * flex horizontal aligned center
 */
const StackedBarChartKeyItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

interface StackedBarChartKeyItemProps {
  name: string;
  color: string;
}

const StackedBarChartKeyItem = ({
  name,
  color,
}: StackedBarChartKeyItemProps) => {
  let background: string = color;
  if (name === 'Cash') {
    background = `linear-gradient(135deg, ${colors.richBlack} 25%, transparent 25%) -4px 0/ 8px 8px, linear-gradient(225deg, #d8d8d8 25%, transparent 25%) -4px 0/ 8px 8px, linear-gradient(315deg, ${colors.richBlack} 25%, transparent 25%) 0px 0/ 8px 8px, linear-gradient(45deg, #d8d8d8 25%, #e8e8e8 25%) 0px 0/ 8px 8px`;
  }

  return (
    <StackedBarChartKeyItemContainer>
      <span
        style={{
          display: 'inline-block',
          height: '0.75rem',
          width: '0.75rem',
          backgroundColor: color,
          background,
        }}
      ></span>
      <TextSmall $noMargin>{name}</TextSmall>
    </StackedBarChartKeyItemContainer>
  );
};

interface StackedBarChartKeyContainerProps {
  $variant: 'full' | 'compact';
}

/**
 * StackedBarChartKeyContainer component
 *
 * flex horizontal aligned center
 */
const StackedBarChartKeyContainer = styled.div<StackedBarChartKeyContainerProps>`
  ${({ $variant }) =>
    $variant === 'compact'
      ? `display: grid; grid-template-columns: auto auto;`
      : 'display: flex; justify-content: center;  padding: 0 4rem; flex-wrap: wrap;'};
  align-items: center;
  margin-top: 1rem;
  gap: 0.25rem 2rem;
`;

interface StackedBarChartKeyProps {
  breakdownItemColors: BreakdownItemColors;
  variant?: 'full' | 'compact';
}

export const StackedBarChartKey = ({
  breakdownItemColors,
  variant = 'full',
}: StackedBarChartKeyProps) => {
  return (
    <StackedBarChartKeyContainer $variant={variant}>
      {breakdownItemColors.map((breakdownItemColor) => {
        return (
          <StackedBarChartKeyItem
            key={breakdownItemColor.name}
            name={breakdownItemColor.name}
            color={breakdownItemColor.color}
          />
        );
      })}
    </StackedBarChartKeyContainer>
  );
};
