import { useTheme } from '@material-ui/core';
import { BuyButton } from 'components/Button/BuyButton';
import { SellButton } from 'components/Button/SellButton';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { BuyDialog } from 'components/feature/mode/BuyDialog/BuyDialog';
import { GaEventNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import { trackGa } from 'helpers/track';
import { useDialog } from 'hooks/useDialog';
import { useIsAssetAvailableToBuyById } from 'hooks/useIsAssetAvailableToBuy';
import { AnyAsset } from 'types/graphqlTypes';

export interface FundActionsProps {
  asset: AnyAsset;
  isin: string;
  userHolds: boolean;
  handleInstrumentChange: (isin: string) => void;
}

export function FundActions({
  asset,
  isin,
  userHolds,
  handleInstrumentChange,
}: FundActionsProps) {
  const theme = useTheme();
  const isDarkUniverse = theme.darkUniverse;
  const { signedIn } = useAuth();
  const isAssetAvailableToBuy = useIsAssetAvailableToBuyById(
    asset.id!.toString()
  );
  const dialogProps = useDialog();
  const { open: isBuyOpen, openDialog } = dialogProps;

  const handleBuyClick = () => {
    const tags =
      asset && asset.tags?.nodes
        ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
        : [];
    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'buy', // Buy, sell, deposit etc
      ecommerce: {
        items: [
          {
            item_id: asset.id,
            item_name: asset.name,
            affiliation: 'Tillit',
            currency: 'GBP',
            item_brand: asset.assetManager?.name,
            item_category: tags[0],
            item_category2: tags[1],
            item_category3: tags[2],
            item_category4: tags[3],
            item_category5: tags[4],
          },
        ],
      },
    });
    openDialog();
  };

  const trackSell = () => {
    const tags =
      asset && asset.tags?.nodes
        ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
        : [];

    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'sell', // Buy, sell, deposit etc
      ecommerce: {
        items: [
          {
            item_id: asset.id,
            item_name: asset.name,
            affiliation: 'Tillit',
            currency: 'GBP',
            item_brand: asset.assetManager?.name,
            item_category: tags[0],
            item_category2: tags[1],
            item_category3: tags[2],
            item_category4: tags[3],
            item_category5: tags[4],
          },
        ],
      },
    });
  };

  if (!isAssetAvailableToBuy) {
    return null;
  }

  if (!isin) {
    return null;
  }

  if (!signedIn) {
    return <BuyButton assetId={asset.id!.toString()} selectedIsin={isin} />;
  }

  return (
    <>
      {isBuyOpen && (
        <BuyDialog
          {...dialogProps}
          assetId={asset.id}
          selectedIsin={isin}
          handleInstrumentChange={handleInstrumentChange}
        />
      )}

      {userHolds && (
        <SellButton
          asset={asset}
          canSell={true}
          isin={isin}
          $color={`${isDarkUniverse ? 'white' : 'secondary'}`}
          variant={`${isDarkUniverse ? 'outlined' : 'contained'}`}
          onClick={trackSell}
        />
      )}

      <CustomButtonV2 $color="primary" onClick={handleBuyClick} $size="normal">
        {userHolds ? 'Buy More' : 'Invest'}
      </CustomButtonV2>
    </>
  );
}
