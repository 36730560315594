import { Container } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const TargetDateBannerContainer = styled.div`
  width: 100%;
  background-color: ${colors.mauve};
`;

export const TargetDateBannerInner = styled(Container)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 0;
`;

export const TargetDateBannerContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.875rem;
  color: ${colors.white};
  gap: 0.5rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 3rem;
    justify-content: space-between;
    align-items: center;
  }
`;

export const CloseForm = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  color: ${colors.white};
  opacity: 0.5;
  font-size: 1.5rem;
  border: none;
  background: none;
  transition: opacity 0.25s ease-in-out;
  outline: none;

  &:hover,
  &:focus {
    opacity: 1;
    outline: none;
  }
`;

export const EditDetailsButton = styled.button`
  position: relative;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${colors.white};
  padding: 0;
  border: none;
  background: none;
  transition: opacity 0.25s ease-in-out;
  outline: none;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    opacity: 0.25;
    background-color: ${colors.white};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 1px;
    opacity: 0.85;
    transform: rotateY(90deg);
    background-color: ${colors.white};
    transition: transform 0.25s ease-in-out;
  }

  &:hover,
  &:focus {
    outline: none;

    &:after {
      transform: rotateY(0deg);
    }
  }
`;
