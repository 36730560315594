import { NationalInsuranceNumberStep as OpenAccountNationalInsuranceNumberStep } from 'components/feature/openAccount/steps/NationalInsuranceNumberStep';
import { useHistory } from 'react-router-dom';
import { UserProfileQueryUserProfile } from 'types/graphqlTypes';

export interface NationalInsuranceNumberStepProps {
  onGoBack: () => void;
  userProfile: Pick<
    UserProfileQueryUserProfile,
    'nationalInsuranceNumberTokenised'
  >;
}

export function NationalInsuranceNumberStep({
  onGoBack,
  userProfile,
}: NationalInsuranceNumberStepProps) {
  const history = useHistory();

  return (
    <OpenAccountNationalInsuranceNumberStep
      onProceed={() => history.push('/isaDeclaration')}
      onGoBack={() => {
        onGoBack();
      }}
      source="secondAccountStep"
      userProfile={userProfile}
    />
  );
}
