import clsx from 'clsx';
import React from 'react';
import { Label, PillContainer, Value } from './DataPill.style';

export interface DataProp {
  value: string;
  label: string | JSX.Element;
}

export interface DataPillProps {
  content: Array<DataProp>;
  dark?: boolean;
  size?: 'normal' | 'small';
}

export function DataPill({
  content,
  dark = false,
  size = 'normal',
}: DataPillProps) {
  return (
    <PillContainer className={clsx(size, content.length <= 1 && 'single')}>
      {content?.map((item, i) => (
        <li key={i}>
          <Value className={clsx(size, dark && 'dark')}>{item.value}</Value>
          <br />
          <Label className={clsx(size, dark && 'dark')}>{item.label}</Label>
        </li>
      ))}
    </PillContainer>
  );
}
