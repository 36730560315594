import { Widget } from '@typeform/embed-react';
import { GaEventNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import { trackGa } from 'helpers/track';
import {
  complaintsPolicy,
  dashboardTransfersPath,
  feedbackForm,
  hexagonPagePath,
  instagramPath,
  linkedInPath,
  privacyPolicy,
  termsAndConditions,
  tillitAboutUsPath,
  tillitHomepage,
  tillitUniversePath,
  transfersWebPage,
  twitterPath,
  youTubePath,
} from 'paths';
import React from 'react';
import {
  AiFillLinkedin,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from 'react-icons/ai';
import { FaYoutubeSquare } from 'react-icons/fa';
import { Logo } from '../TillitLogo/Logo';
import {
  ContentContainer,
  CopyBlock,
  CopyRightsContainer,
  EmailSignup,
  LogoContainer,
  Menu1,
  Menu2,
  PageFooter,
  SocialMenu,
} from './Footer.style';

export interface FooterProps {
  maxWidth?: 'sm' | 'md' | 'lg' | 'xl';
}

export interface MenusProps {
  url: string;
  label: string;
  icon?: JSX.Element;
  className?: string;
  target?: '_blank' | '_self';
  outbound?: boolean;
}

export interface PureFooterProps extends FooterProps {
  menu1: Array<MenusProps>;
  menu2: Array<MenusProps>;
  socialMenu: Array<MenusProps>;
  onClick: (label: string, url: string, outbound?: boolean) => void;
}

export function PureFooter({
  maxWidth,
  menu1,
  menu2,
  socialMenu,
  onClick,
}: PureFooterProps) {
  return (
    <PageFooter>
      <ContentContainer maxWidth={maxWidth}>
        <LogoContainer>
          <Logo color="#ffffff" size={1.5} pad={false} />
        </LogoContainer>
        <Menu1>
          {menu1 &&
            menu1.map((item) => (
              <li key={item.url} className={item.className}>
                <a
                  href={item.url}
                  target={item.target ? item.target : '_blank'}
                  onClick={() => onClick(item.label, item.url, item.outbound)}
                  rel="noreferrer"
                >
                  {item.label}
                </a>
              </li>
            ))}
        </Menu1>
        <Menu2>
          {menu2 &&
            menu2.map((item) => (
              <li key={item.url} className={item.className}>
                <a
                  href={item.url}
                  target={item.target ? item.target : '_blank'}
                  onClick={() => onClick(item.label, item.url, item.outbound)}
                  rel="noreferrer"
                >
                  {item.label}
                </a>
              </li>
            ))}
        </Menu2>
        <EmailSignup>
          <Widget
            id="H0hj0ggH"
            style={{ flexBasis: '100%' }}
            className="my-form"
            inlineOnMobile
          />
        </EmailSignup>
        <SocialMenu>
          {socialMenu &&
            socialMenu.map((item) => (
              <li key={item.url} className={item.className}>
                <a
                  href={item.url}
                  target={item.target ? item.target : '_blank'}
                  onClick={() => onClick(item.label, item.url, item.outbound)}
                  rel="noreferrer"
                >
                  {item.icon && item.icon} {item.label}
                </a>
              </li>
            ))}
        </SocialMenu>
      </ContentContainer>
      <CopyRightsContainer maxWidth={maxWidth}>
        <CopyBlock>&copy; 2024 TILLIT Limited</CopyBlock>
        <CopyBlock>
          TILLIT is the trading name of TILLIT Limited and is registered in
          England & Wales with company number 12357713. Its registered office
          address is 42-46 Princelet Street, London, E1 5LP. TILLIT Limited is
          authorised and regulated by the Financial Conduct Authority (FRN
          983417).
        </CopyBlock>
        <CopyBlock>
          Our website offers information about investing and saving, but not
          personal advice. The value of investments can go up and down, so you
          could get back less than you put in. If you’re not sure which
          investments are right for you, please seek advice from a qualified
          financial adviser.
        </CopyBlock>
      </CopyRightsContainer>
    </PageFooter>
  );
}

export function Footer({ maxWidth = 'xl' }: FooterProps) {
  const { signedIn } = useAuth();
  const menu1: Array<MenusProps> = [
    {
      url: signedIn ? dashboardTransfersPath : transfersWebPage,
      label: signedIn ? 'Transfer to TILLIT' : 'Transfer Concierge',
      target: '_self',
    },
    {
      url: tillitAboutUsPath,
      label: 'About us',
      target: '_self',
    },
    {
      url: `${tillitHomepage}/fees`,
      label: 'Fees',
      target: '_self',
    },
    {
      url: hexagonPagePath,
      label: 'The Universe',
    },
    {
      url: tillitUniversePath,
      label: 'Investment Committee',
      target: '_self',
    },
    {
      url: `${tillitHomepage}/insights`,
      label: 'The insights',
      target: '_self',
    },
    {
      url: `${tillitHomepage}/jargon-flashcards`,
      label: 'Jargon flashcards',
      target: '_self',
    },
    {
      url: `${tillitHomepage}/faqs`,
      label: 'FAQs',
      target: '_self',
    },
    {
      url: `${tillitHomepage}/contact`,
      label: 'Contact us',
      target: '_self',
    },
  ];

  const menu2 = [
    {
      url: termsAndConditions,
      label: 'Terms and conditions',
    },
    {
      url: privacyPolicy,
      label: 'Privacy policy',
    },
    {
      url: complaintsPolicy,
      label: 'Complaints policy',
    },
    {
      url: feedbackForm,
      label: 'Give us feedback',
      className: 'highlighted',
    },
  ];

  const menuSocial = [
    {
      url: linkedInPath,
      label: 'LinkedIn',
      icon: <AiFillLinkedin className="icon" />,
      outbound: true,
    },
    {
      url: twitterPath,
      label: 'Twitter',
      icon: <AiOutlineTwitter className="icon" />,
      outbound: true,
    },
    {
      url: instagramPath,
      label: 'Instagram',
      icon: <AiOutlineInstagram className="icon" />,
      outbound: true,
    },
    {
      url: youTubePath,
      label: 'YouTube',
      icon: <FaYoutubeSquare className="icon" />,
      outbound: true,
    },
  ];

  const handleClick = (label: string, url: string, outbound: boolean) => {
    trackGa({
      event: GaEventNames.click,
      outbound: outbound ? 'true' : 'false',
      link_url: url,
      link_text: label,
    });
  };

  return (
    <PureFooter
      maxWidth={maxWidth}
      menu1={menu1}
      menu2={menu2}
      socialMenu={menuSocial}
      onClick={handleClick}
    />
  );
}
