import { datadogLogs } from '@datadog/browser-logs';
import { Alert } from '@material-ui/lab';
import { tillitFetch } from 'api/tillitFetch';
import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { TextField } from 'components/Form/TextField';
import { StyledLink } from 'components/design-system/Link';
import getCognitoUserContextData from 'helpers/cognitoUserContextData';
import { appResetPasswordPath, openAccountPath } from 'paths';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { useSignInTracking } from '../_shared/useSignInTracking';

const ForgotPasswordWrapper = styled.div`
  margin-top: 1rem;
`;

const emailPasswordSchema = Yup.object().shape({
  username: Yup.string().label('Email').required().min(3),
  password: Yup.string().label('Password').required().min(8),
});

export interface LoginApiResBody {
  requireMfa: boolean;
  requireEmailVerify: boolean;
  session: string;
  success: boolean;
  userId: string;
  userIdIntegrity: string;
  message: string;
  mfaMethod?: string;
}

export interface EmailPasswordFormValues
  extends Yup.Asserts<typeof emailPasswordSchema> {}

interface LoginApi {
  username: string;
  password: string;
  userContext: string | null;
}

const ErrorAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

export const useLogin = () => {
  return useMutation<any, Error, LoginApi>('/identity/login', (data) => {
    return tillitFetch('/identity/login', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
  });
};

interface EmailPasswordStepProps {
  onProceed: (
    values: EmailPasswordFormValues,
    loginAction: LoginApiResBody
  ) => void;
}

export function EmailPasswordStep({ onProceed }: EmailPasswordStepProps) {
  const validationSchema = emailPasswordSchema;
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const loc = useLocation();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const signedOut = loc.search.indexOf('signedOut') > -1;

  const title = signedOut ? 'Sign back in' : 'Sign in';

  const { mutateAsync } = useLogin();

  const trackSignIn = useSignInTracking();

  const onSubmit = async (data: EmailPasswordFormValues, isDirty: boolean) => {
    try {
      setIsLoggingIn(true);
      const userContext = getCognitoUserContextData(data.username);

      const loginResult = await mutateAsync({
        username: data.username,
        password: data.password,
        userContext,
      });

      if (loginResult.status === 200) {
        const loginResultBody = (await loginResult.json()) as LoginApiResBody;
        if (!loginResultBody.requireMfa && loginResultBody.userId) {
          await trackSignIn(loginResult.userId);
        }
        onProceed(data, loginResultBody);
      } else if (loginResult.status === 400) {
        setIsLoggingIn(false);
        datadogLogs.logger.error('/api/account/login - Failed');
        const loginResultBody = (await loginResult.json()) as LoginApiResBody;
        setErrorMessage(loginResultBody.message);
      } else {
        setIsLoggingIn(false);
        datadogLogs.logger.error('/api/account/login - Failed');
        setErrorMessage(
          'Something went wrong - please try again. If the error persists, please contact support'
        );
      }
    } catch (error) {
      setIsLoggingIn(false);
      datadogLogs.logger.error('/api/account/login - Failed');
      setErrorMessage(
        'Something went wrong - please try again. If the error persists, please contact support'
      );
    }
  };

  return (
    <Form<EmailPasswordFormValues>
      onSubmit={onSubmit}
      schema={validationSchema}
    >
      <StepContainer>
        <StepContent>
          <StepTitle>{title}</StepTitle>

          <StepIntroduction mb={2}>
            {signedOut ? (
              <StepIntroductionTypography>
                You were automatically signed out &mdash; but you can sign back
                in again below.
              </StepIntroductionTypography>
            ) : (
              <StepIntroductionTypography>
                Don't have an account yet?{' '}
                <StyledLink href={openAccountPath}>Start investing</StyledLink>
              </StepIntroductionTypography>
            )}
            <StepIntroductionTypography></StepIntroductionTypography>
          </StepIntroduction>

          {errorMessage && (
            <ErrorAlert variant="outlined" severity="error">
              {errorMessage}
            </ErrorAlert>
          )}

          <StepFormContainer>
            <TextField type="email" name="username" label="Email" fullWidth />
            <TextField
              type="password"
              name="password"
              label="Password"
              fullWidth
            />
          </StepFormContainer>
        </StepContent>
        <StepActions>
          <FormState>
            {({ isSubmitting }) => (
              <>
                <StepButton
                  disabled={isSubmitting || isLoggingIn}
                  type="submit"
                  className="magenta"
                >
                  Sign in
                </StepButton>
              </>
            )}
          </FormState>
        </StepActions>
        <ForgotPasswordWrapper>
          <StyledLink href={appResetPasswordPath}>
            Forgot your password?
          </StyledLink>
        </ForgotPasswordWrapper>
      </StepContainer>
    </Form>
  );
}
