import { LocationKeyedSwitch } from 'components/LocationKeyedSwitch';
import { PageContainer } from 'components/PageContainer';
import { AnimatePresence } from 'framer-motion';
import { WrapperType } from 'generated/graphql';
import React from 'react';
import { MemoryRouter, Route, useHistory } from 'react-router-dom';
import { Create, OnSubmittedCallback } from './Create';
import { ErrorStep } from './ErrorStep';
import { SuccessStep } from './SuccessStep';

export interface TransferProps {
  isDialog?: boolean;
  wrapperType: WrapperType;
  onSubmitted?: OnSubmittedCallback;
  skipSuccessStep: boolean;
  onClose?: () => void;
}

export function Transfer({
  isDialog = false,
  wrapperType,
  onSubmitted,
  skipSuccessStep,
  onClose,
}: TransferProps) {
  const history = useHistory();

  return (
    <PageContainer noBottomPadding={!isDialog}>
      <MemoryRouter initialEntries={['/create']}>
        <AnimatePresence>
          <LocationKeyedSwitch>
            <Route path="/create">
              <Create
                wrapperType={wrapperType}
                onSubmitted={onSubmitted}
                skipSuccessStep={skipSuccessStep}
                onClose={onClose}
              />
            </Route>
            <Route path="/success">
              <SuccessStep
                wrapperType={wrapperType}
                onClose={onClose}
                goToBrowseFunds={() => {
                  history.push('/funds');
                }}
                parentHistory={history}
              />
            </Route>
            <Route path="/error">
              <ErrorStep wrapperType={wrapperType} onClose={onClose} />
            </Route>
          </LocationKeyedSwitch>
        </AnimatePresence>
      </MemoryRouter>
    </PageContainer>
  );
}
