import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import { Card } from 'components/design-system/Card/Card';
import {
  H3,
  H5,
  TextAlign as HeadingAlign,
} from 'components/design-system/Heading/Heading';
import { Text, TextAlign } from 'components/design-system/Text/Text';
import { ResumePopover } from 'components/feature/autoSaveInvest/resumePopover/ResumePopover';
import { IntercomEventNames } from 'constants/intercomConstants';
import { PortfolioRebalancing, WrapperType } from 'generated/graphql';
import { HiCheck } from 'react-icons/hi';
import { useIntercom } from 'react-use-intercom';
import { PartialDeep } from 'type-fest';
import { useEffectOnce } from 'usehooks-ts';
import {
  StepActions,
  StepContent,
  StepContentWidth,
} from '../../../../design-system/StepComponents/StepComponents';
import { DpFlowTypes } from '../../flowType';
import { CheckoutFeedback } from './CheckoutFeedback/CheckoutFeedback';
import {
  CardStepContainer,
  CardStepDescription,
  CardStepTitle,
  CardStepWrapper,
  CheckIconWrapper,
} from './OrderReceived.styles';

interface OrderReceivedProps {
  selectedAccountType: WrapperType;
  selectedAccountId: string;
  activeFlow: DpFlowTypes;
  portfolioRebalancing: PartialDeep<PortfolioRebalancing>;
}

export function OrderReceived({
  selectedAccountType,
  selectedAccountId,
  activeFlow,
  portfolioRebalancing,
}: OrderReceivedProps) {
  const { trackEvent } = useIntercom();

  //const portfolioRebalancingId = portfolioRebalancing?.id;
  const isAssetAllocationAutoConfirmEnabled =
    portfolioRebalancing.isAssetAllocationAutoConfirmEnabled;

  useEffectOnce(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    trackEvent(IntercomEventNames.rebalancingOrderReceived);
  });

  const isBuysSellFlow = [
    DpFlowTypes.buysAndSells,
    DpFlowTypes.buysAndSellsExpectedSufficientCash,
    DpFlowTypes.buysAndSellsInsufficientCash,
  ].includes(activeFlow);

  return (
    <>
      <CardStepWrapper>
        <StepContent width={StepContentWidth.wide}>
          <CheckIconWrapper>
            <HiCheck size="1.8rem" />
          </CheckIconWrapper>
          <H3 $textAlign={HeadingAlign.center}>We've received your order</H3>
          {isBuysSellFlow && isAssetAllocationAutoConfirmEnabled && (
            <>
              <Text $textAlign={TextAlign.center}>
                Please note that it may take a few days to settle your orders.
                Once your SELL orders have settled, we will proceed with your
                BUY orders automatically.
              </Text>
              <Text $textAlign={TextAlign.center}>
                In the event that there is insufficient cash to proceed with
                your BUY orders, for example as a result of market movements, we
                will be in touch by email to confirm how you wish to proceed.
              </Text>
              <Text $textAlign={TextAlign.center}>
                You can view the status of your rebalancing trades at any time
                via your Dashboard.
              </Text>
            </>
          )}
          {isBuysSellFlow && !isAssetAllocationAutoConfirmEnabled && (
            <Text $textAlign={TextAlign.center}>
              Please note that it may take a few days to settle your orders.
              Once your SELL orders have settled, you will receive an email
              notification asking you to log into your account to reconfirm the
              BUY orders. Please see below for more details.
            </Text>
          )}
          {!isBuysSellFlow && (
            <>
              <Text $textAlign={TextAlign.center}>
                Please note that it may take a few days to settle your order.
                Once it has settled it will show on your account page in the
                ‘Holdings’ section and you can view and download the contract
                notes in ‘My documents’ on your Dashboard.
              </Text>
            </>
          )}
        </StepContent>

        {isBuysSellFlow && !isAssetAllocationAutoConfirmEnabled && (
          <CardStepWrapper>
            <Card>
              <CardStepContainer>
                <CardStepTitle>1</CardStepTitle>
                <CardStepDescription>
                  <H5>SELL orders placed</H5>
                  <Text>
                    Now that we have received your orders, we will start by
                    placing your SELL orders as the first step. Please note that
                    this may take 3-5 working days. Once these have completed we
                    will notify you via email.{' '}
                  </Text>
                </CardStepDescription>
              </CardStepContainer>
            </Card>
            <Card>
              <CardStepContainer>
                <CardStepTitle>2</CardStepTitle>
                <CardStepDescription>
                  <H5>Log into your TILLIT account to reconfirm BUY orders</H5>
                  <Text>
                    Once your SELL orders have completed, you will receive an
                    email confirmation asking you to log into your TILLIT
                    account to reconfirm your BUY orders. At this stage you can
                    review your BUY orders, see the latest pricing data, and
                    make any changes. Once you are happy to proceed, you just
                    have to confirm the order.
                  </Text>
                </CardStepDescription>
              </CardStepContainer>
            </Card>
            <Card>
              <CardStepContainer>
                <CardStepTitle>3</CardStepTitle>
                <CardStepDescription>
                  <H5>BUY orders placed</H5>
                  <Text>
                    When you have reconfirmed your BUY orders, we will place
                    those for you. Just like with your sell orders, it may take
                    3-5 working days for your orders to settle.
                  </Text>
                </CardStepDescription>
              </CardStepContainer>
            </Card>
            <Card>
              <CardStepContainer>
                <CardStepTitle>4</CardStepTitle>
                <CardStepDescription>
                  <H5>Orders complete!</H5>
                  <Text>
                    As soon as all of your BUY orders have settled, you will
                    receive an email confirmation with all of the details of
                    your order, including all BUY and SELL orders placed as part
                    of your portfolio rebalancing decisions. You can now log
                    into your TILLIT account and view your portfolio as well as
                    download the contract notes, which you will find in ‘My
                    documents’ on your Dashboard.
                  </Text>
                </CardStepDescription>
              </CardStepContainer>
            </Card>
          </CardStepWrapper>
        )}

        <CheckoutFeedback activeFlow={activeFlow}>
          <StepActions>
            <LinkCustomButton
              $size="normal"
              $isWide
              to={`/dashboard/${selectedAccountType.toLowerCase()}`}
            >
              Go to dashboard
            </LinkCustomButton>
          </StepActions>
        </CheckoutFeedback>
      </CardStepWrapper>

      <ResumePopover wrapperType={selectedAccountType} />
    </>
  );
}
