import { yupResolver } from '@hookform/resolvers/yup';
import { DateField } from 'components/Form/DateField';
import { QueryState } from 'components/QueryState';
import {
  NullableRadioFormState,
  YesNoRadios,
} from 'components/design-system/FormElements/YesNoRadios';
import { StyledLink } from 'components/design-system/Link';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  StepActions,
  StepContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { Text } from 'components/design-system/Text/Text';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  useUpdateUserProfileMutation,
  useUserProfileOnboardingQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { tillitFAQs } from 'paths';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { HiExternalLink } from 'react-icons/hi';
import { useQueryClient } from 'react-query';
import type { UserProfileOnboardingQueryUserProfile } from 'types/graphqlTypes';
import * as Yup from 'yup';
import { FormActions } from '../_shared/FormActions';
import { MoreInfoWrapper } from '../_shared/steps.styles';
import {
  FlexibleBenefitAccessedContainer,
  StepFormContainer,
} from './AboutYourOtherPensionsStep.styles';

function useInvalidateMutatedQueries() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(useUserProfileQuery.getKey());
    queryClient.invalidateQueries(useUserProfileOnboardingQuery.getKey());
  };
}

// TODO: provide descriptive validation messages
const aboutYourOtherPensionsFormSchema = Yup.object({
  flexibleBenefitAccessed: Yup.boolean()
    .typeError('Please answer yes or no')
    .required(),
  flexibleBenefitAccessedDate: Yup.date()
    .typeError('Please enter a date')
    .max(new Date())
    .when('flexibleBenefitAccessed', {
      is: true,
      then: (schema) => schema.required('Please enter a date'),
    }),
  optedOutOfEmployerScheme: Yup.boolean()
    .typeError('Please answer yes or no')
    .required(),
});

interface AboutYourOtherPensionsFormValues {
  flexibleBenefitAccessed: NullableRadioFormState;
  flexibleBenefitAccessedDate: string | null;
  optedOutOfEmployerScheme: NullableRadioFormState;
}

/** Yup will converts the form values to  */
type AboutYourOtherPensionsFormSubmittedValues = Yup.InferType<
  typeof aboutYourOtherPensionsFormSchema
>;

export interface AboutYourOtherPensionsStepProps {
  source?: 'openAccountStep';
  onProceed: () => void;
  onGoBack: () => void;
}

function getDatePart(isoDateTime: string | null | undefined) {
  return isoDateTime?.substring(0, isoDateTime?.indexOf('T'));
}

function getBooleanFormValueString(
  field: boolean | null | undefined
): NullableRadioFormState {
  if (typeof field === 'boolean') {
    return field ? 'true' : 'false';
  }

  return undefined;
}

interface AboutYourOtherPensionsStepFormProps
  extends AboutYourOtherPensionsStepProps {
  data: UserProfileOnboardingQueryUserProfile;
}

function AboutYourOtherPensionsStepForm({
  source,
  onProceed,
  onGoBack,
  data,
}: AboutYourOtherPensionsStepFormProps) {
  const {
    mutateAsync: updateUserProfile,
    isError,
    reset,
  } = useUpdateUserProfileMutation();
  const invalidateQueries = useInvalidateMutatedQueries();

  const defaultValues: AboutYourOtherPensionsFormValues = {
    flexibleBenefitAccessed: getBooleanFormValueString(
      data.pensionDetails?.flexibleBenefitAccessed
    ),
    flexibleBenefitAccessedDate:
      getDatePart(data.pensionDetails?.flexibleBenefitAccessedDate) ?? null,
    optedOutOfEmployerScheme: getBooleanFormValueString(
      data.pensionDetails?.optedOutOfEmployerScheme
    ),
  };

  const methods = useForm<AboutYourOtherPensionsFormValues>({
    defaultValues,
    resolver: yupResolver(aboutYourOtherPensionsFormSchema),
  });

  const {
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting },
    register,
    errors,
  } = methods;

  const flexibleBenefitAccessed = watch('flexibleBenefitAccessed');

  const onSubmit: SubmitHandler<AboutYourOtherPensionsFormSubmittedValues> = async (
    data
  ) => {
    const flexBenDate = data.flexibleBenefitAccessedDate;
    const flexibleBenefitAccessedDate = data.flexibleBenefitAccessed
      ? flexBenDate?.toLocaleDateString('sv') + 'T00:00:00'
      : undefined;

    reset();

    try {
      if (isDirty) {
        await updateUserProfile({
          input: {
            pension: {
              flexibleBenefitAccessed: data.flexibleBenefitAccessed,
              flexibleBenefitAccessedDate,
              optedOutOfEmployerScheme: data.optedOutOfEmployerScheme,
            },
          },
        });
        invalidateQueries();
      }

      if (source === 'openAccountStep') {
        trackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.aboutYourOtherPensions,
        });
      }

      onProceed();
    } catch (e) {
      // intentionally empty
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <StepContainer>
          <StepTitle>About your other pensions</StepTitle>
          <StepIntroduction $width={StepIntroductionWidth.extraWide}>
            <StepIntroductionTypography>
              To make sure we handle the tax requirements of your new pension
              correctly, we need to know a little about any other pensions you
              might have.
            </StepIntroductionTypography>
          </StepIntroduction>
          <br />

          <StepFormContainer>
            <FlexibleBenefitAccessedContainer>
              <YesNoRadios
                name="flexibleBenefitAccessed"
                register={register}
                error={errors.flexibleBenefitAccessed}
                defaultValue={defaultValues.flexibleBenefitAccessed}
                label={
                  <Text $noMargin>
                    Have you taken any flexible benefits from an existing
                    pension already?{' '}
                    <StyledLink
                      href={`${tillitFAQs}#what-are-flexible-benefits`}
                      target="_blank"
                      $icon
                    >
                      What does this mean? <HiExternalLink />
                    </StyledLink>
                  </Text>
                }
              />

              {flexibleBenefitAccessed === 'true' && (
                <MoreInfoWrapper>
                  <DateField
                    name="flexibleBenefitAccessedDate"
                    label="What date did you first do this?"
                    inputRef={register}
                  />
                </MoreInfoWrapper>
              )}
            </FlexibleBenefitAccessedContainer>
            <YesNoRadios
              name="optedOutOfEmployerScheme"
              register={register}
              error={errors.optedOutOfEmployerScheme}
              defaultValue={defaultValues.optedOutOfEmployerScheme}
              label={
                <Text $noMargin>
                  Are you opting out of your employer's auto-enrolment pension
                  scheme?
                </Text>
              }
            />
          </StepFormContainer>

          <ServerError isVisible={isError} />

          <StepActions>
            <FormActions onGoBack={onGoBack} disabled={isSubmitting} />
          </StepActions>
        </StepContainer>
      </FormProvider>
    </form>
  );
}

export function AboutYourOtherPensionsStep(
  props: AboutYourOtherPensionsStepProps
) {
  const userProfileOnboardingQuery = useUserProfileOnboardingQuery();

  return (
    <QueryState {...userProfileOnboardingQuery}>
      {(queryResult) => (
        <AboutYourOtherPensionsStepForm
          {...props}
          data={queryResult.data?.userProfile!}
        />
      )}
    </QueryState>
  );
}
