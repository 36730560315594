import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/ResponsiveDialog';
import { WrapperType } from 'generated/graphql';
import React from 'react';
import { SecondAccount } from './SecondAccount';

export interface SecondAccountDialogProps extends ResponsiveDialogProps {
  accountType: WrapperType;
}

export function SecondAccountDialog({
  accountType,
  onClose,
  ...props
}: SecondAccountDialogProps) {
  const handleClose = () => {
    onClose?.();
  };

  const handleCancel = () => {
    onClose?.();
  };

  return (
    <ResponsiveDialog {...props} onClose={handleClose}>
      <SecondAccount
        accountType={accountType}
        onClose={handleClose}
        onCancel={handleCancel}
      />
    </ResponsiveDialog>
  );
}
