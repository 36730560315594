import { Tab, Tabs } from '@material-ui/core';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { vertHexagon } from 'theme/shared/hexa.styles';

export const StyledTabs = styled(({ color, ...otherProps }) => (
  <Tabs {...otherProps} />
))`
  width: 100%;
  position: relative;

  .MuiTabs-fixed {
    max-height: 500px;
  }

  .MuiTabs-flexContainer {
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;

    ${(p) => p.theme.breakpoints.up('md')} {
      width: auto;
      height: 100%;
      flex-direction: column;
    }
  }

  .MuiTabs-indicator {
    background-color: ${(p) => p.color};
    top: 0;
    bottom: 0;
    height: 40px;
    ${vertHexagon};
    z-index: 0;

    ${(p) => p.theme.breakpoints.up('sm')} {
      height: 60px;
    }

    ${(p) => p.theme.breakpoints.up('md')} {
      left: 0;
      height: 60px;
      width: 60px;
    }
  }
`;

export const StyledTab = styled(({ color, ...otherProps }) => (
  <Tab {...otherProps} />
))`
  &.MuiTab-root {
    min-width: 40px;
    min-height: 40px;
    background-color: transparent;
    font-size: 0.875rem;
    font-weight: 300;
    text-transform: inherit;
    color: ${(p) => p.color};
    padding: ${(p) => p.theme.spacing(0.75, 0)};
    margin-right: ${(p) => p.theme.spacing(3)}px;
    ${vertHexagon};

    &:last-child {
      margin-right: 0;
    }

    ${(p) => p.theme.breakpoints.up('sm')} {
      font-size: 1rem;
      min-width: 60px;
      min-height: 60px;
      margin-right: ${(p) => p.theme.spacing(3.5)}px;

      &:last-child {
        margin-right: 0;
      }
    }

    ${(p) => p.theme.breakpoints.up('md')} {
      font-size: 1.5rem;
      min-width: 60px;
      min-height: 60px;
      margin-bottom: ${(p) => p.theme.spacing(3.75)}px;
      margin-right: auto;

      &:last-child {
        margin-right: auto;
        margin-bottom: 0;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${(p) => p.color};
      ${vertHexagon};
      z-index: -1;
    }
    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      background-color: ${colors.white};
      ${vertHexagon};
      z-index: -1;
    }

    ${(p) =>
      p.theme.darkUniverse &&
      `
      color: ${colors.white};
      &:before {
        content: '';
        background-color: ${colors.white};
      }
      &:after {
        content: '';
        background-color: ${colors.richBlack};
      }
      `}
  }

  &.Mui-selected {
    background-color: transparent;
    font-weight: 500;
    color: ${colors.white};
    transition: color 0.2s ease-in, background-color 0.2s ease-in;

    &:before,
    &:after {
      content: none;
    }

    ${(p) =>
      p.theme.darkUniverse &&
      `
      color: ${colors.richBlack};
      &:before {
        content: '';
        background-color: ${colors.white};
      }
      &:after {
        content: '';
        background-color: ${colors.white};
      }
      `}
  }

  &.Mui-disabled {
    color: ${transparentize(0.5, colors.white)};

    &:before {
      background-color: ${(p) => transparentize(0.5, p.color)};
    }

    &:after {
      content: none;
    }
  }
`;

export const DarkUniStyledTab = styled(StyledTab)``;
