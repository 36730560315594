import { Link, Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import { HiOutlineDownload } from 'react-icons/hi';
import styled from 'styled-components';

export const DialogTitle = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

export const CopyContainer = styled.div`
  position: relative;
  width: 100%;

  p {
    margin-bottom: ${(p) => p.theme.spacing(1.5)}px;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

export const AffirmationsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 0;
  max-height: 0;
  opacity: 0;
  &.expanded {
    transition: max-height 0.2s ease-in-out, opacity 0.4s ease-in-out 0.2s,
      margin-top 0.2s ease-in-out;
    margin-top: ${(p) => p.theme.spacing(4)}px;
    max-height: 200px;
    opacity: 1;
  }
`;

export const ActionContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: ${(p) => p.theme.spacing(1)}px;

  & > *:last-child {
    margin-top: ${(p) => p.theme.spacing(1)}px;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    flex-direction: row;

    & > *:last-child {
      margin-top: 0;
      margin-left: ${(p) => p.theme.spacing(2)}px;
    }
  }
`;

export const TransferPromptContainer = styled.div`
  width: 100%;
  margin-top: ${(p) => p.theme.spacing(3.75)}px;
  button {
    width: auto;
  }
`;

export const DownloadDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 32px;
  border-radius: 5px;
  background-color: ${transparentize(0.93, colors.richBlue)};
  justify-content: center;
  align-items: center;
  margin: 37px 0;
  color: ${colors.richBlue};
`;

export const StyledDownloadLink = styled(Link)`
  cursor: pointer;
  color: ${colors.richBlue};
`;

export const StyledIcon = styled(HiOutlineDownload)`
  position: absolute;
  left: 10px;
  font-size: 1.5rem;
  color: ${colors.richBlue};
`;
