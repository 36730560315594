import { Card } from 'components/design-system/Card/Card';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const SelectModeAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const NewCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-wrap: nowrap;
  }
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 1rem 0;
`;

export const SelectAccountCardInner = styled.div`
  display: grid;
  gap: 0.5rem;
  justify-content: center;
`;

export interface AccountCardProps {
  $selected?: boolean;
}

export const AccountCard = styled(Card)<AccountCardProps>`
  flex-grow: 1;
  padding: 10px 20px;
  background-color: ${({ $selected }) =>
    $selected ? colors.richBlue : colors.white};
  h5,
  p {
    color: ${({ $selected }) => ($selected ? colors.white : colors.black)};
  }
`;

export const SelectedAccountAlert = styled.div`
  width: 100%;
  background-color: ${colors['grey-50']};
  border-radius: 5px;
  border: 1px solid ${colors['grey-200']};
  padding: 0.5rem 2rem;
`;
