import { TextButton } from 'components/Button/TextButton';
import { Alert, Severity } from 'components/design-system/Alert/Alert';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import {
  OptionCard,
  OptionCardWrapper,
} from 'components/design-system/Card/OptionCard';
import { StyledLink } from 'components/design-system/Link';
import { TextNormal, TextSmall } from 'components/design-system/Text/Text';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import { AccountType, useUserProfileQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import {
  hexagonPagePath,
  tillitGIAProductPagePath,
  tillitISAProductPagePath,
  tillitSIPPProductPagePath,
} from 'paths';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  StepContainer,
  StepContent,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

export const Warning = styled(TextNormal)`
  min-width: 100%;
  margin: 1rem;
  text-align: center;
`;

const ChooseAccountTypeStepContainer = styled(StepContainer)`
  gap: 3rem;
`;

type OnProceed = (accType: AccountType) => void;

export interface PickAccountTypeProps {
  onProceed: OnProceed;
  onPromoCode?: () => void;
  onTransfer?: () => void;
  promoCode?: string;
}

export function PickAccountType({
  onProceed,
  onTransfer,
  onPromoCode,
  promoCode,
}: PickAccountTypeProps) {
  const [age, setAge] = useState(0);
  const { signedIn } = useAuth();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const referralCode = params.has('referralCode')
    ? (params.get('referralCode') as string)
    : undefined;

  useUserProfileQuery(undefined, {
    enabled: signedIn,
    onSuccess(data) {
      const currentYear = new Date().getFullYear();
      setAge(currentYear - data.userProfile?.dateOfBirth.year);
    },
  });
  const { getSignInPath } = useAuth();

  const [createSippEnabled] = useToggle('global-create-sipps');

  const selectAccount = (accType: AccountType) => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: accType,
    });
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.selectAccountType,
      selectedAccount: accType,
      coupon: promoCode,
    });
    onProceed(accType);
  };

  return (
    <ChooseAccountTypeStepContainer>
      <StepContent>
        <StepTitle>Pick your account</StepTitle>
        <TextNormal
          style={{
            textAlign: 'center',
            marginBottom: '0.5rem',
          }}
        >
          Want to look around first?{' '}
          <StyledLink href={hexagonPagePath}>
            Explore our fund Universe
          </StyledLink>
        </TextNormal>
        {!signedIn && (
          <TextSmall style={{ fontWeight: 300 }} $noMargin>
            Already have an account?{' '}
            <StyledLink
              href={getSignInPath(undefined, undefined, referralCode)}
            >
              Sign in
            </StyledLink>
          </TextSmall>
        )}
      </StepContent>
      <OptionCardWrapper>
        <OptionCard
          title={
            <>
              Stocks & Shares
              <br />
              ISA
            </>
          }
          buttonLabel="Open an ISA"
          onClick={() => selectAccount(AccountType.Isa)}
          extraInfo={
            <TextSmall
              $noMargin
              style={{
                marginTop: '0.25rem',
              }}
            >
              <StyledLink href={tillitISAProductPagePath}>
                Find out more
              </StyledLink>
            </TextSmall>
          }
        >
          <TextNormal $noMargin>
            Invest up to £20,000 per tax year in an ISA and pay no capital gains
            tax on your investment returns
          </TextNormal>
        </OptionCard>
        {createSippEnabled && age < 75 && (
          <OptionCard
            title={
              <>
                Pension
                <br />
                (SIPP)
              </>
            }
            buttonLabel="Open a Pension"
            onClick={() => selectAccount(AccountType.Sipp)}
            extraInfo={
              <TextSmall
                $noMargin
                style={{
                  marginTop: '0.25rem',
                }}
              >
                <StyledLink href={tillitSIPPProductPagePath}>
                  Find out more
                </StyledLink>
              </TextSmall>
            }
          >
            <TextNormal $noMargin>
              A Self-Invested Personal Pension intended for investors who want
              to make their own investment decisions
            </TextNormal>
          </OptionCard>
        )}
        <OptionCard
          title={
            <>
              General Investment
              <br />
              Account (GIA)
            </>
          }
          buttonLabel="Open a GIA"
          onClick={() => selectAccount(AccountType.Gia)}
          extraInfo={
            <TextSmall
              $noMargin
              style={{
                marginTop: '0.25rem',
              }}
            >
              <StyledLink href={tillitGIAProductPagePath}>
                Find out more
              </StyledLink>
            </TextSmall>
          }
        >
          <TextNormal $noMargin>
            With no limits on how much you can invest or how many accounts you
            can open, get started with a GIA
          </TextNormal>
        </OptionCard>
      </OptionCardWrapper>
      <StepContent>
        <Warning>
          Capital at risk. Tax treatment depends on an individual’s
          circumstances and may be subject to change.
        </Warning>
        {!signedIn && (
          <>
            {promoCode ? (
              <Alert severity={Severity.success}>
                <TextNormal $noMargin>
                  Promo code added - <strong>{promoCode}</strong>
                </TextNormal>
              </Alert>
            ) : (
              <TextButton
                onClick={() => {
                  onPromoCode?.();
                }}
              >
                Have a promo code?
              </TextButton>
            )}
          </>
        )}

        {false && (
          <CustomButtonV2
            className="purple"
            fullWidth
            style={{
              whiteSpace: 'break-spaces',
            }}
            onClick={onTransfer}
          >
            Transfer to TILLIT
          </CustomButtonV2>
        )}
      </StepContent>
    </ChooseAccountTypeStepContainer>
  );
}
