import { Typography } from '@material-ui/core';
import { TransferAccountType } from 'components/Dialogs/TransferAccountType/TransferAccountType';
import { GaEventNames } from 'constants/gaConstants';
import {
  OnboardingStatus,
  TransferMethod,
  WrapperType,
  useOnboardingStatusQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useCanTransfer } from 'hooks/useCanTransfer';
import { useHasTransferIntent } from 'hooks/useHasTransferIntent';
import { useTransferStatusSummary } from 'hooks/useTransferStatusSummary';
import { useModal } from 'mui-modal-provider';
import {
  dashboardGiaPath,
  dashboardIsaPath,
  dashboardTransfersPath,
  fundListPath,
  openAccountTransferPath,
} from 'paths';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SubTitle, Title } from '../Cards.styles';
import {
  CardButton,
  CardContainer,
  ContentContainer,
  LinkCardButton,
} from './TransferCard.styles';

export interface TransferContentProps {
  cedingProvider: string;
}

export interface PureTransferCardProps {
  className?: string;
  children: React.ReactNode;
}

export function Wrapper({ className, children }: PureTransferCardProps) {
  return (
    <CardContainer className={className}>
      <Title>Transfers</Title>
      <ContentContainer vAlign="flex-start">{children}</ContentContainer>
    </CardContainer>
  );
}

export function TransferCardContentDefault() {
  const history = useHistory();
  const { showModal } = useModal();

  const onboardingStatus = useOnboardingStatusQuery();
  const transferStatus = useTransferStatusSummary();
  const canTransfer = useCanTransfer();
  const hasTransferIntent = useHasTransferIntent();

  const userProfileQuery = useUserProfileQuery();
  const handleResumeOnboarding = () => {
    history.push(openAccountTransferPath);
  };
  const buttonDisabled = !canTransfer.gia || !canTransfer.isa ? true : false;
  const hasAccounts =
    userProfileQuery.data?.clientSummary?.accounts &&
    userProfileQuery.data?.clientSummary?.accounts.length > 0;
  const isOnboarding = useMemo(
    () =>
      onboardingStatus.data?.userProfile?.onboardingDetails?.status ===
      OnboardingStatus.FurtherInformationNeeded,
    [onboardingStatus.data?.userProfile?.onboardingDetails?.status]
  );

  const handleClick = () => {
    if (transferStatus.summary.status === 'default') {
      if (isOnboarding && hasTransferIntent) {
        handleResumeOnboarding();
      } else {
        handleStartTransfer();
      }
    }
  };
  const handleStartTransfer = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: 'Start Transfer',
    });

    //show modal window with transfer form
    if (hasAccounts) {
      showModal(TransferAccountType);
    } else {
      history.push(openAccountTransferPath);
    }
  };
  const buttonText =
    isOnboarding && hasTransferIntent
      ? 'Resume my transfer'
      : 'Transfer to TILLIT';

  return (
    <Wrapper>
      <SubTitle>Have accounts elsewhere?</SubTitle>
      <Typography>
        You can transfer your Stocks &amp; Shares ISA and GIA from another
        platform to TILLIT in just a few clicks.
      </Typography>
      <ContentContainer vAlign="flex-start">
        <CardButton
          disabled={buttonDisabled}
          onClick={handleClick}
          className="purple"
        >
          {buttonText}
        </CardButton>
      </ContentContainer>
    </Wrapper>
  );
}

export function TransferCardContentRequested({
  cedingProvider,
}: TransferContentProps) {
  return (
    <Wrapper>
      <SubTitle>Transfer request submitted</SubTitle>
      <Typography>
        View more details about your transfer request from {cedingProvider}{' '}
        below.
      </Typography>
      <ContentContainer vAlign="flex-start">
        <LinkCardButton to={dashboardTransfersPath} $color="secondary">
          View details
        </LinkCardButton>
      </ContentContainer>
    </Wrapper>
  );
}

export function TransferCardContentActiveSingle({
  cedingProvider,
}: TransferContentProps) {
  return (
    <Wrapper>
      <SubTitle>Your transfer is on the move</SubTitle>
      <Typography>
        View more details about your transfer from {cedingProvider} below.
      </Typography>
      <ContentContainer vAlign="flex-start">
        <LinkCardButton to={dashboardTransfersPath} $color="secondary">
          View details
        </LinkCardButton>
      </ContentContainer>
    </Wrapper>
  );
}

export function TransferCardContentActiveMultiple() {
  return (
    <Wrapper>
      <SubTitle>Your transfers are on the move</SubTitle>
      <Typography>View more details about your transfers below.</Typography>
      <ContentContainer vAlign="flex-start">
        <LinkCardButton to={dashboardTransfersPath} $color="secondary">
          View details
        </LinkCardButton>
      </ContentContainer>
    </Wrapper>
  );
}

export function TransferCardContentHold() {
  return (
    <Wrapper>
      <SubTitle>Action required</SubTitle>
      <Typography>
        In order to progress your transfer to TILLIT, we need your help
        confirming a few details.
      </Typography>
      <ContentContainer vAlign="flex-start">
        <LinkCardButton to={dashboardTransfersPath} $color="primary">
          View details
        </LinkCardButton>
      </ContentContainer>
    </Wrapper>
  );
}

export function TransferCardContentCanceled({
  cedingProvider,
}: TransferContentProps) {
  return (
    <Wrapper>
      <SubTitle>Your transfers has been cancelled</SubTitle>
      <Typography>
        Your transfer request from {cedingProvider} has been cancelled. View
        more details below.
      </Typography>
      <ContentContainer vAlign="flex-start">
        <LinkCardButton to={dashboardTransfersPath} $color="secondary">
          View details
        </LinkCardButton>
      </ContentContainer>
    </Wrapper>
  );
}

export interface TransferCardContentComplectedProps
  extends TransferContentProps {
  method?: TransferMethod;
  wrapperType?: WrapperType;
}

export function TransferCardContentComplected({
  cedingProvider,
  method,
  wrapperType,
}: TransferCardContentComplectedProps) {
  const handleViewFunds = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'View funds',
      source: 'Transfer card',
    });
  };
  if (method === TransferMethod.Cash) {
    return (
      <Wrapper>
        <SubTitle>Transfer complete!</SubTitle>
        <Typography>
          Your transfer from {cedingProvider} has completed and you can now
          invest your cash on TILLIT.
        </Typography>
        <ContentContainer vAlign="flex-start">
          <LinkCardButton
            onClick={handleViewFunds}
            to={fundListPath}
            className="magenta"
          >
            View funds
          </LinkCardButton>
        </ContentContainer>
      </Wrapper>
    );
  } else if (method === TransferMethod.InSpecie) {
    return (
      <Wrapper>
        <SubTitle>Transfer complete!</SubTitle>
        <Typography>
          You transfer from {cedingProvider} has completed and can now view your
          holdings in your {wrapperType}.
        </Typography>
        <ContentContainer vAlign="flex-start">
          <LinkCardButton
            to={
              wrapperType === WrapperType.Gia
                ? dashboardGiaPath
                : dashboardIsaPath
            }
            className="magenta"
          >
            View my account
          </LinkCardButton>
        </ContentContainer>
      </Wrapper>
    );
  }
  return null;
}
