import { Typography } from '@material-ui/core';
import { CustomButton } from 'components/Button/CustomButton';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledSupportIcon = styled(CustomButton)`
  padding: ${(p) => p.theme.spacing(0.5)}px;
  min-width: ${(p) => p.theme.spacing(4)}px;
  font-size: 2rem;
  background-color: ${colors.lightGrey};

  .MuiButton-label > svg {
    margin-left: 0;
    stroke: ${colors.richBlue};
  }
`;

export const HelperText = styled(Typography)`
  font-size: 0.75rem;
  margin-top: ${(p) => p.theme.spacing(1.25)}px;
  font-family: 300;
  color: ${colors.richBlue};
`;
