import { Container, OutlinedInput } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const PageContainerInner = styled(Container)`
  position: relative;
  padding: 0;
`;

export const ActionContainer = styled.div`
  order: 2;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: ${(p) => p.theme.spacing(2.5)}px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    order: 1;
    width: 50%;
    justify-content: flex-start;
    margin-top: 0;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 25%;
  }
`;

export const FormContainer = styled.div`
  order: 1;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;

  ${(p) => p.theme.breakpoints.up('sm')} {
    order: 2;
    width: 50%;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 25%;
    order: 3;
  }
`;

export const SearchForm = styled.form`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  transition: width 0.2s ease-in-out;

  ${(p) => p.theme.breakpoints.up('sm')} {
    width: 50%;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 60%;
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    width: 70%;
  }

  &.focus {
    width: 100%;
    transition: width 0.4s ease-in-out;
  }
`;

export interface SearchFieldProps {
  $isStuck?: boolean;
}

export const SearchField = styled(OutlinedInput)<SearchFieldProps>`
  color: ${colors.black};
  padding: 0;
  font-size: 0.75rem;
  margin: 0;
  flex: 1;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  border-radius: 1.75rem;
  border: 1px solid ${colors['grey-600']};

  fieldset {
    display: none;
  }

  > input {
    padding-top: ${(p) => p.theme.spacing(0.5)}px;
    padding-bottom: ${(p) => p.theme.spacing(0.5)}px;
  }

  button {
    padding: 0.5rem 0.5rem 0.5rem 12px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 1rem;
    > input {
      padding-top: ${(p) => p.theme.spacing(1.5)}px;
      padding-bottom: ${(p) => p.theme.spacing(1.5)}px;
    }
    button {
      padding: revert;
    }
  }

  @supports (-webkit-touch-callout: none) {
    font-size: 1rem;
  }
`;
