import {
  PortfolioRebalancingStatus,
  WrapperType,
  useCancelPortfolioRebalancingMutation,
  useCreatePortfolioRebalancingMutation,
  usePortfolioRebalancingsQuery,
  useUpdatePortfolioRebalancingMutation,
} from 'generated/graphql';
import { generateDynamicPortfolioConstructionBasketPath } from 'paths';
import { useHistory } from 'react-router-dom';
import { AnyRebalancing } from 'types/graphqlTypes';
import { useFundsBasket } from '../hooks/useFundsBasket';

/**
 * This is use for returning users to edit their order
 */
export function useEditWaitingOrder(
  selectedAccountId: string,
  selectedAccountType: WrapperType,
  portfolioRebalancing: AnyRebalancing
) {
  const history = useHistory();
  const { clear, syncFromServer } = useFundsBasket({
    selectedAccountId,
  });

  const { mutateAsync } = useCancelPortfolioRebalancingMutation();
  const portfolioRebalancingId = portfolioRebalancing.id;

  const portfolioRebalancingsQuery = usePortfolioRebalancingsQuery(
    {
      filter: { active: true, accountId: selectedAccountId },
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
    }
  );
  const draftPortfolioRebalancing = portfolioRebalancingsQuery?.data?.portfolioRebalancings?.find(
    ({ status }) => status === PortfolioRebalancingStatus.Draft
  );
  const hasDraftRebalancing = !!draftPortfolioRebalancing;

  const createPortfolioRebalancingMutationQuery = useCreatePortfolioRebalancingMutation();
  const {
    mutateAsync: createPortfolioRebalancingMutation,
  } = createPortfolioRebalancingMutationQuery;

  const updatePortfolioRebalancingMutationQuery = useUpdatePortfolioRebalancingMutation();
  const {
    mutateAsync: updatePortfolioRebalancingMutation,
  } = updatePortfolioRebalancingMutationQuery;

  if (!portfolioRebalancingId || !selectedAccountId) {
    return null;
  }

  return async () => {
    if (
      portfolioRebalancing &&
      portfolioRebalancing.status !== PortfolioRebalancingStatus.Waiting
    ) {
      // Only allow cancelling if the order is in the waiting state
      throw new Error();
    }

    try {
      if (hasDraftRebalancing) {
        await updatePortfolioRebalancingMutation({
          input: {
            portfolioRebalancingId: draftPortfolioRebalancing?.id,
            buyOrders: portfolioRebalancing?.buyOrders?.map((buyOrder) => ({
              isin: buyOrder.isin,
              amount: buyOrder.amount,
            })),
            sellOrders: [],
          },
        });
      } else {
        await createPortfolioRebalancingMutation({
          input: {
            accountId: selectedAccountId,
            buyOrders: portfolioRebalancing?.buyOrders?.map((buyOrder) => ({
              isin: buyOrder.isin,
              amount: buyOrder.amount,
            })),
            sellOrders: [],
          },
        });
      }

      clear();
      syncFromServer();

      // cancel the portfolio rebalancing
      await mutateAsync({
        id: portfolioRebalancingId,
      });

      // redirect to the portfolio construction page
      history.push(
        generateDynamicPortfolioConstructionBasketPath({
          wrapperType: selectedAccountType.toLowerCase(),
        })
      );
    } catch (e) {
      // @todo:
      console.error(e);
    }
  };
}
