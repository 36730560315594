import { Alert } from 'components/design-system/Alert/Alert';
import {
  FontWeight,
  Text,
  TextSmall,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface KeyValueContainerProps {
  $justifyContent: 'left' | 'space-between';
}

const KeyValueContainer = styled(TextSmall)`
  ${({ $justifyContent }: KeyValueContainerProps) =>
    $justifyContent === 'space-between'
      ? `
      display: flex;
      justify-content: space-between;
      `
      : ''};
  gap: 0.5rem;
  margin-bottom: 0;
`;

interface KeyValueProps {
  label: ReactNode;
  value: ReactNode;
  justifyContent?: undefined | 'space-between';
}
export const KeyValue = ({ label, value, justifyContent }: KeyValueProps) => {
  return (
    <KeyValueContainer $justifyContent={justifyContent}>
      <strong>{label}:</strong> {value}
    </KeyValueContainer>
  );
};

export const MoreDetailsTitle = styled.button`
  border: none;
  padding: 0.75rem 0;
  background: none;
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

interface MoreDetailsContentProps {
  $isOpen: boolean;
}

export const MoreDetailsContent = styled.div<MoreDetailsContentProps>`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0;
  max-height: ${({ $isOpen }) => ($isOpen ? '600px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

export const PillContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
`;

const pillsColorLookup = {
  default: colors.midGrey,
  buy: colors.success,
  sell: colors.danger,
  standingOrder: colors['purple-300'],
};
type pillColors = keyof typeof pillsColorLookup;
interface PillProps {
  $filled?: boolean;
  $color?: pillColors;
}
export const Pill = styled.span<PillProps>`
  border-radius: 1rem;
  padding: 0.25rem 0.75rem;
  border: solid 1px
    ${({ $color }) =>
      $color ? pillsColorLookup[$color] : pillsColorLookup.default};
  ${({ $filled, $color }) =>
    $filled
      ? `background: ${transparentize(
          0.9,
          $color ? pillsColorLookup[$color] : pillsColorLookup.default
        )};`
      : ''}
`;

export const DetailsCard = styled.div`
  width: 100%;
  border: 1px solid ${colors.midGrey};
  border-radius: 10px;
  padding: 1rem;
`;

export const MoreDetailsSubHeading = styled(Text).attrs({
  $fontWeight: FontWeight.normal,
})`
  margin: 0;
  padding: 0 0 0.5rem 0;
  border-bottom: 1px solid ${colors.midGrey};
`;

export const MoreDetailsSubContent = styled.div`
  padding-top: 0.5rem;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  gap: 0px 8px;
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: stretch;
`;

export const Section = styled.div`
  padding: 1rem 0;
  margin: 1.5rem 0;
  border-top: 1px solid ${colors.midGrey};
  border-bottom: 1px solid ${colors.midGrey};
  & + & {
    border-top: none;
  }
`;

export const AmountInputContainer = styled.div`
  margin: 0.5rem 0;
`;

export const FundDoLink = styled(Link)`
  font-size: 0.875rem;
  display: flex;
  gap: 0.25rem;
  font-weight: 300;
  text-decoration: none;
  color: ${colors.richBlack};
  align-items: center;
`;

export const PendingOrdersAlert = styled(Alert)`
  margin-top: 1rem;
`;

export const ErrorMessage = styled(TextSmall)`
  color: ${colors.danger};
`;

export const ShareClassWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const ErrorAlert = styled(Alert)`
  margin-bottom: 1rem;
`;
