import { CashTransactions } from 'components/CashTransaction/CashTransaction';
import { InfoPopover } from 'components/Popover/InfoPopover/InfoPopover';
import { QueryState } from 'components/QueryState';
import {
  ColumnDefinition,
  ResponsiveTable,
} from 'components/Tables/ResponsiveTable/ResponsiveTable';
import * as format from 'formatting';
import {
  CashTransactionStatus,
  CashTransactionSubType,
  CashTransactionsQuery,
  useAccountsQuery,
  useCashTransactionsQuery,
} from 'generated/graphql';
import { useCallback, useMemo } from 'react';
import { SupportedWrapperType } from 'types/utilityTypes';
import {
  AmountCell,
  TransactionsContainer,
} from './CashTransactionTable.style';

type Transaction = Exclude<
  CashTransactionsQuery['cashTransactionsByAccount'],
  null | undefined
>[number];

export interface PureCashTransactionTableProps {
  data: Array<Transaction>;
  dark?: boolean;
}

export function PureCashTransactionTable({
  data,
  dark = false,
}: PureCashTransactionTableProps) {
  const columns = useMemo(() => {
    return [
      {
        name: '',
        key: 'status',
        renderValue: (value: CashTransactionStatus) =>
          value === CashTransactionStatus.Pending && (
            <InfoPopover color="primary" size="small" content="In progress" />
          ),
      },
      {
        name: 'Type',
        key: 'description',
        align: 'left',
        renderValue: (value: string, data: Transaction) =>
          data?.transactionSubType === CashTransactionSubType.Dividend
            ? `${value} - ${data.linkedAssetName}`
            : value,
      },
      {
        name: 'Amount',
        key: 'amount',
        align: 'right',
        renderValue: (value: number) => (
          <AmountCell>{format.currencyFull(value)}</AmountCell>
        ),
      },
      {
        name: 'Date',
        key: 'transactionDate',
        align: 'right',
        renderValue: (value: string) => format.date(value),
      },
    ] as Array<ColumnDefinition<Transaction>>;
  }, []);

  const mobileComponent = useCallback(
    (row: Transaction) => (
      <CashTransactions
        transactionLinkedAsset={row.linkedAssetName || undefined}
        transactionDescription={row.description}
        transactionAmount={row.amount}
        transactionDate={row.transactionDate}
        transactionStatus={row.status}
      />
    ),
    []
  );

  return (
    <TransactionsContainer>
      <ResponsiveTable
        columns={columns}
        data={data}
        noDataMessage="You have no cash transactions."
        renderMobile={mobileComponent}
        dark={dark}
      />
    </TransactionsContainer>
  );
}

export interface CashTransactionTableProps {
  accountType: SupportedWrapperType;
  dark?: boolean;
}

export function CashTransactionTable({
  accountType,
  dark = false,
}: CashTransactionTableProps) {
  const accountsQuery = useAccountsQuery();
  const currentAccount = useMemo(
    () =>
      accountsQuery.data?.accounts?.find(
        (acc) => acc.wrapperType === accountType
      ),
    [accountType, accountsQuery.data?.accounts]
  );
  const cashTransactionQuery = useCashTransactionsQuery(
    {
      id: currentAccount?.id!,
    },
    { enabled: !!currentAccount?.id }
  );

  return (
    <QueryState {...cashTransactionQuery}>
      {() => (
        <PureCashTransactionTable
          data={cashTransactionQuery.data?.cashTransactionsByAccount ?? []}
          dark={dark}
        />
      )}
    </QueryState>
  );
}
