import { colors } from 'constants/colors';
import styled from 'styled-components';

export const CantFindYourProvider = styled.button`
  background: none;
  border: none;
  color: ${colors.magenta};
  padding: 0;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
