import { TextSmall } from 'components/design-system/Text/Text';
import { QueryState } from 'components/QueryState';
import { colors } from 'constants/colors';
import { useAccountsQuery, WrapperType } from 'generated/graphql';
import Select, {
  components,
  OptionProps,
  SingleValueProps,
} from 'react-select';
import styled from 'styled-components';

interface CustomOptionContainerProps {
  isSelected: boolean;
}

const CustomOptionContainer = styled.div<CustomOptionContainerProps>`
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected
      ? `background:${colors.magenta}; > p { color: ${colors.white}; }`
      : ''}
  &:hover {
    background: ${colors.purple};
    > p {
      color: ${colors.white};
    }
  }
`;

const CustomOption = ({
  innerProps,
  isDisabled,
  label,
  isSelected,
}: OptionProps<OptionType>) => {
  const { ref, ...rest } = innerProps;
  return !isDisabled ? (
    <CustomOptionContainer {...rest} isSelected={isSelected}>
      <TextSmall $noMargin>{label}</TextSmall>
    </CustomOptionContainer>
  ) : null;
};

const CustomSingleValue = ({
  children,
  ...props
}: SingleValueProps<OptionType, false>) => (
  <components.SingleValue {...props}>
    <TextSmall $noMargin>Account type: {children}</TextSmall>
  </components.SingleValue>
);

interface HeaderBarProps {
  selectedAccountType: WrapperType | null;
  onChange: (wrapperType: WrapperType | null) => void;
}

interface OptionType {
  label: string;
  value: WrapperType | null;
}

export const SelectAccount = ({
  selectedAccountType,
  onChange,
}: HeaderBarProps) => {
  const accountsQuery = useAccountsQuery();

  return (
    <QueryState {...accountsQuery}>
      {({ data }) => {
        if (!data || !data?.accounts || data?.accounts?.length === 1) {
          return null;
        }
        const options = [
          { value: null, label: 'All' },
          ...(data?.accounts.map((account) => {
            return { value: account.wrapperType, label: account.wrapperType };
          }) || []),
        ];
        return (
          <Select<OptionType>
            placeholder="Account Type"
            options={options}
            onChange={({ value }: OptionType) => {
              onChange(value);
            }}
            defaultValue={options.find(
              ({ value }) => value === selectedAccountType
            )}
            components={{
              Option: CustomOption,
              SingleValue: CustomSingleValue,
            }}
          />
        );
      }}
    </QueryState>
  );
};
