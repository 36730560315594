import { Typography } from '@material-ui/core';
import { CustomButton } from 'components/Button/CustomButton';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useIntercom } from 'react-use-intercom';
import { ActionContainer, DialogTitle } from './Transfer.styles';
export interface ErrorStepProps {
  wrapperType: WrapperType;
  onClose?: () => void;
}

export function ErrorStep({ wrapperType, onClose }: ErrorStepProps) {
  const { showNewMessages } = useIntercom();

  const handleSupport = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `transfer dialog - error - transferring ${wrapperType}`,
    });

    showNewMessages(
      `I am having trouble transferring a ${wrapperType} to TILLIT.`
    );
    onClose?.();
  };

  return (
    <>
      <DialogTitle variant="subtitle1" align="center">
        We need more information.
      </DialogTitle>
      <Typography align="center">
        Before we can transfer your {wrapperType} we need more information.
        Please contact support.
      </Typography>

      <ActionContainer>
        <CustomButton
          className="richBlue"
          fullWidth
          style={{
            whiteSpace: 'break-spaces',
          }}
          onClick={handleSupport}
        >
          Talk to support
        </CustomButton>
      </ActionContainer>
    </>
  );
}
