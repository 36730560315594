import { FormControlLabel, Link, Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

interface TransferButtonsWrapperProps {
  expanded?: boolean;
}

export const TransferButtonsWrapper = styled.div<TransferButtonsWrapperProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 'auto auto';
  grid-gap: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 'auto';
  }
`;

interface TransferAsButtonProps {
  isSelected?: boolean;
  disabled: boolean;
}
export const TransferAsButton = styled.button<TransferAsButtonProps>`
  background: ${colors.white};
  color: ${colors.richBlue};
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: ${({ theme }) => theme.spacing(1.25)}px;
  border: none;
  width: 100%;
  height: 100%;
  font-size: 1.125rem;
  font-weight: 300;
  font-family: ${({ theme }) => theme.typography.headingFontFamily};
  padding: ${({ theme }) => theme.spacing(2, 2.5)};
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  transform: translateY(0);
  transition: background 0.4s ease-in-out, transform 0.4s ease-in-out;
  &.is-selected {
    background: ${colors.richBlue};
    color: ${colors.white};
  }
  &:hover:not([disabled]):not(.is-selected) {
    background: ${colors.richBlue};
    color: ${colors.white};
    transform: translateY(
      ${(p) => (!p.disabled && !p.isSelected ? '-4px' : '0')}
    );
  }
`;

export const TransferCopyWrapper = styled.div`
  background: ${colors.white};
  padding: ${({ theme }) => theme.spacing(3.5, 2, 2.5)};
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const TransferCopyTitle = styled(Typography)`
  color: ${colors.darkBlue};
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 16px;
`;

export const Introduction = styled(Typography)`
  color: ${colors.richBlack};
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  text-align: center;
`;

export const Divider = styled.div`
  width: 90%;
  margin: 0 auto;
  border-top: 1px solid #f2f2f2;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const WhatYouShouldKnow = styled(Typography)`
  color: ${colors.darkBlue};
  text-align: center;
  font-size: 1.125rem;
`;

export const Eligibility = styled(Typography)`
  text-align: center;
  font-size: 1rem;
  margin: 1rem 0 1.5rem;
`;

export const UlWarning = styled(Typography)`
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
`;

export const Ul = styled.ul`
  color: ${colors.richBlack};
  max-width: 450px;
  text-align: left;
  margin: ${({ theme }) => theme.spacing(2, 'auto')};
  padding-right: ${({ theme }) => theme.spacing(4)}px;
`;

interface LiProps {
  component: string;
}

export const Li = styled(Typography)<LiProps>`
  color: ${colors.richBlack};
  font-size: 0.875rem;
`;

export const Note = styled(Typography)`
  color: ${colors.richBlack};
  font-size: 0.875rem;
  font-style: italic;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

export const FormControlLabelConfirmCheckbox = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    color: ${colors.richBlack};
    font-weight: 400;
    font-size: 1rem;
  }
`;

export const CheckboxesWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3.75)}px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const IsaTransferDecLink = styled(Link)`
  color: ${colors.magenta};
  font-weight: 600;
  text-decoration: underline;
`;

export const Warning = styled(Typography)`
  color: ${colors.black};
  font-size: 1rem;
  text-align: center;
  margin-bottom: 28px;
`;

export const ComingSoonLink = styled.p`
  color: ${colors.magenta};
  font-size: 0.75rem;
  text-decoration: underline;
  position: absolute;
  bottom: -4px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  button {
    height: 32px;
    border-radius: 5px;
    width: 100%;
  }
`;

interface CardFaceProps {
  $active: boolean;
}

export const CardFace = styled.div<CardFaceProps>`
  display: ${({ $active }) => ($active ? 'block' : 'none')};
`;

export const StyledSpanLink = styled.span`
  position: relative;
  color: ${colors.magenta};
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${transparentize(0.75, colors.magenta)};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    transform: rotateY(90deg);
    background-color: ${colors.magenta};
    transition: transform 0.4s ease-in-out;
  }

  &:hover {
    text-decoration: none;
    &:after {
      transform: rotateY(0deg);
    }
  }
`;
