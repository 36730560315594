import { HeaderShim } from 'components/design-system/Header';
import { AnyAsset } from 'types/graphqlTypes';
import { DarkActionsBox } from './DarkActionsBox';
import {
  DarkFundTitle,
  DarkHeroContainer,
  DarkIntroContainerInner,
  DarkTitleContainer,
} from './DarkHeader.styles';

export interface DarkHeaderProps {
  title: string;
  isin: string;
  assetId: string;
  userHolds: boolean;
  signedIn: boolean;
  asset: AnyAsset;
}

export function DarkHeader({
  title,
  isin,
  assetId,
  userHolds,
  signedIn,
  asset,
}: DarkHeaderProps) {
  return (
    <DarkHeroContainer maxWidth={false} className="dark-background">
      <HeaderShim />
      <DarkIntroContainerInner maxWidth={'xl'}>
        <DarkTitleContainer>
          <DarkFundTitle variant="h1">{title}</DarkFundTitle>
        </DarkTitleContainer>
        <DarkActionsBox
          userHolds={userHolds}
          authIndicator={signedIn}
          isin={isin}
          assetId={assetId}
          title={title}
          isInHeader={true}
          asset={asset}
        />
      </DarkIntroContainerInner>
    </DarkHeroContainer>
  );
}
