import { useMediaQuery } from '@material-ui/core';
import { CashHoldingCard } from 'components/HoldingCard/CashHoldingCard';
import { DarkUniverseHoldingCard } from 'components/HoldingCard/DarkUniverseHoldingCard';
import { HoldingCard } from 'components/HoldingCard/HoldingCard';
import { ResidualAssetsCard } from 'components/HoldingCard/ResidualAssetsCard';
import {
  AccountsQuery,
  useAccountsQuery,
  WrapperType,
} from 'generated/graphql';
import { filterPositions } from 'helpers/filterPositions';
import { getHoldingName } from 'helpers/instrumentNaming';
import { isDarkUniversePosition, sortPositions } from 'helpers/positionHelpers';
import { useIsAccountClosing } from 'hooks/useAccountStatus';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { SupportedWrapperType } from 'types/utilityTypes';
import { HoldingsContainer } from '../Styles/Holdings.styles';

type Position = Exclude<
  AccountsQuery['accounts'],
  undefined | null
>[number]['positions'][number];

export interface PureNewHoldingsProps {
  positions: Array<Position>;
  accountId: string;
  accountType: WrapperType;
  closingAccount?: boolean;
  pendingOrders: number;
  availableAmount: number;
}

export function PureNewHoldings({
  positions,
  accountId,
  accountType,
  closingAccount = false,
  pendingOrders,
  availableAmount,
}: PureNewHoldingsProps) {
  const [inUniversePositions, residualAssets] = filterPositions(positions);
  const theme = useTheme();
  const atLeastTablet = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const atLeastDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });

  const sorted = sortPositions(inUniversePositions);

  const residualAssetsAmount = residualAssets.reduce(
    (acc, asset) => acc + asset.currentValue,
    0
  );

  const instrumentsHeld = inUniversePositions.map((p) => p.instrument!);

  return (
    <HoldingsContainer>
      {sorted.map((position) => {
        return isDarkUniversePosition(position) ? (
          <DarkUniverseHoldingCard
            key={position.isin}
            position={position}
            accountId={accountId}
            isClosing={closingAccount}
            displayAssetName={getHoldingName(
              position.instrument!,
              instrumentsHeld
            )}
            accountType={accountType}
          />
        ) : (
          <HoldingCard
            key={position.isin}
            position={position}
            accountId={accountId}
            isClosing={closingAccount}
            displayAssetName={getHoldingName(
              position.instrument!,
              instrumentsHeld
            )}
            accountType={accountType}
            hasMeetTheManagerVideo={
              position.instrument?.asset?.meetManagerVideo !== null
            }
            atLeastTablet={atLeastTablet}
            atLeastDesktop={atLeastDesktop}
          />
        );
      })}
      {residualAssetsAmount > 0 && (
        <ResidualAssetsCard amount={residualAssetsAmount} />
      )}
      <CashHoldingCard
        accountId={accountId}
        accountType={accountType}
        closingAccount={closingAccount}
        pendingOrders={pendingOrders}
        availableAmount={availableAmount}
      />
    </HoldingsContainer>
  );
}

export interface NewHoldingsProps {
  accountType: SupportedWrapperType;
}

export function NewHoldings({ accountType }: NewHoldingsProps) {
  const accountsQuery = useAccountsQuery();
  const currentAccount = useMemo(
    () =>
      accountsQuery.data?.accounts?.find(
        (acc) => acc.wrapperType === accountType
      ),
    [accountType, accountsQuery.data?.accounts]
  );

  const availableCash = currentAccount?.valuationSummary?.uninvestedCash ?? 0;
  const isAccountClosing = useIsAccountClosing(currentAccount?.wrapperType!);
  const pendingOrders = currentAccount?.pendingBuyValue ?? 0;

  return (
    <PureNewHoldings
      positions={currentAccount?.positions!}
      accountId={currentAccount?.id!}
      accountType={currentAccount?.wrapperType!}
      closingAccount={isAccountClosing}
      pendingOrders={pendingOrders}
      availableAmount={availableCash}
    />
  );
}
