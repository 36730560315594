import { Typography } from '@material-ui/core';
import {
  OptionCard,
  OptionCardWrapper,
} from 'components/design-system/Card/OptionCard';
import { StyledLink } from 'components/design-system/Link';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  MfaMethod,
  useStartTotpMfaSetupMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { useState } from 'react';
import styled from 'styled-components';
import {
  SkipButton,
  StepContainer,
  StepContent,
  StepTitle,
} from '../../design-system/StepComponents/StepComponents';
import { SetupSmsMfaForm } from './sms/SetupSmsMfaForm';
import { SetupTotpMfaForm } from './totp/SetupTotpMfaForm';
import { TextNormal } from 'components/design-system/Text/Text';

const ChooseMfaMethodFormContainer = styled(StepContainer)`
  gap: 3rem;
`;

enum SetupMfaTypeStep {
  Choose = 'Choose',
  Sms = 'Sms',
  Totp = 'Totp',
}

export interface SetupMfaMethodFormProps {
  proceedBtnText?: string;
  onProceed: () => void;
  onSkip?: () => void;
}

export const SetupMfaMethodForm = ({
  onProceed,
  onSkip,
  proceedBtnText,
}: SetupMfaMethodFormProps) => {
  const [currentStep, setCurrentStep] = useState<SetupMfaTypeStep>(
    SetupMfaTypeStep.Choose
  );
  const [totpSecret, setTotpSecret] = useState('');
  const userProfile = useUserProfileQuery(undefined);

  const {
    mutateAsync: startTotpMfaSetupAsync,
    isError: startTotpMfaSetupError,
    isLoading: startTotpMfaSetupLoading,
  } = useStartTotpMfaSetupMutation({
    onSuccess: (data) => {
      setTotpSecret(data.startTotpMfaSetup.secretCode);
      setCurrentStep(SetupMfaTypeStep.Totp);
    },
  });

  const smsAlreadyRegistered = !!userProfile.data?.userProfile?.phoneNumbers?.find(
    (p) => p?.isVerified && p.useForTwoFactorAuthentication
  );

  const handleChooseSms = () => {
    setCurrentStep(SetupMfaTypeStep.Sms);
  };

  const handleChooseTotp = async () => {
    await startTotpMfaSetupAsync({});
  };

  if (currentStep === SetupMfaTypeStep.Sms) {
    return (
      <SetupSmsMfaForm
        switchingToExistingSms={smsAlreadyRegistered}
        onCancel={() => setCurrentStep(SetupMfaTypeStep.Choose)}
        proceedBtnText={proceedBtnText}
        onProceed={onProceed}
      />
    );
  } else if (currentStep === SetupMfaTypeStep.Totp) {
    return (
      <SetupTotpMfaForm
        onProceed={onProceed}
        totpSecret={totpSecret}
        proceedBtnText={proceedBtnText}
      />
    );
  } else {
    return (
      <ChooseMfaMethodFormContainer>
        <StepContent>
          <StepTitle>Choose how to secure your account</StepTitle>
          <TextNormal
            $noMargin
            style={{
              textAlign: 'center',
              marginBottom: '0.5rem',
            }}
          >
            Secure your account with two-step verification. Not sure which to
            pick?{' '}
            <StyledLink
              href="https://knowledge.tillitinvest.com/faqs#how-do-i-choose-between-sms-or-an-authenticator-app-as-my-two-step-verification-option"
              target="_blank"
            >
              Help me choose.
            </StyledLink>
          </TextNormal>
        </StepContent>
        <OptionCardWrapper>
          <OptionCard
            title="Authenticator app"
            extraInfo={
              userProfile.data?.userProfile?.mfaMethod === MfaMethod.Totp ? (
                <Typography>
                  <strong>This is your current method</strong>
                </Typography>
              ) : undefined
            }
            buttonLabel={
              userProfile.data?.userProfile?.mfaMethod !== MfaMethod.Totp
                ? userProfile.data?.userProfile?.mfaMethod === MfaMethod.Sms
                  ? 'Switch to authenticator app'
                  : 'Use authenticator app'
                : undefined
            }
            buttonDisabled={startTotpMfaSetupLoading}
            onClick={handleChooseTotp}
          >
            <>
              Use codes generated from an authenticator app on your mobile
              phone, like Authy or Google Authenticator.
              {startTotpMfaSetupError && <ServerError isVisible={true} />}
            </>
          </OptionCard>
          <OptionCard
            title="Text message (SMS)"
            extraInfo={
              userProfile.data?.userProfile?.mfaMethod === MfaMethod.Sms ? (
                <Typography>
                  <strong>This is your current method</strong>
                </Typography>
              ) : undefined
            }
            buttonLabel={
              userProfile.data?.userProfile?.mfaMethod !== MfaMethod.Sms
                ? userProfile.data?.userProfile?.mfaMethod === MfaMethod.Totp
                  ? 'Switch to text messages'
                  : 'Use text messages'
                : undefined
            }
            onClick={handleChooseSms}
          >
            <>
              Use codes sent to your mobile phone via text message (SMS).
              {userProfile.data?.userProfile?.mfaMethod === MfaMethod.Totp &&
                ' This will unpair your existing authenticator app.'}
            </>
          </OptionCard>
        </OptionCardWrapper>
        {onSkip && (
          <SkipButton className="richBlue" variant="outlined" onClick={onSkip}>
            Skip for now
          </SkipButton>
        )}
      </ChooseMfaMethodFormContainer>
    );
  }
};
