import { Typography } from '@material-ui/core';
import { CustomButton } from 'components/Button/CustomButton';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { History } from 'history';
import {
  dashboardTransfersPath,
  generateDashboardAccountAddCashPath,
} from 'paths';
import { useEffect } from 'react';
import { ActionContainer, DialogTitle } from './Transfer.styles';

export interface SuccessStepProps {
  wrapperType: WrapperType;
  onClose?: () => void;
  goToBrowseFunds: () => void;
  parentHistory: History;
}

export function SuccessStep({
  wrapperType,
  onClose,
  goToBrowseFunds,
  parentHistory,
}: SuccessStepProps) {
  useEffect(() => {
    parentHistory.push(dashboardTransfersPath);
  }, [parentHistory, wrapperType]);

  const handleBrowseFunds = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'Browse funds',
    });
    onClose?.();
    goToBrowseFunds();
  };

  const handleAddCash = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'transfer dialog - success',
      item_id: 'add cash button clicked',
    });
    onClose?.();
    parentHistory.push(
      generateDashboardAccountAddCashPath({
        wrapperType: getPathSegmentForWrapperType(wrapperType),
      })
    );
  };

  return (
    <>
      <DialogTitle variant="subtitle1" align="center">
        Your {wrapperType} is on the move!
      </DialogTitle>
      <Typography align="center">
        We have sent you an email confirming the details of your transfer and
        we'll be in touch as soon as we have an update.
      </Typography>

      <ActionContainer>
        {wrapperType !== WrapperType.Isa ? (
          <CustomButton
            className="richBlue"
            fullWidth
            style={{
              whiteSpace: 'break-spaces',
            }}
            onClick={handleAddCash}
          >
            Add cash
          </CustomButton>
        ) : null}

        <CustomButton
          className="magenta"
          fullWidth
          style={{
            whiteSpace: 'break-spaces',
          }}
          onClick={handleBrowseFunds}
        >
          Browse funds
        </CustomButton>
      </ActionContainer>
    </>
  );
}
