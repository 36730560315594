import { yupResolver } from '@hookform/resolvers/yup';
import { Accordion } from 'components/Accordion';
import { ControlledCheckbox } from 'components/Form/ControlledCheckbox';
import { QueryState } from 'components/QueryState';
import { StyledLink } from 'components/design-system/Link';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroductionTypography,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import {
  FontWeight,
  Text,
  TextNormal,
} from 'components/design-system/Text/Text';
import { KeyValue } from 'components/feature/PortfolioBuilder/AddToBasket/AddToBasketDialog.style';
import { colors } from 'constants/colors';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { currencyFull, date } from 'formatting';
import {
  IllustrationQuery,
  IllustrationStatus,
  useGenerateIllustrationMutation,
  useIllustrationQuery,
  useUpdateUserProfileMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { pensionIllustrationHelpLandingPage } from 'paths';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { BsFiletypePdf } from 'react-icons/bs';
import { HiChevronDown, HiExternalLink } from 'react-icons/hi';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import { FormActions } from '../_shared/FormActions';
import {
  AccordionWrapper,
  AffirmationsContainer,
  ClickHereButton,
  DownloadLayout,
  DownloadTextLayout,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './IllustrationStep.styles';
import { Spinner } from './Spinner';

function useInvalidateMutatedQueries() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(useUserProfileQuery.getKey());
    queryClient.invalidateQueries(useIllustrationQuery.getKey());
  };
}

interface CustomAccordionSummary {
  label: string;
}

/**
 *  Reusable, styled AccordionSummary
 */
const Summary = ({ label }: CustomAccordionSummary) => {
  return (
    <StyledAccordionSummary
      IconButtonProps={{
        disableRipple: true,
      }}
      expandIcon={
        <HiChevronDown size={20} style={{ color: colors.darkBlue }} />
      }
    >
      <Text $fontWeight={FontWeight.medium} $noMargin>
        {label}
      </Text>
    </StyledAccordionSummary>
  );
};

const illustrationStepFormSchema = Yup.object({
  confirmAffirmation: Yup.boolean()
    .oneOf([true], 'Please check the confirmation checkbox to continue')
    .required(),
});

type IllustrationStepFormValues = Yup.InferType<
  typeof illustrationStepFormSchema
>;

interface IllustrationStepFormProps extends IllustrationStepProps {
  data: IllustrationQuery;
  autoGenerate?: boolean;
}

export function IllustrationStepForm({
  source,
  onProceed,
  onGoBack,
  data,
  autoGenerate = true,
}: IllustrationStepFormProps) {
  const generateIllustrationRef = useRef<HTMLButtonElement | null>(null);
  const illustration = data?.illustration;
  const illustrationAccepted =
    data?.userProfile?.pensionDetails?.illustrationAccepted ?? false;

  const [isGeneratedOnFormLoad] = useState<boolean>(
    illustration?.status === IllustrationStatus.Generated
  );

  const [generateState, setGenerateState] = useState<
    'notReady' | 'triggering-generation' | 'generating' | 'generated'
  >(
    illustration?.status === IllustrationStatus.Generated
      ? 'generated'
      : 'notReady'
  );

  const invalidateQueries = useInvalidateMutatedQueries();

  const {
    mutateAsync: generateIllustration,
    isError: generateIllustrationError,
    isLoading: generateIllustrationLoading,
    reset: resetUpdateUserProfile,
  } = useGenerateIllustrationMutation();
  const {
    mutateAsync: updateUserProfile,
    isError: updateUserProfileError,
    reset: resetGenerateIllustration,
  } = useUpdateUserProfileMutation();

  const isError = generateIllustrationError || updateUserProfileError;

  useIllustrationQuery(
    {},
    {
      onSuccess(data) {
        if (data?.illustration?.status === IllustrationStatus.Generated) {
          setGenerateState('generated');
          if (data?.illustration.downloadUrl) {
            window.location.href = data.illustration.downloadUrl;
          }
        }
      },
      refetchInterval: 2000,
      enabled: generateState === 'generating',
    }
  );

  // form stuff
  const defaultValues = {
    confirmAffirmation: illustrationAccepted,
  };
  const methods = useForm<IllustrationStepFormValues>({
    defaultValues,
    resolver: yupResolver(illustrationStepFormSchema),
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const onSubmit: SubmitHandler<IllustrationStepFormValues> = async (data) => {
    resetUpdateUserProfile();
    try {
      if (isDirty) {
        await updateUserProfile({
          input: {
            pension: {
              illustrationAccepted: data.confirmAffirmation,
            },
          },
        });
        invalidateQueries();
      }

      if (source === 'openAccountStep') {
        trackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.illustration,
        });
      }

      onProceed();
    } catch {
      // error handled by state
    }
  };

  // handlers
  const handleOnGenerate = () => {
    resetGenerateIllustration();
    setGenerateState('triggering-generation');
    try {
      generateIllustration(
        {},
        {
          onSuccess: () => {
            setGenerateState('generating');
          },
        }
      );
    } catch {
      // error handled by state
    }
  };

  const getGrossAdjustedContributionString = (amount: number) => {
    const base = currencyFull(amount);

    return `${base} (+${currencyFull(amount * 0.25)} basic tax relief)`;
  };

  const getTaxAssumptions = () => {
    if (
      data.illustration?.monthlyEmployeeContribution ||
      data.illustration?.monthlyEmployerContribution ||
      data.illustration?.initialPayment
    ) {
      return (
        <div>
          <TextNormal>
            In addition, when considering your contributions we assume that:
          </TextNormal>
          <ul>
            {data.illustration?.initialPayment && (
              <Text as="li" $noMargin>
                We'll reclaim basic rate tax relief of 20% on your initial
                lump-sum payment
              </Text>
            )}
            {data.illustration?.monthlyEmployerContribution && (
              <Text as="li" $noMargin>
                Contributions made by your employer will be made gross of tax,
                and no tax reclaim is due
              </Text>
            )}
            {data.illustration?.monthlyEmployeeContribution && (
              <Text as="li" $noMargin>
                We'll reclaim basic rate tax relief of 20% on contributions made
                from your own bank account each month
              </Text>
            )}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  //This triggers the generation of the illustration when user lands on this step
  //This code is only to test the functionality and it should be refactored
  //if this is the desired functionality so that the illustration is fetched on load
  useEffect(() => {
    if (autoGenerate && generateIllustrationRef.current) {
      generateIllustrationRef.current.click();
    }
  }, [autoGenerate]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <StepContainer>
          <StepTitle>Illustration</StepTitle>
          <StepContent width={StepContentWidth.extraWide}>
            <StepIntroductionTypography>
              We're ready to generate a product illustration for your TILLIT
              Pension. Once you download it you must confirm that you have read
              and understood its contents before we continue. We'll also email
              you, should you want to read it and come back later.
            </StepIntroductionTypography>
            <StepIntroductionTypography>
              <StyledLink
                href={pensionIllustrationHelpLandingPage}
                target="_blank"
                $icon
              >
                Learn about how to understand your illustration{' '}
                <HiExternalLink />
              </StyledLink>
            </StepIntroductionTypography>
            <AccordionWrapper>
              <Accordion>
                <Summary
                  label={
                    'What assumptions are made when illustrating the TILLIT Pension?'
                  }
                />
                <StyledAccordionDetails>
                  <TextNormal>
                    For the illustration we make a few assumptions that you
                    should be aware of when assessing its contents - you can see
                    these in Section 3 of the generated document.
                  </TextNormal>
                  {getTaxAssumptions()}
                  <TextNormal>
                    If you don't agree with the assumptions made, or don't
                    understand them, you may wish to seek independent financial
                    advice to help assess the suitability of the TILLIT Pension
                    for your needs.
                  </TextNormal>
                </StyledAccordionDetails>
              </Accordion>
              <Accordion>
                <Summary
                  label={
                    'What information have I supplied that gets used in the illustration?'
                  }
                />
                <StyledAccordionDetails>
                  <TextNormal>
                    We have used the following information that you've given us
                    to generate the illustration. If any of these details are
                    wrong or have changed you can go back and amend them to
                    create a new illustration.
                  </TextNormal>

                  <KeyValue
                    label="Date of birth"
                    value={date(data?.userProfile?.dateOfBirth!)}
                  />
                  <KeyValue
                    label="Intended retirement age"
                    value={data?.userProfile?.pensionDetails?.retirementAge}
                  />
                  {data.illustration?.initialPayment?.amount && (
                    <KeyValue
                      label="Initial payment"
                      value={getGrossAdjustedContributionString(
                        data.illustration?.initialPayment?.amount
                      )}
                    />
                  )}
                  {data.illustration?.transferFromAnotherProvider?.amount && (
                    <KeyValue
                      label="Transfer from another provider"
                      value={currencyFull(
                        data.illustration.transferFromAnotherProvider?.amount
                      )}
                    />
                  )}
                  {data.illustration?.monthlyEmployeeContribution?.amount && (
                    <KeyValue
                      label="Monthly contribution from your own bank account"
                      value={getGrossAdjustedContributionString(
                        data.illustration.monthlyEmployeeContribution.amount
                      )}
                    />
                  )}
                  {data.illustration?.monthlyEmployerContribution?.amount && (
                    <KeyValue
                      label="Monthly contribution via your employment (gross)"
                      value={currencyFull(
                        data.illustration.monthlyEmployerContribution?.amount
                      )}
                    />
                  )}
                </StyledAccordionDetails>
              </Accordion>
            </AccordionWrapper>
            {generateState === 'generated' && !isGeneratedOnFormLoad && (
              <DownloadLayout>
                <BsFiletypePdf size={'2.5rem'} />
                <DownloadTextLayout>
                  <Text $fontWeight={FontWeight.medium} $noMargin>
                    Your TILLIT Pension illustration will start downloading
                    shortly
                  </Text>
                  <TextNormal $noMargin>
                    Can't see it?{' '}
                    <ClickHereButton
                      href={illustration?.downloadUrl || ''}
                      target="_blank"
                    >
                      Click here <HiExternalLink />
                    </ClickHereButton>
                  </TextNormal>
                </DownloadTextLayout>
              </DownloadLayout>
            )}
            {generateState === 'generated' && isGeneratedOnFormLoad && (
              <DownloadLayout>
                <BsFiletypePdf size={'2.5rem'} />
                <DownloadTextLayout>
                  <Text $fontWeight={FontWeight.medium} $noMargin>
                    TILLIT Pension illustration
                  </Text>
                  <TextNormal $noMargin>
                    <ClickHereButton
                      href={illustration?.downloadUrl || ''}
                      target="_blank"
                    >
                      Download
                    </ClickHereButton>
                  </TextNormal>
                </DownloadTextLayout>
              </DownloadLayout>
            )}
            {['triggering-generation', 'generating'].includes(
              generateState
            ) && (
              <DownloadLayout>
                <Spinner />
                <DownloadTextLayout>
                  <Text $fontWeight={FontWeight.medium} $noMargin>
                    Generating your TILLIT Pension product illustration
                  </Text>
                  <TextNormal $noMargin>
                    This normally just takes a few moments
                  </TextNormal>
                </DownloadTextLayout>
              </DownloadLayout>
            )}
            {generateState === 'generated' && (
              <AffirmationsContainer>
                <ControlledCheckbox
                  name="confirmAffirmation"
                  label="I confirm that I have downloaded, read and understood my illustration for the TILLIT Pension"
                  disabled={isSubmitting || illustrationAccepted}
                />
              </AffirmationsContainer>
            )}
          </StepContent>
          <ServerError isVisible={isError} />
          <StepActions>
            {generateState === 'notReady' ? (
              <>
                <StepButton
                  className="magenta"
                  onClick={handleOnGenerate}
                  disabled={generateIllustrationLoading}
                  ref={generateIllustrationRef}
                >
                  Create my TILLIT pension illustration
                </StepButton>
                <GoBackButton onClick={onGoBack} />
              </>
            ) : (
              <FormActions
                onGoBack={onGoBack}
                disabled={
                  isSubmitting ||
                  ['triggering-generation', 'generating'].includes(
                    generateState
                  )
                }
              />
            )}
          </StepActions>
        </StepContainer>
      </FormProvider>
    </form>
  );
}

interface IllustrationStepProps {
  source?: 'openAccountStep';
  onProceed: () => void;
  onGoBack: () => void;
}
export function IllustrationStep(props: IllustrationStepProps) {
  const illustrationQuery = useIllustrationQuery();

  return (
    <QueryState {...illustrationQuery}>
      {() => {
        return (
          illustrationQuery.data && (
            <IllustrationStepForm {...props} data={illustrationQuery.data} />
          )
        );
      }}
    </QueryState>
  );
}
