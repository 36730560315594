import styled from 'styled-components';

export const DarkActionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing(2.5)}px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    margin-bottom: ${(p) => p.theme.spacing(3.75)}px;
  }

  button {
    width: 100%;
    height: 38px;
    border-radius: 5px !important;

    ${(p) => p.theme.breakpoints.up('sm')} {
      width: auto;
      min-width: 180px;
      height: 34px;
    }
    span {
      gap: 10px;
      svg {
        margin-left: 0;
      }
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 24px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }
`;

export const ActionsContentGrid = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  column-gap: 20px;
  width: 100%;
  justify-items: center;
  margin: 0 auto;
  padding-top: 24px;
  ${(p) => p.theme.breakpoints.up('sm')} {
    width: 70%;
  } ;
`;
