import {
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from '@material-ui/core';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { ExclusivePill } from 'components/design-system/Pill/Pill';
import { HedgingStatus } from 'generated/graphql';
import {
  InstrumentLike,
  getInstrumentSelectorLabel,
} from 'helpers/instrumentNaming';
import _ from 'lodash';
import styled from 'styled-components';

export enum InstrumentSelectorAlignment {
  center = 'center',
  left = 'flex-start',
}

interface StyledRadioGroupProps {
  $align: InstrumentSelectorAlignment;
}

const SelectorContainer = styled.div`
  font-size: 1rem;
`;

const StyledRadioGroup = styled(RadioGroup)<StyledRadioGroupProps>`
  flex-direction: column;
  flex-wrap: nowrap;
  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: center;
    justify-content: ${({ $align }) =>
      $align === InstrumentSelectorAlignment.center ? 'center' : 'flex-start'};
    flex-wrap: wrap;
  }
`;

export interface InstrumentSelectorProps {
  instruments: InstrumentLike[];
  $align?: InstrumentSelectorAlignment;
  value?: string;
  onChange: (isin: string) => void;
}

export function InstrumentSelector({
  instruments,
  $align = InstrumentSelectorAlignment.left,
  value = '',
  onChange,
}: InstrumentSelectorProps) {
  const getHelperText = ({
    incomeAccumulationFlag,
    hedging,
  }: InstrumentLike) => {
    const lookup = {
      ['Accumulation' +
      HedgingStatus.Unhedged]: 'The accumulation share class is for investors wanting income generated by their investment to be automatically reinvested.',
      ['Accumulation' +
      HedgingStatus.Hedged]: 'The accumulation share class is for investors wanting income generated by their investment to be automatically reinvested. Hedged share classes remove the exchange movements between the currency of the share class and the currency of the underlying investments. Hedged share classes may be more expensive than unhedged share classes.',
      ['Income' +
      HedgingStatus.Unhedged]: 'When choosing this share class any income generated by the fund is paid out to you in cash. This share class can be useful for an investor who wants a regular income, such as in retirement.',
      ['Income' +
      HedgingStatus.Hedged]: 'When choosing this share class any income generated by the fund is paid out to you in cash. This share class can be useful for an investor who wants a regular income, such as in retirement. Hedged share classes remove the exchange movements between the currency of the share class and the currency of the underlying investments. Hedged share classes may be more expensive than unhedged share classes.',
    };

    return lookup[(incomeAccumulationFlag ?? '') + hedging];
  };

  return (
    <StyledRadioGroup
      onChange={(e) => onChange(e.currentTarget.value)}
      value={value}
      $align={$align}
    >
      {_(instruments)
        .orderBy([
          (i) => i.isCustomUniverse,
          (i) => i.isDarkUniverse,
          (i) => i.hedging === HedgingStatus.Hedged,
          (i) => i.incomeAccumulationFlag,
        ])
        .map((i) => (
          <FormControlLabel
            key={i.isin}
            value={i.isin}
            control={<Radio />}
            label={
              <SelectorLabel
                label={getInstrumentSelectorLabel(i, instruments)}
                isCustomUniverse={i.isCustomUniverse || false}
                helpText={getHelperText(i)}
              />
            }
          />
        ))
        .value()}
    </StyledRadioGroup>
  );
}

interface SelectorLabelProps {
  label: string;
  helpText?: string;
  isCustomUniverse: boolean;
}

function SelectorLabel({
  label,
  helpText,
  isCustomUniverse,
}: SelectorLabelProps) {
  const canHover = useMediaQuery('(hover: hover)');

  return (
    <SelectorContainer>
      {label}
      {!!helpText && (
        <>
          {' '}
          <InfoPopoverV2
            size="small"
            disablePadding
            content={helpText}
            color="default"
            $width="wide"
            placement={'top'}
          />
        </>
      )}
      {isCustomUniverse && (
        <>
          {' '}
          <ExclusivePill $canHover={canHover}>
            Employer exclusive share class
          </ExclusivePill>
        </>
      )}
    </SelectorContainer>
  );
}
