import { Container, Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const DarkHeroContainer = styled(Container)`
  position: relative;
  background-color: ${colors.richBlack};
  padding: 0;
`;

export const DarkIntroContainerInner = styled(Container)`
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${(p) => p.theme.spacing(0, 2.5, 2.5, 2.5)};

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(0, 5, 5, 5)};
  }
`;

export const DarkTitleContainer = styled.div`
  width: 100%;
  margin-bottom: 60px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    width: 80%;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 60%;
  }
`;

export const DarkFundTitle = styled(Typography)`
  font-weight: 300;
  color: ${colors.white};
  font-size: 2.625rem;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 3.25rem;
  }
`;

export const DarkActionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(p) => p.theme.spacing(1.25)}px;

  > button {
    order: 1;
    width: 100%;
    margin-bottom: ${(p) => p.theme.spacing(1.25)}px;

    &:last-child {
      order: 2;
      width: 100%;
      margin-bottom: ${(p) => p.theme.spacing(1.25)}px;
    }

    ${(p) => p.theme.breakpoints.up('sm')} {
      order: 1;
      width: auto;
      margin-right: ${(p) => p.theme.spacing(1.25)}px;
      margin-bottom: 0;
      &:last-child {
        order: 2;
        width: auto;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  ${(p) => p.theme.breakpoints.up('sm')} {
    flex-wrap: nowrap;
  }
`;
