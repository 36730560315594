import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import { BsArrowDown } from 'react-icons/bs';
import styled, { keyframes } from 'styled-components';

const floatAnimation = keyframes`
  0% { transform: translateY(0) }
  50% { transform: translateY(10px) }
  100% { transform: translateY(0) }
`;

export const Container = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // Padding top avoids content crushing to the header
  // Padding bottom acts as an offset to hide the arrow down
  padding: 100px 20px 80px 20px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 100px 30px 80px 30px;
  }
`;

export const BackLinkCopy = styled(Typography)`
  width: 100%;
  margin-top: 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: auto;
  }

  a {
    color: ${colors.richBlack};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus,
    &:visited {
      color: ${colors.richBlack};
      text-decoration: underline;
      outline: none;
    }
  }
`;

export const ContainerInnerNoVid = styled.div`
  width: 100%;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const StyledArrowDown = styled(BsArrowDown)`
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -1.25rem;
  font-size: 2.5rem;
  cursor: pointer;
  animation: ${floatAnimation} 3s ease-in-out 0s infinite;
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px 14px;
  margin-top: 1rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 500px;
    flex-direction: row;
    justify-content: center;
    button {
      flex-basis: 50%;
    }
  }
`;

export const IconWrapper = styled.div`
  width: 78px;
  height: 78px;
  > img {
    width: 100%;
    height: auto;
  }
`;

export const StyledFundName = styled(Typography)`
  font-weight: 300;
  font-style: italic;
  font-size: 1rem;
  color: ${colors.richBlack};
  margin: 2.5rem 0 0.625rem 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 1.25rem;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 1.625rem;
  }
`;

export const StyledFundOneLiner = styled(Typography)`
  font-weight: 300;
  color: ${colors.richBlack};
  font-size: 2rem;
  margin-bottom: 2rem;
  line-height: initial;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 3.25rem;
  }
`;

export const StyledFundDescription = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    position: relative;
    display: block;
    font-weight: 300;

    &:not(.noVid) {
      &:before {
        ${({ theme }) => theme.breakpoints.up('lg')} {
          left: 0;
          margin-left: 0;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: 40px;
      height: 1px;
      top: -15px;
      left: 50%;
      margin-left: -20px;
      background-color: ${colors.magenta};
      opacity: 0.3;

      ${({ theme }) => theme.breakpoints.up('lg')} {
        margin-left: -25px;
        width: 50px;
      }
    }

    div :not(:first-child) {
      display: none;
    }
  }
`;

export const ContainerInnerVid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px 0;
  max-width: 1920px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 0 60px;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 50%;
    text-align: left;
    align-items: flex-start;

    .Video-header {
      justify-content: flex-start;
    }
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 520px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 640px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 720px;
  }
`;
