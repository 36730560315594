import React from 'react';
import { HiOutlineChatAlt2 } from 'react-icons/hi';
import {
  ButtonContainer,
  HelperText,
  StyledSupportIcon,
} from './SupportIconButton.styles';

export interface SupportIconButtonProps {
  helperText?: string;
  onClick: () => void;
}

export function SupportIconButton({
  helperText,
  onClick,
}: SupportIconButtonProps) {
  return (
    <ButtonContainer>
      <StyledSupportIcon variant="contained" onClick={() => onClick()}>
        <HiOutlineChatAlt2 />
      </StyledSupportIcon>
      {helperText && <HelperText>{helperText}</HelperText>}
    </ButtonContainer>
  );
}
