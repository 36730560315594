import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { colors } from 'constants/colors';
import {
  fundListPath,
  generateAutoSaveInvestPathPath,
  generateDynamicPortfolioConstructionFundsPath,
  generateFundDetailsReturnQueryPath,
} from 'paths';
import { MdOutlineArrowBack } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { isTypeSource } from './fundDetailsTypes';

export const BackLinkCopy = styled(Typography)`
  width: 100%;
  margin-top: 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: auto;
  }

  a {
    color: ${colors.richBlack};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus,
    &:visited {
      color: ${colors.richBlack};
      text-decoration: underline;
      outline: none;
    }
  }
`;

type PathLookup = {
  'portfolio-builder-gia': string;
  'portfolio-builder-isa': string;
  'portfolio-builder-unknown': string;
  funds: string;
  similar?: string;
  'regular-order': string;
};

export const useBackLinkPath = (location: any) => {
  const urlParams = new URLSearchParams(location.search);
  const tags = urlParams.get('tags') || '';
  const search = urlParams.get('search') || '';
  const source = urlParams.get('source') || '';

  if (tags === '' && source === '') {
    return null;
  }

  const pathLookup: PathLookup = {
    'portfolio-builder-gia': generateDynamicPortfolioConstructionFundsPath({
      wrapperType: 'gia',
    }),
    'portfolio-builder-isa': generateDynamicPortfolioConstructionFundsPath({
      wrapperType: 'isa',
    }),
    'portfolio-builder-unknown': generateDynamicPortfolioConstructionFundsPath({
      wrapperType: 'unknown',
    }),
    funds: fundListPath,
    'regular-order': generateAutoSaveInvestPathPath({
      wrapperType: 'isa',
    }),
  };
  const path = pathLookup[isTypeSource(source) ? source : 'funds'];

  const queryPath = generateFundDetailsReturnQueryPath({
    searchTags: tags,
    searchTerm: search,
  });

  return {
    path,
    queryPath,
  };
};

export interface BackToResultsProps {
  $align?: 'flex-start' | 'center';
}

export function BackToResults({ $align = 'center' }: BackToResultsProps) {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const location = useLocation();
  const backLink = useBackLinkPath(location);

  if (!backLink) {
    return null;
  }

  return (
    <BackLinkCopy style={{ justifyContent: isLgUp ? $align : 'center' }}>
      <Link to={`${backLink.path}${backLink.queryPath}`}>
        <MdOutlineArrowBack /> <span>Back to results</span>
      </Link>
    </BackLinkCopy>
  );
}
