import { Container } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { useMode } from 'components/feature/mode/useMode';
import { GaEventNames, GaProperties } from 'constants/gaConstants';
import { useDepositDetailsByAccountQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import type { AccountsQueryAccount } from 'types/graphqlTypes';
import { CantDeposit } from '../newDeposit/steps/CantDeposit';
import {
  LocalRecurringDeposits,
  useAutoSaveInvestState,
} from '../AutoSaveInvestContext';
import { InstructionsStep } from '../newDeposit/steps/InstructionsStep';
import { ResumePopover } from '../resumePopover/ResumePopover';
import { ConfirmStep, ConfirmStepSuccessProps } from './steps/ConfirmStep';
import { SetupAutoInvestSuccessStep } from './steps/SetupAutoInvestSuccessStep';

const ConfirmContainer = styled(Container)`
  padding: 6rem 1rem 1rem;
`;

enum MultistepFormSteps {
  Confirm = 'Confirm',
  Instructions = 'Instructions',
  SetupAutoInvestSuccess = 'SetupAutoInvestSuccess',
}

export interface AutoSaveInvestProps {
  action: 'setup' | 'setup-auto-invest';
  account: AccountsQueryAccount;
}

export function AutoSaveInvestInner({ account, action }: AutoSaveInvestProps) {
  const {
    state,
    clearState,
    totalAllocationProportion,
    totalAllocationAmount,
  } = useAutoSaveInvestState();

  const recurringTransactionsDeposits = state?.deposits;

  const [cachedDeposits] = useState<
    LocalRecurringDeposits[] | null | undefined
  >(recurringTransactionsDeposits);

  const [activeStep, setActiveStep] = useState<MultistepFormSteps>(
    MultistepFormSteps.Confirm
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const [confirmSuccessMeta, setConfirmSuccessMeta] = useState<
    ConfirmStepSuccessProps | undefined
  >();

  const [, setMode] = useMode();

  const recurringTransactionsOrders = state?.orders;

  return (
    <ConfirmContainer>
      {activeStep === MultistepFormSteps.Confirm && (
        <ConfirmStep
          recurringTransactionsDeposits={recurringTransactionsDeposits || []}
          recurringTransactionsOrders={recurringTransactionsOrders || []}
          totalAllocationProportion={totalAllocationProportion}
          totalAllocationAmount={totalAllocationAmount}
          onProceed={(successMeta) => {
            trackGa({
              event: GaEventNames.multiStepFormStep,
              [GaProperties.form]: `auto-save-invest - ${action}`,
              [GaProperties.formStep]: 'Confirm',
            });
            trackGa({
              event: GaEventNames.multiStepFormFinish,
            });
            setConfirmSuccessMeta(successMeta);
            if (
              (state?.deposits?.filter((deposit) => !deposit.isDeleted)
                ?.length ?? 0) === 0
            ) {
              setActiveStep(MultistepFormSteps.SetupAutoInvestSuccess);
            } else {
              setActiveStep(MultistepFormSteps.Instructions);
            }
            clearState();

            setMode({ mode: 'resume' });
          }}
          account={account}
        />
      )}

      {activeStep === MultistepFormSteps.Instructions &&
        account.id &&
        cachedDeposits && (
          <InstructionsStep
            wrapperType={account.wrapperType}
            accountId={account.id}
            deposits={cachedDeposits.filter((deposit) => !deposit.isDeleted)}
            confirmSuccessMeta={confirmSuccessMeta}
          />
        )}
      {activeStep === MultistepFormSteps.SetupAutoInvestSuccess && (
        <SetupAutoInvestSuccessStep
          wrapperType={account.wrapperType}
          confirmSuccessMeta={confirmSuccessMeta!}
        />
      )}

      {(activeStep === MultistepFormSteps.Instructions ||
        activeStep === MultistepFormSteps.SetupAutoInvestSuccess) && (
        <ResumePopover wrapperType={account.wrapperType} />
      )}
    </ConfirmContainer>
  );
}

export function AutoSaveInvestConfirm({
  account,
  action,
}: AutoSaveInvestProps) {
  const depositDetailsByAccountQuery = useDepositDetailsByAccountQuery(
    {
      id: account.id as string,
    },
    { enabled: typeof account.id === 'string' }
  );
  const depositDetailsByAccount =
    depositDetailsByAccountQuery.data?.depositDetailsByAccount;

  const canDeposit = depositDetailsByAccount?.canDeposit;
  const reason = depositDetailsByAccount?.statusReason;

  return (
    <QueryState {...depositDetailsByAccountQuery}>
      {() => {
        if (!canDeposit && reason) {
          return <CantDeposit reason={reason} />;
        }

        return <AutoSaveInvestInner account={account} action={action} />;
      }}
    </QueryState>
  );
}
