import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { StepIntroductionTypography } from 'components/design-system/StepComponents/StepComponents';
import { wrapperNameFriendly } from 'components/feature/Transfers/startTransferForm/steps/_shared/WrapperNameUtils';
import { ComboBoxInput } from 'components/Form/ComboBoxInput';
import { QueryState } from 'components/QueryState';
import { SupportIconButton } from 'components/Support/SupportIconButton';
import { GaEventNames } from 'constants/gaConstants';
import {
  InSpecieSupport,
  TransferMethod,
  useTransferProvidersQuery,
  WrapperType,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import * as yup from 'yup';
import { CantFindYourProvider } from './Create.styles';
import {
  AccountNumberHelpText,
  DialogTitle,
  FormContainer,
  NoOptionCopy,
  StyledInput,
  TransferCopyWrapper,
} from './Transfer.styles';
import { TransferCashCopy, TransferHoldingsCopy } from './TransferCopy';
import {
  TransferAsButton,
  TransferButtonsWrapper,
} from './TransferCopy.styles';

const CEDING_PROVIDER_ACCOUNT_NUMBER__MIN_LENGTH = 2;
const CEDING_PROVIDER_ACCOUNT_NUMBER__MAX_LENGTH = 20;

const accountNumberSchema = yup
  .string()
  .trim()
  .min(
    CEDING_PROVIDER_ACCOUNT_NUMBER__MIN_LENGTH,
    `Please enter at least ${CEDING_PROVIDER_ACCOUNT_NUMBER__MIN_LENGTH} characters`
  )
  .max(
    CEDING_PROVIDER_ACCOUNT_NUMBER__MAX_LENGTH,
    `Please enter a maximum of ${CEDING_PROVIDER_ACCOUNT_NUMBER__MAX_LENGTH} characters`
  )
  .required('Please provide your account number');

const transferSchema = yup.object().shape({
  cedingProviderAccountNumber: accountNumberSchema,
  authorise: yup
    .boolean()
    .oneOf([true], 'Please check the authorise checkbox')
    .required('Please check the authorise checkbox'),
  cedingProvider: yup
    .object()
    .shape({
      id: yup.string(),
      name: yup.string(),
      helpText: yup.string().nullable(),
    })
    .required('Please provide the ceding provider name'),
  transferMethod: yup.string().oneOf(['CASH', 'IN_SPECIE']).required(),
  accept: yup
    .boolean()
    .oneOf([true], 'Please check the accept checkbox')
    .when('transferMethod', {
      is: 'IN_SPECIE',
      then: (s) => s.required('Please check the accept checkbox'),
    }),
  confirmReadAndUnderstood: yup
    .boolean()
    .oneOf([true], 'Please check the read and understood checkbox')
    .when('transferMethod', {
      is: 'IN_SPECIE',
      then: (s) => s.required('Please check the read and understood checkbox'),
    }),
});

interface CedingProvider {
  id: string;
  name: string;
  helpText: string;
  inSpecieSupport: InSpecieSupport;
}

interface FormValues {
  cedingProviderAccountNumber: string;
  authorise: boolean;
  accept?: boolean;
  confirmReadAndUnderstood?: boolean;
  cedingProvider: CedingProvider;
  transferMethod: 'CASH' | 'IN_SPECIE';
}

export type OnSubmittedCallback = (
  cedingProviderId: string,
  cedingProviderAccountNumber: string,
  method: TransferMethod
) => void | Promise<void>;

export interface CreateProps {
  wrapperType: WrapperType;
  onSubmitted?: OnSubmittedCallback;
  skipSuccessStep: boolean;
  onClose?: () => void;
}

export function Create({
  wrapperType,
  onSubmitted,
  skipSuccessStep,
  onClose,
}: CreateProps) {
  const history = useHistory();
  const methods = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(transferSchema),
    defaultValues: {
      cedingProvider: {},
      authorise: false,
      accept: false,
      confirmReadAndUnderstood: false,
    },
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState,
    unregister,
    setValue,
  } = methods;

  const transferMethod = watch('transferMethod');
  const setTransferMethod = (value?: 'CASH' | 'IN_SPECIE') => {
    setValue('transferMethod', value);
  };

  const onSubmit = async (data: FormValues) => {
    try {
      await onSubmitted?.(
        data.cedingProvider.id,
        data.cedingProviderAccountNumber,
        data.transferMethod === 'IN_SPECIE'
          ? TransferMethod.InSpecie
          : TransferMethod.Cash
      );

      if (skipSuccessStep) {
        onClose?.();
      } else {
        history.push(`success`);
      }
    } catch {
      history.push(`error`);
    }
  };

  const { showNewMessages } = useIntercom();
  const transferProvidersQuery = useTransferProvidersQuery({ wrapperType });

  const cedingProvider = watch('cedingProvider');
  const cedingProviderName = cedingProvider?.name;
  const cedingProviderHelpText = cedingProvider?.helpText;
  const cedingProviderInSpecieSupport =
    cedingProvider?.inSpecieSupport ?? InSpecieSupport.MaybeSupported;

  const cedingProviderAccountNumber = watch('cedingProviderAccountNumber');

  const transferDetailsValid =
    cedingProvider?.id &&
    accountNumberSchema.isValidSync(cedingProviderAccountNumber);

  const handleSupportButton = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: 'transfer dialog - create - help with transfer',
    });
    showNewMessages('I need help with my transfer.');
  };

  const handleCantFindYourProviderButton = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: "transfer dialog - create - can't find provider",
    });

    showNewMessages("I need help with my transfer, I can't find my provider");
  };

  const handleSwitchForm = (transferMethod?: 'CASH' | 'IN_SPECIE') => {
    setTransferMethod(transferMethod);
    unregister('authorise');
    unregister('accept');
    unregister('confirmReadAndUnderstood');
  };

  const handleIntercomClick = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: 'transfer dialog - create - provider does not support inspecie',
    });

    const wrapperName = wrapperNameFriendly(wrapperType);

    showNewMessages(
      `I am interested in transferring my ${wrapperName} holdings from ${cedingProvider.name}.`
    );
  };

  return (
    <>
      <DialogTitle variant="subtitle1" align="center">
        Transfer my {wrapperType} to TILLIT
      </DialogTitle>
      <StepIntroductionTypography>
        Can't find your provider?{' '}
        <CantFindYourProvider
          type="button"
          onMouseDown={handleCantFindYourProviderButton}
        >
          Let us know
        </CantFindYourProvider>{' '}
        where you want to transfer from and our Transfers Concierge team will
        look into this for you.
      </StepIntroductionTypography>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="hidden"
            id="transferMethod"
            name="transferMethod"
            ref={register}
          />
          <FormContainer>
            <QueryState {...transferProvidersQuery}>
              {() => (
                <>
                  <Controller
                    control={control}
                    id="cedingProvider"
                    name="cedingProvider"
                    render={({ onChange, value }) => {
                      return (
                        <ComboBoxInput
                          label="Provider name"
                          options={
                            transferProvidersQuery.data?.transferProviders!
                          }
                          value={value}
                          optionLabel={(option) => option.name}
                          onChange={(ev, option) => {
                            if (option === null) {
                              handleSwitchForm();
                            }
                            onChange(option);
                          }}
                          noOptionText={
                            <NoOptionCopy>
                              Can't find your provider?{' '}
                              <CantFindYourProvider
                                type="button"
                                onMouseDown={handleCantFindYourProviderButton}
                              >
                                Let us know
                              </CantFindYourProvider>{' '}
                              where you want to transfer from and our Transfers
                              Concierge team will look into this for you.
                            </NoOptionCopy>
                          }
                        />
                      );
                    }}
                  />
                </>
              )}
            </QueryState>
            <StyledInput
              inputRef={register}
              id="cedingProviderAccountNumber"
              name="cedingProviderAccountNumber"
              label="Account number"
              fullWidth={true}
            />
            {cedingProviderHelpText && (
              <AccountNumberHelpText>
                {cedingProviderHelpText}
              </AccountNumberHelpText>
            )}
          </FormContainer>

          <TransferButtonsWrapper expanded={!!transferMethod}>
            <TransferAsButton
              disabled={!transferDetailsValid}
              onClick={() => handleSwitchForm('CASH')}
              isSelected={transferMethod === 'CASH'}
              className={clsx(transferMethod === 'CASH' && 'is-selected')}
            >
              Transfer as cash
            </TransferAsButton>
            {cedingProviderInSpecieSupport !== InSpecieSupport.NotSupported ? (
              <TransferAsButton
                disabled={!transferDetailsValid}
                onClick={() => handleSwitchForm('IN_SPECIE')}
                className={clsx(
                  transferMethod === 'IN_SPECIE' && 'is-selected'
                )}
              >
                Transfer my holdings
              </TransferAsButton>
            ) : (
              <TransferAsButton
                disabled={!transferDetailsValid}
                onClick={() => handleIntercomClick()}
              >
                Transfer my holdings
              </TransferAsButton>
            )}
          </TransferButtonsWrapper>

          <TransferCopyWrapper>
            <TransferCashCopy
              wrapperType={wrapperType}
              cedingProviderName={cedingProviderName}
              expanded={transferMethod === 'CASH'}
              createTransferLoading={formState.isSubmitting}
            />
            <TransferHoldingsCopy
              wrapperType={wrapperType}
              cedingProviderName={cedingProviderName}
              expanded={transferMethod === 'IN_SPECIE'}
              createTransferLoading={formState.isSubmitting}
            />
          </TransferCopyWrapper>
        </form>
      </FormProvider>

      <SupportIconButton
        helperText="Need help?"
        onClick={handleSupportButton}
      />
    </>
  );
}
