import { yupResolver } from '@hookform/resolvers/yup';
import { deriveMinTradeableAmount } from 'components/Dialogs/Trading/helpers';
import { QueryState } from 'components/QueryState';
import { Alert, Severity } from 'components/design-system/Alert/Alert';
import {
  CustomButtonV2,
  LinkCustomButton,
} from 'components/design-system/Button/CustomButtonV2';
import { H4, H6 } from 'components/design-system/Heading/Heading';
import { TargetDateInfoReturnContextProvider } from 'components/feature/FundDetails/hooks/useTargetDateInfo';
import type {
  AutoSaveInvestAccountState,
  LocalRecurringDeposits,
} from 'components/feature/autoSaveInvest/AutoSaveInvestContext';
import { useMode } from 'components/feature/mode/useMode';
import { colors } from 'constants/colors';
import {
  WrapperType,
  useInstrumentsByIsinsQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { useToggle } from 'hooks/useFeatureToggle';
import { keyBy, mapValues } from 'lodash';
import sumBy from 'lodash/sumBy';
import RecentlyViewedFundsSlice from 'pages/FundDetails/Slices/RecentlyViewedFundsSlice';
import { generateAutoSaveInvestSubPath } from 'paths';
import { createPortal } from 'react-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import type {
  AccountsQueryAccount,
  AccountsQueryAccountPositionInstrument,
  InstrumentsByIsinsQueryInstrumentsInstrument,
} from 'types/graphqlTypes';
import * as Yup from 'yup';
import {
  LocalRecurringOrder,
  useAutoSaveInvestState,
} from '../../AutoSaveInvestContext';
import {
  ListContainer,
  NoFundsSelectedWrapper,
  Section,
  StepLayout,
} from '../SelectFundsPercentages.styles';
import { AlertLink } from '../_shared/styles';
import { deriveFormState } from '../helpers/deriveFormState';
import type { RegularOrderLineFormValues } from '../types';
import { SelectFundsPercentagesStepCard } from './SelectFundsPercentagesCard';
import { SelectRegularDeposits } from './SelectRegularDeposits';

const regularOrderLineSchema = Yup.object().shape({
  included: Yup.string(),
  amount: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .label('Amount')

    .when(['isDeleted', '$skipAmountValidation'], {
      is: (isDeleted: 'false' | 'true', skipAmountValidation: boolean) =>
        isDeleted === 'false' && skipAmountValidation === false,
      then: Yup.number()
        .test(
          'isTradable',
          'Amount must be higher than the minimum tradeable amount for this instrument',
          (value, { options }) => {
            if (!options.context?.showMinTradeUnitStatus) {
              return true;
            }

            const minTradeableAmount =
              // @ts-ignore
              options.context?.instruments[options?.parent?.isin];
            return !!value && value >= minTradeableAmount;
          }
        )
        .min(0.01, 'The amount must be more than 0')
        .required(),
    }),
  percentage: Yup.number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .label('Percentage')
    .when('included', {
      is: 'true',
      then: Yup.number()
        .min(0.001, 'The amount must be more than 0.001')
        .required(),
    }),
});

const selectFundsLinesSchema = Yup.object().shape({
  lines: Yup.array(regularOrderLineSchema).test({
    name: 'sum',
    message: 'The sum of the percentage for all instruments 100',
    test: (lines: any) => {
      return sumBy(lines, (b: any) => b.percentage ?? 0) <= 100;
    },
  }),
});

export const CardPerformanceContainer = styled.div`
  text-align: center;
  position: relative;
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  p {
    flex-basis: 50%;
    &:first-child {
      position: relative;
      &::after {
        position: absolute;
        top: 0;
        right: -1rem;
        content: '';
        width: 1px;
        height: 100%;
        background-color: ${colors['magenta-100']};
      }
    }
  }
`;

export interface SelectFundsPercentagesStepProps {
  recurringTransactionsDeposits: LocalRecurringDeposits[] | null;
  recurringTransactionsOrders: LocalRecurringOrder[] | null;
  account: AccountsQueryAccount;
}

export interface SelectFundsPercentagesInnerProps
  extends SelectFundsPercentagesStepProps {
  recurringTransactionsOrdersInstruments: InstrumentsByIsinsQueryInstrumentsInstrument[];
  setState: (state: AutoSaveInvestAccountState) => void;
  clearState: () => void;
  totalDepositAmount: number;
}

interface FormValues {
  lines: RegularOrderLineFormValues[];
}

export function SelectFundsPercentagesInner({
  recurringTransactionsDeposits,
  recurringTransactionsOrders,
  account,
  recurringTransactionsOrdersInstruments,
  setState,
  clearState,
  totalDepositAmount,
}: SelectFundsPercentagesInnerProps) {
  const [, setMode] = useMode();
  const userProfileQuery = useUserProfileQuery();
  const history = useHistory();
  const [showMinTradeUnitStatus] = useToggle(
    'global-show-min-trade-unit-status'
  );

  const accountPositions = account.positions
    ?.map(({ instrument }) => instrument)
    .filter(
      (instrument) => instrument !== null && instrument !== undefined
    ) as AccountsQueryAccountPositionInstrument[];

  const defaultLines = deriveFormState(
    recurringTransactionsDeposits,
    recurringTransactionsOrders,
    account.positions,
    recurringTransactionsOrdersInstruments
  );

  const instruments = [
    ...recurringTransactionsOrdersInstruments,
    ...accountPositions,
  ];

  const {
    control,
    register,
    handleSubmit,
    setValue,
    errors,
    getValues,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(selectFundsLinesSchema),
    mode: 'onBlur',
    defaultValues: {
      lines: defaultLines,
    },
    context: {
      skipAmountValidation: account.wrapperType === WrapperType.Sipp,
      showMinTradeUnitStatus: !!showMinTradeUnitStatus?.enabled,
      instruments: mapValues(
        keyBy(instruments, 'isin'),
        ({ askPrice, minimumTradeUnit }) =>
          deriveMinTradeableAmount({ askPrice, minimumTradeUnit })
      ),
    },
  });

  const fieldArrayMethod = useFieldArray<RegularOrderLineFormValues>({
    control,
    name: 'lines',
  });
  const { fields, remove, move } = fieldArrayMethod;

  const onSubmit = (data: FormValues) => {
    const newRecurringTransactionsOrders = data.lines?.map(
      (selectedRegularOrder) => ({
        isin: selectedRegularOrder.isin,
        proportion: parseFloat(selectedRegularOrder.percentage) / 100,
        isDeleted: selectedRegularOrder.isDeleted === 'true' ? true : false,
        isEdited: selectedRegularOrder.isEdited === 'true' ? true : false,
        isNew: selectedRegularOrder.isNew === 'true' ? true : false,
      })
    );
    setState({
      deposits: recurringTransactionsDeposits,
      orders: newRecurringTransactionsOrders,
    });

    history.push({
      pathname: generateAutoSaveInvestSubPath({
        wrapperType: getPathSegmentForWrapperType(account.wrapperType),
        action: 'confirm',
      }),
    });
  };

  const watchedLines = watch('lines');

  const updateState = () => {
    const newRecurringTransactionsOrders = watchedLines?.map(
      (selectedRegularOrder) => ({
        isin: selectedRegularOrder.isin,
        proportion: parseFloat(selectedRegularOrder.percentage) / 100,
        isDeleted: selectedRegularOrder.isDeleted === 'true' ? true : false,
        isEdited: selectedRegularOrder.isEdited === 'true' ? true : false,
        isNew: selectedRegularOrder.isNew === 'true' ? true : false,
      })
    );
    setState({
      deposits: recurringTransactionsDeposits,
      orders: newRecurringTransactionsOrders,
    });
  };

  const anyEditedOrDeleted = watchedLines.some(
    (field) =>
      field.isDeleted === 'true' ||
      field.isEdited === 'true' ||
      field.isNew === 'true'
  );

  const handleAbandonChanges = () => {
    const abandon = window.confirm(
      'Are you sure that you want to abandon your changes?'
    );
    if (abandon) {
      clearState();
      setMode(null);
    }
  };

  const modeFooterActionEl = document.getElementById('mode-footer--action');

  return (
    <>
      <QueryState {...userProfileQuery}>
        {() => {
          return (
            <>
              <StepLayout>
                <Section>
                  <ListContainer $smallGap>
                    {anyEditedOrDeleted && (
                      <Alert severity={Severity.info}>
                        <span>
                          You have made changes - you need to click Continue for
                          them to take effect. Or:{' '}
                          <AlertLink onClick={handleAbandonChanges}>
                            abandon your changes
                          </AlertLink>
                          .
                        </span>
                      </Alert>
                    )}
                    <SelectRegularDeposits account={account} />
                  </ListContainer>
                </Section>
              </StepLayout>
              {fields.length === 0 ? (
                <>
                  <StepLayout>
                    <Section>
                      <H6>My regular investments</H6>

                      <NoFundsSelectedWrapper>
                        <H4>No funds selected</H4>
                        <LinkCustomButton
                          to={generateAutoSaveInvestSubPath({
                            wrapperType: getPathSegmentForWrapperType(
                              account.wrapperType
                            ),
                            action: 'add-funds',
                          })}
                        >
                          Add funds
                        </LinkCustomButton>
                      </NoFundsSelectedWrapper>
                    </Section>
                  </StepLayout>
                  <TargetDateInfoReturnContextProvider>
                    <RecentlyViewedFundsSlice headingWrapper="h5" />
                  </TargetDateInfoReturnContextProvider>
                </>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <StepLayout>
                    <Section>
                      <ListContainer>
                        <H6>My regular investments</H6>

                        {fields.map((field, index) => {
                          const instrument = instruments.find(
                            (instrument) => instrument.isin === field.isin
                          );

                          if (!instrument) return null;

                          return (
                            <SelectFundsPercentagesStepCard
                              key={field.id}
                              index={index}
                              field={field}
                              instrument={instrument}
                              register={register}
                              wrapperType={account.wrapperType}
                              accountPositions={accountPositions}
                              setValue={setValue}
                              remove={remove}
                              move={move}
                              totalDepositAmount={totalDepositAmount}
                              errors={errors.lines?.[index]}
                              linesError={errors.lines}
                              getLines={() => getValues().lines || []}
                              onChange={() => updateState()}
                              watchIsDeleted={
                                watch(`lines[${index}].isDeleted`) as
                                  | 'true'
                                  | 'false'
                              }
                              watchIsEdited={
                                watch(`lines[${index}].isEdited`) as
                                  | 'true'
                                  | 'false'
                              }
                              watchIsNew={
                                watch(`lines[${index}].isNew`) as
                                  | 'true'
                                  | 'false'
                              }
                              watchAmount={
                                watch(`lines[${index}].amount`) as number
                              }
                            />
                          );
                        })}
                      </ListContainer>
                    </Section>
                  </StepLayout>
                  {modeFooterActionEl &&
                    createPortal(
                      <CustomButtonV2
                        type="button"
                        onClick={() => {
                          handleSubmit(onSubmit)();
                        }}
                      >
                        Continue
                      </CustomButtonV2>,
                      modeFooterActionEl
                    )}
                </form>
              )}
            </>
          );
        }}
      </QueryState>
    </>
  );
}

export interface SelectFundsSelectedFundsFormProps {
  account: AccountsQueryAccount;
}

export function SelectFundsSelectedFundsForm({
  account,
}: SelectFundsSelectedFundsFormProps) {
  const userProfileQuery = useUserProfileQuery();

  const {
    state,
    setState,
    clearState,
    totalDepositAmount,
  } = useAutoSaveInvestState();

  const recurringTransactionsDeposits = state?.deposits ?? null;
  const recurringTransactionsOrders = state?.orders ?? null;

  const isins = recurringTransactionsOrders?.map(({ isin }) => isin);

  const instrumentsByIsinQuery = useInstrumentsByIsinsQuery(
    { isins: isins },
    {
      enabled: isins && isins?.length > 0,
    }
  );

  return (
    <QueryState {...instrumentsByIsinQuery}>
      {() => (
        <QueryState {...userProfileQuery}>
          {() =>
            state !== null ? (
              <SelectFundsPercentagesInner
                recurringTransactionsDeposits={recurringTransactionsDeposits}
                recurringTransactionsOrders={recurringTransactionsOrders}
                account={account}
                recurringTransactionsOrdersInstruments={
                  instrumentsByIsinQuery.data?.instrumentsByIsins?.nodes || []
                }
                setState={setState}
                clearState={clearState}
                totalDepositAmount={totalDepositAmount}
              />
            ) : null
          }
        </QueryState>
      )}
    </QueryState>
  );
}
